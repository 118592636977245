import { AfterViewChecked, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';


/**
 * Base Component
 */
export class BaseComponent implements AfterViewChecked, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  alteredFields = [];
  authenticationService;

  constructor(authenticationService?, alteredFields?) {
    this.authenticationService = authenticationService || null;
    this.alteredFields = alteredFields || [];
  }

  /**
   * After View Checked
   *
   * Hide elements or set them readonly
   */
  ngAfterViewChecked() {
    this.alteredFields.forEach(role => {
      if (role.role) {
        if (this.authenticationService.isRoleAuthorized([role.role])) {
          if (role.hidden) {
            role.hidden.forEach(item => {
              let element = document.getElementsByName(item);

              if (element && element[0]) {
                let parentElement = element[0].parentNode.parentNode;
                parentElement.parentNode.removeChild(parentElement);
              }
            });
          }

          if (role.readonly) {
            role.readonly.forEach((item) => {
              let element = document.getElementsByName(item);

              if (element && element[0]) {
                element[0].setAttribute('disabled', 'true');
              }
            });
          }
        }
      }
    });
  }

  /**
   * On Destroy
   *
   * Cleanup and un-subscribe from subscriptions
   */
  ngOnDestroy() {
    this.destroy$.next(true);

    this.destroy$.unsubscribe();
  }
}
