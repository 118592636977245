import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { CommonModalComponent } from '../modal/child.modal';
import { ArticleService } from '../../services/article.service';
import { Article } from '../../models/article.model';
import { PackingUnit } from '../../models/packing-unit.model';
import { SupplierService } from '../../services/supplier.service';
import { PackingService } from '../../services/packing.service';
import { StockingService } from '../../services/stocking.service';
import { HubService } from '../../services/hub.service';
import { MessageService, MessageSummary } from '../../services/message.service';
import { AuthenticationService, ROLE_SUPPLIER } from '../../services/authentication.service';

@Component({
  selector: 'app-article-modal',
  templateUrl: './article-modal.component.html',
  styleUrls: ['./article-modal.component.scss']
})
export class ArticleModalComponent {
  @ViewChild('addArticleModal')
  public addArticleModal: CommonModalComponent;

  @ViewChild('articleForm')
  public articleForm: NgForm;

  get isEdit(): boolean {
    return this._isEdit;
  }

  @Input()
  set isEdit(value: boolean) {
    this._isEdit = value;
  }

  @Input()
  public readonly: boolean;

  @Input()
  public hideSupplierInput: boolean;

  @Input()
  public isRoleAdministrator: boolean;

  @Input()
  public article: Article;

  @Output()
  public onSave: EventEmitter<Article> = new EventEmitter<Article>();

  public validationErrors: string[] = [];
  public suppliers: SelectItem[] = [];
  public stockings: SelectItem[] = [];
  public isActives: SelectItem[] = [];
  public packingList: SelectItem[];
  public hubList: SelectItem[];

  private _isEdit: boolean = false;
  private editSupplier: boolean = true;

  // @todo the user model does not exist in this application
  private user: any;

  constructor(private authService: AuthenticationService,
              protected articleService: ArticleService,
              private packingService: PackingService,
              private supplierService: SupplierService,
              private hubService: HubService,
              private messageService: MessageService,
              private stockingService: StockingService,
              private confirmationService: ConfirmationService) {
  }

  public ngOnInit(): void {
    this.user = this.authService.getUser();

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.editSupplier = false;
    }

    this.addArticleModal.onHidden(() => {
      this.articleForm.resetForm();
      this.validationErrors = [];
    });

    this.supplierService.list().subscribe((suppliers) => {
      this.suppliers = suppliers;
    });

    this.packingService.list().subscribe((packings) => {
      this.packingList = packings;
      this.packingList.push({ 'value': 99999, 'label': 'anders' });
    });

    this.hubService.list().subscribe((hubs) => {
      this.hubList = hubs;
    });

    this.stockings = this.stockingService.getStockings();
    this.isActives = this.articleService.getIsActives();
  }

  public handleError(errorResponse: any, message: string = 'Kan geen nieuw artikel aanmaken.'): void {
    this.validationErrors = [];

    if (errorResponse.error && errorResponse.error.message) {
      message = errorResponse.error.message;
    }

    this.messageService.warning(MessageSummary['SOMETHING_WENT_WRONG'], message);
  }

  public addDefaultColloValues(unit): void {
    switch (unit.name) {
      case 6:
        // Europallet
        unit.length = 80;
        unit.width = 120;
        break;
      case 9:
        // Blockpallet
        unit.length = 100;
        unit.width = 120;
        break;
      case 99999:
        // Anders
        unit.is_other = true;
        unit.name = '';
        break;
      default:
        break;
    }
  }

  public submitForm(form: FormGroup): void {
    if (form.valid) {
      if (this.article.packing_units.length === 0) {
        this.messageService.error(MessageSummary['ARTICLE'], 'Voeg minimaal één verpakkingseenheid toe');

        return;
      }

      const data = { ...this.article };

      if (this.isEdit === true) {
        this.articleService.updateArticle(data).subscribe(() => {
          this.articleForm.resetForm();
          this.addArticleModal.hide();
          this.onSave.emit();
        }, (error: any) => {
          this.handleError(error);
        });
      } else {
        this.articleService.createArticle(data).subscribe((result: any) => {
          this.articleForm.resetForm();
          this.addArticleModal.hide();

          const article = { ...result } as Article;
          article.packing_units = result.packing_units.data;

          this.onSave.emit(article);
        }, (error: any) => {
          this.handleError(error);
        });
      }
    } else {
      this.messageService.error(MessageSummary['ARTICLE'], 'Vul alle verpichte velden in (*)');
    }
  }

  public showModal(): void {
    setTimeout(() => {
      if (this.isEdit) {
        this.article.packing_units.map((unit: PackingUnit) => {
          const packingUnit = this.packingList.find((packing: SelectItem) => {
            return packing.label === unit.name;
          });

          if (packingUnit) {
            unit.is_other = false;
            unit.name = packingUnit.value;
          } else {
            unit.is_other = true;
          }

          return unit;
        });
      }

      this.addArticleModal.show();
    }, 200);
  }

  public addPackingUnit(): void {
    const packingUnit = new PackingUnit();
    packingUnit.article_id = this.article.id;
    packingUnit.is_other = false;

    this.article.packing_units.push(packingUnit);
  }

  public confirmDeletePackingUnit(index: number): void {
    let message = 'Weet u zeker dat u deze verpakkingseenheid wilt verwijderen?';
    const packingUnit = this.article.packing_units[index];

    if (packingUnit.id) {
      message += ' Let op; deze wijziging wordt pas doorgevoerd wanneer er op "Opslaan" geklikt wordt.';
    }

    this.confirmationService.confirm({
      key: 'common',
      message: message,
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deletePackingUnit(index);
      }
    });
  }

  public onlyAllowNumbers(event: any, allowDecimals: boolean = false): number {
    let pattern = /^[e,.]/;

    if (allowDecimals === true) {
      pattern = /^[e.]/;
    }

    const regex = new RegExp(pattern);

    if (event.type === 'keydown') {
      if (regex.test(event.key)) {
        // invalid character, prevent input
        event.preventDefault();
        event.stopPropagation();
      }
    } else if (event.type === 'paste') {
      const clipboardData = event.clipboardData.getData('Text');
      let numbers = '';

      for (let i = 0; i < clipboardData.length; i++) {
        if (!regex.test(clipboardData[i])) {
          numbers += clipboardData[i];
        }
      }

      event.preventDefault();
      event.stopPropagation();

      return Number(numbers) < 0 ? 0 : Number(numbers);
    }
  }

  private deletePackingUnit(index: number): void {
    this.article.packing_units.splice(index, 1);
  }
}
