import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class PickupOrderService extends ApiService {

  public store(data): Observable<any> {
    return this.post('pickup-orders', data);
  }
}
