/* tslint:disable:variable-name */
/**
 * Model DeliveryAgreement
 */
export class DeliveryAgreement {
  id?: number;
  day: string;
  time: string;
  address_id: number;
}
