import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/primeng';

import { SupplierService } from '../../../../services/supplier.service';
import { SharedService } from '../../../../services/shared.service';
import { AuthenticationService, ROLE_SUPPLIER } from '../../../../services/authentication.service';
import { BaseComponent } from '../../../../base.component';
import { MessageService, MessageSummary } from '../../../../services/message.service';

@Component({
  selector: 'supplier-details-administrative',
  templateUrl: './supplier-details-administrative.component.html',
  styleUrls: ['./supplier-details-administrative.scss']
})
export class SupplierDetailsAdministrativeComponent extends BaseComponent implements OnInit {

  @ViewChild('form') form;

  @Input() supplier;
  @Input() defaults;

  validationErrors: any = [];
  editMessage: Message[];
  public isRoleSupplier: boolean = false;
  public readonly: boolean = false;

  constructor(
    public sharedService: SharedService,
    protected supplierService: SupplierService,
    protected confirmationService: ConfirmationService,
    protected elRef: ElementRef,
    protected renderer: Renderer2,
    private messageService: MessageService,
    private authService: AuthenticationService) {
    super(authService, [
      {
        'role': ROLE_SUPPLIER,
        'hidden': [],
        'readonly': [
          'vat_number',
          'iban_number',
          'coc_number',
          'email_finance',
          'payment_agreement',
          'invoice_term'
        ]
      }
    ]);
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }
  }

  public onSubmit(form, callback: any = null): void {
    if (form.valid) {
      // Add this attribute to notify backend we're editing administrative data
      this.supplier.update_administrative = true;

      this.supplierService.updateSupplier(this.supplier).subscribe((result: any) => {
        if (result) {
          this.supplier = result;

          if (typeof callback === 'function') {
            callback();
          } else {
            this.validationErrors = [];
            this.messageService.success(MessageSummary['OK'], 'Gegevens opgeslagen');
          }
        } else {
          this.handleError(true);
        }
      }, (error: any) => this.handleError(error));
    } else {
      this.handleError(false, 'Controleer uw invoer.');
    }
  }

  private handleError(error: any, message = 'Opslaan mislukt') {
    this.validationErrors = [];

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        // Remove class 'invalid' for all inputs
        let inputs = this.elRef.nativeElement.querySelectorAll('.form-control');
        inputs.forEach((input) => {
          this.renderer.removeClass(input, 'invalid');
        });

        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);

              // Add class 'invalid' to input
              let el = this.elRef.nativeElement.querySelector('[name=' + field + ']');
              this.renderer.addClass(el, 'invalid');
            });
          }
        }
      }
    }

    this.messageService.error(MessageSummary['SAVE_ADMINISTRATIVE_DATA'], message);

  }
}
