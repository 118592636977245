/* tslint:disable:variable-name */
import { ContactPerson } from './contactPerson.model';
import { AdditionalSellRate } from './additionalSellRate.model';
import { WhitelistedIp } from './whitelisted-ip.model';
import { SupplierFieldMapping } from './supplierFieldMapping';

/**
 * Model Supplier
 */
export class Supplier {
  id?: number;
  hub_ids: number;
  preferred_transporter_id: number;
  to_postnl: boolean;
  can_bundle: boolean;
  is_paying: boolean;
  code: string;
  name: string;
  addresses: any;
  email?: string;
  email_finance?: string;
  phone?: string;
  payment_agreement: string;
  main_contactperson: ContactPerson;
  contactpersons: { data: ContactPerson[] };
  invoice_term: string;
  vat_number?: string;
  iban_number?: string;
  coc_number?: string;
  remarks?: string;
  created_at: string | Date;
  additionalSellRates: AdditionalSellRate[];
  pivot?: any;
  whitelisted_ips?: { data: WhitelistedIp[] };
  field_mapping?: SupplierFieldMapping;
  has_api?: string;
  default_article_id: number;

  constructor() {
    this.payment_agreement = 'direct';
    this.invoice_term = 'per_shipment';
    this.to_postnl = true;
  }
}
