import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClientAddComponent } from '../client-add/client-add.component';

import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_SUPPLIER
} from '../../../services/authentication.service';
import { ClientsService } from '../../../services/clients.service';
import { SharedService } from '../../../services/shared.service';

@Component({
  selector: 'client-list-component',
  templateUrl: './client-list.html',
  styleUrls: ['./client-list.scss']
})
export class ClientListComponent {
  @ViewChild(ClientAddComponent) clientAddComponent: ClientAddComponent;

  records = [];
  totalRecords = 0;
  loading = true;
  defaults = {
    clientCode: '',
    countries: [],
    contactTypes: [],
    clientTypes: [],
    payment_agreements: [],
    suppliers: [],
    transporters: [],
    customers: [],
    hubs: []
  };
  meta = {
    clientCode: '',
    countries: {},
    contactTypes: {},
    types: [],
    payment_agreements: [],
    suppliers: [],
    hubs: [],
    transporters: [],
    customers: []
  };
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public paginationAmount: number = 30;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected clientsService: ClientsService,
    protected sharedService: SharedService,
    protected authService: AuthenticationService) {
  }

  ngOnInit() {
    this.isRoleSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);
    this.paginationAmount = this.sharedService.getRowsPerPage();

    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.router.navigate([this.authService.getUser().client_id], { relativeTo: this.route });
    }
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  loadData(event) {
    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      return;
    }

    this.loading = true;

    this.clientsService.getClients(event, event.first / event.rows + 1, event.rows).subscribe((result) => {
      this.loading = false;
      this.records = result.data;
      this.meta = result.meta;
      this.defaults.clientCode = this.meta.clientCode;
      this.defaults.countries = this.sharedService.mapObjectToArray(this.meta.countries);
      this.defaults.payment_agreements = this.sharedService.mapObjectToArray(this.meta.payment_agreements);
      this.defaults.suppliers = this.sharedService.mapObjectToSelectItems(this.meta.suppliers);
      this.defaults.hubs = this.sharedService.mapObjectToSelectItems(this.meta.hubs);
      this.defaults.transporters = this.sharedService.mapObjectToSelectItems(this.meta.transporters);
      this.defaults.customers = this.sharedService.mapObjectToSelectItems(this.meta.customers);
      this.totalRecords = result.pagination.total;
    });
  }

  onRowSelect(event) {
    this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  addClient(event) {
    this.clientAddComponent.showModal();
  }
}
