import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Supplier } from '../../../models/supplier.model';
import { SharedService } from '../../../services/shared.service';
import { ConfirmationService, LazyLoadEvent, SelectItem } from 'primeng/primeng';
import {
  AuthenticationService,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_TRANSPORTER
} from '../../../services/authentication.service';
import { AdditionalSellRateService } from '../../../services/additional-sell-rate.service';
import { SupplierService } from '../../../services/supplier.service';
import { ClientsService } from '../../../services/clients.service';
import { AdditionalSellRateSupplier } from '../../../models/additionalSellRateSupplier.model';
import { MessageService } from '../../../services/message.service';
import { Client } from '../../../models/client.model';
import { AdditionalSellRateModalComponent } from '../additional-sell-rate-modal/additional-sell-rate-modal.component';
import * as moment from 'moment';
import { HubService } from '../../../services/hub.service';

@Component({
  selector: 'app-additional-sell-rates-list',
  templateUrl: './additional-sell-rates-list.component.html',
  styleUrls: ['./additional-sell-rates-list.component.scss']
})
export class AdditionalSellRatesListComponent implements OnInit {
  public additionalSellRateModal: AdditionalSellRateModalComponent;

  @ViewChild('additionalSellRateModal') set content(content: AdditionalSellRateModalComponent) {
    this.additionalSellRateModal = content;
  }

  get supplier(): Supplier {
    return this._supplier;
  }

  @Input()
  set supplier(value: Supplier) {
    this._supplier = value;

    if (value.contactpersons) {
      this.contactpersonList = [{
        label: 'Geen',
        value: null
      }];

      this.contactpersonList = this.contactpersonList.concat(value.contactpersons.data.map((contactperson) => {
        return {
          label: contactperson.full_name,
          value: contactperson.id
        };
      }));
    }
  }

  get client(): Client {
    return this._client;
  }

  @Input()
  set client(value: Client) {
    this._client = value;

    if (value.contactpersons) {
      this.contactpersonList = [{
        label: 'Geen',
        value: null
      }];

      this.contactpersonList = this.contactpersonList.concat(value.contactpersons.data.map((contactperson) => {
        return {
          label: contactperson.full_name,
          value: contactperson.id
        };
      }));
    }
  }

  public records: AdditionalSellRateSupplier[];
  public paginationAmount: number = 30;
  public totalRecords: number = 0;
  public loading: boolean;
  public filters: { [key: string]: { value?: string | number | string[], matchMode: string } } = {};
  public hubList: SelectItem[];
  public clientList: SelectItem[];
  public supplierList: SelectItem[];
  public addressList: SelectItem[];
  public contactpersonList: SelectItem[];
  public additionalSellRateSupplier: AdditionalSellRateSupplier;
  public additionalSellRateDate?: Date;
  public lazyLoadEvent: LazyLoadEvent;
  public tableVisible: boolean;
  public modalVisible: boolean;
  public isClientOrSupplier: boolean;
  public isRoleTransporter: boolean;
  public isRoleLogisticEmployee: boolean;
  public isRoleHubOperator: boolean;

  public readonlyModal: boolean;

  private _supplier: Supplier;
  private _client: Client;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private supplierService: SupplierService,
    private clientService: ClientsService,
    private hubService: HubService,
    private authService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private additionalSellRateService: AdditionalSellRateService,
    private messageService: MessageService) {
    this.additionalSellRateSupplier = new AdditionalSellRateSupplier();
    this.tableVisible = true;
    this.modalVisible = false;

    this.isRoleTransporter = this.authService.isRoleAuthorized([ROLE_TRANSPORTER]);
    this.isRoleLogisticEmployee = this.authService.isRoleAuthorized([ROLE_LOGISTIC_EMPLOYEE]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);
  }

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.loadData({});

    this.hubService.list().subscribe((hubs) => {
      this.hubList = hubs;
      this.hubList.unshift({ label: 'Alle', value: null });
    });

    this.isClientOrSupplier = this.client !== undefined || this.supplier !== undefined;
  }

  public loadData(event: LazyLoadEvent): void {
    this.lazyLoadEvent = event;
    this.loading = true;

    let getData = null;

    if (this.supplier) {
      getData = this.additionalSellRateService.getAdditionalSellRates(
        event,
        event.first / event.rows + 1,
        event.rows,
        'supplier',
        this.supplier.id
      );
    } else if (this.client) {
      getData = this.additionalSellRateService.getAdditionalSellRates(
        event,
        event.first / event.rows + 1,
        event.rows,
        'client',
        this.client.id
      );
    } else {
      getData = this.additionalSellRateService.getAdditionalSellRates(event, event.first / event.rows + 1, event.rows);
    }

    getData.subscribe((response) => {
      this.records = response.data;
      this.totalRecords = response.pagination.total;

      if (response.meta.addresses) {
        this.addressList = [{
          value: null,
          label: 'Geen'
        }];

        this.addressList = this.addressList.concat(response.meta.addresses);
      }

      if (response.meta.clients) {
        this.clientList = this.sharedService.mapObjectToSelectItems(response.meta.clients, 'Alle');
      }

      if (response.meta.suppliers) {
        this.supplierList = this.sharedService.mapObjectToSelectItems(response.meta.suppliers, 'Alle');
      }

      this.loading = false;
      this.tableVisible = true;
    }, (error) => {
      this.messageService.handleError(error, 'Extra diensten ophalen', 'Extra diensten ophalen mislukt.');
      this.loading = false;
      this.tableVisible = true;
    });
  }

  public addAdditionalSellRate(): void {
    this.additionalSellRateSupplier = new AdditionalSellRateSupplier();
    this.readonlyModal = false;

    if (this.client) {
      this.additionalSellRateSupplier.client_id = this.client.id;
    }

    if (this.supplier) {
      this.additionalSellRateSupplier.supplier_id = this.supplier.id;
    }

    // Auto select HUB when there's only 1
    if (this.hubList && this.hubList.length === 1) {
      this.additionalSellRateSupplier.hub_id = this.hubList[0].value;
    }

    this.modalVisible = true;

    setTimeout(() => {
      this.additionalSellRateModal.showModal();
    }, 0);
  }

  public editAdditionalSellRate(
    additionalSellRateSupplier: AdditionalSellRateSupplier,
    readonly: boolean = false
  ): void {
    this.additionalSellRateSupplier = {
      id: additionalSellRateSupplier.id,
      type: additionalSellRateSupplier.sell_rate.type,
      name: additionalSellRateSupplier.sell_rate.name,
      sell_rate_id: additionalSellRateSupplier.sell_rate_id,
      supplier_id: additionalSellRateSupplier.supplier_id,
      client_id: additionalSellRateSupplier.client_id,
      hub_id: additionalSellRateSupplier.hub_id,
      load_address_id: additionalSellRateSupplier.load_address_id,
      address_id: additionalSellRateSupplier.address_id,
      contactperson_id: additionalSellRateSupplier.contactperson_id,
      is_periodic: additionalSellRateSupplier.is_periodic,
      frequency: additionalSellRateSupplier.frequency,
      amount: additionalSellRateSupplier.amount,
      unit: additionalSellRateSupplier.sell_rate.unit,
      price: additionalSellRateSupplier.sell_rate.price,
      description: additionalSellRateSupplier.description,
      exported_at: additionalSellRateSupplier.exported_at
    };

    this.readonlyModal = readonly;

    if (additionalSellRateSupplier.date) {
      this.additionalSellRateSupplier.date = moment(additionalSellRateSupplier.date).toDate();
    }

    if (additionalSellRateSupplier.end_date) {
      this.additionalSellRateSupplier.end_date = moment(additionalSellRateSupplier.end_date).toDate();
    }

    this.modalVisible = true;

    setTimeout(() => {
      this.additionalSellRateModal.showModal();
    }, 0);
  }

  public confirmDelete(id: number): void {
    this.confirmationService.confirm({
      key: 'additional-sell-rate',
      message: 'Weet u zeker dat u deze extra dienst wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteAdditionalSellRate(id);
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  public updateTable(): void {
    this.loadData(this.lazyLoadEvent);
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  private deleteAdditionalSellRate(id: number): void {
    let deleteMethod = null;
    const additionalSellRateSupplier = this.records.find(t => t.id === id);

    if (additionalSellRateSupplier.supplier_id) {
      deleteMethod = this.supplierService.deleteAdditionalSellRate(additionalSellRateSupplier.supplier_id, id);
    } else {
      deleteMethod = this.clientService.deleteAdditionalSellRate(additionalSellRateSupplier.client_id, id);
    }

    deleteMethod.subscribe(() => {
      this.loadData(this.lazyLoadEvent);
    }, (error: any) => {
      this.messageService.handleError(error, 'Extra dienst verwijderen', 'Er is een fout opgetreden.');
    });
  }
}
