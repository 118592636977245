import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined } from 'util';

import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_FINANCIAL_EMPLOYEE,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER,
  ROLE_TRANSPORTER
} from '../../../services/authentication.service';
import { SupplierService } from '../../../services/supplier.service';
import { SharedService } from '../../../services/shared.service';
import { Supplier } from '../../../models/supplier.model';
import { StateService } from '../../../services/state.service';
import { TabsComponent } from '../../../components/tabs/tabs.component';
import { MessageService, MessageSummary } from '../../../services/message.service';

@Component({
  selector: 'supplier-details-component',
  templateUrl: './supplier-details.component.html',
  styleUrls: ['./supplier-details.component.scss']
})
export class SupplierDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('administrativeTab') administrativeTab;
  @ViewChild('shipmentsTab') shipmentsTab;
  @ViewChild('contactpersonsTab') contactpersonsTab;
  @ViewChild('ordersTab') ordersTab;
  @ViewChild('exportedInvoicesTab') exportedInvoicesTab;
  @ViewChild('additionalSellRatesTab') additionalSellRatesTab;
  @ViewChild(TabsComponent) tabs: TabsComponent;

  public supplier: Supplier = new Supplier();
  public isRoleCustomer: boolean = false;
  public isRoleTransporter: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public isRoleManager: boolean = false;
  public loading: boolean = false;
  public defaults = {
    paymentAgreements: [],
    invoiceTerms: [],
    addressTypes: [],
    countries: [],
    transporters: [],
    communicationTypes: [],
    hubs: []
  };

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    protected supplierService: SupplierService,
    protected authService: AuthenticationService,
    private stateService: StateService,
    private messageService: MessageService,
    private sharedService: SharedService) {
  }

  public ngOnInit(): void {
    this.loading = true;
    this.isRoleCustomer = this.authService.isRoleAuthorized([ROLE_CUSTOMER]);
    this.isRoleTransporter = this.authService.isRoleAuthorized([ROLE_TRANSPORTER]);
    this.isRoleSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);
    this.isRoleManager = this.authService.isRoleAuthorized([ROLE_MANAGER]);

    this.initContactperson();
  }

  public ngAfterViewInit(): void {
    this.activeRoute.queryParams.subscribe(params => {
      switch (params['tab']) {
        case 'contactperson':
          this.tabs.selectTab(this.contactpersonsTab);
          break;
        case 'order':
          this.tabs.selectTab(this.ordersTab);
          break;
        case 'shipment':
          this.tabs.selectTab(this.shipmentsTab);
          break;
        case 'exported-invoices':
          this.tabs.selectTab(this.exportedInvoicesTab);
          break;
        default:
          break;
      }
    });
  }

  public initContactperson(): void {
    this.activeRoute.params.subscribe((params: any) => {
      let id = params['id'];
      this.supplier.id = id.split('?')[0];

      this.loading = true;
      this.supplierService.getSupplier(this.supplier.id).subscribe((supplier: any) => {
          this.loading = false;

          this.supplier = supplier;
          this.supplier.has_api = supplier.has_api;
          this.supplier.addresses = supplier.addresses.data.map((address) => {
            address.delivery_agreements = address.delivery_agreements.data;

            return address;
          });

          this.stateService.setCurrentSupplier(this.supplier);

          if (supplier.hubs.data !== undefined) {
            this.supplier.hub_ids = supplier.hubs.data.map((hub) => {
              return hub.id;
            });
          }

          this.defaults.paymentAgreements = this.sharedService.mapObjectToArray(supplier.meta.paymentAgreements);
          this.defaults.invoiceTerms = this.sharedService.mapObjectToArray(supplier.meta.invoiceTerms);
          this.defaults.addressTypes = this.sharedService.mapObjectToArray(supplier.meta.addressTypes);
          this.defaults.countries = this.sharedService.mapObjectToArray(supplier.meta.countries);
          this.defaults.transporters = this.sharedService.mapObjectToArray(supplier.meta.transporters);
          this.defaults.communicationTypes = this.sharedService.mapObjectToArray(supplier.meta.communicationTypes);
          this.defaults.hubs = this.sharedService.mapObjectToSelectItems(supplier.meta.hubs);

          this.loading = false;
        },
        (error: any) => this.handleError(error));
    });
  }

  public onTabSelect(activeTab) {
    let viewChild = activeTab.id + 'Tab';

    if (!isUndefined(this[viewChild]) && !isUndefined(this[viewChild].onTabSelected)) {
      this[viewChild].onTabSelected(activeTab.action);
    }
  }

  public showAdditionalSellRatesTab(): boolean {
    return this.authService.isRoleAuthorized([
      ROLE_MANAGER,
      ROLE_LOGISTIC_EMPLOYEE,
      ROLE_FINANCIAL_EMPLOYEE,
      ROLE_TRANSPORTER,
      ROLE_HUB_OPERATOR
    ]);
  }

  public showShipmentsTab(): boolean {
    // Hide this tab for now as requested in SIM-353
    return false;
  }

  private handleError(error: any, message = 'Kan leverancier niet openen') {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (errorMessage) {
          message += '<br>' + result.errors[errorMessage][0];
        });
      }
    }

    this.messageService.error(MessageSummary['SOMETHING_WENT_WRONG'], message);
  }
}
