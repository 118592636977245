import { RouterModule, Routes } from '@angular/router';
import { SuppliersComponent } from './suppliers.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';

const SUPPLIERS_ROUTES: Routes = [
  {
    path: '',
    component: SuppliersComponent,
    children: [
      {
        path: '',
        component: SupplierListComponent
      },
      {
        path: ':id',
        component: SupplierDetailsComponent
      },
      {
        path: ':id/:tab',
        component: SupplierDetailsComponent
      }
    ]
  }
];

export const SUPPLIERS_ROUTING = RouterModule.forChild(SUPPLIERS_ROUTES);
