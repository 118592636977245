/* tslint:disable:variable-name */
/**
 * Model PackingUnit
 */
export class PackingUnit {
  public id?: number;
  public article_id: number;
  public name: string;
  public weight: any;
  public size?: number;
  public volume?: any;
  public length: number;
  public width: number;
  public height: number;
  public num_articles?: number;
  public is_other?: boolean;
  public label?: string;
  public value?: number;
}
