import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterMetadata, LazyLoadEvent, SelectItem } from 'primeng/primeng';
import { CommonModalComponent } from '../../../../../components/modal/child.modal';
import { AuthenticationService, ROLE_SUPPLIER } from '../../../../../services/authentication.service';
import { InvoicesService } from '../../../../../services/invoices.service';
import { MessageService, MessageSummary } from '../../../../../services/message.service';
import { StateService } from '../../../../../services/state.service';
import { OrderService } from '../../../../../services/order.service';
import { SharedService } from '../../../../../services/shared.service';
import { Order } from '../../../../../models/order.model';
import { Supplier } from '../../../../../models/supplier.model';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-supplier-detail-order-list',
  templateUrl: './supplier-detail-order-list.component.html',
  styleUrls: ['./supplier-detail-order-list.component.scss']
})
export class SupplierDetailOrderListComponent implements OnInit, OnDestroy {

  @ViewChild('exportModal')
  public exportModal: CommonModalComponent;

  @ViewChild('importModal')
  public importModal: CommonModalComponent;

  public supplier: Supplier;
  public records: Order[];
  public totalRecords: number;
  public loading: boolean;
  public exportLoading: boolean;
  public selectedOrder: Order;
  public isRoleSupplier: boolean = false;
  public readonly: boolean = false;
  public orderStatuses: SelectItem[];
  public selectedOrderStatus: string;
  public tableVisible: boolean;
  public importErrors: string[];
  public paginationAmount: number = 30;
  public filters: { [key: string]: FilterMetadata } = {};
  public selectedCreatedAtFilter: string;
  private destroy$ = new Subject<boolean>();
  private selectedOrderId: number;

  constructor(
    public sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private stateService: StateService,
    private orderService: OrderService,
    private invoicesService: InvoicesService,
    private messageService: MessageService,
    private authService: AuthenticationService) {
    this.tableVisible = true;
  }

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    this.stateService.getCurrentSupplier()
      .pipe(takeUntil(this.destroy$))
      .filter((supplier) => !!supplier)
      .subscribe((supplier) => {
        this.supplier = supplier;
      });

    this.route.queryParams.subscribe((params) => {
      if (params['id'] && params['tab'] === 'order') {
        this.selectedOrderId = params['id'];

        if (this.records && this.records.length > 0) {
          this.selectedOrder = this.records.find((order) => order.id === +this.selectedOrderId);

          if (this.selectedOrder) {
            this.editOrder(this.selectedOrder.id);
          }

          this.selectedOrderId = null;
        }

      }
    });

    this.sharedService.getEnumOptions('orders', 'status').subscribe((orderStatuses) => {
      this.orderStatuses = [];

      for (let key in orderStatuses) {
        if (orderStatuses.hasOwnProperty(key)) {
          this.orderStatuses.push({ label: orderStatuses[key], value: key });
        }
      }
    });
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(event: LazyLoadEvent): void {
    this.loading = true;

    this.filters = event.filters;

    this.orderService.getOrdersOfSuppliers(event, this.supplier.id)
      .subscribe((response) => {
        this.records = response.data;
        this.totalRecords = response.pagination.total;
        this.loading = false;

        if (this.selectedOrderId) {
          this.selectedOrder = this.records.find((order) => order.id === +this.selectedOrderId);

          if (this.selectedOrder) {
            this.editOrder(this.selectedOrder.id);
          }

          this.selectedOrderId = null;
        }
      }, error => {
        this.sharedService.handleError(error, 'Opdrachten ophalen');
        this.loading = false;
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public onRowClick(event: any): void {
    if (event !== undefined) {
      if (event.originalEvent.target.classList.contains('fas') || event.originalEvent.target.classList.contains('fa')) {
        return;
      }
    }

    this.selectedOrder = event.data;

    this.editOrder(this.selectedOrder.id);
  }

  public editOrder(orderId: number): void {
    this.sharedService.openNewWindow(`pages/orders/${orderId}`);
  }

  /**
   * Open the order export modal
   */
  public openExportModal(): void {
    this.exportModal.show();
  }

  /**
   * Open the order import modal
   */
  public openImportModal(): void {
    this.importModal.show();
  }

  /**
   * Get the export data of the given order range
   *
   * @param data
   */
  public handleExportSubmit(data: { startDate: Date, endDate: Date }): void {
    this.exportLoading = true;

    this.invoicesService.post('invoices', {
      start_date: moment(data.startDate).format('Y-M-D'),
      end_date: moment(data.endDate).format('Y-M-D'),
      supplier_id: this.supplier.id
    }).subscribe((invoice: any) => {
      this.exportLoading = false;
      this.exportModal.hide();

      if (invoice.id) {
        this.router.navigate([`/pages/suppliers/${this.supplier.id}`], {
          queryParams: {
            tab: 'exported-invoices',
            id: invoice.id
          }
        });
      } else {
        this.messageService.warning(MessageSummary['EXPORT'], 'Er zijn geen te factureren zendingen.');
      }
    }, () => {
      this.exportLoading = false;
      this.messageService.error(MessageSummary['EXPORT_FAILED'], 'Er is fout opgetreden bij het exporteren.');
    });
  }

  /**
   * Import given CSV file
   *
   * @param file
   */
  public handleImportSubmit(file: File): void {
    this.orderService.import(file, this.supplier.id).subscribe((response) => {
      if (response.status === 'error') {
        this.messageService.error(MessageSummary['IMPORT_FAILED'], 'Controleer uw importbestand.');
        this.importErrors = response.errors;

        return;
      }

      this.importModal.hide();
      this.messageService.success(MessageSummary['OK'], 'Import uitgevoerd');

      // Refresh orders table
      this.tableVisible = false;
      setTimeout(() => {
        this.tableVisible = true;
      }, 0);
    }, (error) => {
      this.messageService.handleError(error, 'Importeren mislukt.');
    });
  }
}
