export enum UserRole {
  ROLE_MANAGER = 1,
  ROLE_LOGISTIC_EMPLOYEE = 2,
  ROLE_FINANCIAL_EMPLOYEE = 3,
  ROLE_CUSTOMER = 4,
  ROLE_SUPPLIER = 5,
  ROLE_TRANSPORTER = 6,
  ROLE_HUB_OPERATOR = 7,
  ROLE_DELIVERER = 8
}
