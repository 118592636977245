import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Client } from '../../models/client.model';
import { Address } from '../../models/address.model';
import { Shipment } from '../../models/shipment.model';
import { Order } from '../../models/order.model';
import { SelectItem } from 'primeng/primeng';
import { ShipmentService } from '../../services/shipment.service';
import { FormGroup, NgForm } from '@angular/forms';
import { Collo } from '../../models/collo.model';
import * as moment from 'moment';
import { SharedService } from '../../services/shared.service';
import { MessageService, MessageSummary } from '../../services/message.service';
import { TransporterService } from '../../services/transporter.service';
import { Observable } from 'rxjs';
import { PackingService } from '../../services/packing.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-add-shipment-modal',
  templateUrl: './add-shipment-modal.component.html',
  styleUrls: ['./add-shipment-modal.component.scss']
})
export class AddShipmentModalComponent implements OnInit {
  @ViewChild('shipmentForm')
  public shipmentForm: NgForm;
  @Input()
  public selectedOrders: Order[];

  @Output()
  public onCancel = new EventEmitter<void>();

  @Output()
  public onSuccess = new EventEmitter<Shipment>();

  public shipment: Shipment = new Shipment();
  public selectedClient: Client = null;
  public selectedAddress: Address = null;
  public colliTableVisible: boolean;
  public packingList$: Observable<SelectItem[]>;
  public payerList: SelectItem[];
  public colloSupplierList: SelectItem[];
  public trackAndTraceOptions: SelectItem[];
  public transporterList: SelectItem[] = [];
  public validationErrors: string[] = [];
  public loading: boolean;
  public minDate = new Date();
  public tomorrow = new Date();
  public selectedLoadDate: Date = null;
  public selectedDischargeDate: Date = null;
  public calendarLocale: any;

  constructor(private shipmentService: ShipmentService,
              private messageService: MessageService,
              private transporterService: TransporterService,
              private packingService: PackingService,
              private router: Router,
              private sharedService: SharedService) {
  }

  public ngOnInit(): void {
    this.selectedClient = this.selectedOrders[0].client;
    this.selectedAddress = this.selectedOrders[0].address;
    this.payerList = this.shipmentService.getPayerList();
    this.fillTransporters();
    this.calendarLocale = this.sharedService.getCalendarLocale();
    this.packingList$ = this.packingService.list();

    this.trackAndTraceOptions = [
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Ja',
        value: true,
      },
    ];

    const colloSupplierList = this.selectedOrders.map((order: Order) => {
      return {
        label: order.supplier.name,
        value: order.supplier.id
      };
    });

    this.colloSupplierList = [];
    colloSupplierList.forEach((supplier: SelectItem) => {
      const supplierExists = this.colloSupplierList.find(colloSupplier => colloSupplier.value === supplier.value);

      if (!supplierExists) {
        this.colloSupplierList.push(supplier);
      }
    });

    this.createShipment();
  }

  public addDefaultColloValues(collo: Collo): void {
    switch (collo.packing_id) {
      case 6:
        // Europallet
        collo.length = 80;
        collo.width = 120;
        break;
      case 9:
        // Blockpallet
        collo.length = 100;
        collo.width = 120;
        break;
      default:
        break;
    }
  }

  public onPayerChange(colloIndex: number, payer: string): void {
    if (payer === 'client') {
      this.shipment.colli[colloIndex].supplier_id = null;
      this.shipment.colli[colloIndex].client_id = this.shipment.client_id;
    } else {
      this.shipment.colli[colloIndex].client_id = null;
    }
  }

  public deleteCollo(index: number): void {
    let colli = [...this.shipment.colli];

    // Delete collo
    colli.splice(index, 1);

    // Ugly hack to remove form control for collo
    const controlIndex = index + 1;
    delete this.shipmentForm.controls['height_' + controlIndex];
    delete this.shipmentForm.controls['weight_' + controlIndex];
    delete this.shipmentForm.controls['length_' + controlIndex];
    delete this.shipmentForm.controls['width_' + controlIndex];
    delete this.shipmentForm.controls['supplier_' + controlIndex];
    delete this.shipmentForm.controls['payer_' + controlIndex];
    delete this.shipmentForm.controls['packing_' + controlIndex];

    this.shipment.colli = colli;
  }

  public addCollo(): void {
    let colli = [...this.shipment.colli];

    // Add new collo
    let newCollo = new Collo();
    newCollo.amount = 1;
    newCollo.packing_id = null;
    newCollo.payer = this.selectedClient.customer || 'client';

    if (newCollo.payer === 'supplier' && this.colloSupplierList.length === 1) {
      newCollo.supplier_id = this.colloSupplierList[0].value;
    } else if (newCollo.payer === 'client') {
      newCollo.client_id = this.selectedClient.id;
    }

    colli.push(newCollo);

    this.shipment.colli = colli;
  }

  public selectDate(event: Date, field: string): void {
    let momentDate = moment(event);
    this.shipment[field] = momentDate.format('YYYY-MM-DD');
  }

  public submitShipment(form: FormGroup): void {
    if (form.valid) {
      this.shipmentService.addShipment(this.shipment).subscribe((result) => {
        this.selectedClient = null;
        this.selectedAddress = null;
        this.selectedOrders = [];
        this.selectedOrders = [];

        this.onSuccess.emit(result);

      }, (error: any) => this.handleError(error));
    } else {
      this.handleError(null, 'SAVE_SHIPMENT', 'Vul a.u.b. alle verplichte velden in.');
    }
  }

  public isPostNL(): boolean {
    if (this.shipment.transporter_id) {
      return this.shipment.transporter_id === 1;
    }

    return false;
  }

  public isDeudekom(): boolean {
    if (this.shipment.transporter_id) {
      return this.shipment.transporter_id === 3;
    }

    return false;
  }

  public hideModal(foo: string) {

  }

  public createShipment(): void {
    this.shipmentService.getColli(this.selectedOrders).subscribe((result) => {
      if (result.colli) {
        let supplierIds = this.selectedOrders.map((order) => {
          return order.supplier_id;
        });

        // Get only unique suppliers
        supplierIds = Array.from(new Set(supplierIds));

        this.shipment.colli = result.colli.map((collo) => {
          collo.payer = this.selectedClient.customer || 'client';

          if (collo.payer === 'supplier' && supplierIds.length === 1) {
            collo.supplier_id = supplierIds[0];
          } else if (collo.payer === 'client') {
            collo.client_id = this.selectedClient.id;
          }

          return collo;
        });
      }

      // Set default load-/discharge date of shipment to preferred delivery date of order
      if (this.selectedOrders[0].preferred_delivery_date) {
        const momentDate = moment(this.selectedOrders[0].preferred_delivery_date);

        this.selectedLoadDate = momentDate.toDate();
        this.selectedDischargeDate = momentDate.toDate();
        this.shipment.load_date = momentDate.format('YYYY-MM-DD');
        this.shipment.discharge_date = momentDate.format('YYYY-MM-DD');
      }

      this.shipment.volume = result.shipment.volume;
      this.shipment.weight = result.shipment.weight;
      this.shipment.num_colli = result.shipment.num_colli;
      this.shipment.transporter_id = result.shipment.transporter_id;

      this.shipment.client_id = this.selectedClient.id;
      this.shipment.address_id = this.selectedAddress.id;
      this.shipment.orders = this.selectedOrders;

      this.shipment.has_track_and_trace = result.shipment.has_track_and_trace ?  result.shipment.has_track_and_trace : false;
      this.shipment.track_and_trace_email = result.shipment.track_and_trace_email ?  result.shipment.track_and_trace_email : this.selectedOrders[0].contactperson ? this.selectedOrders[0].contactperson.email : null;

      this.payerList = this.shipmentService.getPayerList();
      const colloSupplierList = this.selectedOrders.map((order: Order) => {
        return {
          label: order.supplier.name,
          value: order.supplier.id
        };
      });

      this.colloSupplierList = [];
      colloSupplierList.forEach((supplier: SelectItem) => {
        const supplierExists = this.colloSupplierList.find(colloSupplier => colloSupplier.value === supplier.value);

        if (!supplierExists) {
          this.colloSupplierList.push(supplier);
        }
      });

      // Hack to disable autocomplete on datepicker in Chrome
      [].forEach.call(document.querySelectorAll('.ui-calendar input'), function (element) {
        element.setAttribute('autocomplete', 'off');
      });
      // this.orderService.getOrder(this.order.id).subscribe((data: any) => this.processOrderRequest(data));

    });
  }

  private fillTransporters(): void {
    let orderIds: number[] = [];
    if(this.selectedOrders) {
      orderIds = this.selectedOrders.map((order) => {
        return order.id;
      });
    } else if (this.shipment && this.shipment.orders) {
      orderIds = this.shipment.orders.map((order) => {
        return order.id;
      });
    }

    this.transporterService.list(orderIds).subscribe((transporters) => {
      this.transporterList = transporters;
    });
  }

  private handleError(error: any, summary: string = 'SAVE_SHIPMENT', message: string = 'Kan geen nieuwe zending aanmaken.') {
    this.validationErrors = [];
    this.loading = false;

    if (error) {
      const result = error.error;

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);
            });
          }
        }
      }
    }

    this.messageService.error(MessageSummary[summary], message);
  }
}
