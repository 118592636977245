import { NgModule } from '@angular/core';

import { ModalModule } from 'ngx-bootstrap';
import { SharedModule } from '../../shared.module';

import { ARTICLE_ROUTING } from './article.routing';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { ArticleImportComponent } from './article-import/article-import.component';

@NgModule({
  declarations: [
    ArticleListComponent,
    ArticleDetailComponent,
    ArticleImportComponent
  ],
  imports: [
    SharedModule,
    ARTICLE_ROUTING,
    ModalModule
  ]
})
export class ArticleModule {
}
