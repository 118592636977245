import { Component, Input } from '@angular/core';
import { Client } from '../../../../models/client.model';

@Component({
  selector: 'app-client-detail-additional-sell-rates',
  templateUrl: './client-detail-additional-sell-rates.html',
  styleUrls: ['./client-detail-additional-sell-rates.scss']
})
export class ClientDetailAdditionalSellRatesComponent {
  @Input() public client: Client;

  constructor() {

  }
}
