import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class CountryService extends ApiService {

  public getCountries(): Observable<any> {
    return this.get('countries');
  }
}

