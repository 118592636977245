
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Order } from '../models/order.model';
import { LazyLoadEvent } from 'primeng/primeng';
import { Article } from '../models/article.model';
import { Response } from '@angular/http';

interface TableFilter {
  [key: string]: { value?: string | number, matchMode: string };
}

@Injectable()
export class OrderService extends ApiService {
  private filters$: Subject<TableFilter> = new BehaviorSubject(null);

  public setFilters(filters: TableFilter): void {
    this.filters$.next(filters);
  }

  public get filters(): Observable<TableFilter> {
    return this.filters$.asObservable();
  }

  public getOrders(data: any, page: number = 1, perPage: number = 20, status = ''): Observable<any> {
    this.setFilters(data.filters);

    return this.post(`order/filter/${status}?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public getOrder(id: number): Observable<any> {
    return this.get(`order/${id}`);
  }

  public addOrder(order: Order): Observable<any> {
    const newOrder = JSON.parse(JSON.stringify(order));

    return this.post('order', newOrder);
  }

  public updateOrder(data: Order): Observable<any> {
    return this.put(`order`, data);
  }

  public deleteOrder(id: number): Observable<any> {
    return this.delete(`order/${id}`);
  }

  public getOrderLabels(ids: number[]): Observable<Blob> {
    const data = { order_ids: ids };

    return this.getBlob(`order/label`, data).pipe(
      map((response: Blob) => {
        return new Blob([response], { type: 'application/pdf' });
      }),
      catchError((response: Response) => this.handleError(response)));
  }

  public getOrderPackingSlips(ids: number[]): Observable<Blob> {
    const data = { order_ids: ids };

    return this.getBlob(`order/packing-slip`, data).pipe(
      map((response: Blob) => {
        return new Blob([response], { type: 'application/pdf' });
      }),
      catchError((response: Response) => this.handleError(response)));
  }

  public getOrdersOfSuppliers(event: LazyLoadEvent, supplierId: number) {
    event.filters = { ...event.filters, supplier_id: { value: supplierId } };

    return this.getOrders(event, event.first / event.rows + 1, event.rows);
  }

  public addBackorder(orderId: number, articles: Article[]): Observable<any> {
    const data = {
      articles: articles
    };

    return this.post(`order/backorder/${orderId}`, data);
  }

  public getDefaults(supplierId?: number): Observable<any> {
    let url = 'order/defaults/';

    if (supplierId) {
      url += `${supplierId}`;
    }

    return this.get(url);
  }

  /**
   * Call api to fetch export (xsls) file
   */
  public exportOrders(orderIds: number[] = null, filters = {}): Observable<Blob> {
    let url = `order/export`;

    if (orderIds) {
      const orders = orderIds.join(',');
      url += `/${orders}`;
    }

    return this.getBlob(url, JSON.parse(JSON.stringify({ 'filters': filters })));
  }

  /**
   * Print the order label
   *
   * @param ids
   */
  public printOrderLabels(ids: number[]): Observable<any> {
    return new Observable((resolver) => {
      this.getOrderLabels(ids).subscribe((blob) => {
        this.printBlob(blob);
        resolver.next();
        resolver.complete();
      }, error => {
        resolver.error(error);
      });
    });
  }

  /**
   * Print the order packing slip
   *
   * @param ids
   */
  public printOrderPackingSlips(ids: number[]): Observable<any> {
    return new Observable((resolver) => {
      this.getOrderPackingSlips(ids).subscribe((blob) => {
        this.printBlob(blob);
        resolver.next();
        resolver.complete();
      }, error => {
        resolver.error(error);
      });
    });
  }

  /**
   * Rollback status of given orders
   *
   * @param ids
   */
  public rollbackOrderStatuses(ids: number[]): Observable<any> {
    const data = { order_ids: ids };

    return this.put(`order/status-rollback`, data, false);
  }

  /**
   * Rollforward status of given orders
   *
   * @param ids
   */
  public rollforwardOrderStatuses(ids: number[]): Observable<any> {
    const data = { order_ids: ids };

    return this.put(`order/status-rollforward`, data, false);
  }

  /**
   * Import orders from csv file
   *
   * @param file
   * @param supplierId
   */
  public import(file: File, supplierId: number): Observable<any> {
    return this.uploadFile(`order/import/${supplierId}`, file);
  }

  /**
   * test public store from JSON or XML file
   *
   * @param file
   * @param supplierId
   */
  public testImport(file: File, supplierId: number): Observable<any> {
    return this.uploadFile(`order/test-import/${supplierId}`, file);
  }
}
