import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared.module';

import { ImportComponent } from './import.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ImportComponent
  ],
  exports: [
    ImportComponent
  ]
})
export class ImportModule {
}
