import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { first } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { MessageService, MessageSummary } from '../../../../services/message.service';
import { Supplier } from '../../../../models/supplier.model';
import { CommonModalComponent } from '../../../../components/modal/child.modal';
import { WhitelistedIp } from '../../../../models/whitelisted-ip.model';
import { SupplierService } from '../../../../services/supplier.service';
import { TypeofPipe } from '../../../../pipes/typeof.pipe';
import { AuthenticationService } from '../../../../services/authentication.service';
import { ArticleService } from '../../../../services/article.service';

@Component({
  selector: 'supplier-details-api-component',
  templateUrl: './supplier-details-api.component.html',
  styleUrls: ['./supplier-details-api.scss']
})
export class SupplierDetailsApiComponent implements OnInit {
  @Input() public supplier: Supplier;
  @Input() public isRoleManager: boolean;

  @ViewChild('detailsModal') detailsModal: CommonModalComponent;

  public item;
  public ipPattern = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
  public isAdministrator: boolean = false;
  public availableArticles: SelectItem[];

  constructor(
    protected authService: AuthenticationService,
    protected confirmationService: ConfirmationService,
    private messageService: MessageService,
    private supplierService: SupplierService,
    private articleService: ArticleService,
  ) {
    this.isAdministrator = this.authService.isAdministrator();
  }

  public ngOnInit(): void {
    this.articleService.list(this.supplier.id).subscribe((result: any) => {
      this.availableArticles = result;
    }, (error: any) => this.handleError(error));
  }

  public addItem(item?): void {
    this.item = item || new WhitelistedIp();
    this.detailsModal.show();
  }

  public closeModal(): void {
    this.detailsModal.hide();
  }

  public saveItem(item: WhitelistedIp): void {
    const supplierIps = this.supplier.whitelisted_ips.data
      .map((whitelistedIp: WhitelistedIp) =>
        (this.item.id && whitelistedIp.id === this.item.id) ? item.ip_address : whitelistedIp.ip_address);
    const data = {
      ...this.supplier,
      whitelisted_ips: {
        data: this.item.id ? supplierIps : [...supplierIps, item.ip_address]
      }
    };

    this.supplierService.updateSupplier(data).pipe(first()).subscribe(
      (response) => {
        this.messageService.success(MessageSummary['OK'], 'Leveranier opgeslagen');
        this.supplier.whitelisted_ips.data = response.whitelisted_ips.data;
        this.supplier.has_api = response.has_api;

        this.detailsModal.hide();
      }, (error) => {
        this.handleError(error);
      }
    );
  }

  public downloadDocumentation(): void {
    this.supplierService.downloadDocumentation().subscribe((response) => {
      FileSaver.saveAs(response, `LogistiekeHubNL-api-sftp-documentatie.zip`);
    });
  }

  public saveConfiguration(): void {
    this.supplierService.updateSupplier(this.supplier).subscribe(
      (response: Supplier) => {
        this.messageService.success('OK', 'Gegevens opgeslagen');
        this.supplier.field_mapping = response.field_mapping;
        this.supplier.whitelisted_ips.data = response.whitelisted_ips.data;
        this.supplier.has_api = response.has_api;
      }, (error) => {
        this.handleError(error);
      });
  }

  public confirmDelete(item): void {
    this.confirmationService.confirm({
      key: 'ip_address',
      message: 'Weet je zeker dat je dit item wilt verwijderen?',
      accept: () => {
        this.deleteItem(item);
      }
    });
  }

  private deleteItem(item): void {
    const supplierIps = this.supplier.whitelisted_ips.data
      .map((whitelistedIp: WhitelistedIp) => whitelistedIp.ip_address)
      .filter((ipAddress: string) => ipAddress !== item.ip_address);
    const data = {
      ...this.supplier,
      whitelisted_ips: {
        data: [...supplierIps]
      }
    };

    this.supplierService.updateSupplier(data).pipe(first()).subscribe(
      (response: Supplier) => {
        this.supplier.whitelisted_ips.data = response.whitelisted_ips.data;
      }, (error) => {
        this.handleError(error);
      }
    );
  }

  private handleError(error: any, message = 'Er is een fout opgetreden'): void {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (err) {
          message += '<br>' + result.errors[err][0];
        });
      }
    }

    this.messageService.error(MessageSummary['SOMETHING_WENT_WRONG'], message);
  }
}
