/* tslint:disable:variable-name */
/**
 * Model AdditionalSellRateSupplier
 */
import { SellRate } from './sell-rate.model';

export class AdditionalSellRateSupplier {
  id: number;
  sell_rate_id: number;
  supplier_id?: number;
  client_id?: number;
  hub_id?: number;
  address_id?: number;
  load_address_id?: number;
  user_id?: number;
  contactperson_id?: number;
  is_periodic?: boolean;
  frequency?: string;
  sell_rate?: SellRate;
  type?: string;
  name?: string;
  amount: number;
  unit?: string;
  price?: number;
  description?: string;
  date?: string | Date;
  end_date?: string | Date;
  exported_at?: string | Date;
  invoiced_at?: string | Date;
}
