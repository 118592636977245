import { Pipe } from '@angular/core';

@Pipe({
  name: 'currencyFormatInput'
})
export class CurrencyFormatInput {
  transform(value: any,
            decimalLength: number = 2,
            chunkDelimiter: string = '.',
            decimalDelimiter: string = ',') {
    let num;
    if (typeof value === 'string') {
      num = value.replace(chunkDelimiter, decimalDelimiter);
    } else if (value) {
      num = value.toFixed(decimalLength).replace(chunkDelimiter, decimalDelimiter);
    } else {
      return value;
    }

    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
  }
}
