import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

const defaultRouting = [
  {
    path: '',
    loadChildren: './pages/pages.module#PagesModule'
  },
  {
    path: '**',
    redirectTo: 'pages/clients'
  }
];

export const routes: Routes = defaultRouting;

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { useHash: true });
