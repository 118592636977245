import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LazyLoadEvent } from 'primeng/primeng';
import * as FileSaver from 'file-saver';

import { CommonModalComponent } from '../modal/child.modal';

import { SharedService } from '../../services/shared.service';
import { ShipmentOrderService } from '../../services/shipmentOrder.service';
import { ShipmentService } from '../../services/shipment.service';
import { TabsComponent } from '../tabs/tabs.component';
import { MessageService, MessageSummary } from '../../services/message.service';

@Component({
  selector: 'app-edit-shipment-order-modal',
  templateUrl: './edit-shipment-order-modal.component.html',
  styleUrls: ['./edit-shipment-order-modal.component.scss']
})
export class EditShipmentOrderModalComponent {

  @ViewChild('editShipmentOrderModal') editModal: CommonModalComponent;
  @ViewChild('shipmentStatusModal') shipmentStatusModal: CommonModalComponent;
  @ViewChild('commercialInvoiceModal') commercialInvoiceModal: CommonModalComponent;

  @Input() isRoleCustomer;
  @Input() defaults;
  @Input() client;

  @Output() shipmentOrderUpdatedEvent = new EventEmitter();

  shipmentOrder;
  statuses: any[] = [];
  currentStatus;
  loading: boolean = false;
  commercialInvoiceExists: boolean = false;

  id;
  history = {
    records: [],
    loading: true,
    totalRecords: 0
  };
  shipmentHistory = false;
  selectedStatusOld: string = '';
  selectedStatusNew: string = '';

  localDefaults = {
    statuses: []
  };
  public paginationAmount: number = 30;

  constructor(
    protected shipmentService: ShipmentService,
    protected sharedService: SharedService,
    protected shipmentOrderService: ShipmentOrderService,
    protected tabs: TabsComponent,
    private messageService: MessageService) {
    this.paginationAmount = this.sharedService.getRowsPerPage();
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public open(data): void {
    this.shipmentOrder = data;
    this.id = data.id;
    this.commercialInvoiceExists = false;

    this.checkIfCommercialInvoiceExists();

    if (this.shipmentOrder.status !== 'Afgeleverd' && this.shipmentOrder.status !== 'Afgehandeld') {
      this.statuses = this.defaults.statuses;
    } else {
      this.statuses = this.defaults.statusesCompleted.filter((status) => {
        return status.label !== 'Alle';
      });

    }

    this.currentStatus = this.shipmentOrder.status.id;

    this.editModal.show();
  }

  checkIfNotPartOfEu(senderCountryId, receiverCountryId) {
    return false;
  }

  save(event) {
    this.commercialInvoiceModal.hide();
    this.loading = true;
    this.shipmentOrder.status.id = this.currentStatus;
    this.shipmentOrder.post_action = event.type;
    if (event.data) {
      this.shipmentOrder.export_reason_id = event.data.commercialInvoiceData.reasonForExport;
      this.shipmentOrder.alternate_export_reason = event.data.commercialInvoiceData.alternateReason;
      this.shipmentOrder.coc_number = event.data.commercialInvoiceData.coc_number;
      this.shipmentOrder.vat_number = event.data.commercialInvoiceData.vat_number;
    }

    this.shipmentOrderService.updateShipmentOrder(this.shipmentOrder).subscribe((data) => {
      if (event.type === 'commercial-invoice') {
        this.shipmentOrderService.downloadCommercialInvoice(data.id).subscribe((response) => {
          FileSaver.saveAs(response, 'commercial-invoice.pdf');
        }, err => {
          this.handleError(err);
        });
      }
      this.editModal.hide();
      this.shipmentOrderUpdatedEvent.emit();
      let message = 'De wijzigingen zijn opgeslagen';

      if (data.status.code === 'booked') {
        message += ' en de zending is ingeboekt bij de vervoerder';
      }

      if (data.status.code === 'failed') {
        message += ' maar de zending kon niet worden ingeboekt bij de vervoerder';
      }

      this.messageService.success(MessageSummary['OK'], message);
      this.loading = false;
    }, (error) => {
      this.loading = false;
      this.handleError(error);
    });
  }

  public handleError(error: any, message = 'Kan zending niet bijwerken.') {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (err) {
          message += '<br>' + result.errors[err][0];
        });
      }
    }

    this.messageService.error(MessageSummary['SOMETHING_WENT_WRONG'], message);
  }

  createCommercialInvoice() {
    this.editModal.hide();
    this.commercialInvoiceModal.show();
  }

  onCancelCommercialInvoice() {
    this.commercialInvoiceModal.hide();
    this.editModal.show();
  }

  onSaveCommercialInvoice(event) {
    if (event.file) {
      event.type = 'close';
      this.shipmentOrderService.uploadCommercialInvoice(event.file, this.shipmentOrder.id).subscribe((data) => {
        this.commercialInvoiceModal.hide();
        this.save(event);
      }, err => {
        this.handleError(err);
      });
    } else {
      this.save(event);
    }
  }

  checkIfCommercialInvoiceExists() {
    this.loading = true;
    this.shipmentOrderService.checkIfCommercialInvoiceExists(this.shipmentOrder.id).subscribe((data) => {
      this.commercialInvoiceExists = data.success;
    }, err => {
      this.handleError(err);
    }, () => {
      this.loading = false;
    });
  }

  showShipmentStatus() {
    this.editModal.hide();
    this.shipmentStatusModal.show();
    this.shipmentHistory = true;
  }

  hideShipmentStatus() {
    this.shipmentStatusModal.hide();
    this.shipmentHistory = false;
    this.editModal.show();
  }

  public loadHistoryData(event: LazyLoadEvent): void {
    this.shipmentOrderService.getHistory(event, event.first / event.rows + 1, event.rows, this.id)
      .subscribe((data) => {
        this.history.loading = false;
        this.history.records = data.data;
        this.history.totalRecords = data.meta.pagination.total;

        this.localDefaults.statuses = this.sharedService.createDropdownOptions(data.meta.statuses, 'Alle');
      });
  }
}
