import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, FileUpload, Message, SelectItem } from 'primeng/primeng';

import { CommonModalComponent } from '../../../../components/modal/child.modal';
import { TabsComponent } from '../../../../components/tabs/tabs.component';

import { SharedService } from '../../../../services/shared.service';
import { ClientsService } from '../../../../services/clients.service';
import { ContactPersonsService } from '../../../../services/contactpersons.service';

import { ContactPerson } from '../../../../models/contactPerson.model';
import { Client } from '../../../../models/client.model';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_SUPPLIER
} from '../../../../services/authentication.service';
import { MessageService, MessageSummary } from '../../../../services/message.service';

@Component({
  selector: 'client-details-contactpersons',
  templateUrl: './client-details-contactpersons.component.html',
  styleUrls: ['./client-details-contactpersons.component.scss']
})
export class ClientDetailsContactpersonsComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('editModal') editModal: CommonModalComponent;
  @ViewChild('imageUploader') imageUploader: FileUpload;
  @ViewChild('cpForm') cpForm;
  @Input() client: Client;
  @Input() defaults;
  @Output() onRefreshData = new EventEmitter();

  public contactPersons: any[];
  public archivedContactpersons: any[];
  public showArchived: boolean;

  public validationErrors = [];
  public messages: Message[] = [];
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public readonly: boolean = false;
  public genders;

  public editableContactPerson: ContactPerson = {
    id: null,
    client_id: null,
    supplier_id: null,
    first_name: '',
    last_name_prefix: null,
    last_name: '',
    full_name: '',
    gender: 'unknown',
    email: '',
    image_url: null,
    phone_number: '',
    communication_type: 'informal',
    function: '',
    remarks: null,
    post_action: null,
    archived: false,
    has_user_account: false,
    name: null
  };

  public modals = [
    'add-contactpersons', null
  ];
  public fromAddClient = false;
  public loadingFromClient: boolean = false;
  public loading: boolean = false;
  public isNew = true;
  public communicationTypeList: SelectItem[] = [
    { label: 'Formeel', value: 'formal' },
    { label: 'Informeel', value: 'informal' }
  ];

  public btnDisabled: boolean = false;

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    public sharedService: SharedService,
    protected clientsService: ClientsService,
    protected contactPersonsService: ContactPersonsService,
    protected tabs: TabsComponent,
    protected elRef: ElementRef,
    protected renderer: Renderer2,
    protected confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthenticationService) {

    this.contactPersons = [];
    this.archivedContactpersons = [];
    this.showArchived = false;

    this.initContactpersons();
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR])) {
      this.isRoleHubOperator = true;
      this.readonly = false;
    }

    this.genders = this.contactPersonsService.getGenders();
  }

  public ngOnChanges(): void {
    this.initContactpersons();

    if (this.client.client_code && this.fromAddClient) {
      this.editableContactPerson.first_name = this.client.first_name;
      this.editableContactPerson.last_name_prefix = this.client.last_name_prefix;
      this.editableContactPerson.last_name = this.client.last_name;
      this.editableContactPerson.email = this.client.email;
      this.editableContactPerson.phone_number = this.client.phone;
      this.editableContactPerson.has_user_account = false;
      this.editableContactPerson.archived = false;
      this.loadingFromClient = false;
    }
  }

  public goToUser(personId: number): void {
    this.router.navigate([`/pages/manager/users/${personId}`]);
  }

  public createUser(personId: number): void {
    this.contactPersonsService.createUser(personId).subscribe((result) => {
      this.refreshClient();
      this.parseResults(result, 'Gebruiker account aangemaakt');
    }, (error: any) => this.handleError(error, 'CREATE_USER'));
  }

  public editContactPerson(contactperson: ContactPerson): void {
    this.isNew = (!contactperson);

    let form: HTMLFormElement = <HTMLFormElement>document.getElementById('contact-form');
    form.reset();

    if (contactperson) {
      this.editableContactPerson = contactperson;
      this.editableContactPerson.has_user_account = false;
    }

    this.editModal.show();
  }

  public resetEditableContactPerson(): void {
    this.validationErrors = [];

    let inputs = this.elRef.nativeElement.querySelectorAll('.form-control');
    inputs.forEach((input) => {
      this.renderer.removeClass(input, 'invalid');
    });

    if (this.imageUploader) {
      this.imageUploader.clear();
    }

    this.editableContactPerson = {
      id: null,
      client_id: null,
      first_name: '',
      last_name_prefix: null,
      last_name: '',
      full_name: '',
      gender: 'unknown',
      email: '',
      image_url: '',
      phone_number: '',
      communication_type: 'informal',
      function: '',
      remarks: null,
      post_action: null,
      archived: false
    };
  }

  public addContactPerson(modal: any): void {
    modal.show();
  }

  public cancelContactPerson(): void {
    this.clearAndHideForm();
  }

  public confirmDelete(personId: number): void {
    this.confirmationService.confirm({
      key: 'contactpersons',
      message: 'Weet u zeker dat u deze contactpersoon wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteContactPerson(personId);
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  public submitContactPerson(form: any): void {
    this.validationErrors = [];

    // Trim any pre- or suffix spacing
    if (form.controls.phone_number.value) {
      form.controls.phone_number.setValue(form.controls.phone_number.value.trim());
    }

    if (form.valid) {
      this.btnDisabled = true;

      if (this.editableContactPerson.phone_number === null) {
        delete this.editableContactPerson.phone_number;
      }

      if (this.editableContactPerson.email === null) {
        delete this.editableContactPerson.email;
      }

      if (this.editableContactPerson.gender === null) {
        this.editableContactPerson.gender = 'unknown';
      }

      if (this.editableContactPerson.communication_type === null) {
        this.editableContactPerson.communication_type = 'informal';
      }

      if (this.editableContactPerson.supplier_id === 0) {
        this.editableContactPerson.supplier_id = null;
      }

      // Set checkbox value to contactperson.archived
      if (this.isNew) {
        this.editableContactPerson.archived = false;
        this.editableContactPerson.has_user_account = form.controls.userAccount.value || false;
      } else {
        this.editableContactPerson.archived = form.controls.archived.value || false;
      }

      this.loading = true;

      if (!this.isNew) { // update
        this.contactPersonsService.updateContactPerson(this.editableContactPerson).subscribe((result) => {
          if (this.editableContactPerson.has_user_account === true) {
            this.createUser(result.id);
          }

          this.parseResults(result, 'Contactpersoon opgeslagen');

          if (this.imageUploader) {
            this.imageUploader.upload();
          }

          this.clearAndHideForm();
          this.loading = false;
          this.btnDisabled = false;
        }, (error: any) => this.handleError(error, 'SAVE_CONTACT'));
      } else { // new
        this.editableContactPerson.client_id = this.client.id;

        // Only create user when client-status is 'client' or 'new client'
        if (this.fromAddClient) {
          this.editableContactPerson.post_action = 'create_user';
        }

        this.contactPersonsService.addContactPerson(this.editableContactPerson).subscribe((result) => {
          if (this.editableContactPerson.has_user_account === true) {
            this.createUser(result.id);
          }

          if (result.success === false) {
            this.handleError(null, result.message);
            this.loading = false;
          } else {
            this.parseResults(result, 'Contactpersoon toegevoegd');
            if (result.contactperson) {
              this.editableContactPerson.id = result.contactperson.id;
              this.client.contactpersons.data.push(result.contactperson);
            } else {
              this.editableContactPerson.id = result.id;
              this.client.contactpersons.data.push(result);
            }

            if (this.imageUploader) {
              this.imageUploader.upload();
            }

            this.clearAndHideForm();
            this.resetRoute();
            this.loading = false;
            this.btnDisabled = false;
          }
        }, (error: any) => this.handleError(error, 'SAVE_CONTACT', 'Vul alle verplichte velden in (*)'));
      }
    } else {
      this.handleError(null, 'CLIENT', 'Controleer uw invoer.');

      if (form.controls.phone_number.valid === false) {
        this.validationErrors.push('Het telefoonnummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }
    }
  }

  public resetRoute(): void {
    this.router.navigate([this.router.url.split('#')[0].split('?')[0]], { queryParams: {} });
  }

  public parseResults(result: ContactPerson, message: string): void {
    if (result) {
      this.editModal.onHidden(() => { // Reload tables
        this.refreshClient();
      });

      this.messageService.success(MessageSummary['SUCCESSFUL'], message);
      this.editModal.hide();
    } else {
      this.handleError(true, 'CONTACT', message);
    }
  }

  public clearAndHideForm(): void {
    this.validationErrors = [];
    this.resetEditableContactPerson();
    let frm: HTMLFormElement = <HTMLFormElement>document.getElementById('contact-form');

    this.editModal.hide();
  }

  public refreshClient(): void {
    this.clientsService.getClient(this.client.id).subscribe((data: any) => {
      this.client = data;
      this.onRefreshData.emit({ client: data });
      this.initContactpersons();
    });
  }

  /**
   * Live updates main contact to new selected main contact
   * @param contactperson
   */
  public updateMainContact(contactperson: ContactPerson): void {
    let isMainContact = this.client.main_contactperson_id === contactperson.id;

    if (!isMainContact || isMainContact === undefined) {
      this.client.main_contactperson_id = contactperson.id;
    } else {
      this.client.main_contactperson_id = null;
    }
  }

  public getContactPersonModalTitle(): string {
    if (this.editableContactPerson.archived) {
      return 'inzien';
    } else if (this.isNew) {
      return 'toevoegen';
    } else {
      return 'bewerken';
    }
  }

  public handleError(error: any, summary: string, message: string = 'Er is een fout voorgekomen.') {
    this.validationErrors = [];
    this.btnDisabled = false;

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        // Remove class 'invalid' for all inputs
        let inputs = this.elRef.nativeElement.querySelectorAll('.form-control');
        inputs.forEach((input) => {
          this.renderer.removeClass(input, 'invalid');
        });

        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);

              // Add class 'invalid' to input
              let el = this.elRef.nativeElement.querySelector('[name=' + field + ']');
              this.renderer.addClass(el, 'invalid');
            });
          }
        }
      }
    }

    this.messageService.error(MessageSummary[summary], message);
    this.loading = false;
  }

  public ngAfterViewInit(): void {
    this.activeRoute.queryParams.subscribe((params: any) => {
      this.fromAddClient = params['fromAddClient'];
      if (this.fromAddClient) {
        this.loadingFromClient = true;
      }

      this.editModal.onHidden(() => {
        this.clearAndHideForm();
      });
    });

    this.modals['add-contactpersons'] = [this.addContactPerson, this.editModal];
    this.sharedService.handleHash(this.activeRoute, this.modals);
  }

  public myUploadHandler(event): void {
    if (!(event.files.length > 0)) {
      return;
    }

    let file: File = event.files[0];
    let contactPersonId = this.editableContactPerson.id;
    this.contactPersonsService.uploadImageForContactPerson(file, contactPersonId).subscribe((data) => {
      this.editableContactPerson.image_url = data.url;
      let contactPersonIndex = this.client.contactpersons.data.findIndex(c => c.id === contactPersonId);
      this.client.contactpersons.data[contactPersonIndex].image_url = data.url;
      this.clearAndHideForm();
    });
  }

  public deleteImage() {
    let contactPersonId = this.editableContactPerson.id;
    this.contactPersonsService.deleteImageForContactPerson(contactPersonId).subscribe((data: any) => {
      if (data.success === true) {
        this.editableContactPerson.image_url = null;
        let contactPersonIndex = this.client.contactpersons.data.findIndex(c => c.id === contactPersonId);
        this.client.contactpersons.data[contactPersonIndex].image_url = null;
      }
    });
  }

  public addShipmentOrder() {
    this.router.navigate([`/pages/shipments/0/${this.client.id}`]);
  }

  public showArchivedPersons(): void {
    this.showArchived = !this.showArchived;
  }

  private initContactpersons(): void {
    this.contactPersons = [];
    this.archivedContactpersons = [];

    if (this.client && this.client.contactpersons) {
      this.client.contactpersons.data.forEach((contactPerson) => {
        contactPerson.name = (
          (contactPerson.first_name || '') + ' ' +
          (contactPerson.last_name_prefix || '') + ' ' +
          (contactPerson.last_name || '')
        )
          .replace('  ', ' ');
        if (contactPerson.archived === true) {
          this.archivedContactpersons.push(contactPerson);
        } else {
          this.contactPersons.push(contactPerson);
        }
      });
    }
  }

  private deleteContactPerson(personId: number): void {
    this.contactPersonsService.deleteContactPerson(personId).subscribe((result) => {
      this.parseResults(result, 'Contactpersoon verwijderd');
      this.refreshClient();
    });
  }
}
