/* tslint:disable:variable-name */
import { Address } from './address.model';
import { ContactPerson } from './contactPerson.model';
import { Supplier } from './supplier.model';
import { ClientType } from './client-type.model';

/**
 * Model Client
 */
export class Client {
  id?: number;
  client =  { data: Client };
  client_id?: number;
  can_bundle: boolean;
  is_paying: boolean;
  transporter_id?: number;
  client_code: string;
  client_type_id: number;
  company_name: string;
  name?: string;
  first_name: string;
  last_name: string;
  last_name_prefix: string;
  email?: string;
  email_finance?: string;
  phone?: string;
  main_contactperson_id: number;
  payment_agreement: string;
  addresses: any;
  contactpersons: { data: ContactPerson[] };
  iban_number?: string;
  coc_number?: string;
  vat_number?: string;
  supplier_ids: number[];
  suppliers?: { data: Supplier[] };
  update_administrative?: boolean;
  remarks?: string;
  customer?: string;

  client_type: ClientType;

  constructor(setDefaults: boolean = false) {
    if (setDefaults) {
      this.client_type_id = 1;
      this.payment_agreement = 'direct';
      this.addresses = [new Address()];
      this.customer = 'supplier';
      this.client_type = new ClientType('business');
      this.is_paying = false;
      this.can_bundle = false;
    }
  }
}
