import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/primeng';

import { ImportService } from '../../services/import.service';
import { SupplierService } from '../../services/supplier.service';

@Component({
  selector: 'import-component',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportComponent implements OnInit {

  importType = 'zone';
  fileList;
  response;
  loading = false;
  minDate = new Date(new Date().getFullYear(), 0, 1);
  maxDate = new Date(new Date().getFullYear(), 11, 31);

  suppliers: SelectItem[] = [];
  selectedSupplier;

  classStatus = 'alert-success';

  fromDate;
  untilDate;

  constructor(
    protected importService: ImportService,
    protected supplierService: SupplierService) {
  }

  ngOnInit() {
    this.fromDate = this.minDate;
    this.untilDate = this.maxDate;

    this.supplierService.getAllSuppliersShort().subscribe(data => {
      this.suppliers = [];

      data.data.forEach(supplier => {
        this.suppliers.push({ label: supplier.name, value: supplier.id });
      });

      this.suppliers.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        } else if (a.label > b.label) {
          return 1;
        } else {
          return 0;
        }
      });
    });
  }

  myUploadHandler(event) {
    this.fileList = event.files;
    this.uploadFiles();
  }

  uploadFiles() {
    this.loading = true;

    this.importService.importFiles(
      this.fileList,
      this.importType,
      this.fromDate,
      this.untilDate,
      this.selectedSupplier
    ).subscribe((data) => {
      this.loading = false;
      this.response = data;

      if (!this.response.statistics) {
        this.classStatus = 'alert-danger';
      } else if (
        this.response.statistics.total > 0 &&
        this.response.statistics.new === this.response.statistics.total
      ) {
        this.classStatus = 'alert-success';
      } else if (this.response.statistics.total > 0 && this.response.statistics.new > 0) {
        this.classStatus = 'alert-warning';
      } else if (this.response.statistics.total === 0 || this.response.statistics.new === 0) {
        this.classStatus = 'alert-danger';
      }
    });
  }
}
