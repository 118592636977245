
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Transporter } from '../models/transporter.model';

@Injectable()
export class TransporterService extends ApiService {

  public getTransporters(data: any, page: number = 1, perPage: number = 20): Observable<any> {
    return this.post(`transporters/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination
        };
      }),
      catchError(this.handleError));
  }

  public list(orderIds: any[] = []): Observable<{ label: string, value: number }[]> {
    return this.post('transporters/list', {orders: orderIds}).pipe(map((response: any) => response.transporters));
  }

  public getTransporter(transporterId: number): Observable<any> {
    return this.get(`transporters/${transporterId}`);
  }

  public updateTransporter(transporter: Transporter): Observable<any> {
    return this.put('transporters', transporter);
  }

  public addTransporter(transporter: Transporter): Observable<any> {
    return this.post('transporters', transporter);
  }

  public deleteTransporter(transporterId: number): Observable<any> {
    return this.delete(`transporters/${transporterId}`);
  }

  public exportShipments(transporterId: number, hubId: number, distinct: boolean, from: string, to: string): Observable<Blob> {
    transporterId = transporterId ? transporterId : -1;
    let distinctValue: string = distinct ? '1' : '0';
    if (to) {
      return this.getBlob(`transporters/export/${transporterId}/${hubId}/${distinctValue}/${from}/${to}`);
    }
    return this.getBlob(`transporters/export/${transporterId}/${hubId}/${distinctValue}/${from}`);
  }
}

