
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Article } from '../models/article.model';
import { LazyLoadEvent, SelectItem } from 'primeng/primeng';

@Injectable()
export class ArticleService extends ApiService {

  public getArticle(id: number): Observable<any> {
    return this.get(`articles/${id}`).pipe(map((response: any) => response.data));
  }

  public getArticles(data, page = 1, perPage = 20) {
    return this.post(`articles/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return response;
      }),
      map((result: any) => {
        return {
          data: result.data,
          pagination: result.meta.pagination,
          meta: result.meta
        };
      }),
      catchError(this.handleError));
  }

  public list(supplierId?: number, valueProperty?: string, labelProperties?: string[]): Observable<any> {
    let url = 'articles/list';

    if (supplierId) {
      url += `/${supplierId}`;
    }

    if (valueProperty && labelProperties) {
      url += `?_valueProperty=${valueProperty}&_labelProperties=${labelProperties.join(',')}`;
    }

    return this.get(url).pipe(map((response: any) => response.articles));
  }

  public getIsActives(): Array<SelectItem> {
    return [
      { 'value': true, 'label': 'Ja' },
      { 'value': false, 'label': 'Nee' }
    ];
  }

  public getPackingUnitList(articleId: number): Observable<any> {
    return this.get(`packing-units/list/${articleId}`);
  }

  public updateArticle(data: Article): Observable<Article> {
    return this.put(`articles`, data);
  }

  public createArticle(data: Article): Observable<Article> {
    return this.post(`articles`, data);
  }

  public getAll(supplierId?: number, hubId?: number): Observable<Article[]> {
    let url = 'articles/get';

    if (supplierId) {
      url += `/${supplierId}`;
    }

    if (hubId) {
      url += `/${hubId}`;
    }

    return this.get(url).pipe(map((response: any) => response.data));
  }

  deleteArticle(articleId: number) {
    return this.delete('articles/' + articleId);
  }

  public import(file: File, data: any): Observable<any> {
    return this.uploadFile('articles/import', file, data);
  }

  public export(): Observable<Blob> {
    return this.getBlob(`articles/export`);
  }

  public getSupplierArticles(supplierId: number, event: LazyLoadEvent): Observable<any> {
    if (supplierId) {
      event.filters = { ...event.filters, supplier_id: { value: supplierId } };
    }

    return this.getArticles(event);
  }

  public getVolume(article: Article): number {
    let volume: number = 0;

    if (article.packing_unit.volume) {
      volume = article.packing_unit.volume;
    } else if (article.packing_unit.length && article.packing_unit.width && article.packing_unit.height) {
      volume = (
        +article.packing_unit.length / 100 * +article.packing_unit.width / 100 * +article.packing_unit.height / 100
      );
    } else {
      return 0;
    }

    volume = Math.round(+volume * 100) / 100;

    return volume;
  }
}
