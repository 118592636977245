
import {map} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Address } from '../../../models/address.model';
import { CountryService } from '../../../services/country.service';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/primeng';
import { HubService } from '../../../services/hub.service';
import { SharedService } from '../../../services/shared.service';
import { MessageService, MessageSummary } from '../../../services/message.service';

@Component({
  selector: 'app-address-add',
  templateUrl: './address-add.component.html',
  styleUrls: ['./address-add.component.scss']
})
export class AddressAddComponent implements OnInit {
  get hubId(): number {
    return this._hubId;
  }

  @Input()
  set hubId(value: number) {
    this._hubId = value;

    if (this.addressForm) {
      this.addressForm.patchValue({ hub_id: value });
    }
  }

  get addressType(): string {
    return this._addressType;
  }

  @Input()
  set addressType(value: string) {
    this._addressType = value;

    this.buildAddressForm();
  }

  @Input()
  public contactpersons?: SelectItem[] = [];

  @Output()
  public onSubmit: EventEmitter<Address>;

  @Output()
  public onCancel: EventEmitter<void>;

  public addressForm: FormGroup;
  public countries$: Observable<SelectItem[]>;
  public hubs$: Observable<SelectItem[]>;
  public addressTypes: SelectItem[];
  public loadingZipcode: boolean;
  public zipcodeIsValid: boolean;

  private _hubId: number;
  private _addressType: string;

  constructor(public sharedService: SharedService,
              private formBuilder: FormBuilder,
              private countryService: CountryService,
              private messageService: MessageService,
              private hubService: HubService) {
    this.onSubmit = new EventEmitter();
    this.onCancel = new EventEmitter();
  }

  public ngOnInit(): void {
    this.countries$ = this.countryService.getCountries().pipe(map((response) => response.data.map((country) => {
      return { label: country.name, value: country.id };
    })));
    this.hubs$ = this.hubService.list();

    this.sharedService.getEnumOptions('addresses', 'type').subscribe((addressTypes) => {
      this.addressTypes = [];

      for (let key in addressTypes) {
        if (addressTypes.hasOwnProperty(key)) {
          this.addressTypes.push({ label: addressTypes[key], value: key });
        }
      }
    });
  }

  /**
   * Submit the form
   */
  public submit(): void {
    if (this.addressForm.valid) {
      this.onSubmit.emit(this.addressForm.value);

      this.addressForm.reset();
      this.buildAddressForm();
    }
  }

  /**
   * Execute onCancel event emitter
   */
  public cancel(): void {
    this.addressForm.reset();
    this.buildAddressForm();
    this.onCancel.emit();
  }

  public formatZipCode(event): void {
    this.addressForm.controls['postcode'].patchValue(this.sharedService.formatZipCode(event.target.value));
  }

  /**
   * Find the street and location of zipcode and house number are entered
   */
  public focusOutFunction(): void {
    if (this.addressForm.get('postcode').value && this.addressForm.get('house_number').value) {
      let output = this.sharedService.checkZipcode(this.addressForm.value);

      if (output['success']) {
        this.loadingZipcode = true;

        this.sharedService.getZipcode(output).subscribe((result) => {
            if (result !== null) {
              this.addressForm.patchValue({ street: result.street, location: result.city });
              this.loadingZipcode = false;
              this.zipcodeIsValid = true;
            }
          },
          (error: any) => this.handleZipcodeError(error));
      }
    }
  }

  private handleZipcodeError(error: any): void {
    if (error) {
      this.addressForm.patchValue({ street: '', location: '' });
      this.zipcodeIsValid = false;

      this.messageService.warning(MessageSummary['ZIPCODE_CHECK'], 'Adres niet gevonden o.b.v. postcode en huisnummer');
    }
  }

  /**
   * Build the address form
   */
  private buildAddressForm(): void {
    const fields = {
      postcode: ['', Validators.required],
      house_number: ['', Validators.required],
      house_number_suffix: [''],
      hub_id: [null, Validators.nullValidator],
      country_id: [154, Validators.required],
      street: ['', Validators.required],
      location: ['', Validators.required],
      extra_address_line: [''],
      type: [this.addressType, Validators.required],
      agreements: [''],
      description: [''],
      contactperson_id: [null]
    };

    if (this.addressType === 'discharge') {
      fields['hub_id'] = [null, Validators.required];
    }

    this.addressForm = this.formBuilder.group(fields);
  }
}
