import { throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import { Headers, Http, Response } from '@angular/http';
import { environment } from '../../environments/environment';

// Role constants
export const ROLE_MANAGER = 1;
export const ROLE_LOGISTIC_EMPLOYEE = 2;
export const ROLE_FINANCIAL_EMPLOYEE = 3;
export const ROLE_CUSTOMER = 4;
export const ROLE_SUPPLIER = 5;
export const ROLE_TRANSPORTER = 6;
export const ROLE_HUB_OPERATOR = 7;
export const ROLE_DELIVERER = 8;

@Injectable()
export class AuthenticationService {
  public authListener = new EventEmitter();
  private authenticated: boolean = false;

  constructor(protected http: Http) {
    this.authenticated = !!localStorage.getItem('user');
  }

  public loginUser(user: any): any {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const body = JSON.stringify(user);

    return this.http
      .post(`${environment.API_URL}/auth/login`, body, { headers: headers, withCredentials: true }).pipe(
      map((data: Response) => data.json()),
      map((data: any) => {
        if (data.meta && data.meta.token) {
          localStorage.setItem('user', JSON.stringify(data));

          this.authenticated = true;

          this.authChanged(this.authenticated);

          return true;
        }

        return false;
      }),
      catchError(this.handleError));
  }

  public forgotPassword(user: any): Observable<Response> {
    const body = JSON.stringify(user);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.http
      .post(`${environment.API_URL}/auth/password/email`, body, {
        headers
      }).pipe(
      map((data: Response) => data.json()),
      catchError(this.handleError));
  }

  public resetPassword(user: any): Observable<Response> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const body = JSON.stringify(user);

    return this.http
      .post(`${environment.API_URL}/auth/password/reset`, body, {
        headers
      }).pipe(
      map((data: Response) => data.json()),
      catchError(this.handleError));
  }

  public logoutUser(): Observable<Response> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.http
      .post(`${environment.API_URL}/auth/logout`, '', { headers: headers, withCredentials: true }).pipe(
      map((data: Response) => data.json()),
      map((result: any) => {
        if (result.success) {
          this.removeUser();

          return result;
        }

        return false;
      }),
      catchError(this.handleError));
  }

  public refreshToken(): Observable<string> {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    return this.http
      .post(`${environment.API_URL}/auth/refresh`, '', {
        headers,
        withCredentials: true
      }).pipe(
      map((data: Response) => data.json()),
      map((data: any) => {
        if (data.meta && data.meta.token) {
          localStorage.setItem('user', JSON.stringify(data));

          this.authenticated = true;

          this.authChanged(this.authenticated);

          return data.meta.token;
        }
      }),
      catchError(this.handleError));
  }

  public isAuthenticated(): boolean {
    return this.authenticated;
  }

  public isRoleAuthorized(roles: any): boolean {
    let user: any = this.getUser();

    if (user && user.role && roles && roles instanceof Array) {
      return roles.indexOf(user.role.id) !== -1;
    }

    return false;
  }

  public getToken(): string {
    let user: any = this.getUser();

    if (user) {
      return user.meta.token;
    } else {
      return '';
    }
  }

  public getUser(): any {
    return JSON.parse(localStorage.getItem('user'));
  }

  public isAdministrator(): boolean {
    return this.getUser().role.url === 'administrator';
  }

  public updateToken(token: string): void {
    if (token) {
      let user = this.getUser();

      user.meta.token = token;

      this.setUser(user);
    } else {
      this.logoutUser();
    }
  }

  public removeUser(): void {
    localStorage.removeItem('user');
    this.authenticated = false;
    this.authChanged(this.authenticated);
  }

  private setUser(user: any): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  private authChanged(bool: boolean): void {
    this.authListener.emit(bool);
  }

  private handleError(error: any): Observable<any> {
    return observableThrowError(error);
  }
}
