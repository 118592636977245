
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Shipment } from '../models/shipment.model';
import { Order } from '../models/order.model';
import { SelectItem } from 'primeng/primeng';
import { Response } from '@angular/http';
import { ShipmentScan } from '../models/shipment-scan.model';

@Injectable()
export class ShipmentService extends ApiService {

  public getShipments(data: any, page: number = 1, perPage: number = 20, status = ''): Observable<any> {
    return this.post(`shipment/filter/${status}?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return response;
      }),
      map((result: any) => {
        return {
          data: result.data,
          pagination: result.meta.pagination,
          meta: result.meta
        };
      }),
      catchError(this.handleError));
  }

  public getShipment(id: number): Observable<any> {
    return this.get(`shipment/${id}`);
  }

  public addShipment(data: Shipment): Observable<any> {
    return this.post('shipment', data);
  }

  public updateShipment(data: Shipment): Observable<any> {
    return this.put(`shipment`, data);
  }

  public getColli(data: Order[]): Observable<any> {
    return this.post('shipment/calculate-colli', { 'orders': data });
  }

  public getSupplierShipments(supplierId: number, data: any, page: number = 1, perPage: number = 20): Observable<any> {
    return this.post(
      `shipment/filter/supplier/${supplierId}?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return response;
      }),
      map((result: any) => {
        return {
          data: result.data,
          pagination: result.meta.pagination,
          meta: result.meta
        };
      }),
      catchError(this.handleError));
  }

  public getStatusList(): Observable<any> {
    return this.get('shipment/status-list').pipe(map((response) => response));
  }

  public getTypesList(): Observable<{ label: string, value: number }[]> {
    return this.get('shipment/type-list').pipe(map((response: any) => response));
  }

  public getShipmentLabels(ids: number[], barcode: string = null): Observable<Blob> {
    const data = { shipment_ids: ids };

    return this.getBlob(`shipment/shipment-label?barcode=${barcode}`, data).pipe(
      map((response: Blob) => {
        return response;
      }),
      catchError((response: Response) => this.handleError(response)));
  }

  public getShipmentPackingSlips(ids: number[]): Observable<Blob> {
    const data = { shipment_ids: ids };

    return this.getBlob(`shipment/packing-slip`, data).pipe(
      map((response: Blob) => {
        return response;
      }),
      catchError((response: Response) => this.handleError(response)));
  }

  public downloadExport(supplierId, shipmentIds: number[]): Observable<Blob> {
    const shipments = shipmentIds.join(',');

    return this.getBlob(`shipment/export/${supplierId}/${shipments}`);
  }

  public exportScans(shipmentId: number): Observable<Blob> {
    return this.getBlob(`shipment/scan-export/${shipmentId}`);
  }

  public sendToTransporter(id: number): Observable<any> {
    return this.get(`shipment/send-to-transporter/${id}`).pipe(map((response) => response));
  }

  /**
   * Print shipment labels
   *
   * @param ids
   * @param barcode
   */
  public printShipmentLabels(ids: number[], barcode: string = null): Observable<void> {
    return new Observable((resolver) => {
      this.getShipmentLabels(ids, barcode).subscribe((blob) => {
        this.printBlob(blob);

        resolver.next();
        resolver.complete();
      });
    });
  }

  /**
   * Print packing slip
   *
   * @param ids
   */
  public printShipmentPackingSlips(ids: number[]): Observable<void> {
    return new Observable((resolver) => {
      this.getShipmentPackingSlips(ids).subscribe((blob) => {
        this.printBlob(blob);

        resolver.next();
        resolver.complete();
      });
    });
  }

  /**
   * Cancel the given shipment
   *
   * @param shipment
   * @param orderIdsToRefresh
   */
  public cancelShipment(shipment: Shipment, orderIdsToRefresh: number[]): Observable<any> {
    return this.put(`shipment/${shipment.id}/cancel`, { ...shipment, order_ids: orderIdsToRefresh }, false);
  }

  /**
   * Copy the given shipment
   *
   * @param shipment
   * @param orderIds
   * @param isReturn
   */
  public copyShipment(shipment: Shipment, orderIds: number[], isReturn: boolean = false): Observable<any> {
    const data = {
      ...shipment,
      order_ids: orderIds,
      is_return: isReturn
    };

    return this.put(`shipment/${shipment.id}/copy`, data, false);
  }

  public getPayerList(): SelectItem[] {
    return [
      {
        label: 'Ontvanger',
        value: 'client'
      },
      {
        label: 'Leverancier',
        value: 'supplier'
      }
    ];
  }

  /**
   * Rollback status of given shipments
   *
   * @param ids
   */
  public rollbackShipmentStatuses(ids: number[]): Observable<any> {
    const data = { shipment_ids: ids };

    return this.put(`shipment/status-rollback`, data, false);
  }

  /**
   * Rollforward status of given shipments
   *
   * @param ids
   */
  public rollforwardShipmentStatuses(ids: number[]): Observable<any> {
    const data = { shipment_ids: ids };

    return this.put(`shipment/status-rollforward`, data, false);
  }

  public downloadPostNLReceipt(shipmentId: number): Observable<any> {
    return this.getBlob(`shipment/delivery-receipt/${shipmentId}`);
  }

  public getShipmentScanDetail(code: string): Observable<any> {
    return this.get(`shipment/scan/${code}`);
  }

  public saveShipmentScanDetail(shipmentScan: ShipmentScan): Observable<any> {
    return this.post('shipment/scan', shipmentScan);
  }
}
