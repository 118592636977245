import { Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../../base.component';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { FormGroup, NgForm } from '@angular/forms';
import { Shipment } from '../../../models/shipment.model';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { WizardStep } from '../../../components/printing-procedure-wizard/printing-procedure-wizard.component';
import { UserRole } from '../../../models/user-role.enum';
import { ShipmentStatus } from '../../../models/shipment-status.enum';
import { Order } from '../../../models/order.model';
import { SharedService } from '../../../services/shared.service';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER
} from '../../../services/authentication.service';
import { TransporterService } from '../../../services/transporter.service';
import { PackingService } from '../../../services/packing.service';
import { ShipmentService } from '../../../services/shipment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { Observable ,  forkJoin } from 'rxjs';
import * as moment from 'moment';
import { Collo } from '../../../models/collo.model';
import * as FileSaver from 'file-saver';
import { AddressService } from '../../../services/address.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class ShipmentDetailsComponent extends BaseComponent {
  @ViewChild('shipmentLabelModal')
  public shipmentLabelModal: CommonModalComponent;
  @ViewChild('printingModal')
  public printingModal: CommonModalComponent;
  @ViewChild('refreshOrderModal')
  public refreshOrderModal: CommonModalComponent;
  @ViewChild('editDeliveryAddressModal')
  public editDeliveryAddressModal: CommonModalComponent;
  @ViewChild('addAddressModal')
  public addAddressModal: CommonModalComponent;
  @ViewChild('shipmentForm')
  public shipmentForm: NgForm;
  @ViewChild('returnTypeModal')
  public returnTypeModal: CommonModalComponent;

  public shipment: Shipment;
  public minDate = new Date();
  public tomorrow = new Date();
  public selectedLoadDate: Date = null;
  public selectedDischargeDate: Date = null;
  public validationErrors: string[] = [];
  public calendarLocale: any = null;
  public statusList: SelectItem[];
  public packingList: SelectItem[];
  public transporterList: SelectItem[];
  public typeList: SelectItem[];
  public payerList: SelectItem[];
  public colloSupplierList: SelectItem[];
  public trackAndTraceOptions: SelectItem[];
  public barcode: string;
  public displayBarCodeError: boolean;
  public shipmentLabelLoading: boolean;
  public shipmentPackingSlipLoading: boolean;
  public postNLReceiptLoading: boolean;
  public sendToTransporterLoading: boolean;
  public isRoleSupplier: boolean = false;
  public isRoleCustomer: boolean = false;
  public readonly: boolean = false;
  public wizardSteps: WizardStep[];
  public userRoles = UserRole;
  public shipmentStatuses = ShipmentStatus;
  public rollbackStatus: { value: ShipmentStatus, label: string };
  public selectedOrders: Order[] = [];
  public isRefused: boolean = false;
  public copyShipmentEnabled: boolean = false;
  public clientAddresses: SelectItem[];
  public editAddress: boolean;
  public hasReturnWasteField: boolean;
  public hasTwoDecimalsPattern = /^[0-9]+(\,[0-9]{1,2})?$/;
  public shipmentFormSubmitted: boolean = false;

  constructor(private sharedService: SharedService,
              private authService: AuthenticationService,
              private transporterService: TransporterService,
              private packingService: PackingService,
              private shipmentService: ShipmentService,
              private router: Router,
              private route: ActivatedRoute,
              private addressService: AddressService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService) {
    super();

    this.shipment = new Shipment();
    this.fillTransporters();

    if (!this.shipment.has_track_and_trace) {
      this.shipment.has_track_and_trace = false;
    }

    this.trackAndTraceOptions = [
      {
        label: 'Nee',
        value: false,
      },
      {
        label: 'Ja',
        value: true,
      },
    ];

    this.tomorrow.setDate(this.minDate.getDate() + 1);
    this.calendarLocale = this.sharedService.getCalendarLocale();
  }

  public ngOnInit(): void {
    this.fillTransporters();
    this.editAddress = false;

    this.hasReturnWasteField = this.authService.isRoleAuthorized(
      [ROLE_MANAGER, ROLE_LOGISTIC_EMPLOYEE, ROLE_HUB_OPERATOR]
    );

    if (this.authService.isRoleAuthorized([ROLE_MANAGER])) {
      this.minDate = null;
    }

    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    this.packingService.list().subscribe((packings) => {
      this.packingList = packings;
    });

    this.shipmentService.getTypesList().subscribe((types: any) => {
      this.typeList = types;
    });

    this.payerList = this.shipmentService.getPayerList();

    this.wizardSteps = [
      {
        stepTitle: 'VerzendLabel',
        buttonIconClass: 'fas fa-print',
        buttonText: 'Print verzendlabel',
        callback: (barcode: string) => {
          this.shipmentService.printShipmentLabels([this.shipment.id], barcode).subscribe(_ => {
            this.loadShipment();
          }, (error) => {
            this.sharedService.handleError(error, 'Label kon niet worden geprint.');
            this.loadShipment();
          });
        },
        shipmentLabelModal: true
      },
      {
        stepTitle: 'Pakbon zending',
        buttonIconClass: 'fas fa-print',
        buttonText: 'Print pakbon',
        callback: () => {
          return new Observable((observer) => {
            this.shipmentService.printShipmentPackingSlips([this.shipment.id]).subscribe(_ => {
              observer.next();
              observer.complete();
              this.printingModal.hide();
            }, (error) => {
              this.sharedService.handleError(error, 'Pakbon kon niet worden geprint.');
            });
          });
        }
      }
    ];

    this.printingModal.onHidden(() => {
      // Reload shipment
      this.loadShipment();
    });

    this.loadShipment();
  }

  public selectDate(event: Date, field: string): void {
    let momentDate = moment(event);
    this.shipment[field] = momentDate.format('YYYY-MM-DD');
  }

  public addCollo(): void {
    let colli = [...this.shipment.colli];

    // Add new collo
    let newCollo = new Collo();
    newCollo.amount = 1;
    newCollo.packing_id = null;
    newCollo.payer = this.shipment.client.customer || 'client';

    if (newCollo.payer === 'supplier' && this.colloSupplierList.length === 1) {
      newCollo.supplier_id = this.colloSupplierList[0].value;
    } else if (newCollo.payer === 'client') {
      newCollo.client_id = this.shipment.client.id;
    }

    const supplierIds = this.shipment.orders.map((order) => {
      return order.supplier_id;
    });

    if (supplierIds.length === 1) {
      newCollo.supplier_id = supplierIds[0];
    }

    colli.push(newCollo);

    this.shipment.colli = colli;
  }

  public deleteCollo(index: number): void {
    let colli = [...this.shipment.colli];

    // Delete collo
    colli.splice(index, 1);

    // Ugly hack to remove form control for collo
    const controlIndex = index + 1;
    delete this.shipmentForm.controls['height_' + controlIndex];
    delete this.shipmentForm.controls['weight_' + controlIndex];
    delete this.shipmentForm.controls['length_' + controlIndex];
    delete this.shipmentForm.controls['width_' + controlIndex];
    delete this.shipmentForm.controls['supplier_' + controlIndex];
    delete this.shipmentForm.controls['payer_' + controlIndex];
    delete this.shipmentForm.controls['packing_' + controlIndex];

    this.shipment.colli = colli;
  }

  public saveShipment(form: FormGroup): void {
    this.shipmentFormSubmitted = true;

    if (form.valid) {
      this.shipmentService.updateShipment(this.shipment).subscribe((result) => {
        this.validationErrors = [];
        this.messageService.success(MessageSummary['OK'], 'De zending is opgeslagen');

        setTimeout(() => {
          window.close();
        }, 500);
      }, (error: any) => this.handleError(error));
    } else {
      this.messageService.error(MessageSummary['SAVE_SHIPMENT'], 'Vul a.u.b. alle verplichte velden in');
    }
  }

  public downloadShippingLabelClicked(): void {
    this.shipmentFormSubmitted = true;

    if (this.shipment.transporter.has_api || this.shipment.transporter.has_internal_barcode) {
      this.downloadShippingLabel();
    } else {
      this.shipmentLabelModal.show();
    }
  }

  public downloadPackingSlip(): void {
    this.shipmentFormSubmitted = true;
    this.shipmentPackingSlipLoading = true;

    this.shipmentService.getShipmentPackingSlips([this.shipment.id]).subscribe((blob) => {
      FileSaver.saveAs(blob, `${this.shipment.code}-zending-pakbon.pdf`);
      this.shipmentPackingSlipLoading = false;
    }, _ => {
      this.shipmentPackingSlipLoading = false;
      this.messageService.error(MessageSummary['SERVER_ERROR'], 'Er is iets fout gegaan tijdens het opvragen van het document.');
    });
  }

  public downloadPostNLReceipt(): void {
    this.shipmentFormSubmitted = true;
    this.postNLReceiptLoading = true;

    this.shipmentService.downloadPostNLReceipt(this.shipment.id).pipe(
      finalize(() => this.postNLReceiptLoading = false)
    ).subscribe((response: Blob) => {
      FileSaver.saveAs(response, `${this.shipment.code}-postNL-ontvangstbewijs.pdf`);
    }, _ => {
      this.messageService.error(MessageSummary['SERVER_ERROR'], 'Er is iets fout gegaan tijdens het opvragen van het document.');
    });
  }

  public isDisabled(): boolean {
    if (this.shipment && this.shipment.transporter) {
      return (this.shipment.transporter_id === 1 && this.shipment.status !== 'open');
    }

    return false;
  }

  public isSaveDisabled(isButton: boolean = false): boolean {
    if (this.shipment && this.shipment.type === 'return-waste') {
      return !(this.shipment.status === 'delivered' || this.shipment.status === 'open');
    }

    if (this.shipment && this.shipment.transporter && !isButton) {
      return (this.shipment.transporter_id === 1 && this.shipment.status !== 'open');
    }

    return false;
  }

  public onPayerChange(colloIndex: number, payer: string): void {
    if (payer === 'client') {
      this.shipment.colli[colloIndex].supplier_id = null;
      this.shipment.colli[colloIndex].client_id = this.shipment.client_id;
    } else {
      this.shipment.colli[colloIndex].client_id = null;
    }
  }

  public confirmSendToTransporter(): void {
    const datesValid = (this.shipment.load_date && this.shipment.discharge_date);

    this.shipmentFormSubmitted = true;

    if (this.shipment.colli.length === 0) {
      this.messageService.error(MessageSummary['BAD_INPUT'], 'Voeg tenminste 1 collo toe.');
    } else if (!this.shipmentForm.valid || !datesValid) {
      this.messageService.error(MessageSummary['BAD_INPUT'], 'Vul alle velden correct in.');
    } else {
      this.confirmationService.confirm({
        key: 'shipment',
        message: 'De zending wordt nu doorgestuurd naar de vervoerder, weet u het zeker?',
        accept: () => {
          [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
            element.style.display = 'none';
          });

          // Save shipment and send to transporter
          this.shipmentService.updateShipment(this.shipment).subscribe((result) => {
            this.sendToTransporter();
          }, (error: any) => this.handleError(error));
        },
        reject: () => {
          [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
            element.style.display = 'none';
          });
        }
      });
    }
  }

  /**
   * Open the printing modal
   */
  public openPrintingModal(): void {
    this.shipmentFormSubmitted = true;
    this.printingModal.show();
  }

  public downloadShippingLabel(barcode: string = null): void {
    this.shipmentLabelLoading = true;
    this.displayBarCodeError = false;

    this.shipmentService.getShipmentLabels([this.shipment.id], barcode).subscribe((blob) => {
      FileSaver.saveAs(blob, `${this.shipment.code}-zending-label.pdf`);
      this.shipmentLabelLoading = false;
      this.shipmentLabelModal.hide();
      this.barcode = null;
      this.displayBarCodeError = false;
      this.loadShipment();
    }, (error) => {
      this.shipmentLabelLoading = false;
      this.messageService.error(MessageSummary['SERVER_ERROR'], 'Er is iets fout gegaan tijdens het opvragen van het document.');
      this.sharedService.handleError(error, 'Label kon niet worden gedownload.');
      this.loadShipment();
    });
  }

  /**
   * Close the printing modal
   */
  public closePrintingModal(): void {
    this.printingModal.hide();
  }

  /**
   * Update the status of this shipment
   *
   * @param status
   */
  public updateStatus(status: ShipmentStatus): void {
    this.shipmentFormSubmitted = true;

    this.shipmentService.updateShipment(this.shipment).subscribe(() => {
      this.shipment.status = status;
      this.messageService.success(MessageSummary['OK'], 'Zending status opgeslagen');
      this.rollbackStatus = this.getRollbackStatus(status);
    });
  }

  /**
   * Display confirm dialog to rollback the status of the shipment
   *
   * @param status
   */
  public showConfirmRollback(status: { value: ShipmentStatus, label: string }): void {
    this.shipmentFormSubmitted = true;

    this.confirmationService.confirm({
      message: `Weet u zeker dat u de status terug wilt zetten naar: ${status.label}`,
      key: 'shipment',
      accept: () => {
        this.updateStatus(status.value);
      }
    });
  }

  /**
   * Cancel the current shipment
   */
  public cancelShipment(): void {
    this.confirmationService.confirm({
      message: 'Weet u zeker dat u de zending wilt annuleren?',
      key: 'shipment',
      accept: () => {
        this.shipmentService.cancelShipment(
          this.shipment,
          this.selectedOrders.map((order) => order.id)
        ).subscribe(() => {
          this.shipment.status = ShipmentStatus.CANCELLED;
          this.messageService.success(MessageSummary['OK'], 'De zending is geannuleerd');

          this.refreshOrderModal.hide();
        }, (error) => {
          this.handleError(error, 'Zending annuleren');
        });
      }
    });
  }

  /**
   * Copy the current shipment
   */
  public copyShipment(): void {
    this.shipmentService.copyShipment(
      this.shipment,
      this.selectedOrders.map((order) => order.id)
    ).subscribe((newShipment) => {
      this.messageService.success(MessageSummary['OK'], 'De zending is gekopieerd');
      this.router.navigate([`/pages/shipments/${newShipment.id}`]);

      this.refreshOrderModal.hide();
    }, (error) => {
      this.handleError(error, 'Zending kopieren');
    });
  }

  /**
   * Return the current shipment
   */
  public returnShipment(): void {
    this.shipmentFormSubmitted = true;

    [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
      element.style.display = 'none';
    });

    // Save shipment and send to transporter
    this.shipmentService.copyShipment(
      this.shipment,
      this.shipment.orders.map((order) => order.id),
      true
    ).pipe(
      finalize(() => this.returnTypeModal.hide())
    ).subscribe((newShipment) => {
      this.messageService.success(MessageSummary['OK'], 'Retourzending aangemaakt');
      this.router.navigate([`/pages/shipments/${newShipment.id}`]);
    }, (error) => {
      this.handleError(error, 'Zending retourneren');
    });
  }

  public isPostNL(): boolean {
    if (this.shipment.transporter_id) {
      return this.shipment.transporter_id === 1 && this.shipment.to_postnl;
    }

    return false;
  }

  public isDeudekom(): boolean {
    if (this.shipment.transporter_id) {
      return this.shipment.transporter_id === 3 && this.shipment.to_postnl;
    }

    return false;
  }

  public showOrderRefreshModal(): void {
    this.shipmentFormSubmitted = true;
    this.copyShipmentEnabled = false;
    this.refreshOrderModal.show();
  }

  public openCopyShipment(): void {
    this.shipmentFormSubmitted = true;
    this.copyShipmentEnabled = true;
    this.refreshOrderModal.show();
  }

  public returnOrder(orderId: number): void {
    // Create a shipment...
    this.router.navigate([`/pages/orders/${orderId}/return`]);
  }

  public openOrderDetail(orderId: number): void {
    this.sharedService.openNewWindow(`pages/orders/${orderId}`);
  }

  public setShipmentFinished(): void {
    this.shipmentFormSubmitted = true;
    this.shipment.finished = true;

    this.shipmentService.updateShipment(this.shipment).subscribe((res) => {
      this.messageService.success(MessageSummary['OK'], 'De zending is afgehandeld');
    });
  }

  public onAddressChange(value: number): void {
    if (value === -1) {

    }
  }

  public exportScans(): void {
    this.shipmentService.exportScans(this.shipment.id).subscribe((response: Blob) => {
      const timeStamp = new Date().getTime();
      const fileName = `Export scans - ${this.shipment.code} - ${timeStamp}`;

      FileSaver.saveAs(response, fileName + '.xlsx');
    }, (errorResponse) => {
      this.sharedService.handleError(errorResponse, 'Export downloaden');
    });
  }

  public trackAndTrace(url: string): void {
    if (url === null) {
      return;
    }

    window.open(url, '_blank');
  }

  public displayReturnTypeModal(): void {
    this.returnTypeModal.show();
  }

  public canBeReturned(): boolean {
    return this.shipment.status === this.shipmentStatuses.DELIVERED && this.shipment.type !== 'return-waste';
  }

  private loadShipment(): void {
    this.route.params.subscribe((params) => {
      forkJoin(
        this.shipmentService.getStatusList(),
        this.shipmentService.getShipment(+params['id'])
      ).subscribe((result) => {
        this.statusList = result[0];
        this.shipment = result[1];
        this.shipment.orders = result[1].orders.data;
        this.fillTransporters();
        this.shipment.track_and_trace_email = this.shipment.track_and_trace_email ?  this.shipment.track_and_trace_email : this.shipment.orders[0].contactperson ? this.selectedOrders[0].contactperson.email : null;

        this.shipment.colli = result[1].colli.data;
        this.shipment.scans = result[1].scans.data;
        this.barcode = result[1].barcode;

        this.getClientAddresses(this.shipment.client_id);

        if (result[1].load_date) {
          const momentDate = moment(result[1].load_date);
          this.selectedLoadDate = momentDate.toDate();
          this.shipment.load_date = momentDate.format('YYYY-MM-DD');
        }

        if (result[1].discharge_date) {
          const momentDate = moment(result[1].discharge_date);
          this.selectedDischargeDate = momentDate.toDate();
          this.shipment.discharge_date = momentDate.format('YYYY-MM-DD');
        }

        const colloSupplierList = this.shipment.orders.map((order: Order) => {
          return {
            label: order.supplier_name,
            value: order.supplier_id
          };
        });

        this.colloSupplierList = [];
        colloSupplierList.forEach((supplier: SelectItem) => {
          const supplierExists = this.colloSupplierList.some(colloSupplier => colloSupplier.value === supplier.value);

          if (!supplierExists) {
            this.colloSupplierList.push(supplier);
          }
        });

        this.rollbackStatus = this.getRollbackStatus(this.shipment.status);

        this.isRefused = this.shipment.status === this.shipmentStatuses.REFUSED;
      });
    });
  }

  private fillTransporters(): void {
    let orderIds: number[] = [];
    if(this.selectedOrders && this.selectedOrders.length > 0) {
      orderIds = this.selectedOrders.map((order) => {
        return order.id;
      });
    } else if (this.shipment && this.shipment.orders){
      orderIds =this.shipment.orders.map((order) => {
        return order.id;
      });
    }

    this.transporterService.list(orderIds).subscribe((transporters) => {
      this.transporterList = transporters;
    });
  }

  private sendToTransporter(): void {
    this.sendToTransporterLoading = true;

    this.shipmentService.sendToTransporter(this.shipment.id).subscribe(_ => {
      this.sendToTransporterLoading = false;
      this.loadShipment();
      this.messageService.success(MessageSummary['OK'], 'De zending is doorgestuurd naar de vervoerder');
    }, _ => {
      this.sendToTransporterLoading = false;
    });
  }

  private handleError(error: any, summary: string = 'Zending', message: string = 'Zending opslaan mislukt.'): void {
    this.validationErrors = [];

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);
            });
          }
        }
      }
    }

    this.messageService.error(summary, message);
  }

  /**
   * Get the status to rollback to
   *
   * @param status
   *
   * @return {label: string, value: ShipmentStatus} | null
   */
  private getRollbackStatus(status: ShipmentStatus): { label: string, value: ShipmentStatus } | null {
    let rollbackStatus: { label: string, value: ShipmentStatus } = { label: null, value: null };

    switch (status) {
      case ShipmentStatus.PLANNED:
        if (this.shipment.transporter_id !== 1) {
          rollbackStatus.value = ShipmentStatus.OPEN;
        }

        break;
      case ShipmentStatus.CANCELLED:
        rollbackStatus.value = ShipmentStatus.OPEN;
        break;
      case ShipmentStatus.INVOICED:
        rollbackStatus.value = ShipmentStatus.DELIVERED;
        break;
      default:
        rollbackStatus.value = null;
        break;
    }

    if (rollbackStatus) {
      const statusInList = this.statusList.find((shipmentStatus) => shipmentStatus.value === rollbackStatus.value);
      if (statusInList) {
        rollbackStatus = { value: rollbackStatus.value, label: statusInList.label };
      }
    }

    return rollbackStatus;
  }

  private getClientAddresses(clientId: number): void {
    this.addressService.getClientAddresses(clientId, 'discharge').subscribe((addresses) => {
      this.clientAddresses = addresses.map(({ full_address, id }) => {
        return {
          label: full_address,
          value: id
        };
      });

      this.clientAddresses.unshift({ value: -1, label: 'Adres toevoegen' });
    });
  }
}
