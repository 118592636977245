
import { filter, takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Order } from '../../../models/order.model';
import { OrderService } from '../../../services/order.service';
import { Observable, Subject } from 'rxjs';
import { MessageService } from '../../../services/message.service';
import { ShipmentService } from '../../../services/shipment.service';

@Component({
  selector: 'app-return-order',
  templateUrl: './return-order.component.html',
  styleUrls: ['./return-order.component.scss']
})
export class ReturnOrderComponent implements OnInit, OnDestroy {
  public order$: Observable<Order>;

  private destroy$ = new Subject<boolean>();

  constructor(private route: ActivatedRoute,
              private messageService: MessageService,
              private shipmentService: ShipmentService,
              private orderService: OrderService) {
  }

  public ngOnInit(): void {
    this.order$ = this.route.params.pipe(
      filter((params) => !!params.id)).pipe(
      takeUntil(this.destroy$))
      .mergeMap((params) => this.orderService.getOrder(params.id));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
