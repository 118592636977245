import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import * as FileSaver from 'file-saver';

import { CommonModalComponent } from '../modal/child.modal';

import { SharedService } from '../../services/shared.service';
import { ShipmentOrderService } from '../../services/shipmentOrder.service';
import { MessageService, MessageSummary } from '../../services/message.service';

@Component({
  selector: 'app-shipment-order-result',
  templateUrl: './shipment-order-result.component.html',
  styleUrls: ['./shipment-order-result.component.scss']
})
export class ShipmentOrderResultComponent {
  @ViewChild('exportModal') exportReasonModal: CommonModalComponent;
  @ViewChild('confirmModal') confirmModal: CommonModalComponent;
  @ViewChild('fileInput') fileInput;

  @Input() shipmentOrder;
  @Input() client;
  @Input() isRoleCustomer;
  @Input() isRoleGuest;
  @Input() defaults;
  @Input() nonEu;
  @Input() isModal;
  @Input() commercialInvoiceExists = false;

  @Output() storeShipmentOrder = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Output() onBack = new EventEmitter();
  @Output() onCreateCommercialInvoice = new EventEmitter();

  exportReasons: any[] = [];
  reasonForExport = '';
  alternateReason: string;
  commercialInvoiceFile: File = null;
  upload: boolean;
  selectedMethod: boolean = false;

  constructor(
    public sharedService: SharedService,
    protected shipmentOrderService: ShipmentOrderService,
    protected confirmationService: ConfirmationService,
    private messageService: MessageService) {
  }

  save(type?: string) {
    if (!type) {
      type = 'close';
    }

    this.storeShipmentOrder.emit({ type: type, file: this.commercialInvoiceFile });
  }

  cancel() {
    this.onCancel.emit();
  }

  back() {
    this.onBack.emit();
  }

  getLabel(id) {
    event.stopPropagation();
    let downloadPopup = this.sharedService.openPopupWindow();

    this.shipmentOrderService.getLabel(id).subscribe((response) => {
      let fileUrl = URL.createObjectURL(response);

      this.sharedService.popupWindowSetContent(downloadPopup, fileUrl);
    }, (error: any) => {
      downloadPopup.close();
      this.handleError(error);
    });
  }

  getCountryById(id) {
    let country = this.defaults.countries.find(c => c.id === id);

    if (country) {
      return country.name;
    }

    return null;
  }

  handleError(error: any, message = 'Kan geen verzending opslaan') {
    if (error) {
      if (error.status && error.status === 404) {
        message = 'Er bestaat geen label voor deze verzending.';
      } else {
        const result = error.json();

        if (result.message) {
          message = result.message;
        }

        if (result.errors) {
          Object.keys(result.errors).forEach(function (err) {
            message += '<br>' + result.errors[err][0];
          });
        }
      }
    }

    this.messageService.error(MessageSummary['SAVE_SHIPMENT'], message);
  }

  openExportReasonModal() {
    this.onCreateCommercialInvoice.emit();
  }

  createCommercialInvoice(form) {
    if (!form.valid) {
      this.messageService.error(MessageSummary['REQUIRED_FIELDS'], 'Vul alle verplichte velden in. (*)');
    } else {
      if (this.reasonForExport === '0') {
        this.shipmentOrder.alternate_export_reason = this.alternateReason;
        this.shipmentOrder.export_reason_id = null;
      } else {
        this.shipmentOrder.export_reason_id = this.reasonForExport;
      }

      this.exportReasonModal.hide();
      this.exportReasonModal.onHidden(() => {
        if (this.commercialInvoiceExists) {
          this.confirmModal.show();
        } else {
          this.save('commercial-invoice');
        }
      });
    }
  }

  onFileChange(fileInput: any) {
    this.commercialInvoiceFile = fileInput.target.files[0];
  }

  selectMethod(upload: boolean) {
    this.selectedMethod = true;
    this.upload = upload;
  }

  submitUpload(form) {
    if (this.commercialInvoiceFile === null) {
      this.messageService.error(MessageSummary['SELECT_FILE'], 'Selecteer een pdf bestand om te uploaden');
    } else {
      this.exportReasonModal.hide();
      if (this.commercialInvoiceExists) {
        this.confirmModal.show();
      } else {
        this.save();
      }
    }
  }

  onRejectConfirmModal() {
    this.confirmModal.hide();
    this.commercialInvoiceFile = null;
    this.fileInput.nativeElement.value = '';
    this.save();
  }

  downloadCommercialInvoice(id) {
    this.shipmentOrderService.downloadCommercialInvoice(id).subscribe((response) => {
      FileSaver.saveAs(response, 'commercial-invoice.pdf');
    }, err => {
      this.handleError(err);
    });
  }
}
