import { Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customDate'
})
export class CustomDate {
  transform(value: any, format: string = '', input: string = null): string {
    if (value === null || value === '' || value === undefined) {
      return null;
    }

    // Try and parse the passed value
    let momentDate = moment(value, input);

    // If moment didn't understand the value, return it unformatted
    if (!momentDate.isValid()) {
      return value;
    }

    // Otherwise, return the date formatted as requested
    return momentDate.format(format);
  }
}
