import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FileUpload, SelectItem } from 'primeng/primeng';
import { SupplierService } from '../../services/supplier.service';
import { AuthenticationService, ROLE_SUPPLIER } from '../../services/authentication.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-order-import-modal',
  templateUrl: './order-import-modal.component.html',
  styleUrls: ['./order-import-modal.component.scss']
})
export class OrderImportModalComponent {

  private suppliers: SelectItem[];
  public selectedSupplier: number;
  public isRoleSupplier: boolean = false;

  @Output()
  public onSubmit: EventEmitter<{ file: File, supplierId: number }>;

  @ViewChild('fileUpload')
  fileUpload: FileUpload;

  constructor(private supplierService: SupplierService,
              private authService: AuthenticationService,
              private messageService: MessageService) {
    this.onSubmit = new EventEmitter();
    this.selectedSupplier = this.authService.getUser().supplier_id;
    this.suppliers = [{ label: 'Selecteer Leverancier', value: null }];

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
    }


    if(!this.isRoleSupplier) {
      this.supplierService.list().subscribe((suppliers) => {
        this.suppliers.push(...suppliers);
      });
    }
  }

  /**
   * Process uploaded file
   *
   * @param event
   */
  public uploadHandler(event: any): void {
    if (!(event.files.length > 0)) {
      return;
    }
  }

  /**
   * Submit the form
   */
  public submit(): void {
    if (!this.fileUpload.files[0]) {
      this.messageService.error('Er is iets fout gegaan', 'Er is geen bestand geselecteerd');

      return;
    }

    if (!this.selectedSupplier) {
      this.messageService.error('Er is iets fout gegaan', 'Er is geen leverancier geselecteerd');

      return;
    }

    this.onSubmit.emit({ file: this.fileUpload.files[0], supplierId: this.selectedSupplier });
  }
}
