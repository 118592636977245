
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Address } from '../models/address.model';

@Injectable()
export class AddressService extends ApiService {

  /**
   * Create an address
   *
   * @param address
   *
   * @return Observable<Address>
   */
  public create(address: Address): Observable<Address> {
    return this.post('address', address);
  }

  public getClientAddresses(clientId: number, type: string): Observable<Address[]> {
    return this.get(`address/client/${clientId}?type=${type}`).pipe(map((response: any) => response.data));
  }
}
