import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { DeliveryAgreement } from '../../models/deliveryAgreement.model';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-delivery-agreement-table',
  templateUrl: './delivery-agreement-table.component.html',
  styleUrls: ['./delivery-agreement-table.component.scss']
})
export class DeliveryAgreementTableComponent {
  @Input()
  public deliveryAgreements: DeliveryAgreement[];

  @Output()
  public deleteDeliveryAgreement = new EventEmitter<DeliveryAgreement>();

  @Output()
  public saveDeliveryAgreement = new EventEmitter<DeliveryAgreement>();

  @Output()
  public updateDeliveryAgreement = new EventEmitter<DeliveryAgreement>();

  public tableVisible: boolean;
  public weekDays: SelectItem[];

  constructor() {
    this.tableVisible = true;
    this.weekDays = SharedService.getWeekDaysList();
  }

  public delete(deliveryAgreement: DeliveryAgreement): void {
    // let colli = [...this.shipment.colli];
    //
    // // Delete collo
    // const index = colli.findIndex(collo => collo.id === colloId);
    // colli.splice(index, 1);
    //
    // // Reset numbers for colli
    // for (let i = 0; i < colli.length; i++) {
    //   colli[i].num = i + 1;
    // }
    //
    // this.shipment.colli = colli;
  }

  public addDeliveryAgreement(): void {
    const newDeliveryAgreement = new DeliveryAgreement();

    this.deliveryAgreements.push(newDeliveryAgreement);

    // Refresh table
    this.tableVisible = false;
    setTimeout(() => {
      this.tableVisible = true;
    }, 0);

    // this.isEdit = false;
    // this.editableDeliveryAgreement = null;
    // this.deliveryAgreementForm.reset();
    // this.deliveryAgreementModal.show();
  }
}
