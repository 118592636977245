
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { SelectItem } from 'primeng/primeng';
import { ROLE_TRANSPORTER } from './authentication.service';

@Injectable()
export class AdditionalSellRateService extends ApiService {

  public getAdditionalSellRates(
    event: any,
    page: number = 1,
    perPage: number = 20,
    type?: string,
    typeId?: number
  ): Observable<any> {
    if (type && typeId) {
      if (event.filters) {
        event.filters[`${type}_id`] = { value: typeId };
      } else if (type === 'client') {
        event.filters = {
          'client_id': {
            value: typeId
          }
        };
      } else if (type === 'supplier') {
        event.filters = {
          'supplier_id': {
            value: typeId
          }
        };
      }
    }

    return this.post(`additional-sell-rates/filter?page=${page}&perPage=${perPage}`, event).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public getAll(): Observable<any> {
    return this.get('additional-sell-rates');
  }

  public list(): Observable<any> {
    return this.get('additional-sell-rates/list').pipe(map((response: any) => response.sell_rates));
  }

  public getAdditionalSellRateTypeList(): SelectItem[] {
    const isRoleTransporter = this.authService.isRoleAuthorized([ROLE_TRANSPORTER]);
    const types = [
      {
        label: 'Transportdiensten',
        value: 'transportdiensten'
      }
    ];

    if (!isRoleTransporter) {
      types.push({
        label: 'HUBdiensten',
        value: 'hubdiensten'
      }, {
        label: 'Opslag',
        value: 'opslag'
      });
    }

    return types;
  }

  public getAdditionalSellRateFrequencyList(): SelectItem[] {
    return [{
      label: 'per dag',
      value: 'per dag'
    }, {
      label: 'per week',
      value: 'per week'
    }, {
      label: 'per maand',
      value: 'per maand'
    }];
  }

  public export(): Observable<Blob> {
    return this.getBlob(`additional-sell-rates/export`);
  }

  public import(file: File): Observable<any> {
    return this.uploadFile('additional-sell-rates/import', file);
  }
}
