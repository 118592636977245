import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { AdditionalSellRateSupplier } from '../models/additionalSellRateSupplier.model';

@Injectable()
export class ClientsService extends ApiService {
  public getClients(data: any, page: number = 1, perPage: number = 20): Observable<any> {
    return this.post(`clients/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public getClient(id: number): Observable<any> {
    return this.get('clients/' + id + '/edit');
  }

  public list(valueProperty?: string, labelProperties?: string[]): Observable<any> {
    let url = 'clients/list';

    if (valueProperty && labelProperties) {
      url += `?_valueProperty=${valueProperty}&_labelProperties=${labelProperties.join(',')}`;
    }

    return this.get(url).pipe(map((response: any) => response.clients));
  }

  public addClient(client: any): Observable<any> {
    return this.post('clients', client);
  }

  public updateClient(client: any): Observable<any> {
    return this.put('clients', client);
  }

  public addAdditionalSellRate(
    clientId: number,
    additionalSellRateSupplier: AdditionalSellRateSupplier
  ): Observable<any> {
    return this.post(`clients/${clientId}/additional-sell-rates`, additionalSellRateSupplier);
  }

  public updateAdditionalSellRate(
    clientId: number,
    additionalSellRateSupplier: AdditionalSellRateSupplier
  ): Observable<any> {
    return this.put(`clients/${clientId}/additional-sell-rates`, additionalSellRateSupplier);
  }

  public deleteAdditionalSellRate(clientId: number, id: number): Observable<any> {
    return this.delete(`clients/${clientId}/additional-sell-rates/${id}`);
  }
}
