import { BaMenuService } from '../../../theme/services/baMenu';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_SUPPLIER
} from '../../../services/authentication.service';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import * as FileSaver from 'file-saver';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { SharedService } from '../../../services/shared.service';
import { ShipmentService } from '../../../services/shipment.service';
import { Shipment } from '../../../models/shipment.model';
import { ShipmentStatus } from '../../../models/shipment-status.enum';
import { ShipmentExport } from '../../../models/shipment-export';
import { TransporterService } from '../../../services/transporter.service';
import { DatePipe, formatDate } from '@angular/common';
import { isArray } from 'rxjs/internal-compatibility';

@Component({
  selector: 'shipment-list-component',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit {
  @ViewChild('exportModal')
  public exportModal: CommonModalComponent;

  public exportErrors: string[];
  public tableVisible: boolean = true;
  public status: string;
  public records: Shipment[] = [];
  public totalRecords: number = 0;
  public loading: boolean = true;
  public filters: { [key: string]: { value: string | number, matchMode: string } } = {};
  public transporters: SelectItem[] = [];
  public selectedTransporter: number;
  public clients: SelectItem[] = [];
  public selectedClient: number;
  public selectedShipments: Shipment[] = [];
  public packings: SelectItem[] = [];
  public shipmentStatuses: SelectItem[] = [];
  public shipmentTypes: SelectItem[] = [];
  public selectedShipmentStatus: string = '';
  public selectedShipmentType: string = '';
  public calendarLocale: any = null;
  public suppliers: SelectItem[] = [];
  public isRoleSupplier: boolean = false;
  public isRoleCustomer: boolean = false;
  public isRoleHubOperator: boolean = false;
  public readonly: boolean = false;
  public bulkActionsEnabled: boolean = false;
  public bulkActions: SelectItem[];
  public selectedBulkAction: string;
  public selectedLoadDateFilter: string;
  public selectedDischargeDateFilter: string;
  public selectedCreatedAtFilter: string;
  public tableEvent: any;
  public isUndelivered: boolean;
  public paginationAmount: number = 30;

  constructor(
    public sharedService: SharedService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected shipmentService: ShipmentService,
    protected authService: AuthenticationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private menuService: BaMenuService,
    private transporterService: TransporterService,
    private datepipe: DatePipe) {
    this.calendarLocale = this.sharedService.getCalendarLocale();
  }

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.route.params.subscribe((params: any) => {
      if (params.status) {
        this.isUndelivered = params.status === 'undelivered';

        if (params.status !== this.status) {
          this.status = params.status;

          // Refresh table
          this.tableVisible = false;
          setTimeout(() => {
            this.tableVisible = true;
          }, 0);
        }
      }
    });

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
      this.readonly = true;

      this.filters = {
        client_id: {
          value: this.authService.getUser().client_id,
          matchMode: 'equals'
        }
      };
    }

    if (this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR])) {
      this.isRoleHubOperator = true;
      this.readonly = false;
    }

    this.shipmentService.getStatusList().subscribe(result => {
      this.shipmentStatuses = result;
    });

    this.shipmentTypes = [
      {
        label: 'Alle',
        value: ''
      },
      {
        label: 'Reguliere stroom',
        value: 'regular-flow'
      },
      {
        label: 'Retourstroom goederen',
        value: 'return-goods'
      },
      {
        label: 'Retourstroom emballage',
        value: 'return-packaging'
      },
      {
        label: 'Retourstroom afval',
        value: 'return-waste'
      }
    ];

    if (this.isUndelivered === true) {
      this.shipmentStatuses = [
        {
          label: 'Alle',
          value: ShipmentStatus.UNDELIVERED
        },
        {
          label: 'Zending aangemaakt',
          value: ShipmentStatus.OPEN
        },
        {
          label: 'Aangemeld bij vervoerder',
          value: ShipmentStatus.REGISTERED
        },
        {
          label: 'Geaccepteerd door vervoerder',
          value: ShipmentStatus.ACCEPTED
        },
        {
          label: 'Ritten gepland',
          value: ShipmentStatus.PLANNED
        },
        {
          label: 'Gereed voor transport',
          value: ShipmentStatus.READY_FOR_TRANSPORT
        },
        {
          label: 'Zending geladen',
          value: ShipmentStatus.LOADED
        },
        {
          label: 'Zending onderweg',
          value: ShipmentStatus.UNDERWAY
        },
        {
          label: 'Zending geweigerd',
          value: ShipmentStatus.REFUSED
        },
        {
          label: 'Afleveradres onbekend',
          value: ShipmentStatus.ADDRESS_UNKNOWN
        },
        {
          label: 'Afleveradres gesloten',
          value: ShipmentStatus.ADDRESS_CLOSED
        },
        {
          label: 'Afleveradres onbereikbaar',
          value: ShipmentStatus.ADDRESS_UNREACHABLE
        },
        {
          label: 'Niet geleverd, buiten tijdsvenster',
          value: ShipmentStatus.NOT_DELIVERED_OUTSIDE_TIME
        },
        {
          label: 'Voorgemeld, niet aangeleverd',
          value: ShipmentStatus.ACCEPTED_NOT_ARRIVED
        },
        {
          label: 'Ladingdrager afgehaald',
          value: ShipmentStatus.LOAD_CARRIER_RETRIEVED
        },
        {
          label: 'Zending gelost op Hub',
          value: ShipmentStatus.DELIVERED_AT_HUB
        },
        {
          label: 'Niet afgehaald, zending niet aanwezig',
          value: ShipmentStatus.NOT_RETRIEVED_NOT_AVAILABLE
        },
        {
          label: 'Zending niet afgehaald, geen of incorrect label aanwezig',
          value:  ShipmentStatus.NOT_RETRIEVED_INCORRECT_LABEL
        },
        {
          label: 'Zending niet goed verpakt',
          value: ShipmentStatus.INCORRECT_PACKING
        },
        {
          label: 'Verzender niet aanwezig',
          value: ShipmentStatus.DISPATCHER_UNAVAILABLE
        },
        {
          label: 'Afhaalopdracht geannuleerd',
          value: ShipmentStatus.PICKUP_REQUEST_CANCELLED
        }
      ];
    }

    this.bulkActions = [
      { label: 'Status rollforward', value: 'rollforward' },
      { label: 'Status rollback', value: 'rollback' },
      { label: 'Pakbonnen printen', value: 'print_packing_slips' },
      { label: 'Verzendlabels printen', value: 'print_labels' }
    ];
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(event: any): void {
    this.loading = true;

    this.tableEvent = event;

    if (this.filters.client_id) {
      event.filters = Object.assign(event.filters, { client_id: this.filters.client_id });
    }

    this.shipmentService.getShipments(
      event,
      event.first / event.rows + 1,
      event.rows,
      this.status
    ).subscribe((result) => {
      this.loading = false;
      this.records = result.data;
      this.totalRecords = result.pagination.total;

      this.transporters = this.sharedService.mapObjectToSelectItems(result.meta.transporters, 'Alle');
      this.clients = this.sharedService.mapObjectToSelectItems(result.meta.clients, 'Alle');
      this.packings = this.sharedService.mapObjectToSelectItems(result.meta.packings, 'Alle');
      this.suppliers = this.sharedService.mapObjectToSelectItems(result.meta.suppliers);
    });
  }

  public onRowClick(event: any): void {
    if (this.bulkActionsEnabled) {
      return;
    }

    this.sharedService.openNewWindow(`pages/shipments/${event.data.id}`);
  }

  public refreshTable(): void {
    this.loadData(this.tableEvent);
  }

  public enableBulkActions(): void {
    this.bulkActionsEnabled = !this.bulkActionsEnabled;
    this.selectedShipments = [];
  }

  public doBulkAction(): void {
    const shipmentIds = this.selectedShipments.map((shipment: Shipment) => {
      return shipment.id;
    });

    // Determine bulk action
    let action = null;
    switch (this.selectedBulkAction) {
      case 'rollforward':
        action = 'rollforwardShipmentStatuses';

        break;
      case 'rollback':
        action = 'rollbackShipmentStatuses';

        break;
      case 'print_packing_slips':
        action = 'printShipmentPackingSlips';

        break;
      case 'print_labels':
        action = 'printShipmentLabels';

        break;
      default:
        this.loading = false;
        this.selectedBulkAction = null;

        return;
    }

    if (action === 'rollbackShipmentStatuses') {
      this.confirmationService.confirm({
        message: 'Weet u zeker dat u een rollback wilt uitvoeren?',
        accept: () => {
          this.executeBulkAction(action, shipmentIds);
        }
      });
    } else {
      this.executeBulkAction(action, shipmentIds);
    }
  }

  /**
   * Open the shipment export modal
   */
  public openExportModal(): void {
    this.exportModal.show();
  }

  /**
   * Export given shipments
   *
   * @param data
   */
  public handleExportSubmit(data: ShipmentExport): void {

    let from: string = '';
    let to: string = null;

    if(isArray(data.date) && data.date[1]) {
      from = this.datepipe.transform(data.date[0], 'yyyy-MM-dd')
      to = this.datepipe.transform(data.date[1], 'yyyy-MM-dd')
    } else {
      from = this.datepipe.transform(data.date, 'yyyy-MM-dd')
    }
    this.transporterService.exportShipments(data.transporterId, data.hubId, data.distinctShipmentNumbers, from, to).subscribe((response) => {
        const timeStamp = new Date().getTime();
        let filenameDate: string = '';

      if(isArray(data.date) && data.date[1]) {
        filenameDate = `${this.datepipe.transform(data.date[0], 'dd-MM-yyyy')} - ${this.datepipe.transform(data.date[1], 'dd-MM-yyyy')}`;
        } else {
          filenameDate = this.datepipe.transform(data.date, 'dd-MM-yyyy');
        }

        const fileName = `Export Zendingen ${data.transporter} ${filenameDate} - ${timeStamp}`;
        FileSaver.saveAs(response, fileName + '.xlsx');

        this.exportModal.hide();
        this.messageService.success(MessageSummary['OK'], 'Export uitgevoerd');
    }, (error) => {
      this.messageService.handleError(error, 'Exporteren mislukt.');
    });
  }

  private executeBulkAction(action: string, shipmentIds: number[]): void {
    this.loading = true;

    // Perform bulk action
    this.shipmentService[action](shipmentIds).subscribe(() => {
      this.loading = false;
      this.messageService.success(MessageSummary['OK'], 'Bulkactie uitgevoerd.');

      // Reset selected orders and action
      this.selectedShipments = [];
      this.selectedBulkAction = null;
      this.bulkActionsEnabled = false;

      // Reload table
      this.refreshTable();
    }, (error) => {
      this.loading = false;
      this.selectedBulkAction = null;
      this.messageService.handleError(error, 'Bulkactie mislukt');
    });
  }
}
