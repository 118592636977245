import { Component, Input } from '@angular/core';

@Component({
  selector: 'tab',
  template: `
    <div [hidden]="!active" class="tab-pane active" [class.invalid-tab]="invalid" id="{{ id }}" role="tabpanel">
      <ng-content></ng-content>
    </div>
  `
})
export class TabComponent {
  @Input('tabTitle') title: string;
  @Input('tabIcon') icon: string;
  @Input('tabId') id: string;
  @Input('tabAction') action: string;
  @Input() active = false;
  @Input() invalid = false;
}
