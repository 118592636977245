import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthenticationService, ROLE_DELIVERER } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    protected authService: AuthenticationService,
    protected router: Router) {
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      // Not Authenticated
      this.router.navigate(['/auth/login']);

      return false;
    }

    if (this.authService.isRoleAuthorized(route.data['role'])) {
      // Access granted
      return true;
    }

    if (this.authService.isRoleAuthorized([ROLE_DELIVERER])) {
      this.router.navigate(['/pages/handheld-terminal']);
    } else {
      // Not Authorized
      this.router.navigate(['/pages/clients']);
    }

    return false;
  }
}
