/* tslint:disable:variable-name */
import { Hub } from './hub.model';
import { DeliveryAgreement } from './deliveryAgreement.model';

/**
 * Model Address
 */
export class Address {
  id: number;
  hub_id?: number;
  contactperson_id?: number;
  description?: string;
  type: string;
  postcode: string;
  street: string;
  house_number: number | string;
  house_number_suffix?: string;
  location: string;
  country_id: number | string;
  state_region_province?: string;
  extra_address_line?: string;
  full_address?: string;
  agreements?: string;
  hub?: Hub;
  delivery_agreements?: DeliveryAgreement[];
  client_id?: number;

  constructor() {
    this.country_id = 154; // Default is 'Netherlands'
  }
}
