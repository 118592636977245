import { FormGroup } from '@angular/forms';

export class DateValidator {
  public static dateLessThan(
    fromFormControlName: string,
    fromFormControlLabel: string,
    toFormControlName: string,
    toFormControlLabel: string
  ) {
    return (formGroup: FormGroup): { [key: string]: string } => {
      const fromDate = formGroup.get(fromFormControlName);
      const toDate = formGroup.get(toFormControlName);

      if (fromDate.value > toDate.value) {
        return {
          dates: `De datum ${fromFormControlLabel} moet voor de datum ${toFormControlLabel} liggen`
        };
      }

      return {};
    };
  }

  public static dateGreaterThan(
    fromFormControlName: string,
    fromFormControlLabel: string,
    toFormControlName: string,
    toFormControlLabel: string
  ) {
    return (formGroup: FormGroup): { [key: string]: string } => {
      const fromDate = formGroup.get(fromFormControlName);
      const toDate = formGroup.get(toFormControlName);

      if (fromDate.value < toDate.value) {
        return {
          dates: `De datum ${toFormControlLabel} moet na de datum ${fromFormControlLabel} liggen`
        };
      }

      return {};
    };
  }
}
