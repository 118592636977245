
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

// Todo: Remove shipment order service and dependencies through whole application
@Injectable()
export class ShipmentOrderService extends ApiService {
  public updateShipmentOrder(shipment): Observable<any> {
    return this.put(`shipment-order`, shipment);
  }

  public getLabel(id: number): Observable<Blob> {
    return this.getBlob(`shipment-order/${id}/label`).pipe(map((data: Blob) => {
      return new Blob([data], { type: 'application/pdf' });
    }));
  }

  public checkIfCommercialInvoiceExists(id): Observable<any> {
    return this.get(`shipment-order/commercial-invoice/${id}`);
  }

  public uploadCommercialInvoice(file: File, id): Observable<any> {
    return this.uploadFile(`shipment-order/upload-commercial-invoice/${id}`, file);
  }

  public downloadCommercialInvoice(id): Observable<Blob> {
    return this.getBlob(`shipment-order/download-commercial-invoice/${id}`).pipe(
      map((data: Blob) => {
        return new Blob([data], { type: 'application/pdf' });
      }),
      catchError(this.handleError));
  }

  public getHistory(event, page = 1, perPage = 20, shipmentOrderId = null): Observable<any> {
    let url = `shipment-order/history?page=${page}&perPage=${perPage}`;

    if (shipmentOrderId) {
      event.filters['shipment_order_id'] = {
        value: shipmentOrderId,
        matchMode: 'equals'
      };
    }

    if (event.filters['email']) {
      event.filters['user.email'] = event.filters['email'];
    } else {
      delete event.filters['user.email'];
    }

    return this.post(url, {
      first: event.first,
      rows: event.rows,
      sortOrder: event.sortOrder,
      sortField: event.sortField,
      filters: event.filters,
      globalFilter: null
    });
  }
}
