
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Conversion } from '../models/conversion.model';
import { LazyLoadEvent, SelectItem } from 'primeng/primeng';
import { Observable } from 'rxjs';
import { ConversionResponse } from '../models/conversionResponse.model';

@Injectable()
export class ConversionService extends ApiService {

  public getConversionsForSupplier(event: LazyLoadEvent, supplierId: number): Observable<ConversionResponse> {
    event.filters = { ...event.filters, supplier_id: { value: supplierId } };

    return this.getConversions(event);
  }

  public getConversionsForClient(event: LazyLoadEvent, clientId: number): Observable<ConversionResponse> {
    event.filters = { ...event.filters, client_id: { value: clientId } };

    return this.getConversions(event);
  }

  public getConversions(data: any, page: number = 1, perPage: number = 20): Observable<ConversionResponse> {
    return this.post(`conversion/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public addConversion(conversion: Conversion): Observable<any> {
    return this.post('conversion', conversion);
  }

  public updateConversion(conversion: Conversion): Observable<any> {
    return this.put('conversion', conversion);
  }

  public deleteConversion(id: number): Observable<any> {
    return this.delete(`conversion/${id}`);
  }

  public getTypeList(isSupplier: boolean): SelectItem[] {
    if (isSupplier === true) {
      return [
        {
          label: 'Verpakkingseenheid',
          value: 'packing_unit'
        },
        {
          label: 'Ontvangernummer',
          value: 'client_code'
        }
      ];
    }

    return [
      {
        label: 'Verpakkingseenheid',
        value: 'packing_unit'
      },
      {
        label: 'Leveranciersnummer',
        value: 'supplier_code'
      },
      {
        label: 'Artikelnummer',
        value: 'article_number'
      }
    ];
  }
}
