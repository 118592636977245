import { Directive, ElementRef, Input } from '@angular/core';
import { UserRole } from '../models/user-role.enum';
import { AuthenticationService } from '../services/authentication.service';

/**
 * Display the element based on if the user has either of the given roles
 */
@Directive({
  selector: '[appOnlyRoles]'
})
export class OnlyRolesDirective {
  @Input()
  public appOnlyRoles: UserRole[];

  constructor(private authService: AuthenticationService, private element: ElementRef) {
  }

  public ngOnInit(): void {
    this.element.nativeElement.style.display = this.authService.isRoleAuthorized(this.appOnlyRoles) ? 'auto' : 'none';
  }
}
