import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceDotToComma'
})
export class ReplaceDotToCommaPipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
      return value;
    }

    return value.toString().replace(/\./g, ',');
  }
}
