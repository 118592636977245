export enum OrderStatus {
  RECEIVED = 'received',
  READY_FOR_PICKING = 'ready_for_picking',
  READY_FOR_SHIPMENT = 'ready_for_shipment',
  BACKORDER = 'backorder',
  PLANNED = 'planned',
  CANCELLED = 'cancelled',
  REFUSED = 'refused',
  PACKINGSLIP_PRINTED = 'packingslip_printed',
  CHECKED = 'checked'
}
