/* tslint:disable:max-line-length */
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap';

import { SUPPLIERS_ROUTING } from './suppliers.routing';
import { SharedModule } from '../../shared.module';

import { SuppliersComponent } from './suppliers.component';
import { SupplierListComponent } from './supplier-list/supplier-list.component';
import { SupplierAddComponent } from './supplier-add/supplier-add.component';
import { SupplierDetailsComponent } from './supplier-details/supplier-details.component';
import { SupplierDetailsCommonComponent } from './supplier-details/common/supplier-details-common.component';
import { SupplierDetailsAdministrativeComponent } from './supplier-details/administrative/supplier-details-administrative.component';
import { SupplierDetailsContactpersonsComponent } from './supplier-details/contactpersons/supplier-details-contactpersons.component';
import { SupplierDetailArticleListComponent } from './supplier-details/articles/supplier-detail-article-list/supplier-detail-article-list.component';
import { SupplierDetailOrderListComponent } from './supplier-details/orders/supplier-detail-order-list/supplier-detail-order-list.component';
import { SupplierShipmentListComponent } from './supplier-details/shipments/supplier-shipment-list/supplier-shipment-list.component';
import { SupplierDetailAdditionalSellRatesComponent } from './supplier-details/additional-sell-rates/supplier-detail-additional-sell-rates';
import { SupplierDetailsApiComponent } from './supplier-details/supplier-details-api/supplier-details-api.component';

@NgModule({
  declarations: [
    SuppliersComponent,
    SupplierListComponent,
    SupplierAddComponent,
    SupplierDetailsComponent,
    SupplierDetailsCommonComponent,
    SupplierDetailsAdministrativeComponent,
    SupplierDetailsContactpersonsComponent,
    SupplierShipmentListComponent,
    SupplierDetailArticleListComponent,
    SupplierDetailOrderListComponent,
    SupplierDetailAdditionalSellRatesComponent,
    SupplierDetailsApiComponent
  ],
  imports: [
    SharedModule,
    SUPPLIERS_ROUTING,
    ModalModule
  ],
  entryComponents: [
    SupplierAddComponent,
    SupplierDetailsCommonComponent,
    SupplierDetailsAdministrativeComponent
  ]
})

export class SuppliersModule {
}
