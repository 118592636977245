
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable, Subject } from 'rxjs';
import { ClientType } from '../models/client-type.model';

@Injectable()
export class ClientTypesService extends ApiService {
  public $refreshTableData: Subject<void> = new Subject();

  public list(valueProperty?: string, labelProperties?: string[]): Observable<any> {
    let url = 'client-types/list';

    if (valueProperty && labelProperties) {
      url += `?_valueProperty=${valueProperty}&_labelProperties=${labelProperties.join(',')}`;
    }

    return this.get(url).pipe(map((response: any) => response.client_types));
  }

  public getClientTypes(data: any, page: number = 1, perPage: number = 20): Observable<any> {
    return this.post(`client-types/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public addClientType(clientType: ClientType): Observable<any> {
    return this.post('client-types', clientType);
  }

  public updateClientType(clientType: ClientType): Observable<any> {
    return this.put('client-types', clientType);
  }

  public deleteClientType(id: number): Observable<any> {
    return this.delete(`client-types/${id}`);
  }

  public refreshTableData(): void {
    this.$refreshTableData.next();
  }
}
