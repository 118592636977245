/* tslint:disable:variable-name */
import { Client } from './client.model';
import { Address } from './address.model';
import { Supplier } from './supplier.model';
import { Hub } from './hub.model';
import { Article } from './article.model';
import { ContactPerson } from './contactPerson.model';
import { Shipment } from './shipment.model';
import { OrderStatus } from './order-status.enum';

/**
 * Model Order
 */
export class Order {
  id: number;
  parent_id?: number;
  client_id: number;
  address_id: number;
  supplier_id: number;
  shipment_id?: number;
  contactperson_id?: number;
  hub_id?: number;
  status: OrderStatus;
  status_translated: string;
  number: string;
  code: string;
  articles: Article[];
  client: Client;
  address: Address;
  supplier: Supplier;
  hub?: Hub;
  contactperson: ContactPerson;
  shipment?: Shipment;
  remarks: string;
  preferred_delivery_date: string | Date;
  created_at: string | Date;
  total_volume?: any;
  total_weight?: any;
  reference: string;
  supplier_name?: string;
  loadAddress: Address;
  load_address_id: number;
}
