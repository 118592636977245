
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class UsersService extends ApiService {

  public getUsers(data, page = 1, perPage = 20): Observable<any> {
    return this.post(`users/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public getUser(id: number): Observable<any> {
    return this.get('users/' + id + '/edit');
  }

  public getUsersInfo(): Observable<any> {
    return this.get('users/create');
  }

  public addUser(user: any, sendPasswordResetLink = false): Observable<any> {
    return this.post('users?sendResetLink=' + sendPasswordResetLink, user);
  }

  public updateUser(user: any): Observable<any> {
    return this.put('users', user);
  }

  public deleteUser(id: number): Observable<any> {
    return this.delete(`users/${id}`);
  }

  public generatePassword(): string {
    let valid = false;
    let chars = 'abcdefghijklmnopqrstuvwxyz$@$!%*?&ABCDEFGHIJKLMNOP1234567890';
    let regexp = new RegExp('^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,255}$');
    let getPassword = () => {
      let password = '';
      for (let i = 0; i < 20; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return password;
    };
    let pass = getPassword();

    while (!valid) {
      if (regexp.test(pass)) {
        valid = true;
      } else {
        pass = getPassword();
      }
    }

    return pass;
  }
}
