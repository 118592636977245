import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Supplier } from '../../../models/supplier.model';
import { SharedService } from '../../../services/shared.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/primeng';
import {
  AuthenticationService,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_TRANSPORTER
} from '../../../services/authentication.service';
import { MessageService } from '../../../services/message.service';
import { Conversion } from '../../../models/conversion.model';
import { ConversionModalComponent } from '../conversion-modal/conversion-modal.component';
import { ConversionService } from '../../../services/conversion.service';
import { Client } from '../../../models/client.model';

@Component({
  selector: 'app-conversion-list',
  templateUrl: './conversion-list.component.html',
  styleUrls: ['./conversion-list.component.scss']
})
export class ConversionListComponent implements OnInit {
  @ViewChild('conversionModal')
  public conversionModal: ConversionModalComponent;

  @Input()
  public supplier?: Supplier;

  @Input()
  public client?: Client;

  public records: Conversion[];
  public loading: boolean;
  public conversion: Conversion;
  public lazyLoadEvent: LazyLoadEvent;
  public tableVisible: boolean;
  public isRoleTransporter: boolean;
  public isRoleLogisticEmployee: boolean;
  public isRoleHubOperator: boolean;

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private conversionService: ConversionService,
    private messageService: MessageService) {
    this.conversion = new Conversion();
    this.tableVisible = true;

    this.isRoleTransporter = this.authService.isRoleAuthorized([ROLE_TRANSPORTER]);
    this.isRoleLogisticEmployee = this.authService.isRoleAuthorized([ROLE_LOGISTIC_EMPLOYEE]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);
  }

  public ngOnInit(): void {
    this.loadData({});
  }

  public loadData(event: LazyLoadEvent): void {
    this.lazyLoadEvent = event;
    this.loading = true;

    if (this.supplier) {
      this.conversionService.getConversionsForSupplier(event, this.supplier.id).subscribe((response) => {
        this.records = response.data;
        this.loading = false;
        this.tableVisible = true;
      }, (error) => {
        this.messageService.handleError(error, 'Data ophalen', 'Data ophalen mislukt.');
        this.loading = false;
        this.tableVisible = true;
      });
    } else if (this.client) {
      this.conversionService.getConversionsForClient(event, this.client.id).subscribe((response) => {
        this.records = response.data;
        this.loading = false;
        this.tableVisible = true;
      }, (error) => {
        this.messageService.handleError(error, 'Data ophalen', 'Data ophalen mislukt.');
        this.loading = false;
        this.tableVisible = true;
      });
    }
  }

  public addConversion(): void {
    this.conversion = new Conversion();

    this.conversionModal.showModal();
  }

  public editConversion(conversion: Conversion): void {
    this.conversion = conversion;

    this.conversionModal.showModal();
  }

  public confirmDelete(id: number): void {
    this.confirmationService.confirm({
      key: 'conversion',
      message: 'Weet u zeker dat u deze waarde wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteConversion(id);
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  public updateTable(): void {
    this.loadData({});
  }

  private deleteConversion(id: number): void {
    this.conversionService.deleteConversion(id).subscribe(() => {
      this.loadData(this.lazyLoadEvent);
    }, (error: any) => {
      this.messageService.handleError(error, 'Waarde verwijderen', 'Er is een fout opgetreden.');
    });
  }
}
