/* tslint:disable:variable-name */
/**
 * Model ArticleOrder
 */
export class ArticleOrder {
  public id?: number;
  public article_id: number;
  public order_id?: number;
  public packing_unit_id: number;
  public amount: number;
  public weight?: number;
  public length?: number;
  public width?: number;
  public height?: number;
}
