import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap';

import { ORDERS_ROUTING } from './orders.routing';
import { SharedModule } from '../../shared.module';

import { OrdersComponent } from './orders.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderAddComponent } from './order-add/order-add.component';
import { ReactiveFormsModule } from '@angular/forms';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ReturnOrderComponent } from './return-order/return-order.component';

@NgModule({
  declarations: [
    OrdersComponent,
    OrderListComponent,
    OrderAddComponent,
    OrderListComponent,
    OrderDetailsComponent,
    ReturnOrderComponent
  ],
  imports: [
    SharedModule,
    ORDERS_ROUTING,
    ModalModule,
    ReactiveFormsModule
  ],
  entryComponents: []
})

export class OrdersModule {
}
