import {
  ROLE_CUSTOMER,
  ROLE_DELIVERER,
  ROLE_FINANCIAL_EMPLOYEE,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER,
  ROLE_TRANSPORTER
} from '../services/authentication.service';

let pagesMenu = [];

// Application manager
pagesMenu[ROLE_MANAGER] = [
  {
    path: 'pages',
    children: [
      {
        path: 'clients',
        data: {
          menu: {
            title: 'Ontvangers',
            icon: 'far fa-fw fa-user',
            selected: false,
            expanded: false,
            order: 100,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'suppliers',
        data: {
          menu: {
            title: 'Leveranciers',
            icon: 'fas fa-fw fa-dolly-flatbed',
            selected: false,
            expanded: false,
            order: 200,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'articles',
        data: {
          menu: {
            title: 'Artikelen',
            icon: 'fas fa-fw fa-box-open',
            selected: false,
            expanded: false,
            order: 300,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'orders',
        data: {
          menu: {
            title: 'Opdrachten',
            icon: 'fas fa-fw fa-clipboard-list',
            selected: false,
            expanded: false,
            order: 400,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'orders', 'list', 'open'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'orders'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      {
        path: 'shipments',
        data: {
          menu: {
            title: 'Zendingen',
            icon: 'fas fa-fw fa-shipping-fast',
            selected: false,
            expanded: false,
            order: 600,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'shipments', 'list', 'undelivered'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'shipments', 'list'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      {
        path: 'invoices',
        data: {
          menu: {
            title: 'Factuurregels',
            icon: 'fas fa-fw fa-file-invoice-dollar',
            selected: false,
            expanded: false,
            order: 700,
            pathMatch: 'prefix'
          }
        }
      },
      // ToDo: Temporarily disabled until LHNL wants to use the Emballage functionality
      // {
      //   path: 'packaging',
      //   data: {
      //     menu: {
      //       title: 'Emballage',
      //       icon: 'fas fa-cubes',
      //       selected: false,
      //       expanded: false,
      //       order: 800,
      //       pathMatch: 'prefix'
      //     }
      //   },
      //   children: [
      //     {
      //       path: 'overview',
      //       data: {
      //         menu: {
      //           title: 'Overzicht'
      //         }
      //       }
      //     },
      //     {
      //       path: 'list',
      //       data: {
      //         menu: {
      //           title: 'Emballage Wijzigen'
      //         }
      //       }
      //     }
      //   ]
      // },
      {
        path: 'sell-rates',
        data: {
          menu: {
            title: 'Extra diensten',
            icon: 'fas fa-fw fa-suitcase',
            selected: false,
            expanded: false,
            order: 900,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'handheld-terminal',
        data: {
          menu: {
            title: 'Handterminal',
            icon: 'fa fa-qrcode',
            selected: false,
            expanded: false,
            order: 1000,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'manager',
        data: {
          menu: {
            title: 'Beheer',
            icon: 'fas fa-fw fa-cog',
            selected: false,
            expanded: false,
            order: 1100,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: 'users',
            data: {
              menu: {
                title: 'Gebruikers'
              }
            }
          },
          {
            path: 'selling-prices',
            data: {
              menu: {
                title: 'Verkooptarieven'
              }
            }
          },
          {
            path: 'transporters',
            data: {
              menu: {
                title: 'Vervoerders'
              }
            }
          },
          {
            path: 'hubs',
            data: {
              menu: {
                title: 'Hubs'
              }
            }
          },
          {
            path: 'packings',
            data: {
              menu: {
                title: 'Verzendeenheden'
              }
            }
          },
          {
            path: 'client-types',
            data: {
              menu: {
                title: 'Type ontvangers'
              }
            }
          },
          {
            path: 'big-mile-export',
            data: {
              menu: {
                title: 'BigMile Export'
              }
            }
          },
          {
            path: 'suez-export',
            data: {
              menu: {
                title: 'Suez Export'
              }
            }
          },
          {
            path: 'test-import',
            data: {
              menu: {
                title: 'Test import'
              }
            }
          }
        ]
      }
    ]
  }
];

// Logistic Employee
pagesMenu[ROLE_LOGISTIC_EMPLOYEE] = [
  {
    path: 'pages',
    children: [
      {
        path: 'clients',
        data: {
          menu: {
            title: 'Ontvangers',
            icon: 'far fa-fw fa-user',
            selected: false,
            expanded: false,
            order: 0,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'suppliers',
        data: {
          menu: {
            title: 'Leveranciers',
            icon: 'fas fa-fw fa-dolly-flatbed',
            selected: false,
            expanded: false,
            order: 200,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'articles',
        data: {
          menu: {
            title: 'Artikelen',
            icon: 'fas fa-fw fa-box-open',
            selected: false,
            expanded: false,
            order: 300,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'orders',
        data: {
          menu: {
            title: 'Opdrachten',
            icon: 'fas fa-fw fa-clipboard-list',
            selected: false,
            expanded: false,
            order: 400,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'orders', 'list', 'open'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'orders'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      {
        path: 'shipments',
        data: {
          menu: {
            title: 'Zendingen',
            icon: 'fas fa-fw fa-shipping-fast',
            selected: false,
            expanded: false,
            order: 600,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'shipments', 'list', 'undelivered'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'shipments', 'list'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      // ToDo: Temporarily disabled until LHNL wants to use the Emballage functionality
      // {
      //   path: 'packaging',
      //   data: {
      //     menu: {
      //       title: 'Emballage',
      //       icon: 'fas fa-cubes',
      //       selected: false,
      //       expanded: false,
      //       order: 800,
      //       pathMatch: 'prefix'
      //     }
      //   },
      //   children: [
      //     {
      //       path: 'overview',
      //       data: {
      //         menu: {
      //           title: 'Overzicht'
      //         }
      //       }
      //     },
      //     {
      //       path: 'list',
      //       data: {
      //         menu: {
      //           title: 'Emballage Wijzigen'
      //         }
      //       }
      //     }
      //   ]
      // }
    ]
  }
];

// Financial employee
pagesMenu[ROLE_FINANCIAL_EMPLOYEE] = [
  {
    path: 'pages',
    children: [
      {
        path: 'clients',
        data: {
          menu: {
            title: 'Ontvangers',
            icon: 'far fa-fw fa-user',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'suppliers',
        data: {
          menu: {
            title: 'Leveranciers',
            icon: 'fas fa-fw fa-dolly-flatbed',
            selected: false,
            expanded: false,
            order: 200,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'orders',
        data: {
          menu: {
            title: 'Opdrachten',
            icon: 'fas fa-fw fa-clipboard-list',
            selected: false,
            expanded: false,
            order: 400,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'orders', 'list', 'open'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'orders'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      {
        path: 'shipments',
        data: {
          menu: {
            title: 'Zendingen',
            icon: 'fas fa-fw fa-shipping-fast',
            selected: false,
            expanded: false,
            order: 600,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'shipments', 'list', 'undelivered'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'shipments', 'list'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      {
        path: 'invoices',
        data: {
          menu: {
            title: 'Factuurregels',
            icon: 'fas fa-fw fa-file-invoice-dollar',
            selected: false,
            expanded: false,
            order: 700,
            pathMatch: 'prefix'
          }
        }
      }
    ]
  }
];

// Customer
pagesMenu[ROLE_CUSTOMER] = [
  {
    path: 'pages',
    children: [
      {
        path: 'clients',
        data: {
          menu: {
            title: 'Mijn gegevens',
            icon: 'far fa-fw fa-user',
            selected: true,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'orders',
        data: {
          menu: {
            title: 'Opdrachten',
            icon: 'fas fa-fw fa-clipboard-list',
            selected: false,
            expanded: false,
            order: 400
          }
        }
      },
      // ToDo: Temporarily disabled until LHNL wants to use the Emballage functionality
      // {
      //   path: 'packaging',
      //   data: {
      //     menu: {
      //       title: 'Emballage',
      //       icon: 'fas fa-cubes',
      //       selected: false,
      //       expanded: false,
      //       order: 800,
      //       pathMatch: 'prefix'
      //     }
      //   },
      //   children: [
      //     {
      //       path: 'overview',
      //       data: {
      //         menu: {
      //           title: 'Overzicht'
      //         }
      //       }
      //     }
      //   ]
      // }
    ]
  }
];

// Supplier
pagesMenu[ROLE_SUPPLIER] = [
  {
    path: 'pages',
    children: [
      {
        path: 'clients',
        data: {
          menu: {
            title: 'Ontvangers',
            icon: 'far fa-fw fa-user',
            selected: true,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'articles',
        data: {
          menu: {
            title: 'Artikelen',
            icon: 'fas fa-fw fa-box-open',
            selected: false,
            expanded: false,
            order: 300,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'suppliers',
        data: {
          menu: {
            title: 'Mijn gegevens',
            icon: 'fas fa-fw fa-dolly-flatbed',
            selected: false,
            expanded: false,
            order: 200,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'orders',
        data: {
          menu: {
            title: 'Opdrachten',
            icon: 'fas fa-fw fa-clipboard-list',
            selected: false,
            expanded: false,
            order: 400
          }
        }
      },
      // ToDo: Temporarily disabled until LHNL wants to use the Emballage functionality
      // {
      //   path: 'packaging',
      //   data: {
      //     menu: {
      //       title: 'Emballage',
      //       icon: 'fas fa-cubes',
      //       selected: false,
      //       expanded: false,
      //       order: 800,
      //       pathMatch: 'prefix'
      //     }
      //   },
      //   children: [
      //     {
      //       path: 'overview',
      //       data: {
      //         menu: {
      //           title: 'Overzicht'
      //         }
      //       }
      //     }
      //   ]
      // },
      {
        path: 'manager',
        data: {
          menu: {
            title: 'Beheer',
            icon: 'fas fa-fw fa-cog',
            selected: false,
            expanded: false,
            order: 1000,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: 'test-import',
            data: {
              menu: {
                title: 'Test import'
              }
            }
          }
        ]
      }
    ]
  }
];

// Transporter
pagesMenu[ROLE_TRANSPORTER] = [
  {
    path: 'pages',
    children: [
      {
        path: 'clients',
        data: {
          menu: {
            title: 'Ontvangers',
            icon: 'far fa-fw fa-user',
            selected: false,
            expanded: false,
            order: 100,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'suppliers',
        data: {
          menu: {
            title: 'Leveranciers',
            icon: 'fas fa-fw fa-dolly-flatbed',
            selected: false,
            expanded: false,
            order: 200,
            pathMatch: 'prefix'
          }
        }
      }
    ]
  }
];

// Deliverer
pagesMenu[ROLE_DELIVERER] = [
  {
    path: 'handheld-terminal',
    data: {
      menu: {
        title: 'Handterminal',
        icon: 'fa fa-qrcode',
        selected: false,
        expanded: false,
        order: 0,
        pathMatch: 'prefix'
      }
    }
  }
];

// Hub employee
pagesMenu[ROLE_HUB_OPERATOR] = [
  {
    path: 'pages',
    children: [
      {
        path: 'clients',
        data: {
          menu: {
            title: 'Ontvangers',
            icon: 'far fa-fw fa-user',
            selected: false,
            expanded: false,
            order: 0
          }
        }
      },
      {
        path: 'suppliers',
        data: {
          menu: {
            title: 'Leveranciers',
            icon: 'fas fa-fw fa-dolly-flatbed',
            selected: false,
            expanded: false,
            order: 200,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'articles',
        data: {
          menu: {
            title: 'Artikelen',
            icon: 'fas fa-fw fa-box-open',
            selected: false,
            expanded: false,
            order: 300,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'orders',
        data: {
          menu: {
            title: 'Opdrachten',
            icon: 'fas fa-fw fa-clipboard-list',
            selected: false,
            expanded: false,
            order: 400,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'orders', 'list', 'open'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'orders'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      {
        path: 'shipments',
        data: {
          menu: {
            title: 'Zendingen',
            icon: 'fas fa-fw fa-shipping-fast',
            selected: false,
            expanded: false,
            order: 600,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: ['/', 'pages', 'shipments', 'list', 'undelivered'],
            data: {
              menu: {
                title: 'In behandeling'
              }
            }
          },
          {
            path: ['/', 'pages', 'shipments', 'list'],
            data: {
              menu: {
                title: 'Alle'
              }
            }
          }
        ]
      },
      {
        path: 'invoices',
        data: {
          menu: {
            title: 'Factuurregels',
            icon: 'fas fa-fw fa-file-invoice-dollar',
            selected: false,
            expanded: false,
            order: 700,
            pathMatch: 'prefix'
          }
        }
      },
      // ToDo: Temporarily disabled until LHNL wants to use the Emballage functionality
      // {
      //   path: 'packaging',
      //   data: {
      //     menu: {
      //       title: 'Emballage',
      //       icon: 'fas fa-cubes',
      //       selected: false,
      //       expanded: false,
      //       order: 800,
      //       pathMatch: 'prefix'
      //     }
      //   },
      //   children: [
      //     {
      //       path: 'overview',
      //       data: {
      //         menu: {
      //           title: 'Overzicht'
      //         }
      //       }
      //     },
      //     {
      //       path: 'list',
      //       data: {
      //         menu: {
      //           title: 'Emballage Wijzigen'
      //         }
      //       }
      //     }
      //   ]
      // },
      {
        path: 'sell-rates',
        data: {
          menu: {
            title: 'Extra diensten',
            icon: 'fas fa-fw fa-suitcase',
            selected: false,
            expanded: false,
            order: 900,
            pathMatch: 'prefix'
          }
        }
      },
      {
        path: 'manager',
        data: {
          menu: {
            title: 'Beheer',
            icon: 'fas fa-fw fa-cog',
            selected: false,
            expanded: false,
            order: 1000,
            pathMatch: 'prefix'
          }
        },
        children: [
          {
            path: 'users',
            data: {
              menu: {
                title: 'Gebruikers'
              }
            }
          },
          {
            path: 'hubs',
            data: {
              menu: {
                title: 'Hubs'
              }
            }
          },
          {
            path: 'packings',
            data: {
              menu: {
                title: 'Verzendeenheden'
              }
            }
          },
          {
            path: 'client-types',
            data: {
              menu: {
                title: 'Type ontvangers'
              }
            }
          },
          {
            path: 'big-mile-export',
            data: {
              menu: {
                title: 'BigMile Export'
              }
            }
          },
          {
            path: 'suez-export',
            data: {
              menu: {
                title: 'Suez Export'
              }
            }
          },
          {
            path: 'test-import',
            data: {
              menu: {
                title: 'Test import'
              }
            }
          }
        ]
      }
    ]
  }
];

export const PAGES_MENU = pagesMenu;
