import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, FileUpload, Message, SelectItem } from 'primeng/primeng';

import { CommonModalComponent } from '../../../../components/modal/child.modal';
import { TabsComponent } from '../../../../components/tabs/tabs.component';

import { SharedService } from '../../../../services/shared.service';
import { ContactPersonsService } from '../../../../services/contactpersons.service';

import { ContactPerson } from '../../../../models/contactPerson.model';
import { Supplier } from '../../../../models/supplier.model';
import { SupplierService } from 'app/services/supplier.service';
import { AuthenticationService, ROLE_SUPPLIER } from '../../../../services/authentication.service';
import { MessageService, MessageSummary } from '../../../../services/message.service';

@Component({
  selector: 'supplier-details-contactpersons',
  templateUrl: './supplier-details-contactpersons.component.html',
  styleUrls: ['./supplier-details-contactpersons.component.scss']
})
export class SupplierDetailsContactpersonsComponent implements AfterViewInit, OnChanges, OnInit {

  @ViewChild('editModal') editModal: CommonModalComponent;
  @ViewChild('imageUploader') imageUploader: FileUpload;
  @ViewChild('cpForm') cpForm;

  @Input() supplier: Supplier;
  @Input() defaults;
  @Input() isRoleCustomer;
  @Output() onRefreshData = new EventEmitter();

  public contactPersons: ContactPerson[];
  public archivedContactpersons: ContactPerson[];
  public showArchived: boolean;

  public validationErrors = [];
  public messages: Message[] = [];
  public isRoleSupplier: boolean = false;
  public readonly: boolean = false;
  public genders;

  public editableContactPerson: ContactPerson = {
    id: null,
    client_id: null,
    first_name: '',
    last_name_prefix: null,
    last_name: '',
    full_name: '',
    email: '',
    gender: 'unknown',
    image_url: null,
    phone_number: '',
    communication_type: 'informal',
    function: '',
    remarks: null,
    post_action: null,
    archived: false
  };

  public modals = [
    'add-contactpersons', null
  ];
  public loading: boolean = false;
  public isNew = true;
  public communicationTypeList: SelectItem[] = [
    { label: 'Formeel', value: 'formal' },
    { label: 'Informeel', value: 'informal' }
  ];

  public btnDisabled: boolean = false;

  constructor(
    protected router: Router,
    protected activeRoute: ActivatedRoute,
    public sharedService: SharedService,
    protected supplierService: SupplierService,
    protected contactPersonsService: ContactPersonsService,
    protected tabs: TabsComponent,
    protected elRef: ElementRef,
    protected renderer: Renderer2,
    protected confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthenticationService) {
    this.contactPersons = [];
    this.archivedContactpersons = [];
    this.showArchived = false;

    this.initContactpersons();
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    this.genders = this.contactPersonsService.getGenders();
  }

  public ngOnChanges(): void {
    this.initContactpersons();
  }

  public editContactPerson(contactperson: ContactPerson): void {
    this.isNew = (!contactperson);

    let form: HTMLFormElement = <HTMLFormElement>document.getElementById('contact-form');
    form.reset();

    if (contactperson) {
      this.editableContactPerson = contactperson;
    }

    this.editModal.show();
  }

  public resetEditableContactPerson(): void {
    this.validationErrors = [];

    let inputs = this.elRef.nativeElement.querySelectorAll('.form-control');
    inputs.forEach((input) => {
      this.renderer.removeClass(input, 'invalid');
    });

    if (this.imageUploader) {
      this.imageUploader.clear();
    }

    this.editableContactPerson = {
      id: null,
      client_id: null,
      first_name: '',
      last_name_prefix: null,
      last_name: '',
      full_name: '',
      gender: 'unknown',
      email: '',
      image_url: '',
      phone_number: '',
      communication_type: 'informal',
      function: '',
      remarks: null,
      post_action: null,
      archived: false
    };
  }

  public addContactPerson(modal: any): void {
    modal.show();
  }

  public cancelContactPerson(): void {
    this.clearAndHideForm();
  }

  public confirmDelete(personId: number): void {
    this.confirmationService.confirm({
      key: 'contactpersons',
      message: 'Weet u zeker dat u deze contactpersoon wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteContactPerson(personId);
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  public submitContactPerson(form: any): void {
    this.validationErrors = [];

    // Trim any pre- or suffix spacing
    if (form.controls.phone_number.value) {
      form.controls.phone_number.setValue(form.controls.phone_number.value.trim());
    }

    if (form.valid) {
      this.btnDisabled = true;

      if (this.editableContactPerson.phone_number === null) {
        delete this.editableContactPerson.phone_number;
      }

      if (this.editableContactPerson.email === null) {
        delete this.editableContactPerson.email;
      }

      if (this.editableContactPerson.gender === null) {
        this.editableContactPerson.gender = 'unknown';
      }

      if (this.editableContactPerson.communication_type === null) {
        this.editableContactPerson.communication_type = 'informal';
      }

      if (this.editableContactPerson.client_id === 0) {
        this.editableContactPerson.client_id = null;
      }

      if (this.isNew) {
        this.editableContactPerson.archived = false;
      } else {
        this.editableContactPerson.archived = form.controls.archived.value || false;
      }

      this.editableContactPerson.supplier_id = this.supplier.id;
      this.loading = true;

      if (!this.isNew) { // update
        this.contactPersonsService.updateContactPerson(this.editableContactPerson).subscribe((result: any) => {
          this.parseResults(result, 'Contactpersoon opgeslagen');

          if (this.imageUploader) {
            this.imageUploader.upload();
          }

          this.clearAndHideForm();
          this.loading = false;
          this.btnDisabled = false;
        }, (error: any) => this.handleError(error, 'SAVE_CONTACT'));
      } else { // new
        this.editableContactPerson.supplier_id = this.supplier.id;

        this.contactPersonsService.addContactPerson(this.editableContactPerson).subscribe((result: any) => {
          if (result.success === false) {
            this.handleError(null, 'SAVE_CONTACT', result.message);
            this.loading = false;
          } else {
            this.parseResults(result, 'Contactpersoon toegevoegd');
            if (result) {
              this.editableContactPerson.id = result.id;
              this.supplier.contactpersons.data.push(result);
            }

            if (this.imageUploader) {
              this.imageUploader.upload();
            }

            this.clearAndHideForm();
            this.resetRoute();
            this.loading = false;
            this.btnDisabled = false;
          }
        }, (error: any) => this.handleError(error, 'SAVE_CONTACT', 'Vul alle verplichte velden in (*)'));
      }
    } else {
      this.handleError(null, 'SAVE_CONTACT', 'Controleer uw invoer.');

      if (form.controls.phone_number.valid === false) {
        this.validationErrors.push('Het telefoonnummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }
    }
  }

  public resetRoute(): void {
    this.router.navigate([this.router.url.split('#')[0].split('?')[0]], { queryParams: {} });
  }

  public parseResults(result: ContactPerson, message: string): void {
    if (result) {
      this.editModal.onHidden(() => { // Reload tables
        this.refreshClient();
      });

      this.messageService.success(MessageSummary['OK'], message);

      this.editModal.hide();
    } else {
      this.handleError(true, message);
    }
  }

  public clearAndHideForm(): void {
    this.validationErrors = [];
    this.resetEditableContactPerson();

    this.editModal.hide();
  }

  public refreshClient(): void {
    this.supplierService.getSupplier(this.supplier.id).subscribe((data: any) => {
      this.supplier = data;
      this.initContactpersons();
      this.onRefreshData.emit({ supplier: data });
    });
  }

  public getContactPersonModalTitle(): string {
    if (this.editableContactPerson.archived) {
      return 'inzien';
    } else if (this.isNew) {
      return 'toevoegen';
    } else {
      return 'bewerken';
    }
  }

  public handleError(error: any, summary: string, message: string = 'Er is een fout voorgekomen.') {
    this.validationErrors = [];
    this.btnDisabled = false;

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        // Remove class 'invalid' for all inputs
        let inputs = this.elRef.nativeElement.querySelectorAll('.form-control');
        inputs.forEach((input) => {
          this.renderer.removeClass(input, 'invalid');
        });

        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);

              // Add class 'invalid' to input
              let el = this.elRef.nativeElement.querySelector('[name=' + field + ']');
              this.renderer.addClass(el, 'invalid');
            });
          }
        }
      }
    }

    this.messageService.error(MessageSummary[summary], message);
    this.loading = false;
  }

  public ngAfterViewInit(): void {
    this.activeRoute.queryParams.subscribe((params: any) => {
      this.editModal.onHidden(() => {
        this.clearAndHideForm();
      });
    });

    this.modals['add-contactpersons'] = [this.addContactPerson, this.editModal];
    this.sharedService.handleHash(this.activeRoute, this.modals);
  }

  public myUploadHandler(event): void {
    if (!(event.files.length > 0)) {
      return;
    }

    let file: File = event.files[0];
    let contactPersonId = this.editableContactPerson.id;
    this.contactPersonsService.uploadImageForContactPerson(file, contactPersonId).subscribe((data) => {
      this.editableContactPerson.image_url = data.url;
      let contactPersonIndex = this.supplier.contactpersons.data.findIndex(c => c.id === contactPersonId);
      this.supplier.contactpersons.data[contactPersonIndex].image_url = data.url;
      this.clearAndHideForm();
    });
  }

  public deleteImage() {
    let contactPersonId = this.editableContactPerson.id;
    this.contactPersonsService.deleteImageForContactPerson(contactPersonId).subscribe((data: any) => {
      if (data.success === true) {
        this.editableContactPerson.image_url = null;
        let contactPersonIndex = this.supplier.contactpersons.data.findIndex(c => c.id === contactPersonId);
        this.supplier.contactpersons.data[contactPersonIndex].image_url = null;
      }
    });
  }

  public showArchivedPersons(): void {
    this.showArchived = !this.showArchived;
  }

  private initContactpersons(): void {
    this.contactPersons = [];
    this.archivedContactpersons = [];

    if (this.supplier && this.supplier.contactpersons) {
      this.supplier.contactpersons.data.forEach((contactPerson) => {
        contactPerson.name = (
          (contactPerson.first_name || '') + ' ' +
          (contactPerson.last_name_prefix || '') + ' ' +
          (contactPerson.last_name || '')
        )
          .replace('  ', ' ');
        if (contactPerson.archived === true) {
          this.archivedContactpersons.push(contactPerson);
        } else {
          this.contactPersons.push(contactPerson);
        }
      });
    }
  }

  private deleteContactPerson(personId: number): void {
    this.contactPersonsService.deleteContactPerson(personId).subscribe((result) => {
      this.parseResults(result, 'Contactpersoon verwijderd');
      this.refreshClient();
    });
  }
}
