import { Component, Input, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'common-modal',
  templateUrl: './child.modal.html',
  styleUrls: ['./child.modal.scss']
})
export class CommonModalComponent {

  @ViewChild('childModal') public childModal: ModalDirective;
  @Input() title: string;
  @Input() size: string = 'sm';

  constructor() {
  }

  show() {
    this.childModal.config = {
      ignoreBackdropClick: true
    };

    this.childModal.show();
  }

  hide() {
    this.childModal.hide();
  }

  onShown(action: () => void) {
    this.childModal.onShown.subscribe(() => {
      action();
    });
  }

  onHidden(action: () => void) {
    this.childModal.onHidden.subscribe(() => {
      action();
    });
  }
}
