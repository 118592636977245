import { Component, Input, OnInit } from '@angular/core';
import { ShipmentListComponent } from '../../../../shipments/shipment-list/shipment-list.component';
import { Supplier } from '../../../../../models/supplier.model';
import { Shipment } from '../../../../../models/shipment.model';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-supplier-shipment-list',
  templateUrl: './supplier-shipment-list.component.html',
  styleUrls: ['./supplier-shipment-list.component.scss']
})
export class SupplierShipmentListComponent extends ShipmentListComponent implements OnInit {
  @Input()
  public supplier: Supplier;

  public selectedShipments: Shipment[] = [];
  public isRoleSupplier: boolean = false;
  public paginationAmount: number = 30;

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.route.queryParams.subscribe((params) => {
      if (params['id'] && params['tab'] === 'shipment') {
        this.sharedService.openNewWindow(`pages/shipments/${+params['id']}`);
      }
    });

    super.ngOnInit();
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(event: any): void {
    event.filters = { ...event.filters, ...this.filters };

    this.shipmentService.getSupplierShipments(
      this.supplier.id,
      event,
      event.first / event.rows + 1,
      event.rows
    ).subscribe((result) => {
      this.loading = false;
      this.records = result.data;
      this.totalRecords = result.pagination.total;

      this.transporters = this.sharedService.mapObjectToSelectItems(result.meta.transporters, 'Alle');
      this.clients = this.sharedService.mapObjectToSelectItems(result.meta.clients, 'Alle');
      this.packings = this.sharedService.mapObjectToSelectItems(result.meta.packings, 'Alle');
    });
  }

  public onRowSelect(event: any): void {
    if (!this.isRoleSupplier) {
      if (event.originalEvent.originalEvent) {
        if (event.originalEvent.originalEvent.target.classList.contains('ui-chkbox-box')) {
          return;
        }
      }
    }

    this.selectedShipments = [];

    this.sharedService.openNewWindow(`pages/shipments/${event.data.id}`);
  }

  public downloadExport(): void {
    const shipmentIds = this.selectedShipments.map(s => s.id);

    this.shipmentService.downloadExport(this.supplier.id, shipmentIds).subscribe((response: Blob) => {
      const timeStamp = new Date().getTime();
      const fileName = `Export zendingen - ${this.supplier.name} - ${timeStamp}`;

      FileSaver.saveAs(response, fileName + '.xlsx');

      this.selectedShipments = [];

      // Refresh table
      this.tableVisible = false;
      setTimeout(() => {
        this.tableVisible = true;
      }, 0);
    }, errorResponse => {
      this.sharedService.handleError(errorResponse, 'Export downloaden');
    });
  }
}
