import { ApplicationRef, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { createInputTransfer, createNewHosts, removeNgStyles } from '@angularclass/hmr';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { TabsComponent } from './components/tabs/tabs.component';

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from './app.routing';
// App is our top level component
import { App } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './theme/nga.module';
import { PagesModule } from './pages/pages.module';
// Guards
import { AuthGuard } from './guards';
// Interceptors
import { TokenInterceptor } from './interceptors/token.interceptor';
// Services
import { ApiService } from './services/api.service';
import { ArticleService } from './services/article.service';
import { AuthenticationService } from './services/authentication.service';
import { ConfirmationService, GrowlModule } from 'primeng/primeng';
import { ClientsService } from './services/clients.service';
import { ContactPersonsService } from './services/contactpersons.service';
import { CountryService } from './services/country.service';
import { HubService } from './services/hub.service';
import { ImportService } from './services/import.service';
import { InvoicesService } from './services/invoices.service';
import { OrderService } from './services/order.service';
import { PackingService } from './services/packing.service';
import { SharedService } from './services/shared.service';
import { ShipmentService } from './services/shipment.service';
import { ShipmentOrderService } from './services/shipmentOrder.service';
import { SupplierService } from './services/supplier.service';
import { StockingService } from './services/stocking.service';
import { TransporterService } from './services/transporter.service';
import { UsersService } from './services/users.service';
import { PickupOrderService } from './services/pickupOrder.service';
import { SearchService } from './services/search.service';
import { SellRatesService } from './services/sell-rates.service';
import { StateService } from './services/state.service';
import { MessageService } from './services/message.service';
import { MessageService as PrimeNgMessageService } from 'primeng/components/common/messageservice';
import { AdditionalSellRateService } from './services/additional-sell-rate.service';
import { AddressService } from './services/address.service';
import { ConversionService } from './services/conversion.service';
// Modules
import { ArticleModule } from './pages/article/article.module';
import { ClientsModule } from './pages/clients/clients.module';
import { ImportModule } from './components/import/import.component.module';
import { SuppliersModule } from './pages/suppliers/suppliers.module';
import { OrdersModule } from './pages/orders/orders.module';
import { ShipmentsModule } from './pages/shipments/shipments.module';
// Pipes
import { CurrencyFormat } from './pipes/currency-format.pipe';
import { ReplaceDotToCommaPipe } from './pipes/replace-dot-to-comma.pipe';
import { Sort } from './pipes/sort.pipe';
import { InvoiceRowsService } from './services/invoice-rows.service';
import { ClientTypesService } from './services/client-types.service';
import { DatePipe } from '@angular/common';
import { TypeofPipe } from './pipes/typeof.pipe';
import { environment } from '../environments/environment';
import * as Raven from 'raven-js';

// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  ConfirmationService
];

export type StoreType = {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
};

if (environment.sentryUrl) {
  Raven.config(
    environment.sentryUrl
  ).install();
}

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    if (environment.sentryUrl) {
      Raven.captureException(err);
    } else {
      throw err;
    }
  }
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [App],
  imports: [
    // import Angular's modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    PagesModule,
    routing,
    ModalModule,
    TooltipModule,
    ImportModule,
    ClientsModule,
    ArticleModule,
    SuppliersModule,
    OrdersModule,
    ShipmentsModule,
    GrowlModule
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    APP_PROVIDERS,
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    AuthenticationService,
    AuthGuard,
    ApiService,
    SharedService,
    UsersService,
    ClientsService,
    ClientTypesService,
    ContactPersonsService,
    DatePipe,
    ShipmentService,
    PackingService,
    CountryService,
    SupplierService,
    StockingService,
    ShipmentOrderService,
    ImportService,
    InvoicesService,
    InvoiceRowsService,
    PickupOrderService,
    ArticleService,
    TabsComponent,
    TransporterService,
    HubService,
    OrderService,
    StateService,
    MessageService,
    PrimeNgMessageService,
    SellRatesService,
    SearchService,
    AdditionalSellRateService,
    AddressService,
    ConversionService,
    CurrencyFormat,
    ReplaceDotToCommaPipe,
    Sort,
    TypeofPipe,
  ]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public appState: AppState) {
  }

  hmrOnInit(store: StoreType) {
    if (!store || !store.state) {
      return;
    }
    console.log('HMR store', JSON.stringify(store, null, 2));
    // set state
    this.appState._state = store.state;
    // set input values
    if ('restoreInputValues' in store) {
      let restoreInputValues = store.restoreInputValues;
      setTimeout(restoreInputValues);
    }
    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  hmrOnDestroy(store: StoreType) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // save state
    const state = this.appState._state;
    store.state = state;
    // recreate root elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // save input values
    store.restoreInputValues = createInputTransfer();
    // remove styles
    removeNgStyles();
  }

  hmrAfterDestroy(store: StoreType) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
