import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { SelectItem } from 'primeng/primeng';
import { CommonModalComponent } from '../../modal/child.modal';
import {
  AuthenticationService,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_TRANSPORTER
} from '../../../services/authentication.service';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { isUndefined } from 'util';
import { Supplier } from '../../../models/supplier.model';
import { ConversionService } from '../../../services/conversion.service';
import { Conversion } from '../../../models/conversion.model';
import { ClientsService } from '../../../services/clients.service';
import { PackingService } from '../../../services/packing.service';
import { Client } from '../../../models/client.model';
import { SupplierService } from '../../../services/supplier.service';
import { ArticleService } from '../../../services/article.service';

@Component({
  selector: 'app-conversion-modal',
  templateUrl: './conversion-modal.component.html',
  styleUrls: ['./conversion-modal.component.scss']
})
export class ConversionModalComponent implements OnInit {
  @ViewChild('conversionModal')
  public conversionModal: CommonModalComponent;

  @ViewChild('conversionForm')
  public form;

  @Input()
  public supplier?: Supplier;

  @Input()
  public client?: Client;

  get conversion(): Conversion {
    return this._conversion;
  }

  @Input()
  set conversion(value: Conversion) {
    this._conversion = value;

    this.onTypeChange();
  }

  @Output()
  public onSave: EventEmitter<Conversion> = new EventEmitter<Conversion>();

  public typeList: SelectItem[];
  public conversionOptions: SelectItem[];
  public validationErrors = [];
  public isRoleTransporter: boolean;
  public isRoleLogisticEmployee: boolean;
  public isRoleHubOperator: boolean;

  private _conversion: Conversion;

  constructor(
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private conversionService: ConversionService,
    private packingService: PackingService,
    private clientService: ClientsService,
    private articleService: ArticleService,
    private supplierService: SupplierService,
    private messageService: MessageService) {
    this.conversion = new Conversion();

    this.isRoleTransporter = this.authService.isRoleAuthorized([ROLE_TRANSPORTER]);
    this.isRoleLogisticEmployee = this.authService.isRoleAuthorized([ROLE_LOGISTIC_EMPLOYEE]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);
  }

  public ngOnInit(): void {
    this.getFormData();
  }

  public showModal(): void {
    this.conversionModal.show();
  }

  public getFormData(): void {
    this.typeList = this.conversionService.getTypeList(!!this.supplier);
  }

  public onTypeChange(resetLocalValue: boolean = false): void {
    if (resetLocalValue) {
      this.conversion.local_value = null;
    }

    switch (this.conversion.type) {
      case 'packing_unit':
        this.packingService.list('code', ['name', 'code']).subscribe((result) => {
          this.conversionOptions = result;
        });

        break;
      case 'client_code':
        this.clientService.list('client_code', ['company_name', 'client_code']).subscribe((result) => {
          this.conversionOptions = result;
        });

        break;
      case 'article_number':
        this.articleService.list(null, 'number', ['name', 'number']).subscribe((result) => {
          this.conversionOptions = result;
        });

        break;
      case 'supplier_code':
        this.supplierService.list('code', ['name', 'code']).subscribe((result) => {
          this.conversionOptions = result;
        });

        break;
      default:
        break;
    }
  }

  public submit(): void {
    if (this.form.valid) {
      const isNew = this.conversion.id === null || isUndefined(this.conversion.id);
      let saveMethod = null;

      if (isNew) {
        if (this.supplier) {
          this.conversion.supplier_id = this.supplier.id;
        } else if (this.client) {
          this.conversion.client_id = this.client.id;
        }

        saveMethod = this.conversionService.addConversion(this.conversion);
      } else {
        saveMethod = this.conversionService.updateConversion(this.conversion);
      }

      saveMethod.subscribe((response) => {
        this.conversion = new Conversion();
        this.onSave.emit(response);
        this.form.resetForm();
        this.conversionModal.hide();
      });
    } else {
      this.messageService.error(MessageSummary['SAVE_VALUE'], 'Vul alle verplichte velden in (*)');
    }
  }

  public cancel(): void {
    this.conversion = new Conversion();
    this.form.resetForm();
    this.conversionModal.hide();
  }
}
