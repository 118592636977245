import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { routing } from './pages.routing';
import { SharedModule } from '../shared.module';
import { NgaModule } from '../theme/nga.module';

import { Pages } from './pages.component';

@NgModule({
  imports: [
    CommonModule,
    NgaModule,
    routing,
    SharedModule
  ],
  declarations: [
    Pages
  ],
  exports: []
})

export class PagesModule {
}
