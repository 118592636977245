import { RouterModule, Routes } from '@angular/router';

import { OrdersComponent } from './orders.component';
import { OrderListComponent } from './order-list/order-list.component';
import { OrderAddComponent } from './order-add/order-add.component';
import { ReturnOrderComponent } from './return-order/return-order.component';
import { OrderDetailsComponent } from './order-details/order-details.component';

const ORDERS_ROUTES: Routes = [
  {
    path: '',
    component: OrdersComponent,
    children: [
      {
        path: '',
        component: OrderListComponent
      },
      {
        path: 'add',
        component: OrderAddComponent
      },
      {
        path: 'list/:status',
        component: OrderListComponent
      },
      {
        path: 'list/:status/:id',
        component: OrderDetailsComponent
      },
      {
        path: ':id/return',
        component: ReturnOrderComponent
      },
      {
        path: ':id/:open',
        component: OrderDetailsComponent
      },
      {
        path: ':id',
        component: OrderDetailsComponent
      }
    ]
  }
];

export const ORDERS_ROUTING = RouterModule.forChild(ORDERS_ROUTES);
