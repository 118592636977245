
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Packing } from '../models/packing.model';

@Injectable()
export class PackingService extends ApiService {

  public getPackings(data: any, page: number = 1, perPage: number = 20): Observable<any> {
    return this.post(`packings/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public getPacking(id: number): Observable<any> {
    return this.get(`packings/${id}`);
  }

  public list(valueProperty?: string, labelProperties?: string[]): any {
    let url = 'packings/list';

    if (valueProperty && labelProperties) {
      url += `?_valueProperty=${valueProperty}&_labelProperties=${labelProperties.join(',')}`;
    }

    return this.get(url).pipe(map((response: any) => response.packings));
  }

  public listDepositable(valueProperty?: string, labelProperties?: string[]): Observable<any> {
    let url = 'packings/list-depositable';

    if (valueProperty && labelProperties) {
      url += `?_valueProperty=${valueProperty}&_labelProperties=${labelProperties.join(',')}`;
    }

    return this.get(url).pipe(map((response: any) => response.packings));
  }

  public addPacking(data: Packing): Observable<any> {
    return this.post('packings', data);
  }

  public updatePacking(data: Packing): Observable<any> {
    return this.put(`packings`, data);
  }

  public deletePacking(id: number): Observable<any> {
    return this.delete(`packings/${id}`);
  }

  public getPackingsOverview(page, perPage, data: any): Observable<any> {
    return this.post(`packings/overview?page=${page}&per_page=${perPage}`, data);
  }

  public exportPackingOverview(data: any): Observable<any> {
    return this.getBlob('packings/overview/export', data);
  }
}

