import { RouterModule, Routes } from '@angular/router';

import { ShipmentsComponent } from './shipments.component';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { ShipmentDetailsComponent } from './shipment-details/shipment-details.component';

const SHIPMENTS_ROUTES: Routes = [
  {
    path: '',
    component: ShipmentsComponent,
    children: [
      {
        path: '',
        component: ShipmentListComponent
      },
      {
        path: 'list',
        component: ShipmentListComponent
      },
      {
        path: ':id',
        component: ShipmentDetailsComponent
      },
      {
        path: 'list/:status',
        component: ShipmentListComponent
      },
      {
        path: 'list/:status/:id',
        component: ShipmentDetailsComponent
      }
    ]
  }
];

export const SHIPMENTS_ROUTING = RouterModule.forChild(SHIPMENTS_ROUTES);
