import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';

import { BaseComponent } from 'app/base.component';
import { TabsComponent } from '../../../../components/tabs/tabs.component';

import { SupplierService } from '../../../../services/supplier.service';
import { Supplier } from '../../../../models/supplier.model';
import { Address } from '../../../../models/address.model';
import { SharedService } from '../../../../services/shared.service';
import { AuthenticationService, ROLE_MANAGER, ROLE_SUPPLIER } from '../../../../services/authentication.service';
import { MessageService, MessageSummary } from '../../../../services/message.service';

@Component({
  selector: 'supplier-details-common',
  templateUrl: './supplier-details-common.component.html',
  styleUrls: ['./supplier-details-common.component.scss']
})
export class SupplierDetailsCommonComponent extends BaseComponent implements OnInit {

  @ViewChild('form') public form;

  get supplier(): Supplier {
    return this._supplier;
  }

  @Input()
  set supplier(value: Supplier) {
    if (value.contactpersons) {
      this.contactpersons = value.contactpersons.data.map((contactperson) => {
        return { label: contactperson.full_name, value: contactperson.id };
      });
    }

    this._supplier = value;
  }

  @Input() public defaults: any;

  public validationErrors = [];
  public contactpersons: SelectItem[] = [];
  public isRoleSupplier: boolean = false;
  public isRoleManger: boolean = false;
  public readonly: boolean = false;

  private _supplier: Supplier;

  constructor(
    public sharedService: SharedService,
    protected supplierService: SupplierService,
    protected router: Router,
    protected tabs: TabsComponent,
    protected confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthenticationService) {
    super(authService, [
      {
        'role': ROLE_SUPPLIER,
        'hidden': [
          'remarks'
        ],
        'readonly': [
          'code',
          'name',
          'email',
          'phone',
          'hub',
          'preferred_transporter'
        ]
      }
    ]);
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    this.isRoleManger = this.authService.isRoleAuthorized([ROLE_MANAGER]);
  }

  public submitSupplierForm(form: FormGroup, redirectToList: boolean = true) {
    if (form.valid) {
      this.updateSupplier('Gegevens opgeslagen', redirectToList);
    } else {
      this.handleError(null, 'SAVE_SUPPLIER', 'Vul alle verplichte velden in (*)');
    }
  }

  public updateAddresses(addresses: Address[]): void {
    this.supplier.addresses = addresses;
    this.submitSupplierForm(this.form, false);
  }

  private updateSupplier(message = 'Leverancier opgeslagen', redirectToList: boolean = false) {
    this.supplierService.updateSupplier(this.supplier).subscribe((supplier: Supplier) => {
      this.messageService.success(MessageSummary['OK'], message);

      this.supplier.addresses = supplier.addresses.data;

      if (redirectToList) {
        this.router.navigate(['pages/suppliers']);
      }
    }, (error) => {
      this.handleError(error);
    });
  }

  private handleError(error: any, summary = 'OK', message = 'Kan leverancier niet opslaan') {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (err) {
          message += '<br>' + result.errors[err][0];
        });
      }
    }

    this.messageService.success(MessageSummary[summary], message);
  }
}
