
import {mergeMap} from 'rxjs/operators';
/* tslint:disable:max-line-length */
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Client } from '../../../models/client.model';
import { ContactPersonsService } from '../../../services/contactpersons.service';
import { Address } from '../../../models/address.model';
import { StateService } from '../../../services/state.service';
import { Subject } from 'rxjs';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { Supplier } from '../../../models/supplier.model';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { Defaults } from '../../../models/defaults.model';
import { ClientsService } from '../../../services/clients.service';
import { SharedService } from '../../../services/shared.service';
import { CountryService } from '../../../services/country.service';
import { Country } from '../../../models/country.model';
import { Article } from '../../../models/article.model';
import { ArticleService } from '../../../services/article.service';
import { OrderService } from '../../../services/order.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_MANAGER,
  ROLE_SUPPLIER
} from '../../../services/authentication.service';
import { PackingUnit } from '../../../models/packing-unit.model';
import { HubService } from '../../../services/hub.service';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { AddressService } from '../../../services/address.service';
import { Order } from '../../../models/order.model';
import { ShipmentService } from '../../../services/shipment.service';
import { ArticlesWithPackingUnitSelectorComponent } from '../../../components/articles-with-packing-unit-selector/articles-with-packing-unit-selector.component';
import { ReplaceDotToCommaPipe } from '../../../pipes/replace-dot-to-comma.pipe';

@Component({
  selector: 'app-edit-order-modal',
  templateUrl: './order-add.component.html',
  styleUrls: ['./order-add.component.scss']
})
export class OrderAddComponent implements OnInit, OnDestroy {
  @ViewChild('addressModal') addressModal: CommonModalComponent;
  @ViewChild('articlesWithPackingUnitSelectorComponent') articlesWithPackingUnitSelectorComponent: ArticlesWithPackingUnitSelectorComponent;

  public client: Client;
  public orderForm: FormGroup;
  public addressForm: FormGroup;
  public articles: Article[] = [];
  public contactpersons: SelectItem[] = [];
  public addresses: SelectItem[] = [];
  public loadAddresses: SelectItem[] = [];
  public hubs: SelectItem[] = [];
  public destroy$ = new Subject<boolean>();
  public suppliers: Supplier[] = [];
  public today = new Date();
  public tomorrow = new Date();
  public nextWorkingDay = new Date();
  public defaults: Defaults = new Defaults();
  public selectedAddress: Address;
  public selectedLoadAddress: Address;
  public loadingZipcode: boolean;
  public countries: Country[];
  public selectedCountry: Country;
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public isManager: boolean = false;
  public article: Article;
  public customLoadAddress: boolean = false;
  public addAddressType: string = 'discharge';
  public loadingArticles: boolean;
  public calendarLocale: any = null;
  public articlesToReturn: Article[] = [];
  public invalidArticles: number[] = [];
  public supplierId: number;

  @Input()
  public order: Order;

  @Input()
  public isReturn = false;

  constructor(public sharedService: SharedService,
              private formBuilder: FormBuilder,
              private router: Router,
              private contactPersonService: ContactPersonsService,
              private clientService: ClientsService,
              private countryService: CountryService,
              private orderService: OrderService,
              private articleService: ArticleService,
              private stateService: StateService,
              private authService: AuthenticationService,
              private messageService: MessageService,
              private hubService: HubService,
              private addressService: AddressService,
              private shipmentService: ShipmentService,
              private confirmationService: ConfirmationService,
              private replaceDotToCommaPipe: ReplaceDotToCommaPipe) {
    this.tomorrow.setDate(this.today.getDate() + 1);
    this.nextWorkingDay = this.tomorrow;

    const nextWorkingDay = moment(this.nextWorkingDay);
    if (nextWorkingDay.day() === 6) { // Saturday
      this.nextWorkingDay.setDate(this.nextWorkingDay.getDate() + 2);
    } else if (nextWorkingDay.day() === 0) { // Sunday
      this.nextWorkingDay.setDate(this.nextWorkingDay.getDate() + 1);
    }

    this.calendarLocale = this.sharedService.getCalendarLocale();
    this.loadingArticles = false;
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_MANAGER])) {
      this.isManager = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR])) {
      this.isRoleHubOperator = true;
    }

    this.buildForm();
    this.buildAddressForm();

    if (this.order) {
      this.customLoadAddress = true;
      this.order.articles = this.order.articles['data'];
      this.order.preferred_delivery_date = this.tomorrow;
      this.order.remarks = '';

      this.order.articles = this.order.articles.map((article) => {
        article.amountToReturn = 0;

        article.packing_unit = {
          id: article.packing_unit_id,
          article_id: article.id,
          name: article.packing_unit_name,
          weight: article.weight,
          length: article.length,
          width: article.width,
          height: article.height
        };

        return article;
      });

      this.articlesToReturn = this.order.articles;

      this.getClientOptions(this.order.client_id);
      this.orderForm.patchValue({
        ...this.order,
        load_address_id: this.order.address_id,
        address_id: this.order.load_address_id
      });
    }

    let supplierId = null;

    if (this.isRoleSupplier) {
      const user = this.authService.getUser();
      supplierId = user.supplier_id;
      this.supplierId = user.supplier_id;
    }

    this.orderService.getDefaults(supplierId).subscribe((defaults: any) => {
      this.defaults.paymentAgreements = this.sharedService.mapObjectToArray(defaults.paymentAgreements);
      this.defaults.addressTypes = this.sharedService.mapObjectToArray(defaults.addressTypes);
      this.defaults.countries = this.sharedService.mapObjectToArray(defaults.countries);
      this.defaults.communicationTypes = this.sharedService.mapObjectToArray(defaults.communicationTypes);
      this.defaults.clients = this.sharedService.mapObjectToSelectItems(defaults.clients);
    });

    this.hubService.list().subscribe((hubs) => {
      this.hubs = hubs;
    });

    this.addressForm.valueChanges.subscribe(() => {
      this.focusOutFunction();
    });

    this.countryService.getCountries().subscribe((response) => {
      this.countries = response.data;
      this.selectedCountry = this.countries.find((country) => country.id === 154);
    });

    if (this.isRoleCustomer) {
      const clientId = this.authService.getUser().client_id;

      this.getClientOptions(clientId);
    }
  }

  public getClientOptions(clientId: number): void {
    this.client = null;

    this.clientService.getClient(clientId).subscribe((client) => {
      this.client = client;
      this.client.addresses = client.addresses.data;

      this.suppliers = client.suppliers.data.map((supplier: Supplier) => {
        return {
          label: supplier.name,
          value: supplier.id
        };
      });

      if (this.order) {
        this.loadAddresses = this.formatAddressOptions(this.client.addresses, true);
      } else {
        this.addresses = this.formatAddressOptions(this.client.addresses, true, true);
      }

      this.contactpersons = this.client.contactpersons.data.map((contactperson) => {
        return {
          label: `${contactperson.first_name || ''} ${contactperson.last_name_prefix || ''} ${contactperson.last_name}`,
          value: contactperson.id
        };
      });

      this.contactpersons.unshift({ label: 'Geen', value: null });

      if (this.order) {
        this.onAddressChange(this.order.address_id);
      }
    });
  }

  public reloadContactpersons(): void {
    this.clientService.getClient(this.client.id).subscribe((client) => {
      this.contactpersons = client.contactpersons.data.map((contactperson) => {
        return {
          label: `${contactperson.first_name || ''} ${contactperson.last_name_prefix || ''} ${contactperson.last_name}`,
          value: contactperson.id
        };
      });

      this.contactpersons.unshift({ label: 'Geen', value: null });
    });
  }

  /**
   * On address dropdown change
   *
   * @param selectedAddressValue
   */
  public onAddressChange(selectedAddressValue: number): void {
    if (!this.order) {
      if (selectedAddressValue === -1) {
        this.addAddressType = 'discharge';
        this.openModal();
      } else {
        this.selectedAddress = this.client.addresses.find((address) => address.id === selectedAddressValue);

        if (!this.selectedAddress.hub_id) {
          this.messageService.error(MessageSummary['RETRIEVE_ARTICLES'], 'Adres is niet gekoppeld aan een hub');

          return;
        }

        if (this.selectedAddress.contactperson_id) {
          this.orderForm.patchValue({ contactperson_id: this.selectedAddress.contactperson_id });
        }

        this.orderForm.patchValue({ hub_id: this.selectedAddress.hub_id });
        this.getArticles();
        this.getLoadAddresses();
      }
    } else {
      this.selectedAddress = this.order.address;
      this.getLoadAddresses();
    }
  }

  public changeLoadAddress(): void {
    this.customLoadAddress = true;
  }

  public submit(): void {
    if (this.orderForm.valid) {
      const order = this.orderForm.value;
      order.status = 'received';
      order.client_id = this.client.id;
      order.preferred_delivery_date = moment(order.preferred_delivery_date).format('YYYY-MM-DD');

      if (!this.order) {
        order.articles = this.articlesWithPackingUnitSelectorComponent.articles.map((article) => {
          article.packing_unit.weight = article.packing_unit.weight * article.amount;

          return article;
        });
      } else {
        if (this.validateReturnOrderArticles()) {
          order.articles = this.articlesToReturn.map((article) => {
            article.amount = article.amountToReturn;

            return article;
          });
          order.articles = order.articles.filter((article) => article.amount !== 0);
        } else {
          return;
        }
      }

      this.orderService.addOrder(order).subscribe((createdOrder: Order) => {
        this.messageService.success(MessageSummary['OK'], 'Opdracht opgeslagen');

        if (this.order) {
          this.shipmentService.getColli([createdOrder]).pipe(mergeMap(({ shipment, colli }) => {

            shipment.transporter_id = 1; // Default transporter is PostNL
            shipment.client_id = createdOrder.client_id;
            shipment.address_id = this.selectedAddress.id;
            shipment.orders = [createdOrder];

            const momentDate = moment(createdOrder.preferred_delivery_date);
            shipment.load_date = momentDate.format('YYYY-MM-DD');
            shipment.discharge_date = momentDate.format('YYYY-MM-DD');

            let supplierIds = [createdOrder].map((item) => {
              return item.supplier_id;
            });

            // Get only unique suppliers
            supplierIds = Array.from(new Set(supplierIds));

            shipment.colli = colli.map((collo) => {
              collo.payer = this.order.client.customer || 'client';

              if (collo.payer === 'supplier' && supplierIds.length === 1) {
                collo.supplier_id = supplierIds[0];
              } else if (collo.payer === 'client') {
                collo.client_id = this.order.client.id;
              }

              return collo;
            });

            if (this.isReturn) {
              shipment.type = 'return-goods';
            }

            return this.shipmentService.addShipment(shipment);
          })).subscribe((response) => {
            this.router.navigate([`/pages/shipments/${response.id}`]);
          }, error => {
            this.sharedService.handleError(error, 'Opdracht opslaan');
          });
        } else {
          this.router.navigate([`/pages/orders/${createdOrder.id}`]);
        }
      }, error => {
        this.sharedService.handleError(error, 'Opdracht opslaan');
      });
    } else {
      let message = 'Vul alle verplichte velden in (*)';

      if (this.orderForm.get('address_id').invalid) {
        message = 'Selecteer een adres';
      }

      this.messageService.error(MessageSummary['SAVE_ORDER'], message);
    }
  }

  public onSupplierChange(event: Event): void {
    this.supplierId = this.orderForm.get('supplier_id').value;

    this.getArticles();
    this.getLoadAddresses();
  }

  public openModal(): void {
    this.buildAddressForm();
    this.addressModal.show();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public cancelAddress(): void {
    this.addressForm.reset();
    this.addressModal.hide();

    if (this.orderForm.get('address_id').value === -1) {
      this.orderForm.patchValue({ address_id: null });
    }
  }

  public submitAddress(address: Address): void {
    if (this.addAddressType === 'load') {
      this.addressService.create(address).subscribe((createdAddress: Address) => {
        this.selectedLoadAddress = createdAddress;
        this.orderForm.patchValue({ load_address_id: createdAddress.id });
        this.addressModal.hide();
      });
    } else {
      this.client.addresses.push(address);
      this.selectedAddress = address;

      if (this.selectedAddress.contactperson_id) {
        this.orderForm.patchValue({ contactperson_id: this.selectedAddress.contactperson_id });
      }

      this.updateClient();
      this.getArticles();
      this.getLoadAddresses();
    }
  }

  public updateClient(): void {
    this.clientService.updateClient(this.client).subscribe((client) => {
      this.stateService.setCurrentClient(client);
      this.client = client;
      this.client.addresses = client.addresses.data;
      this.addresses = this.formatAddressOptions(client.addresses);
      this.selectedAddress = this.client.addresses[this.client.addresses.length - 1];
      this.orderForm.patchValue({ address_id: this.selectedAddress.id });
      this.addressModal.hide();
    }, error => {
      this.sharedService.handleError(error, 'Adres opslaan');
    });
  }

  public showArticles(): boolean {
    // Address must be selected
    if (!this.selectedAddress) {
      return false;
    }

    // Address must be attached to hub
    if (!this.selectedAddress.hub_id) {
      return false;
    }

    // Supplier must be selected
    if (!this.orderForm.get('supplier_id').value) {
      return false;
    }

    return true;
  }

  public getTotalWeight(): number {
    if (this.articlesWithPackingUnitSelectorComponent && this.articlesWithPackingUnitSelectorComponent.articles) {
      return this.articlesWithPackingUnitSelectorComponent.articles.reduce(
        (accumulator: number, currentValue: Article) => {
          if (currentValue.packing_unit) {
            return accumulator +
              (parseFloat(String(currentValue.packing_unit.weight).replace(',', '.')) * currentValue.amount);
          }

          return accumulator;
        },
        0
      );
    }

    return 0;
  }

  public getTotalVolume(): number {
    if (this.articlesWithPackingUnitSelectorComponent && this.articlesWithPackingUnitSelectorComponent.articles) {
      let totalVolume = this.articlesWithPackingUnitSelectorComponent.articles.reduce(
        (accumulator: number, currentValue: Article) => {
          if (currentValue.packing_unit) {
            return accumulator + (this.articleService.getVolume(currentValue) * currentValue.amount);
          }

          return accumulator;
        },
        0
      );

      totalVolume = Math.round(totalVolume * 100) / 100;

      return totalVolume;
    }

    return 0;
  }

  private getArticles(): void {
    if (!this.selectedAddress) {
      return;
    }

    this.loadingArticles = true;

    const hubId = this.selectedAddress.hub_id;
    if (!hubId) {
      this.messageService.error(MessageSummary['RETRIEVE_ARTICLES'], 'Adres is niet gekoppeld aan een hub');

      return;
    }

    if (!this.supplierId) {
      return;
    }

    let self = this;
    this.articleService.getAll(this.supplierId, hubId).subscribe((articles) => {
      this.articles = articles.map((article) => {
        article.packing_units = article.packing_units['data'];
        article.label = `${article.number} - ${article.name}`;

        article.packing_units.forEach(function (value: PackingUnit, index: number) {
          let optionLabel = value.name;

          if (value.weight && value.length && value.width && value.height) {
            let volume = (value.length * value.width * value.height) / 1000000;
            volume = Math.round(+volume * 100) / 100;
            volume = self.replaceDotToCommaPipe.transform(volume);

            optionLabel += ` (${value.weight}kg, ${value.length}x${value.width}x${value.height}, ${volume}m3)`;
          }

          value.label = optionLabel;
          value.value = value.id;
        });

        return article;
      });

      this.loadingArticles = false;
    });
  }

  private focusOutFunction(): void {
    if (this.addressForm.value.postcode && this.addressForm.value.house_number) {
      let output = this.sharedService.checkZipcode(this.addressForm.value);

      if (output['success']) {
        this.loadingZipcode = true;

        this.sharedService.getZipcode(output).subscribe((result) => {
            if (result !== null) {
              this.addressForm.patchValue({ street: result.street });
              this.addressForm.patchValue({ city: result.city });

              this.loadingZipcode = false;
            }
          },
          (error: any) => {
            this.messageService.warning(MessageSummary['ZIPCODE_CHECK'], 'Adres niet gevonden o.b.v. postcode en huisnummer');
          });
      }
    }
  }

  private buildForm(): void {
    let config = {
      address_id: ['', Validators.required],
      load_address_id: ['', Validators.nullValidator],
      supplier_id: [null, Validators.required],
      hub_id: [null],
      contactperson_id: [null, Validators.nullValidator],
      preferred_delivery_date: [this.nextWorkingDay, Validators.required],
      remarks: [''],
      code: [null],
      reference: ['']
    };

    if (!this.isRoleCustomer) {
      config['client_id'] = [null, Validators.required];
    } else {
      config['client_id'] = [null, Validators.nullValidator];
    }

    this.orderForm = this.formBuilder.group(config);

    if (this.isRoleSupplier) {
      const user = this.authService.getUser();
      const supplierId = user.supplier_id;

      this.orderForm.patchValue({ supplier_id: supplierId });
    }
  }

  private buildAddressForm(): void {
    let config = {
      postcode: ['', Validators.required],
      house_number: ['', Validators.required],
      house_number_suffix: [''],
      country_id: [154, Validators.required],
      street: ['', Validators.required],
      location: ['', Validators.required],
      extra_address_line: ['']
    };

    if (this.addAddressType === 'discharge') {
      config['hub_id'] = [null, Validators.required];
    }

    this.addressForm = this.formBuilder.group(config);
  }

  private formatAddressOptions(
    addresses: Address[],
    addNewAddressOption: boolean = true,
    addDescriptionToLabel: boolean = false
  ): SelectItem[] {
    const formattedAddresses = addresses.map((address: Address) => {
      let label = `${address.street || ''} ${address.house_number}${address.house_number_suffix || ''} ${address.location}`;
      if (addDescriptionToLabel && address.description) {
        label += ` (${address.description})`;
      }

      return {
        label: label,
        value: address.id
      };
    });

    if (addNewAddressOption) {
      formattedAddresses.push({ label: 'Adres toevoegen', value: -1 });
    }

    return formattedAddresses;
  }

  private getLoadAddresses(): void {
    if (!this.selectedAddress) {
      return;
    }

    const hubId = this.selectedAddress.hub_id;
    if (!hubId) {
      this.messageService.error(MessageSummary['RETRIEVE_LOADING_ADDRESSES'], 'Losadres is niet gekoppeld aan een hub');

      return;
    }

    const supplierId = this.orderForm.get('supplier_id').value;
    if (!supplierId) {
      return;
    }

    const supplierData = this.client.suppliers.data.find((supplier) => supplier.id === supplierId);
    const supplierAddresses = supplierData.addresses.data.filter((supplierAddress) => {
      return supplierAddress.hub_id === hubId;
    });

    // Get supplier address
    let loadAddresses = supplierAddresses.map((supplierAddress) => {
      supplierAddress.location = `${supplierAddress.location} (leverancier)`;

      return supplierAddress;
    });

    if (this.client) {
      // Add client addresses
      const clientAddresses = this.client.addresses.map((clientAddress) => {
        clientAddress.location = `${clientAddress.location} (ontvanger)`;

        return clientAddress;
      });

      loadAddresses = [...supplierAddresses, ...clientAddresses];
    }

    // Add HUB address
    if (this.selectedAddress.hub) {
      const hubAddress = this.selectedAddress.hub.address;

      hubAddress.location = `${hubAddress.location} (HUB)`;
      loadAddresses.push(this.selectedAddress.hub.address);
    }

    if (this.order) {
      this.addresses = this.formatAddressOptions(loadAddresses, false, true);
    } else {
      this.loadAddresses = this.formatAddressOptions(loadAddresses, false);
    }
  }

  private validateReturnOrderArticles(): boolean {
    this.invalidArticles = [];

    for (let i = 0; i < this.articlesToReturn.length; i++) {
      if (
        (this.articlesToReturn[i].amountToReturn > this.articlesToReturn[i].amount) ||
        (this.articlesToReturn[i].amountToReturn < 0)
      ) {
        this.invalidArticles.push(i);
      }
    }

    if (this.invalidArticles.length > 0) {
      this.messageService.error(
        'Opdracht retourneren',
        'Het aantal te retourneren van een of meerdere artikelen is onjuist. Controleer uw invoer'
      );
      return false;
    }

    return true;
  }
}
