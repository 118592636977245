import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, DataTable, SelectItem } from 'primeng/primeng';

import { ArticleService } from '../../../services/article.service';
import {
  AuthenticationService,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER, ROLE_SUPPLIER
} from '../../../services/authentication.service';
import { SupplierService } from '../../../services/supplier.service';

import { Article } from '../../../models/article.model';
import { PackingUnit } from '../../../models/packing-unit.model';
import { ArticleModalComponent } from '../../../components/article-modal/article-modal.component';
import { ArticleImportComponent } from '../article-import/article-import.component';
import { SharedService } from '../../../services/shared.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit {

  @ViewChild('articleModal') articleModal: ArticleModalComponent;
  @ViewChild('importArticles') importArticles: ArticleImportComponent;
  @ViewChild('dt') public dataTable: DataTable;

  public article: Article;
  public loading: boolean;
  public records: any[];
  public tableEvent: any;
  public totalRecords: number;
  public suppliersList: SelectItem[];
  public isEdit: boolean;
  public tableVisible: boolean;
  public selectedSupplier: number;
  public allowEdit: boolean;
  public allowDelete: boolean;
  public isRoleAdministrator: boolean = false;
  public isSupplier: boolean = false;
  public booleanList: SelectItem[] = [
    { label: 'Alle', value: '' },
    { label: 'Ja', value: true },
    { label: 'Nee', value: false }
  ];

  public supplierId: number;
  public paginationAmount: number = 30;

  constructor(protected router: Router,
              protected articleService: ArticleService,
              protected supplierService: SupplierService,
              protected authService: AuthenticationService,
              protected confirmationService: ConfirmationService,
              private sharedService: SharedService) {
    this.loading = false;
    this.records = [];
    this.totalRecords = 0;
    this.suppliersList = [];
    this.isEdit = false;
    this.allowDelete = false;
    this.tableVisible = true;
    this.selectedSupplier = 0;
    this.supplierId = 0;
    this.allowEdit = false;

    this.resetArticle();
  }

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.supplierService.list().subscribe((response) => {
      this.suppliersList = response;
      this.suppliersList.unshift({ label: 'Alle', value: null });
    });

    this.allowEdit = this.authService.isRoleAuthorized([
      ROLE_MANAGER,
      ROLE_HUB_OPERATOR,
      ROLE_LOGISTIC_EMPLOYEE,
      ROLE_SUPPLIER
    ]);

    this.allowDelete = this.authService.isRoleAuthorized([
      ROLE_MANAGER,
      ROLE_HUB_OPERATOR,
      ROLE_LOGISTIC_EMPLOYEE
    ]);

    this.isRoleAdministrator = this.authService.isRoleAuthorized([
      ROLE_HUB_OPERATOR,
      ROLE_MANAGER
    ]);

    this.isSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]) && this.authService.getUser().supplier_id;

    if (this.allowEdit === false || this.isSupplier) {
      this.supplierId = this.authService.getUser().supplier_id;
    }
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public getArticleData(event): void {
    this.tableEvent = event;

    this.articleService.getArticles(event, event.first / event.rows + 1, event.rows).subscribe((response) => {
      this.loading = false;
      this.records = response.data;
      this.totalRecords = response.meta.pagination.total;
    });
  }

  public addArticle(): void {
    this.isEdit = false;
    this.resetArticle();

    // Always add a extra empty rule
    this.addPackingUnit();
    this.articleModal.showModal();
  }

  public onRowSelect(event: any): void {
    if (event !== undefined) {
      if (event.originalEvent.target.classList.contains('fas') || event.originalEvent.target.classList.contains('fa')) {
        return;
      }
    }

    if (!this.allowEdit) {
      return;
    }

    this.isEdit = true;

    this.article = event.data;

    if (this.article.hubs.data !== undefined) {
      this.article.hub_ids = this.article.hubs.data.map((hub) => {
        return hub.id;
      });
    }

    if (this.article.packing_units['data']) {
      this.article.packing_units = event.data.packing_units.data;
    }

    this.article = this.sharedService.deepCopy(this.article);

    this.articleModal.showModal();
  }

  public showImportModal(): void {
    this.importArticles.showModal();
  }

  public downloadArticles(): void {
    this.articleService.export().subscribe((response: Blob) => {
      const timeStamp = new Date().getTime();
      const fileName = `Export artikelen - ${timeStamp}`;

      FileSaver.saveAs(response, fileName + '.xlsx');
    }, (errorResponse) => {
      this.sharedService.handleError(errorResponse, 'Export downloaden');
    });
  }

  public confirmDelete(id: number, event: any): void {
    event.stopPropagation();

    this.confirmationService.confirm({
      message: 'Weet u zeker dat u dit artikel wilt verwijderen?',
      accept: () => {
        this.deleteArticle(id);
      }
    });
  }

  public viewDetail(id: number): void {
    this.router.navigate([`/pages/articles/${id}`]);
  }

  public updateTable(): void {
    this.getArticleData(this.tableEvent);
  }

  private translateStockingItem(stocking): string {
    switch (stocking) {
      case 1:
        return 'Ja';
      case 2:
        return 'Nee';
      case 3:
        return 'Ja en Nee';
      default:
        return '';
    }
  }

  private translateIsActiveItem(isActive): string {
    return isActive === true ? 'Ja' : 'Nee';
  }

  private addPackingUnit(): void {
    const packingUnit = new PackingUnit();
    packingUnit.is_other = false;

    this.article.packing_units.push(packingUnit);
  }

  private deleteArticle(id: number): void {
    this.articleService.deleteArticle(id).subscribe(() => {
      this.getArticleData({ filters: this.dataTable.filters });
    }, error => {
      this.sharedService.handleError(error, 'Artikel verwijderen');
    });
  }

  private resetArticle(): void {
    this.article = new Article();
    this.article.is_stockitem = 2;
    this.article.packing_units = [];

    if (this.allowEdit === false || this.isSupplier) {
      this.article.supplier_id = this.supplierId;
    }
  }
}
