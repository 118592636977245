export enum ShipmentStatus {
  OPEN = 'open',
  REGISTERED = 'registered',
  PLANNED = 'planned',
  ACCEPTED = 'accepted',
  REFUSED = 'refused',
  READY_FOR_TRANSPORT = 'ready_for_transport',
  DELIVERED = 'delivered',
  UNDELIVERED = 'undelivered',
  RETURN = 'return',
  INVOICED = 'invoiced',
  CANCELLED = 'cancelled',
  LOADED = 'loaded',
  UNDERWAY = 'underway',
  ADDRESS_UNKNOWN = 'address_unknown',
  ADDRESS_CLOSED = 'address_closed',
  ADDRESS_UNREACHABLE = 'address_unreachable',
  NOT_DELIVERED_OUTSIDE_TIME = 'not_delivered_outside_time',
  ACCEPTED_NOT_ARRIVED = 'accepted_not_arrived',
  LOAD_CARRIER_RETRIEVED = 'load_carrier_retrieved',
  DELIVERED_AT_HUB = 'delivered_at_hub',
  NOT_RETRIEVED_NOT_AVAILABLE = 'not_retrieved_not_available',
  NOT_RETRIEVED_INCORRECT_LABEL = 'not_retrieved_incorrect_label',
  INCORRECT_PACKING = 'incorrect_packing',
  DISPATCHER_UNAVAILABLE = 'dispatcher_unavailable',
  PICKUP_REQUEST_CANCELLED = 'pickup_request_cancelled'
 }
