import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Supplier } from '../../models/supplier.model';
import { SelectItem } from 'primeng/primeng';
import { FieldMapping } from '../../models/fieldMapping';
import { FieldMappingOverride } from '../../models/fieldMappingOverride';
import { FieldMappingExtraField } from '../../models/fieldMappingExtraField';

@Component({
  selector: 'app-supplier-field-mapping',
  templateUrl: './supplier-field-mapping.component.html',
  styleUrls: ['./supplier-field-mapping.component.scss']
})
export class SupplierFieldMappingComponent implements OnInit {
  @Input()
  public supplier: Supplier;
  @Output()
  public save: EventEmitter<void> = new EventEmitter<void>();

  public mappedFields: SelectItem[];
  public field: string;
  public config: FieldMapping[];
  public selectedField: FieldMapping;
  public availableFields: SelectItem[];

  constructor() {
    this.config = [];
  }

  public ngOnInit(): void {
    this.config = Object.keys(this.supplier.field_mapping.configurations).map(key => this.supplier.field_mapping.configurations[key]);

    this.config.forEach((field) => {
      if (field.enabled === null || !field.hasOwnProperty('enabled')) {
        field.enabled = true;
      }

      if (field.active && field.hasOwnProperty('fieldOverride')) {
        for (const key in field.fieldOverride) {
          this.config.find((configField: FieldMapping) => {
            return configField.key === key;
          }).enabled = false;
        }
      }
    });

    this.updateDropdown();
  }

  /**
   * Change selected field
   *
   * @param event
   */
  public changeField(event: any): void {
    this.selectedField = event.value;
  }

  /**
   * Activate the selected field and update the dropdown options
   */
  public activateField(): void {
    this.selectedField.active = true;

    if (this.selectedField.fieldOverride) {
      for (const key in this.selectedField.fieldOverride) {
        this.config.find((field: FieldMapping) => {
          return key === field.key
        }).enabled = false;
      }
    }

    this.updateDropdown();
  }

  /**
   * Deactivate the supplied field and update the dropdown options
   *
   * @param fieldMapping
   */
  public deactivateField(fieldMapping: FieldMapping): void {
    fieldMapping.active = false;

    if (fieldMapping.hasOwnProperty('fieldOverride')) {
      for (const key in fieldMapping.fieldOverride) {
        this.config.find((field: FieldMapping) => {
          return key === field.key
        }).enabled = true;
      }
    }

    this.updateDropdown();
  }

  /**
   * Re-populate the data in the dropdown
   */
  private updateDropdown(): void {
    let inactiveFields: FieldMapping[] = this.config.filter((configField: FieldMapping) => {
        return configField.active === false && configField.required === false
      }
    );

    this.availableFields = inactiveFields.map((configField: FieldMapping) => {
      return { label: configField.key, value: configField };
    });

    if (inactiveFields.length > 0) {
      this.selectedField = inactiveFields[inactiveFields.length - 1];
    }
  }

  /**
   * Determine style for the FieldMapping row
   * @param data
   */
  public rowStyleClass(data: FieldMapping): string {
    let classes = [];

    if (!data.active) {
      classes.push('hidden');
    }

    if (!data.enabled) {
      classes.push('disabled');
    }

    return classes.join(' ');
  }

  public saveConfiguration(): void {
    this.save.emit();
  }

}
