/* tslint:disable:variable-name */
import { Supplier } from './supplier.model';
import { Client } from './client.model';

/**
 * Model Conversion
 */
export class Conversion {
  id: number;
  supplier_id?: number;
  client_id?: number;
  type: string;
  external_value: string;
  local_value: string;
  supplier?: Supplier;
  client?: Client;
}
