
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { AdditionalSellRateSupplier } from '../models/additionalSellRateSupplier.model';

@Injectable()
export class SupplierService extends ApiService {
  public valueChanged = {
    zipcode: '',
    house_number: '',
    house_number_suffix: ''
  };
  public changed = false;

  public getSuppliers(data, page = 1, perPage = 20): Observable<any> {
    return this.post(`suppliers/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public getAllSuppliersShort(): Observable<any> {
    return this.get('suppliers/get');
  }

  public getSupplier(supplierId: number): Observable<any> {
    return this.get('suppliers/' + supplierId + '/edit');
  }

  public updateSupplier(supplier: any): Observable<any> {
    if (supplier.whitelisted_ips.data &&
      supplier.whitelisted_ips.data.length &&
      typeof supplier.whitelisted_ips.data[0] === 'object') {
      supplier.whitelisted_ips.data = supplier.whitelisted_ips.data.map(item => item.ip_address);
    }

    supplier.has_api = !!supplier.has_api;
    return this.put('suppliers', supplier);
  }

  public addSupplier(supplier: any): Observable<any> {
    return this.post('suppliers', supplier);
  }

  public downloadDocumentation(): Observable<Blob> {
    return this.getBlob('documentation/download-bundle');
  }

  public checkZipcode(address: any): any {
    let result = {};

    let countryId = address.country_id;
    // if country_id == NL
    // tslint:disable-next-line:radix
    if (parseInt(countryId) === 154) {
      let valid = true;
      let houseNumber = address.house_number;
      let houseNumberAddition = address.house_number_suffix;
      let zipcode = address.postcode;
      let error = {};

      if (typeof (zipcode) === 'undefined' || zipcode === '') {
        valid = false;
        error['postcode'] = 'Postcode is leeg';
      } else {
        zipcode = zipcode.toUpperCase();
      }
      if (typeof (houseNumber) === 'undefined' || houseNumber === '') {
        valid = false;
        error['house_number'] = 'huisnummer is leeg';
      }

      let regeNL = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
      if (!regeNL.test(zipcode)) {
        valid = false;
        if (!error['postcode']) {
          error['postcode'] = 'postcode is niet correct ingevuld (1234AB)';
        }
      }

      if (isNaN(houseNumber)) {
        valid = false;
        if (!error['house_number']) {
          error['house_number'] = 'huisnummer is niet geldig';
        }
      }

      if (valid) {
        this.changed = false;
        if (this.valueChanged.zipcode === '' || this.valueChanged.zipcode !== zipcode) {
          this.valueChanged.zipcode = zipcode;
          this.changed = true;
        }
        if (this.valueChanged.house_number === '' || this.valueChanged.house_number !== houseNumber) {
          this.valueChanged.house_number = houseNumber;
          this.changed = true;
        }
        if (this.valueChanged.house_number_suffix !== houseNumberAddition) {
          this.valueChanged.house_number_suffix = houseNumberAddition;
          this.changed = true;
        }
        if (this.changed === true) {
          result['postcode'] = zipcode;
          result['house_number'] = houseNumber;
          result['houseNumberAddition'] = houseNumberAddition;
          result['success'] = true;
        } else {
          result['success'] = false;
        }

      } else {
        result['success'] = false;
      }
    } else {
      result['success'] = false;
    }

    return result;
  }

  public getZipcode(client: any): Observable<any> {
    if (client.houseNumberAddition !== null) {
      return this.get(
        `suppliers/address/${client.postcode.toUpperCase()}/${client.house_number}/${client.houseNumberAddition}`
      );
    }

    return this.get(`suppliers/address/${client.postcode.toUpperCase()}/${client.house_number}`);
  }

  public list(valueProperty?: string, labelProperties?: string[]): any {
    let url = 'suppliers/list';

    if (valueProperty && labelProperties) {
      url += `?_valueProperty=${valueProperty}&_labelProperties=${labelProperties.join(',')}`;
    }

    return this.get(url).pipe(map((response: any) => response.suppliers));
  }

  public addAdditionalSellRate(
    supplierId: number,
    additionalSellRateSupplier: AdditionalSellRateSupplier
  ): Observable<any> {
    return this.post(`suppliers/${supplierId}/additional-sell-rates`, additionalSellRateSupplier);
  }

  public updateAdditionalSellRate(
    supplierId: number,
    additionalSellRateSupplier: AdditionalSellRateSupplier
  ): Observable<any> {
    return this.put(`suppliers/${supplierId}/additional-sell-rates`, additionalSellRateSupplier);
  }

  public deleteAdditionalSellRate(supplierId: number, id: number): Observable<any> {
    return this.delete(`suppliers/${supplierId}/additional-sell-rates/${id}`);
  }
}

