import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { SelectItem } from 'primeng/primeng';

@Injectable()
export class ContactPersonsService extends ApiService {

  public addContactPerson(contactperson: any): Observable<any> {
    return this.post('contactpersons', contactperson);
  }

  public updateContactPerson(contactperson: any): Observable<any> {
    return this.put('contactpersons', contactperson);
  }

  public deleteContactPerson(id: number): Observable<any> {
    return this.delete('contactpersons/' + id);
  }

  public uploadImageForContactPerson(file: File, id: number): Observable<any> {
    return this.uploadFile(`contactpersons/image/${id}`, file);
  }

  public deleteImageForContactPerson(id: number): Observable<any> {
    return this.delete(`contactpersons/image/${id}`);
  }

  public createUser(personId): Observable<any> {
    return this.post(`contactpersons/user/${personId}`, []);
  }

  public getGenders(): SelectItem[] {
    return [
      {
        label: 'Man',
        value: 'male'
      },
      {
        label: 'Onbekend',
        value: 'none'
      },
      {
        label: 'Vrouw',
        value: 'female'
      }
    ];
  }
}
