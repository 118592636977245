import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SupplierAddComponent } from '../supplier-add/supplier-add.component';

import { AuthenticationService, ROLE_SUPPLIER } from '../../../services/authentication.service';
import { SupplierService } from '../../../services/supplier.service';
import { SharedService } from '../../../services/shared.service';
import { Supplier } from '../../../models/supplier.model';
import { StateService } from '../../../services/state.service';

@Component({
  selector: 'supplier-list-component',
  templateUrl: './supplier-list.component.html',
  styleUrls: ['./supplier-list.component.scss']
})
export class SupplierListComponent {
  @ViewChild(SupplierAddComponent)
  public supplierAddComponent: SupplierAddComponent;

  public tableVisible: boolean = true;
  public records: Supplier[] = [];
  public totalRecords: number = 0;
  public loading: boolean = true;
  public defaults: any = {
    code: '',
    countries: [],
    transporters: [],
    paymentAgreements: [],
    invoiceTerms: [],
    hubs: []
  };
  public meta: any = {
    code: '',
    countries: [],
    transporters: [],
    paymentAgreements: [],
    invoiceTerms: [],
    hubs: []
  };
  public paginationAmount: number = 30;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected supplierService: SupplierService,
    protected authService: AuthenticationService,
    private stateService: StateService,
    private sharedService: SharedService) {
  }

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.router.navigate([this.authService.getUser().supplier_id], { relativeTo: this.route });
    }
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(event: any): void {
    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      return;
    }

    this.loading = true;

    this.supplierService.getSuppliers(event, event.first / event.rows + 1, event.rows).subscribe((result: any) => {
      this.loading = false;
      this.records = result.data;
      this.meta = result.meta;
      this.defaults.code = this.meta.code;
      this.defaults.countries = this.sharedService.mapObjectToArray(this.meta.countries);
      this.defaults.transporters = this.sharedService.mapObjectToArray(this.meta.transporters);
      this.defaults.paymentAgreements = this.sharedService.mapObjectToArray(this.meta.paymentAgreements);
      this.defaults.invoiceTerms = this.sharedService.mapObjectToArray(this.meta.invoiceTerms);
      this.defaults.hubs = this.sharedService.mapObjectToSelectItems(this.meta.hubs);
      this.totalRecords = result.pagination.total;
    });
  }

  public onRowSelect(event: any): void {
    this.stateService.setCurrentSupplier(event.data);

    this.router.navigate([event.data.id], { relativeTo: this.route });
  }

  public addSupplier(event: any): void {
    this.supplierAddComponent.showModal();
  }

  public onSupplierSubmit(): void {
    // Trigger visibility to refresh table
    this.tableVisible = false;

    setTimeout(() => {
      this.tableVisible = true;
    }, 0);
  }
}
