import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined } from 'util';

import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_FINANCIAL_EMPLOYEE,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER,
  ROLE_TRANSPORTER
} from '../../../services/authentication.service';
import { ClientsService } from '../../../services/clients.service';
import { Client } from '../../../models/client.model';
import { SharedService } from '../../../services/shared.service';
import { TabsComponent } from '../../../components/tabs/tabs.component';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { TabComponent } from '../../../components/tabs/tab.component';

@Component({
  selector: 'client-details-component',
  templateUrl: './client-details.html',
  styleUrls: ['./client-details.scss']
})
export class ClientDetailsComponent implements OnInit {

  @ViewChild('administrativeTab') administrativeTab;
  @ViewChild('shipmentsTab') shipmentsTab;
  @ViewChild('contactpersonsTab') contactpersonsTab;
  @ViewChild('ordersTab') ordersTab;
  @ViewChild('invoiceTab') invoiceTab: TabComponent;
  @ViewChild('tabs') tabs: TabsComponent;

  public client: Client = new Client();
  public loading: boolean = false;
  public isRoleManager: boolean = false;
  public isRoleLogistic: boolean = false;
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleTransporter: boolean = false;
  public isRoleHubOperator: boolean = false;
  public defaults: any = {
    paymentAgreements: [],
    addressTypes: [],
    countries: [],
    communicationTypes: [],
    transporters: [],
    customer: [],
    hubs: [],
    suppliers: []
  };

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private sharedService: SharedService,
    private clientsService: ClientsService,
    private messageService: MessageService,
    private authService: AuthenticationService) {
  }

  public ngOnInit(): void {
    this.loading = true;
    this.isRoleManager = this.authService.isRoleAuthorized([ROLE_MANAGER]);
    this.isRoleLogistic = this.authService.isRoleAuthorized([ROLE_LOGISTIC_EMPLOYEE]);
    this.isRoleCustomer = this.authService.isRoleAuthorized([ROLE_CUSTOMER]);
    this.isRoleSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]);
    this.isRoleTransporter = this.authService.isRoleAuthorized([ROLE_TRANSPORTER]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);

    this.activeRoute.queryParams.subscribe(params => {
      switch (params['tab']) {
        case 'contactperson':
          this.tabs.selectTab(this.contactpersonsTab);
          break;
        case 'order':
          this.tabs.selectTab(this.ordersTab);
          break;
        case 'shipment':
          this.tabs.selectTab(this.shipmentsTab);
          break;
        case 'exported-invoices':
          this.tabs.selectTab(this.invoiceTab);
          break;
        default:
          break;
      }
    });

    this.initClient();
  }

  public showAdditionalSellRatesTab(): boolean {
    return this.authService.isRoleAuthorized([
      ROLE_MANAGER,
      ROLE_LOGISTIC_EMPLOYEE,
      ROLE_FINANCIAL_EMPLOYEE,
      ROLE_TRANSPORTER
    ]);
  }

  public onTabSelect(activeTab: any): void {
    let viewChild = activeTab.id + 'Tab';

    if (!isUndefined(this[viewChild]) && !isUndefined(this[viewChild].onTabSelected)) {
      this[viewChild].onTabSelected(activeTab.action);
    }
  }

  public initClient(): void {
    this.activeRoute.params.subscribe((params: any) => {
      const id = params['id'];
      this.client.id = id.split('?')[0];
      this.loading = true;

      this.clientsService.getClient(this.client.id).subscribe((client: any) => {
          this.loading = false;

          this.client = client;
          this.client.addresses = client.addresses.data.map((address) => {
            if (address.delivery_agreements) {
              address.delivery_agreements = address.delivery_agreements.data;
            }

            return address;
          });

          if (this.client.suppliers.data !== undefined) {
            this.client.supplier_ids = client.suppliers.data.map((supplier) => {
              return supplier.id;
            });
          }

          this.defaults.paymentAgreements = this.sharedService.mapObjectToArray(client.meta.payment_agreements);
          this.defaults.addressTypes = this.sharedService.mapObjectToArray(client.meta.addressTypes);
          this.defaults.countries = this.sharedService.mapObjectToArray(client.meta.countries);
          this.defaults.communicationTypes = this.sharedService.mapObjectToArray(client.meta.communicationTypes);
          this.defaults.transporters = this.sharedService.mapObjectToSelectItems(client.meta.transporters);
          this.defaults.customers = this.sharedService.mapObjectToSelectItems(client.meta.customers);
          this.defaults.hubs = this.sharedService.mapObjectToSelectItems(client.meta.hubs);

          if (client.meta.suppliers) {
            const clientSuppliers = { ...client.meta.suppliers };

            for (let supplierId in client.meta.suppliers) {
              if (!this.client.supplier_ids.includes(+supplierId)) {
                delete clientSuppliers[supplierId];
              }
            }

            this.defaults.suppliers = this.sharedService.mapObjectToSelectItems(clientSuppliers, 'Alle');
          }

          this.loading = false;
        },
        (error: any) => this.handleError(error));
    });
  }

  private handleError(error: any, message: string = 'Kan relatie niet openen'): void {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (errorMessage) {
          message += '<br>' + result.errors[errorMessage][0];
        });
      }
    }

    this.messageService.error(MessageSummary['SOMETHING_WENT_WRONG'], message);
  }
}
