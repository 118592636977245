export class Defaults {
  genders?: { [key: string]: string }[];
  paymentAgreements?: { [key: string]: string }[];
  addressTypes?: { [key: string]: string }[];
  countries?: { [key: string]: string }[];
  communicationTypes?: { [key: string]: string }[];
  branches?: { [key: string]: string }[];
  clientTypes?: { [key: string]: string }[];
  clientStatuses?: { [key: string]: string }[];
  packings?: { [key: string]: string }[];
  clients?: { [key: string]: string }[];
}
