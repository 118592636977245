
import {throwError as observableThrowError,  BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, filter, switchMap, take } from 'rxjs/operators';



import { AuthenticationService } from 'app/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthenticationService, protected router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token) {
      request = this.addToken(request, token);
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else {
          return observableThrowError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService
        .refreshToken()
        .pipe(
          switchMap((token: string) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token);
            return next.handle(this.addToken(request, token));
          })
        ).pipe(
        catchError(err => {
          // If the refresh token action returns 401, then clean auth data and redirect to login
          this.isRefreshing = false;
          this.refreshTokenSubject.complete();
          this.refreshTokenSubject = new BehaviorSubject<any>(null);
          this.authService.removeUser();
          this.router.navigate(['/auth/login']);
          return observableThrowError(err);
        }));
    } else {
      // Wait util a valid token is available again
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap((token: string) => {
          return next.handle(this.addToken(request, token));
        })
      );
    }
  }
}
