import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { BaseComponent } from 'app/base.component';
import { TabsComponent } from '../../../../components/tabs/tabs.component';
import { ClientsService } from '../../../../services/clients.service';
import { SupplierService } from '../../../../services/supplier.service';
import { Address } from '../../../../models/address.model';
import { Client } from '../../../../models/client.model';
import { SharedService } from '../../../../services/shared.service';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_SUPPLIER
} from '../../../../services/authentication.service';
import { MessageService, MessageSummary } from '../../../../services/message.service';
import { ClientTypesService } from '../../../../services/client-types.service';
import { ClientType } from '../../../../models/client-type.model';

@Component({
  selector: 'client-details-common',
  templateUrl: './client-details-common.component.html',
  styleUrls: ['./client-details-common.scss']
})
export class ClientDetailsCommonComponent extends BaseComponent implements OnInit {
  @ViewChild('form') public form;

  get client(): Client {
    return this._client;
  }

  @Input()
  set client(value: Client) {
    if (value.contactpersons) {
      this.contactpersons = value.contactpersons.data.map((contactperson) => {
        return { label: contactperson.full_name, value: contactperson.id };
      });
    }

    this._client = value;
  }

  @Input() public defaults: any;

  @Output() onRefreshData: EventEmitter<any> = new EventEmitter();

  public suppliers: SelectItem[] = [];
  public contactpersons: SelectItem[] = [];
  public clientTypeItems: SelectItem[] = [];
  public clientTypes: ClientType[] = [];
  public clientList: SelectItem[] = [];
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public readonly: boolean = false;

  private _client: Client;

  constructor(
    protected clientsService: ClientsService,
    protected clientTypesService: ClientTypesService,
    protected router: Router,
    protected tabs: TabsComponent,
    public sharedService: SharedService,
    protected confirmationService: ConfirmationService,
    private supplierService: SupplierService,
    private messageService: MessageService,
    private authService: AuthenticationService) {
    super(authService, [
      {
        'role': ROLE_CUSTOMER,
        'hidden': [
          'remarks'
        ],
        'readonly': [
          'client_code',
          'company_name',
          'first-name',
          'last-name-prefix',
          'last-name',
          'email',
          'phone'
        ]
      },
      {
        'role': ROLE_SUPPLIER,
        'hidden': [
          'remarks'
        ],
        'readonly': [
          'client_code',
          'company_name',
          'first-name',
          'last-name-prefix',
          'last-name',
          'email',
          'phone'
        ]
      }
    ]);

    this.contactpersons = [];
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR])) {
      this.isRoleHubOperator = true;
      this.readonly = false;
    }

    this.supplierService.list().subscribe((suppliers) => {
      this.suppliers = suppliers;
    });

    this.clientsService.list().subscribe((clients) => {
      this.clientList = clients;
    });

    this.clientTypesService.getClientTypes(null, null, 99999).subscribe(result => {
      this.clientTypes = result.data;
    });

    this.clientTypesService.list().subscribe((clientTypes) => {
      this.clientTypeItems = clientTypes;
    });
  }

  public submitClientForm(form: FormGroup): void {
    if (form.valid) {
      this.updateClient('Gegevens opgeslagen');
    }
  }

  public updateClient(message: string = 'Ontvanger opgeslagen', redirectToList: boolean = true): void {
    this.clientsService.updateClient(this.client).subscribe((client: Client) => {
      this.messageService.success(MessageSummary['OK'], message);

      this.client.addresses = client.addresses.data;

      if (redirectToList) {
        this.router.navigate(['pages/clients']);
      }
    }, (error) => {
      this.handleError(error);
    });
  }

  public updateAddresses(addresses: Address[]): void {
    this.client.addresses = addresses;
    this.updateClient('Adres opgeslagen', false);
  }

  public updateNewClient(clientTypeId: number): void {
    if (clientTypeId) {
      const type = this.clientTypes.find(clientType => clientType.id === clientTypeId);
      if (type) {
        this.client.client_type.code = type.code;
        this.client.client_type.name = type.name;
      }
    }
  }

  private handleError(error: any, summary = 'CLIENT', message = 'Kan ontvanger niet opslaan'): void {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (err) {
          message += '<br>' + result.errors[err][0];
        });
      }
    }

    this.messageService.error(MessageSummary[summary], message);
  }
}
