/**
 * Model ClientType
 */
export class ClientType {
  id?: number;
  code: string;
  name: string;

  constructor(defaultCode: string = null) {
    if (defaultCode) {
      this.code = defaultCode;
    }
  }
}
