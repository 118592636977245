import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Message, SelectItem } from 'primeng/primeng';
import { SharedService } from '../../services/shared.service';
import { Transporter } from '../../models/transporter.model';
import { TransporterService } from '../../services/transporter.service';
import { HubService } from '../../services/hub.service';

@Component({
  selector: 'app-shipment-export-modal',
  templateUrl: './shipment-export-modal.component.html',
  styleUrls: ['./shipment-export-modal.component.scss']
})
export class ShipmentExportModalComponent implements OnInit {

  @Input()
  public loading: boolean = false;

  @Output()
  public onSubmit: EventEmitter<{ startDate: Date, endDate: Date }>;

  public today: Date;
  public formGroup: FormGroup;
  public calendarLocale: any;
  public errorMessages: Message[];
  public transporterList: SelectItem[];
  public hubList: SelectItem[];

  constructor(private formBuilder: FormBuilder,
              private sharedService: SharedService,
              private transporterService: TransporterService,
              private hubService: HubService) {
    this.onSubmit = new EventEmitter();
    this.today = new Date();
    this.calendarLocale = this.sharedService.getCalendarLocale();
  }

  public ngOnInit(): void {
    this.transporterService.list().subscribe((data) => {
      this.transporterList = data;
    });

    this.hubService.list().subscribe((data) => {
      this.hubList = data;
    });

    this.formGroup = this.formBuilder.group({
      date: [this.today, Validators.required],
      transporterId: [null],
      transporter: [null],
      hubId: [null, Validators.required],
      distinctShipmentNumbers: [null]
    });
  }

  /**
   * Patch the name of the transporter on dropdown change
   *
   * @param $event
   */
  public changeTransporter($event: any): void {
    let transporter = this.transporterList.find(pack => pack.value === $event.value);
    this.formGroup.patchValue({transporter: transporter.label})
  }

  /**
   * Submit the form
   */
  public submit(): void {
    if (this.formGroup.valid) {
      this.onSubmit.emit(this.formGroup.value);
    }
  }
}
