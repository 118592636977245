
import {map} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InvoicesService } from '../../../services/invoices.service';
import { Invoice } from '../../../models/invoice.model';
import { Observable } from 'rxjs';
import * as FileSaver from 'file-saver';
import { SharedService } from '../../../services/shared.service';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { InvoiceRow } from '../../../models/invoice-row.model';
import { InvoiceRowsService } from '../../../services/invoice-rows.service';
import { AuthenticationService, ROLE_HUB_OPERATOR, ROLE_SUPPLIER } from '../../../services/authentication.service';

@Component({
  selector: 'app-exported-invoice-detail',
  templateUrl: './exported-invoice-detail.component.html',
  styleUrls: ['./exported-invoice-detail.component.scss']
})
export class ExportedInvoiceDetailComponent implements OnInit {
  @Output()
  public onUpdate: EventEmitter<void>;

  public exporting: boolean = false;
  public invoice$: Observable<Invoice>;
  public invoiceStatuses$: Observable<SelectItem[]>;
  public loading: boolean = false;
  public tableVisible: boolean = true;
  public invoiceRows: InvoiceRow[];
  public totalRecords: number = 0;
  public paginationAmount: number = 30;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;

  private _invoiceId: number;

  get invoiceId(): number {
    return this._invoiceId;
  }

  @Input()
  set invoiceId(value: number) {
    this.invoice$ = this.invoicesService.getInvoice(value).pipe(map((invoice) => {
      return invoice;
    }));

    if (value) {
      this.loadData(value, {});
    }

    this._invoiceId = value;
  }

  constructor(private invoicesService: InvoicesService,
              private invoiceRowsService: InvoiceRowsService,
              private authService: AuthenticationService,
              private sharedService: SharedService,
              private confirmationService: ConfirmationService) {
    this.onUpdate = new EventEmitter();
  }

  public ngOnInit(): void {
    this.isRoleSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);

    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.invoiceStatuses$ = this.sharedService.getEnumOptions('invoices', 'status').pipe(map((enums) => {
      const enumSelectItems: SelectItem[] = [];

      for (const key in enums) {
        if (enums.hasOwnProperty(key)) {
          enumSelectItems.push({ label: enums[key], value: key });
        }
      }

      return enumSelectItems;
    }));
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(invoiceId: number, event: any): void {
    if (!invoiceId) {
      return;
    }

    this.loading = true;

    event.filters = {
      invoice_id: {
        value: invoiceId,
        matchMode: 'equals'
      }
    };

    this.invoiceRowsService.getInvoiceRows(event, event.first / event.rows + 1, event.rows)
      .subscribe((data: any) => {
        this.loading = false;
        this.invoiceRows = data.data;
        this.totalRecords = data.meta.pagination.total;
      });
  }

  /**
   * Download the excel that contains all the invoice rows
   */
  public downloadExcel(): void {
    this.exporting = true;

    this.invoicesService.downloadExcel(this.invoiceId).subscribe((response) => {
      this.exporting = false;
      FileSaver.saveAs(response, `Factuurregels.xlsx`);
      this.onUpdate.emit();
    });
  }

  /**
   * Set status to invoiced
   */
  public setStatusInvoiced(): void {
    this.invoicesService.setStatusInvoiced(this.invoiceId).subscribe(() => {
      this.onUpdate.emit();
    });
  }

  /**
   * Update the status of the invoice
   */
  public rollback(invoice: Invoice): void {
    this.confirmationService.confirm({
      message: 'Weet u zeker dat u de status naar teruggedraaid wil zetten?',
      key: 'exported-invoice',
      accept: () => {
        this.invoicesService.rollback(invoice.id).subscribe(() => {
          this.onUpdate.emit();
        });
      }
    });
  }
}
