import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AuthGuard } from '../guards';
import { Pages } from './pages.component';
import {
  ROLE_CUSTOMER,
  ROLE_DELIVERER,
  ROLE_FINANCIAL_EMPLOYEE,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER,
  ROLE_TRANSPORTER
} from '../services/authentication.service';

export const routes: Routes = [
  {
    path: 'pages',
    component: Pages,
    children: [
      {
        path: '',
        redirectTo: 'clients',
        pathMatch: 'full',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_CUSTOMER,
            ROLE_FINANCIAL_EMPLOYEE,
            ROLE_HUB_OPERATOR,
            ROLE_CUSTOMER
          ]
        }
      },
      {
        path: 'articles',
        loadChildren: 'app/pages/article/article.module#ArticleModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_SUPPLIER,
            ROLE_HUB_OPERATOR,
            ROLE_LOGISTIC_EMPLOYEE
          ]
        }
      },
      {
        // All users need access to edit their profile
        path: 'manager',
        loadChildren: 'app/pages/manager/manager.module#ManagerModule'
      },
      {
        path: 'clients',
        loadChildren: 'app/pages/clients/clients.module#ClientsModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_CUSTOMER,
            ROLE_FINANCIAL_EMPLOYEE,
            ROLE_LOGISTIC_EMPLOYEE,
            ROLE_SUPPLIER,
            ROLE_HUB_OPERATOR,
            ROLE_TRANSPORTER
          ]
        }
      },
      {
        path: 'suppliers',
        loadChildren: 'app/pages/suppliers/suppliers.module#SuppliersModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_CUSTOMER,
            ROLE_LOGISTIC_EMPLOYEE,
            ROLE_FINANCIAL_EMPLOYEE,
            ROLE_SUPPLIER,
            ROLE_HUB_OPERATOR,
            ROLE_TRANSPORTER
          ]
        }
      },
      {
        path: 'orders',
        loadChildren: 'app/pages/orders/orders.module#OrdersModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_CUSTOMER,
            ROLE_LOGISTIC_EMPLOYEE,
            ROLE_FINANCIAL_EMPLOYEE,
            ROLE_HUB_OPERATOR,
            ROLE_SUPPLIER
          ]
        }
      },
      {
        path: 'shipments',
        loadChildren: 'app/pages/shipments/shipments.module#ShipmentsModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_LOGISTIC_EMPLOYEE,
            ROLE_FINANCIAL_EMPLOYEE,
            ROLE_CUSTOMER,
            ROLE_HUB_OPERATOR,
            ROLE_SUPPLIER
          ]
        }
      },
      {
        path: 'invoices',
        loadChildren: 'app/pages/invoices/invoices.module#InvoicesModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_HUB_OPERATOR,
            ROLE_FINANCIAL_EMPLOYEE
          ]
        }
      },
      {
        path: 'sell-rates',
        loadChildren: 'app/pages/sell-rates/sell-rates.module#SellRatesModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_HUB_OPERATOR
          ]
        }
      },
      {
        path: 'packaging',
        loadChildren: 'app/pages/packaging/packaging.module#PackagingModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_HUB_OPERATOR,
            ROLE_CUSTOMER,
            ROLE_SUPPLIER,
            ROLE_LOGISTIC_EMPLOYEE
          ]
        }
      },
      {
        path: 'handheld-terminal',
        loadChildren: 'app/pages/handheld-terminal/handheld-terminal.module#HandheldTerminalModule',
        canActivate: [AuthGuard],
        data: {
          role: [
            ROLE_MANAGER,
            ROLE_DELIVERER
          ]
        }
      },
      {
        // amongst others: dashboard
        path: '**',
        redirectTo: 'clients'
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: 'app/pages/auth/auth.module#AuthModule'
  },
  {
    path: '**',
    redirectTo: 'pages/clients'
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
