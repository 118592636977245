import { throwError as observableThrowError,  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ApiService {
  private decimalFields: string[] = [
    'weight',
    'total_weight',
    'length',
    'width',
    'height',
    'volume',
    'total_volume',
    'price',
    'amount'
  ];

  constructor(protected http: HttpClient, protected authService: AuthenticationService) {
  }

  public getBaseUrl(): string {
    const user: any = this.authService.getUser();

    if (user) {
      return environment.API_URL + '/' + user.role.url;
    }

    return environment.API_URL;
  }

  public get(url: string, noCache?: boolean): Observable<any> {
    const headers = this.getHeaders();

    if (noCache) {
      headers.set('Cache-Control', 'no-cache');
      headers.set('Pragma', 'no-cache');
    }

    return this.http
      .get(`${this.getBaseUrl()}/${url}`, { headers: headers, responseType: 'json' }).pipe(
      map((result: any) => {
        return this.transformData(result);
      }),
      catchError(this.handleError));
  }

  public post(url: string, data: any): Observable<any> {
    let body = this.deepCopy(data);
    body = this.transformData(body, 'outgoing');
    body = JSON.stringify(body);

    return this.http
      .post(`${this.getBaseUrl()}/${url}`, body, {
        headers: this.getHeaders(),
        responseType: 'json'
      }).pipe(
      map((result: any) => {
        return this.transformData(result);
      }),
      catchError(this.handleError));
  }

  public put(url: string, data: any, appendId: boolean = true): Observable<any> {
    let body = this.deepCopy(data);
    body = this.transformData(body, 'outgoing');
    body = JSON.stringify(body);

    return this.http
      .put(`${this.getBaseUrl()}/${url}/${appendId ? data.id : ''}`, body, {
        headers: this.getHeaders(),
        responseType: 'json'
      }).pipe(
      map((result: any) => {
        return this.transformData(result);
      }),
      catchError(this.handleError));
  }

  public delete(url: string): Observable<HttpResponse<any>> {
    return this.http
      .delete(`${this.getBaseUrl()}/${url}`, { headers: this.getHeaders() }).pipe(
      map((result: any) => {
        return this.transformData(result);
      }),
      catchError(this.handleError));
  }

  public uploadFile(url: string, file: File, options?: any): Observable<HttpResponse<any>> {
    let data: FormData = new FormData();
    data.append('uploadFile', file, file.name);

    if (options) {
      let arrOptions = this.mapObjectToArray(options);

      for (let index = 0; index < arrOptions.length; index++) {
        data.append(arrOptions[index].key, arrOptions[index].label);
      }
    }

    return this.http
      .post(`${this.getBaseUrl()}/${url}`, data, {
        headers: this.getHeaders(false),
        responseType: 'json'
      }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError));
  }

  public getBlob(url: string, data: any = null): Observable<Blob> {
    if (!data) {
      return this.http
        .get(`${this.getBaseUrl()}/${url}`, {
          headers: this.getHeaders(),
          responseType: 'blob'
        }).pipe(
        map((response: any) => {
          return new Blob([response], {
            type: response.type
          });
        }),
        catchError(this.handleError));
    } else {
      const body = JSON.stringify(this.transformData(this.deepCopy(data), 'outgoing'));

      return this.http
        .post(`${this.getBaseUrl()}/${url}`, body, {
          headers: this.getHeaders(),
          responseType: 'blob'
        }).pipe(
        map((response: any) => {
          return new Blob([response], {
            type: response.type
          });
        }),
        catchError(this.handleError));
    }
  }

  /**
   * Print the given blob
   *
   * @param blob
   */
  public printBlob(blob: Blob): void {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement('iframe');

    iframe.style.display = 'none';
    iframe.src = blobUrl;

    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  public transformData(data: any, type: string = 'incoming') {
    let fromSeparator = '.';
    let toSeparator = ',';

    if (type === 'outgoing') {
      fromSeparator = ',';
      toSeparator = '.';
    }

    for (let field in data) {
      if (data[field] !== null && data[field] !== undefined) {
        if (data[field].data && data[field].data instanceof Array) {
          data[field].data = data[field].data.map(item => {
            return this.transformData(item, type);
          });
        } else if (data[field] instanceof Array) {
          data[field] = data[field].map(item => {
            return this.transformData(item, type);
          });
        } else if (data[field] instanceof Object) {
          data[field] = this.transformData(data[field], type);
        } else if (this.decimalFields.includes(field) && data[field].toString().includes(fromSeparator)) {
          if (type === 'incoming') {
            data[field] = data[field].toString().replace(fromSeparator, toSeparator);
          } else {
            data[field] = data[field].replace(fromSeparator, toSeparator);
          }
        }
      }
    }

    return data;
  }

  public handleError(error: any): Observable<any> {
    return observableThrowError(error);
  }

  private mapObjectToArray(obj): { key: any; label: string }[] {
    return Object.keys(obj).map(key => {
      return { key, label: obj[key] };
    });
  }

  private deepCopy(oldObj: any): any {
    let newObj = oldObj;

    if (oldObj && typeof oldObj === 'object') {
      newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};

      for (let i in oldObj) {
        if (oldObj.hasOwnProperty(i)) {
          newObj[i] = this.deepCopy(oldObj[i]);
        }
      }
    }

    return newObj;
  }

  private getHeaders(contentType: boolean = true): HttpHeaders {
    if (contentType) {
      return new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json'
      });
    }

    return new HttpHeaders({
      Accept: 'application/json'
    });
  }
}
