import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModalComponent } from '../modal/child.modal';
import { ConfirmationService } from 'primeng/primeng';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from '../../services/shared.service';
import { LoadingAgreement } from '../../models/loadingAgreement.model';
import { MessageService, MessageSummary } from '../../services/message.service';

@Component({
  selector: 'app-loading-agreement-table',
  templateUrl: './loading-agreement-table.component.html',
  styleUrls: ['./loading-agreement-table.component.scss']
})
export class LoadingAgreementTableComponent implements OnInit {
  @Input()
  public loadingAgreements: LoadingAgreement[];

  @ViewChild('loadingAgreementModal')
  public loadingAgreementModal: CommonModalComponent;

  @Output()
  public deleteLoadingAgreement = new EventEmitter<LoadingAgreement>();

  @Output()
  public saveLoadingAgreement = new EventEmitter<LoadingAgreement>();

  @Output()
  public updateLoadingAgreement = new EventEmitter<LoadingAgreement>();

  public isEdit: boolean;
  public editableLoadingAgreement: LoadingAgreement;
  public loadingAgreementForm: FormGroup;
  public weekDays = SharedService.getWeekDaysList();

  constructor(private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private formBuilder: FormBuilder) {
  }

  public ngOnInit(): void {
    this.buildForm();
  }

  public confirmDelete(loadingAgreement: LoadingAgreement): void {
    this.confirmationService.confirm({
      key: 'common',
      message: 'Weet u zeker dat u deze laadafspraak wilt verwijderen?',
      accept: () => {
        this.deleteLoadingAgreement.emit(loadingAgreement);
      },
      reject: () => {
      }
    });
  }

  public addLoadingAgreement(): void {
    this.isEdit = false;
    this.editableLoadingAgreement = null;
    this.loadingAgreementForm.reset();
    this.loadingAgreementModal.show();
  }

  public openEditModal(loadingAgreement: LoadingAgreement): void {
    this.editableLoadingAgreement = { ...loadingAgreement };
    this.isEdit = true;
    this.loadingAgreementForm.patchValue(this.editableLoadingAgreement);
    this.loadingAgreementModal.show();
  }

  public submit(): void {
    if (this.loadingAgreementForm.valid) {
      const loadingAgreement = this.loadingAgreementForm.value;
      this.loadingAgreementModal.hide();
      this.isEdit
        ? this.updateLoadingAgreement.emit(loadingAgreement)
        : this.saveLoadingAgreement.emit(loadingAgreement);
    } else {
      this.messageService.error(MessageSummary['SAVE_LOADING_AGREEMENT'], 'Vul alle verplichte velden in');
    }
  }

  public cancel(): void {
    this.loadingAgreementModal.hide();
  }

  private buildForm(): void {
    this.loadingAgreementForm = this.formBuilder.group({
      id: [null],
      day: [this.weekDays[0].value, Validators.required],
      time: ['', Validators.required]
    });
  }
}
