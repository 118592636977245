import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { SupplierService } from '../../../services/supplier.service';
import { SharedService } from '../../../services/shared.service';
import { Supplier } from '../../../models/supplier.model';
import { Address } from '../../../models/address.model';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { SelectItem } from 'primeng/primeng';

@Component({
  selector: 'supplier-add-component',
  templateUrl: './supplier-add.component.html',
  styleUrls: ['./supplier-add.component.scss']
})
export class SupplierAddComponent implements OnInit {

  @ViewChild('addSupplierModal') addSupplierModal: CommonModalComponent;
  @ViewChild('houseNo') houseNoField;
  @ViewChild('zipcode') zipcodeField;
  @ViewChild('nsForm') newSupplierForm;

  @Input() defaults;

  @Output() onSupplierSubmit: EventEmitter<void> = new EventEmitter<void>();

  validationErrors = [];

  public addressTypeList: SelectItem[];
  public newSupplier: Supplier = new Supplier();
  public zipCodeIsValid = false;
  public loadingZipcodeCheck = false;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected supplierService: SupplierService,
    private messageService: MessageService,
    public sharedService: SharedService) {
    this.newSupplier.addresses = [new Address()];
  }

  public ngOnInit(): void {
    this.sharedService.getEnumOptions('addresses', 'type').subscribe((addressTypes) => {
      this.addressTypeList = this.sharedService.mapObjectToSelectItems(addressTypes);
    });

    this.addSupplierModal.onHidden(() => {
      this.newSupplierForm.resetForm();
    });

    Observable.merge(this.houseNoField.valueChanges, this.zipcodeField.valueChanges)
      .debounceTime(400)
      .subscribe((houseNo) => {
        this.zipCodeIsValid = false;

        if (this.newSupplier.addresses[0].postcode && this.newSupplier.addresses[0].house_number) {
          this.findZipcode();
        }
      });
  }

  public findZipcode() {
    this.loadingZipcodeCheck = true;
    let output = this.supplierService.checkZipcode(this.newSupplier.addresses[0]);

    if (output['success']) {
      this.supplierService.getZipcode(output).subscribe((result) => {
          this.loadingZipcodeCheck = false;
          if (result) {
            this.zipCodeIsValid = true;
            this.newSupplier.addresses[0].street = result.street;
            this.newSupplier.addresses[0].location = result.city;
          } else {
            this.handleZipcodeError(true);
          }
        },
        (error: any) => this.handleZipcodeError(error));
    } else {
      this.loadingZipcodeCheck = false;
    }
  }

  public handleZipcodeError(error) {
    if (error) {
      this.loadingZipcodeCheck = false;
      this.zipCodeIsValid = false;
      this.newSupplier.addresses[0].street = '';
      this.newSupplier.addresses[0].location = '';
      this.messageService.warning(MessageSummary['ZIPCODE_CHECK'], 'Adres niet gevonden o.b.v. postcode en huisnummer');
    }
  }

  public showModal() {
    this.newSupplier = new Supplier();
    this.newSupplier.addresses = [new Address()];

    this.validationErrors = [];

    this.addSupplierModal.show();
  }

  public hideModal() {
    this.newSupplierForm.resetForm();
    this.addSupplierModal.hide();
  }

  public submitSupplier(form) {
    this.validationErrors = [];

    if (form.valid) {
      this.mapNewSupplier();

      this.supplierService.addSupplier(this.newSupplier).subscribe((result) => {
          this.parseResults(result);
          this.hideModal();

          this.onSupplierSubmit.emit();
        },
        (error: any) => this.handleError(error));
    } else {
      this.handleError(null, 'Controleer uw invoer.');

      if (form.controls.vat_number && form.controls.vat_number.valid === false) {
        this.validationErrors.push('Het BTW-nummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }

      if (form.controls.coc_number && form.controls.coc_number.valid === false) {
        this.validationErrors.push('Het KvK-nummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }
    }
  }

  private parseResults(result) {
    if (result) {
      this.newSupplier.id = result.id;
      this.messageService.success(MessageSummary['OK'], 'Leverancier opgeslagen');
      this.addSupplierModal.hide();
    } else {
      this.handleError(true);
    }
  }

  private handleError(error: any, message = 'Kan geen nieuwe leverancier aanmaken.') {
    this.validationErrors = [];

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);
            });
          }
        }
      }
    }

    this.messageService.error(MessageSummary['SOMETHING_WENT_WRONG'], message);
  }

  private mapNewSupplier() {
    if (this.newSupplier.addresses[0].country_id === '-1') {
      this.newSupplier.addresses[0].country_id = '';
    }
  }
}
