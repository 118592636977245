/* tslint:disable:variable-name */
import { Packing } from './packing.model';
import { Shipment } from './shipment.model';

/**
 * Model Collo
 */
export class Collo {
  id: number;
  packing_id: number;
  shipment_id: number;
  client_id?: number;
  supplier_id?: number;
  length: number;
  width: number;
  height: number;
  weight: number;
  payer: string;
  packing?: Packing;
  shipment?: Shipment;
  amount?: number;
}
