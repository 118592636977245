
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Hub } from '../models/hub.model';

@Injectable()
export class HubService extends ApiService {

  public getHubs(data: any, page: number = 1, perPage: number = 20): Observable<any> {
    return this.post(`hub/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return {
          data: response.data,
          pagination: response.meta.pagination,
          meta: response.meta
        };
      }),
      catchError(this.handleError));
  }

  public list(): Observable<any> {
    return this.get('hub/list').pipe(map((response: any) => response.hubs));
  }

  public getHub(id: number): Observable<any> {
    return this.get(`hub/${id}`);
  }

  public addHub(data: Hub): Observable<any> {
    return this.post('hub', data);
  }

  public updateHub(data: Hub): Observable<any> {
    return this.put(`hub`, data);
  }

  public deleteHub(id: number): Observable<any> {
    return this.delete(`hub/${id}`);
  }

  public exportBigMile(start: string, end: string, id: number): Observable<Blob> {
      return this.getBlob(`hub/export-bigmile`, {'start': start, 'end': end, 'id': id});
  }

  public exportSuez(start: string, end: string, id: number): Observable<Blob> {
    return this.getBlob(`hub/export-suez`, {'start': start, 'end': end, 'id': id});
  }
}
