import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class SellRatesService extends ApiService {

  public export(): Observable<Blob> {
    return this.getBlob(`sell-rates/export`);
  }

  public import(file: File): Observable<any> {
    return this.uploadFile('sell-rates/import', file);
  }
}
