
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class InvoicesService extends ApiService {
  public getInvoices(data: any, page = 1, perPage = 20, processed = false): any {
    return this.post(`invoices/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return response;
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError));
  }

  public getInvoice(id: number): Observable<any> {
    return this.get('invoices/' + id, true);
  }

  /**
   * Download the excel of the given invoice
   *
   * @param id
   *
   * @return Observable<any>
   */
  public downloadExcel(id: number): Observable<Blob> {
    return this.getBlob(`invoices/export/${id}`);
  }

  /**
   * Rollback the given invoice
   *
   * @param id
   *
   * @return Observable<any>
   */
  public rollback(id: number): Observable<any> {
    return this.post(`invoices/rollback/${id}`, {});
  }

  /**
   * Set the give invoice to status Invoiced
   *
   * @param id
   *
   * @return Observable<any>
   */
  public setStatusInvoiced(id: number): Observable<any> {
    return this.post(`invoices/invoiced/${id}`, {});
  }
}
