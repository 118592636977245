/* tslint:disable:variable-name */
import { PackingUnit } from './packing-unit.model';
import { Hub } from './hub.model';

/**
 * Model Article
 */
export class Article {
  public id?: number;
  public supplier_id: number;
  public number: string;
  public is_stockitem: number;
  public is_active: boolean;
  public packing_units: PackingUnit[];
  public name: string;
  public amount?: number;
  public weight?: any;
  public length?: number;
  public width?: number;
  public height?: number;
  public volume?: any;
  public num_backorder?: number;
  public supplier: any;
  public packing_unit?: PackingUnit;
  public packing_unit_name?: string;
  public packing_unit_id?: number;
  public hubs: { data: Hub[] };
  public hub_ids?: number[];
  public label?: string;
  public amountToReturn?: number;
}
