import { RouterModule, Routes } from '@angular/router';

import { ClientsComponent } from './clients.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientTypeListComponent } from '../manager/client-types/client-type-list/client-type-list.component';

const CLIENTS_ROUTES: Routes = [
  {
    path: '',
    component: ClientsComponent,
    children: [
      {
        path: '',
        component: ClientListComponent
      },
      {
        path: ':id',
        component: ClientDetailsComponent
      },
      {
        path: ':id/:tab',
        component: ClientDetailsComponent
      }
    ]
  }
];

export const CLIENTS_ROUTING = RouterModule.forChild(CLIENTS_ROUTES);
