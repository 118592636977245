
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable()
export class InvoiceRowsService extends ApiService {

  public getInvoiceRows(data: any, page: number = 1, perPage: number = 20): Observable<any> {
    return this.post(`invoice-rows/filter?page=${page}&perPage=${perPage}`, data).pipe(
      map((response: any) => {
        return response;
      }),
      map((result: any) => {
        return result;
      }),
      catchError(this.handleError));
  }
}
