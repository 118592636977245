import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';

import { SharedService } from '../../../services/shared.service';
import { OrderService } from '../../../services/order.service';
import { Order } from '../../../models/order.model';
import { Article } from '../../../models/article.model';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_MANAGER,
  ROLE_SUPPLIER
} from '../../../services/authentication.service';
import { BaseComponent } from '../../../base.component';
import { OrderStatus } from '../../../models/order-status.enum';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { UserRole } from '../../../models/user-role.enum';
import { WizardStep } from '../../../components/printing-procedure-wizard/printing-procedure-wizard.component';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { Observable } from 'rxjs';
import { Address } from '../../../models/address.model';
import { AddressService } from '../../../services/address.service';
import { ShipmentService } from '../../../services/shipment.service';
import { Shipment } from '../../../models/shipment.model';
import { PackingUnit } from '../../../models/packing-unit.model';
import { ArticleModalComponent } from '../../../components/article-modal/article-modal.component';
import { ReplaceDotToCommaPipe } from '../../../pipes/replace-dot-to-comma.pipe';
import { ShipmentStatus } from '../../../models/shipment-status.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent extends BaseComponent {
  @ViewChild('printingModal')
  public printingModal: CommonModalComponent;
  @ViewChild('addressModal')
  public addressModal: CommonModalComponent;
  @ViewChild('newArticleModal')
  public newArticleModal: ArticleModalComponent;
  @ViewChild('addShipmentModal')
  public addShipmentModal: CommonModalComponent;

  @Input()
  public order: Order = new Order;

  @Output()
  public onClose: EventEmitter<void> = new EventEmitter<void>();

  public validationErrors: any = [];
  public selectedPreferredDeliveryDate: Date = null;
  public calendarLocale: any = null;

  public addresses: SelectItem[];
  public contactpersons: SelectItem[];
  public hubs: SelectItem[];
  public statuses: SelectItem[];
  public pickSlipLoading: boolean = false;
  public orderLabelLoading: boolean = false;
  public isRoleManager: boolean = false;
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public readonly: boolean = false;
  public today = new Date();
  public tomorrow = new Date();
  public nextWorkingDay = new Date();
  public addedToShipment: boolean = false;
  public orderStatus = OrderStatus;
  public userRoles = UserRole;
  public wizardSteps: WizardStep[];
  public rollbackStatus: { value: OrderStatus, label: string };
  public loadAddresses: SelectItem[];
  public isEditable = false;
  public shipment: Shipment;
  public shipmentStatuses = ShipmentStatus;
  public showAddShipmentModal: boolean;
  public newArticle: Article;
  private newAddressType: string;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private activeRoute: ActivatedRoute,
    private sharedService: SharedService,
    private addressService: AddressService,
    private orderService: OrderService,
    private shipmentService: ShipmentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private replaceDotToCommaPipe: ReplaceDotToCommaPipe) {
    super(authService, [
      {
        'role': ROLE_CUSTOMER,
        'readonly': [
          'remarks'
        ]
      }
    ]);

    this.selectedPreferredDeliveryDate = null;
    this.calendarLocale = this.sharedService.getCalendarLocale();

    this.tomorrow.setDate(this.today.getDate() + 1);
    this.nextWorkingDay = this.tomorrow;

    const nextWorkingDay = moment(this.nextWorkingDay);
    if (nextWorkingDay.day() === 6) { // Saturday
      this.nextWorkingDay.setDate(this.nextWorkingDay.getDate() + 2);
    } else if (nextWorkingDay.day() === 0) { // Sunday
      this.nextWorkingDay.setDate(this.nextWorkingDay.getDate() + 1);
    }
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_MANAGER])) {
      this.isRoleManager = true;
      this.nextWorkingDay = null;
    }

    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = false;
    }

    if (this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR])) {
      this.isRoleHubOperator = true;
      this.readonly = false;
    }

    this.addShipmentModal.onHidden(() => {
      this.showAddShipmentModal = false;
    });

    this.activeRoute.params.subscribe(params => {
      const orderId = +params['id'];

      this.orderService.getOrder(orderId).subscribe((data: any) => this.processOrderRequest(data));
    });

    this.wizardSteps = [
      {
        stepTitle: 'Opdrachtlabel',
        buttonText: 'Opdrachtlabel printen',
        buttonIconClass: 'fas fa-print',
        callback: () => {
          return new Observable((observer) => {
            this.orderService.printOrderLabels([this.order.id]).subscribe(() => {
              observer.next();
              observer.complete();
            });
          });
        }
      },
      {
        stepTitle: 'Opdracht pakbon',
        buttonText: 'Pakbon printen',
        buttonIconClass: 'fas fa-print',
        callback: () => {

          return new Observable((observer) => {
            this.orderService.printOrderPackingSlips([this.order.id]).subscribe(() => {
              if (this.order.status === OrderStatus.CHECKED) {
                this.order.status = OrderStatus.PACKINGSLIP_PRINTED;
                this.rollbackStatus = this.getRollbackStatus(OrderStatus.PACKINGSLIP_PRINTED);
              }

              observer.next();

              this.printingModal.hide();
            });
          });
        }
      }
    ];

    this.printingModal.onShown(() => {
      $('.modal').scrollTop(0);
    });
  }

  public selectPreferredDeliveryDate(event): void {
    const momentDate = moment(event);
    this.order.preferred_delivery_date = momentDate.format('YYYY-MM-DD');
  }

  public addNewArticle(): void {
    this.newArticle = new Article();
    this.newArticle.is_stockitem = 2;
    this.newArticle.is_active = true;
    this.newArticle.packing_units = [];
    this.newArticle.supplier_id = this.order.supplier_id;
    this.newArticle.packing_units.push(new PackingUnit());

    this.newArticleModal.showModal();
  }

  public onSaveNewArticle(article: Article): void {
    // Add new article to order
    const orderArticle = { ...article };

    orderArticle.amount = 1;

    if (orderArticle.packing_units[0]) {
      orderArticle.packing_unit_id = orderArticle.packing_units[0].id;
      orderArticle.packing_unit_name = orderArticle.packing_units[0].name;
      orderArticle.packing_unit = orderArticle.packing_units[0];
      orderArticle.length = orderArticle.packing_units[0].length;
      orderArticle.width = orderArticle.packing_units[0].width;
      orderArticle.height = orderArticle.packing_units[0].height;
      orderArticle.weight = orderArticle.packing_units[0].weight;

      if (orderArticle.length && orderArticle.width && orderArticle.height) {
        let volume = (orderArticle.length * orderArticle.width * orderArticle.height) / 1000000;
        volume = Math.round(+volume * 100) / 100;
        volume = this.replaceDotToCommaPipe.transform(volume);

        orderArticle.volume = volume;
      }
    }

    let articles = this.order.articles;
    articles.push(orderArticle);

    this.updateArticles(articles);
  }

  public updateArticles(articles: Article[]): void {
    this.order.articles = [...articles];

    const articleVolumes = this.order.articles.filter(a => a.volume)
      .map((article) => ({ volume: parseFloat(article.volume.toString().replace(',', '.')), amount: article.amount }));

    this.order.total_volume = 0;

    for (let i = 0; i < articleVolumes.length; i++) {
      this.order.total_volume += (articleVolumes[i].volume * articleVolumes[i].amount);
    }

    this.order.total_volume = Math.round(this.order.total_volume * 100) / 100;
    this.order.total_volume = this.order.total_volume.toString().replace('.', ',');

    const articleWeights = this.order.articles.filter(a => a.weight)
      .map((article) => ({ weight: parseFloat(article.weight.toString().replace(',', '.')), amount: article.amount }));

    this.order.total_weight = 0;

    for (let i = 0; i < articleWeights.length; i++) {
      this.order.total_weight += (articleWeights[i].weight * articleWeights[i].amount);
    }

    this.order.total_weight = this.order.total_weight.toString().replace('.', ',');
  }

  public onSubmit(form): void {
    if (form.valid) {
      this.orderService.updateOrder(this.order).subscribe((result: any) => {
        if (result) {
          this.messageService.success(MessageSummary['OK'], 'Opdracht opgeslagen');

          this.onClose.emit();

          setTimeout(() => {
            window.close();
          }, 500);
        } else {
          this.handleError(false);
        }
      }, (error: any) => this.handleError(error));
    } else {
      this.handleError(null, 'Vul alle verplichte velden in (*)');
    }
  }

  public handleError(error: any, summary: string = 'Opdracht', message = 'Kan wijzigingen niet opslaan.'): void {
    this.validationErrors = [];

    if (error) {
      let result: any = null;

      if (error instanceof HttpErrorResponse){
        result = error.error;
      } else {
        result = error.json();
      }

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);
            });
          }
        }
      }
    }

    this.messageService.error(summary, message);
  }

  public downloadPickSlip(): void {
    this.pickSlipLoading = true;

    this.orderService.getOrderPackingSlips([this.order.id]).subscribe((blob) => {
      this.pickSlipLoading = false;
      FileSaver.saveAs(blob, `${this.order.id}-pakbon.pdf`);
    }, (error) => {
      this.pickSlipLoading = false;
    });
  }

  public downloadOrderLabel(): void {
    this.orderLabelLoading = true;

    this.orderService.getOrderLabels([this.order.id]).subscribe((data) => {
      this.orderLabelLoading = false;
      FileSaver.saveAs(data, `${this.order.id}-opdrachtlabel.pdf`);
    }, (error) => {
      this.orderLabelLoading = false;
    });
  }

  public isPostNL(): boolean {
    if (this.order.shipment && this.order.shipment.transporter) {
      return this.order.shipment.transporter_id === 1;
    }

    return false;
  }

  public confirmDeleteOrder(): void {
    this.confirmationService.confirm({
      key: 'order',
      message: 'Weet u zeker dat u deze opdracht wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteOrder();
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  /**
   * Display cancel confirmation dialog
   */
  public cancelOrderConfirm(): void {
    this.confirmationService.confirm({
      key: 'order',
      message: 'Weet u zeker dat u deze opdracht wilt annuleren?',
      accept: () => {
        this.cancelOrder();
      }
    });
  }

  public openPrintingModal(): void {
    this.printingModal.show();
  }

  /**
   * Close the printing modal
   */
  public closePrintingModal(): void {
    this.printingModal.hide();
  }

  /**
   * Set the status of the order to packingslip_printed
   */
  public packingSlipPrinted(): void {
    if (this.order.status === OrderStatus.CHECKED) {

    }

    this.order.status = OrderStatus.PACKINGSLIP_PRINTED;
    this.orderService.updateOrder(this.order).subscribe(() => {

    });
  }

  /**
   * Change the status of the order to the given status
   *
   * @param status
   */
  public changeStatus(status: OrderStatus): void {
    this.order.status = status;
    this.updateOrder(this.order);
  }

  public showConfirmRollback(status: { value: OrderStatus, label: string }): void {
    this.confirmationService.confirm({
      message: `Weet u zeker dat u de status terug wilt zetten naar: ${status.label}`,
      key: 'order',
      accept: () => {
        this.changeStatus(status.value);
      }
    });
  }

  /**
   * Check whether the current user is allowed to perform a status rollback action
   */
  public isAllowedRollback(): boolean {
    if (this.order.status === OrderStatus.CHECKED) {
      return true;
    }

    return this.authService.isRoleAuthorized([
      UserRole.ROLE_LOGISTIC_EMPLOYEE,
      UserRole.ROLE_HUB_OPERATOR,
      UserRole.ROLE_MANAGER
    ]);
  }

  /**
   * Open the address modal
   */
  public openAddressModal(): void {
    this.addressModal.show();
  }

  /**
   * Open the address modal if the 'nieuw adres' (id: -1) option is selected
   *
   * @param id
   */
  public onAddressChange(id: number): void {
    if (id === -1) {
      this.newAddressType = 'discharge';
      this.openAddressModal();
    }
  }

  /**
   * Open the address modal if the 'nieuw adres' (id: -1) option is selected
   *
   * @param id
   */
  public onLoadAddressChange(id: number): void {
    if (id === -1) {
      this.newAddressType = 'load';
      this.openAddressModal();
    }
  }

  /**
   * Store the newly added address and attach it to the order
   */
  public handleAddressSubmit(address: Address): void {
    if (address) {
      address.type = 'discharge'; // Type is 'laad-/losadres'

      this.addressService.create(address).subscribe((createdAddress: Address) => {
        if (this.newAddressType === 'load') {
          this.order.load_address_id = createdAddress.id;
          this.order.loadAddress = createdAddress;
          this.loadAddresses = this.getLoadAddressDropdownOptions(this.order);
        } else {
          this.order.address_id = createdAddress.id;
          this.order.address = createdAddress;

          this.addresses.push({
            label: createdAddress.full_address,
            value: createdAddress.id
          });
        }

        this.addressModal.hide();
      });
    }
  }

  /**
   * Close the address modal enad reset the selected dropdown
   */
  public handleCancelAddress(): void {
    this.addressModal.hide();

    this.order.load_address_id = this.loadAddresses[0].value;
  }

  public createShipment(): void {
    this.orderService.updateOrder(this.order).subscribe((order) => {
      this.addShipmentModal.show();
      this.showAddShipmentModal = true;
    }, error => {
      this.messageService.error(MessageSummary['SAVE_ORDER'], 'Er is iets fout gegaan');
    });
  }

  public onShipmentSuccess({ id }: Shipment): void {
    this.showAddShipmentModal = false;

    // Redirect to shipment detail page
    this.router.navigate([`pages/shipments/${id}`]);
  }

  public returnOrder(orderId: number): void {
    // Create a shipment...
    this.router.navigate([`/pages/orders/${orderId}/return`]);
  }

  /**
   * Process the get request of an order
   *
   * @param data
   */
  private processOrderRequest(data: any): void {
    this.order = data;
    this.order.articles = data.articles.data;

    if (data.meta) {
      this.contactpersons = this.sharedService.mapObjectToSelectItems(data.meta.contactpersons);
      this.hubs = this.sharedService.mapObjectToSelectItems(data.meta.hubs);
      this.statuses = this.sharedService.mapObjectToSelectItems(data.meta.statuses);
      this.addresses = this.getDischargeAddressDropdownOptions(data.meta.addresses);

      if (!this.order.shipment_id) {
        // Filter out shipment statuses if order is not attached to shipment
        this.statuses = this.statuses.filter((status) => {
          return !status.value.includes('shipment') || status.value === 'ready_for_shipment';
        });
      }
    }

    if (this.order.loadAddress) {
      const loadAddress = this.addresses.find((address) => {
        return address.label === this.order.loadAddress.full_address;
      });


      if (loadAddress) {
        this.order.load_address_id = loadAddress.value;
      }
    }

    const dischargeAddress = this.addresses.find((address) => {
      return address.label === this.order.address.full_address;
    });

    if (dischargeAddress) {
      this.order.address_id = dischargeAddress.value;
    }

    this.addedToShipment = data.shipment_id !== null;

    if (data.preferred_delivery_date) {
      const momentDate = moment(data.preferred_delivery_date);
      this.selectedPreferredDeliveryDate = momentDate.toDate();
      this.order.preferred_delivery_date = momentDate.format('YYYY-MM-DD');
    }

    this.loadAddresses = this.getLoadAddressDropdownOptions(this.order);
    this.rollbackStatus = this.getRollbackStatus(this.order.status);

    // Determine if order is editable
    if (this.readonly || (this.order.shipment) || this.order.status === OrderStatus.CANCELLED || this.isRoleSupplier) {
      this.isEditable = false;
    } else {
      this.isEditable = true;
    }
  }

  /**
   * Update an order
   *
   * @param order
   */
  private updateOrder(order: Order): void {
    this.orderService.updateOrder(order).subscribe((item) => {
      this.processOrderRequest(item);
      this.messageService.success(MessageSummary['OK'], 'Opdracht opgeslagen');
    }, error => {
      this.messageService.error(MessageSummary['SAVE_ORDER'], 'Er is iets fout gegaan');
    });
  }

  /**
   * Cancel an order
   */
  private cancelOrder(): void {
    this.order.status = OrderStatus.CANCELLED;
    this.order.contactperson_id = this.order.contactperson_id === 0 ? null : this.order.contactperson_id;
    this.order.hub_id = this.order.hub_id === 0 ? null : this.order.hub_id;

    this.orderService.updateOrder(this.order).subscribe((result: any) => {
      if (result) {
        this.messageService.success(MessageSummary['OK'], 'Opdracht geannuleerd');
      } else {
        this.handleError(false);
      }
    }, (error: any) => this.handleError(error));
  }

  private deleteOrder(): void {
    this.orderService.deleteOrder(this.order.id).subscribe((data) => {
      this.router.navigate(['/pages/orders']);
    }, (error: any) => {
      const result = error.json();
      const message = result.message;
      this.handleError(false, message);
    });
  }

  /**
   * Get the status to rollback to
   *
   * @param status
   *
   * @return {label: string, value: OrderStatus} | null
   */
  private getRollbackStatus(status: OrderStatus): { label: string, value: OrderStatus } | null {
    let rollbackStatus: { label: string, value: OrderStatus } = { label: null, value: null };

    switch (status) {
      case OrderStatus.CHECKED:
        rollbackStatus.value = OrderStatus.RECEIVED;
        break;
      case OrderStatus.READY_FOR_SHIPMENT:
        rollbackStatus.value = OrderStatus.RECEIVED;
        break;
      case OrderStatus.PACKINGSLIP_PRINTED:
      case OrderStatus.BACKORDER:
      case OrderStatus.CANCELLED:
        rollbackStatus.value = OrderStatus.CHECKED;
        break;
      default:
        rollbackStatus = null;
        break;
    }

    if (rollbackStatus) {
      const statusInList = this.statuses.find((shipmentStatus) => shipmentStatus.value === rollbackStatus.value);

      if (statusInList) {
        rollbackStatus = { value: rollbackStatus.value, label: statusInList.label };
      }
    }

    return rollbackStatus;
  }

  /**
   * Get the options for the load address dropdown
   *
   * @param order
   *
   * @return SelectItem[]
   */
  private getLoadAddressDropdownOptions(order: Order): SelectItem[] {
    // Get supplier address
    let supplierAddresses = this.order.supplier.addresses.data.filter((supplierAddress) => {
      return supplierAddress.hub_id === order.hub_id;
    });

    supplierAddresses = supplierAddresses.map((supplierAddress) => {
      return {
        label: `${supplierAddress.full_address} (leverancier)`,
        value: supplierAddress.id
      };
    });

    // Get client addresses
    const clientAddresses = this.addresses.map((clientAddress) => {
      clientAddress.label = `${clientAddress.label} (ontvanger)`;

      return clientAddress;
    });

    const loadAddresses = [...supplierAddresses, ...clientAddresses];

    // Add HUB address
    if (order.hub) {
      loadAddresses.push(
        {
          label: `${order.hub.address.full_address} (HUB)`,
          value: order.hub.address.id
        }
      );
    }

    return loadAddresses;
  }

  private getDischargeAddressDropdownOptions(clientAddresses: Address[]): SelectItem[] {
    // Get client addresses
    const addresses = this.sharedService.mapObjectToSelectItems(clientAddresses);

    // Add order address if not present
    const addressIds = addresses.map(a => a.value);
    if (!addressIds.includes(this.order.address_id)) {
      addresses.push({
        label: this.order.address.full_address,
        value: this.order.address.id
      });
    }

    // Add option to add new discharge address
    addresses.push({
      label: 'Adres toevoegen',
      value: -1
    });

    return addresses;
  }
}
