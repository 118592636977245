import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/primeng';

export interface WizardStep {
  stepTitle: string;
  buttonText: string;
  callback: (barcode?: string) => any;
  buttonIconClass: string;
  shipmentLabelModal?: boolean;
}

@Component({
  selector: 'app-printing-procedure-wizard',
  templateUrl: './printing-procedure-wizard.component.html',
  styleUrls: ['./printing-procedure-wizard.component.scss']
})
export class PrintingProcedureWizardComponent {
  get wizardSteps(): WizardStep[] {
    return this._wizardSteps;
  }

  @Input()
  set wizardSteps(wizardSteps: WizardStep[]) {
    if (wizardSteps) {
      this.stepItems = [];

      for (let step of wizardSteps) {
        this.stepItems.push({ label: step.stepTitle });
      }
    }

    this._wizardSteps = wizardSteps;
  }

  @Input()
  public useBarcode: boolean = true;

  @Output()
  public onCancel: EventEmitter<void>;

  @Output()
  public onPackingSlipPrinted: EventEmitter<void>;

  public activeStepIndex: number = 0;
  public stepItems: MenuItem[];
  public loading: boolean;

  private _wizardSteps: WizardStep[];

  constructor() {
    this.onCancel = new EventEmitter<void>();
    this.onPackingSlipPrinted = new EventEmitter<void>();
    this.wizardSteps = [];
  }

  /**
   * Execute the given cancel eventEmitter
   */
  public cancel(): void {
    this.onCancel.emit();
  }

  /**
   * Go to the next step
   *
   * @param doCallback
   */
  public nextStep(doCallback: boolean = true): void {
    this.loading = true;

    if (doCallback) {
      this.wizardSteps[this.activeStepIndex].callback().subscribe(() => {
        this.loading = false;

        if (this.activeStepIndex !== this.wizardSteps.length - 1) {
          this.activeStepIndex++;
        }
      });
    } else {
      // Ugly solution for now; assume that print popup shows within 5 seconds
      setTimeout(() => {
        this.loading = false;

        if (this.activeStepIndex !== this.wizardSteps.length - 1) {
          this.activeStepIndex++;
        }
      }, 5000);
    }
  }
}
