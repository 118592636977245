import { Component, Input } from '@angular/core';
import { Supplier } from '../../../../models/supplier.model';

@Component({
  selector: 'app-supplier-detail-additional-sell-rates',
  templateUrl: './supplier-detail-additional-sell-rates.html',
  styleUrls: ['./supplier-detail-additional-sell-rates.scss']
})
export class SupplierDetailAdditionalSellRatesComponent {
  @Input() public supplier: Supplier;

  constructor() {

  }
}
