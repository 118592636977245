import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Select the value of an input on focus
 */
@Directive({
  selector: '[selectOnFocus]'
})
export class SelectOnFocusDirective {
  constructor(private element: ElementRef) {
  }

  @HostListener('click') onClick() {
    this.element.nativeElement.select();
  }
}
