import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { SharedService } from '../../../services/shared.service';
import { SelectItem } from 'primeng/primeng';
import { CommonModalComponent } from '../../modal/child.modal';
import {
  AuthenticationService,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_TRANSPORTER
} from '../../../services/authentication.service';
import { AdditionalSellRateService } from '../../../services/additional-sell-rate.service';
import { SupplierService } from '../../../services/supplier.service';
import { AdditionalSellRateSupplier } from '../../../models/additionalSellRateSupplier.model';
import { MessageService, MessageSummary } from '../../../services/message.service';
import * as moment from 'moment';
import { HubService } from '../../../services/hub.service';
import { ClientsService } from '../../../services/clients.service';
import { isUndefined } from 'util';
import { CurrencyFormat } from '../../../pipes/currency-format.pipe';
import { NgForm } from '@angular/forms';
import { SellRate } from '../../../models/sell-rate.model';

@Component({
  selector: 'app-additional-sell-rate-modal',
  templateUrl: './additional-sell-rate-modal.component.html',
  styleUrls: ['./additional-sell-rate-modal.component.scss']
})
export class AdditionalSellRateModalComponent implements OnInit, OnChanges {
  @ViewChild('additionalSellRateModal')
  public additionalSellRateModal: CommonModalComponent;

  @ViewChild('additionalSellRateForm')
  public form: NgForm;

  @Input()
  public supplierId: number;

  @Input()
  public clientId: number;

  @Input()
  public addressList: SelectItem[];

  @Input()
  public contactpersonList: SelectItem[];

  @Input()
  public readonly: boolean;

  get additionalSellRateSupplier(): AdditionalSellRateSupplier {
    return this._additionalSellRateSupplier;
  }

  @Input()
  set additionalSellRateSupplier(value: AdditionalSellRateSupplier) {
    if (value.type) {
      this.selectedType = value.type;

      this.getServices();
    } else {
      this.selectedType = null;
    }

    if (value.name) {
      this.selectedService = value.name;
    } else {
      this.selectedService = null;
    }

    if (value.unit) {
      this.selectedUnit = value.unit;

      this.getUnits();
    } else {
      this.selectedUnit = null;
    }

    if (value.sell_rate_id) {
      this.additionalSellRateSupplier.sell_rate_id = value.sell_rate_id;
    }

    if (value.date) {
      this.additionalSellRateDate = value.date as Date;
    } else {
      this.additionalSellRateDate = null;
    }

    if (value.end_date) {
      this.additionalSellRateEndDate = value.end_date as Date;
    } else {
      this.additionalSellRateEndDate = null;
    }

    if (value.price) {
      this.price = value.price;
    }

    if (value.is_periodic && value.exported_at) {
      this.isPeriodic = true;
    }

    this._additionalSellRateSupplier = value;
  }

  @Output()
  public onSave: EventEmitter<AdditionalSellRateSupplier> = new EventEmitter<AdditionalSellRateSupplier>();

  public sellRates: SellRate[];
  public typeList: SelectItem[];
  public serviceList: any[];
  public hubList: SelectItem[];
  public unitList: any[];
  public frequencyList: SelectItem[];
  public filteredServices: SelectItem[];
  public filteredUnits: any[];
  public additionalSellRateDate?: Date;
  public additionalSellRateEndDate?: Date;
  public selectedType: string;
  public selectedService: string;
  public selectedUnit: string;
  public price: number;
  public validationErrors = [];
  public calendarLocale: any;
  public isRoleTransporter: boolean;
  public isRoleLogisticEmployee: boolean;
  public isRoleHubOperator: boolean;
  public isPeriodic: boolean = false;

  private _additionalSellRateSupplier: AdditionalSellRateSupplier = new AdditionalSellRateSupplier();

  constructor(
    private sharedService: SharedService,
    private supplierService: SupplierService,
    private clientService: ClientsService,
    private authService: AuthenticationService,
    private additionalSellRateService: AdditionalSellRateService,
    private hubService: HubService,
    private messageService: MessageService,
    private currencyFormat: CurrencyFormat) {
    this.additionalSellRateSupplier = new AdditionalSellRateSupplier();

    this.calendarLocale = this.sharedService.getCalendarLocale();

    this.isRoleTransporter = this.authService.isRoleAuthorized([ROLE_TRANSPORTER]);
    this.isRoleLogisticEmployee = this.authService.isRoleAuthorized([ROLE_LOGISTIC_EMPLOYEE]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);
  }

  public ngOnInit(): void {
    this.getFormData();

    this.additionalSellRateModal.onHidden(() => {
      this.cancelAdditionalSellRate();
    });
  }

  public ngOnChanges() {
    if (this.additionalSellRateSupplier && this.additionalSellRateSupplier.name) {
      this.selectedService = this.additionalSellRateSupplier.name;
    }
  }

  public showModal(): void {
    this.additionalSellRateModal.show();
  }

  public getFormData(): void {
    // Get types
    this.typeList = this.additionalSellRateService.getAdditionalSellRateTypeList();

    // Auto select type when there's only one
    if (this.typeList.length === 1) {
      this.selectedType = this.typeList[0].value;
    }

    // Get HUBs
    this.hubService.list().subscribe((response) => {
      this.hubList = response;
    });

    // Get frequencies
    this.frequencyList = this.additionalSellRateService.getAdditionalSellRateFrequencyList();

    // Get sell rates
    this.additionalSellRateService.getAll().subscribe((response) => {
      this.sellRates = response.sell_rates;

      const uniqueServices = [];

      this.serviceList = response.sell_rates.filter((item) => {
        if (uniqueServices.includes(item.name)) {
          return false;
        }

        uniqueServices.push(item.name);

        return true;
      }).map((item) => {
        return {
          value: item.name,
          label: item.name,
          type: item.type,
          service: item.name,
          name: item.name,
          description: item.description
        };
      });

      this.getServices();

      this.unitList = response.sell_rates.map((item) => {
        return {
          id: item.id,
          type: item.type,
          sell_rate_id: item.id,
          sell_rate_service: item.name,
          sell_rate_name: item.name,
          sell_rate_unit: item.unit,
          price: item.price,
          value: item.unit,
          label: item.unit
        };
      });

      this.getUnits();
    });
  }

  public onPropertyChange(property: string): void {
    switch (property) {
      case 'type':
        this.getServices();

        break;
      case 'service':
        this.getUnits();

        break;
      case 'hub':
        break;
      case 'unit':
        // this.onUnitChange($event.value);
        break;
      default:
        break;
    }

    this.findSellRate();

    this.resetFields(property);
  }

  public getServices(): void {
    this.selectedService = null;

    if (this.serviceList) {
      this.filteredServices = this.serviceList.filter((sellRate) => {
        return sellRate.type === this.selectedType;
      });
    } else {
      this.filteredServices = [];
    }

    if (this.additionalSellRateSupplier && this.additionalSellRateSupplier.name) {
      this.selectedService = this.additionalSellRateSupplier.name;
    }
  }

  public findSellRate(): void {
    this.additionalSellRateSupplier.sell_rate_id = null;

    let filteredSellRates = this.sellRates.filter((sellRate) => {
      let result = sellRate.type === this.selectedType &&
        sellRate.name === this.selectedService &&
        sellRate.unit === this.selectedUnit;

      if (result && this.additionalSellRateSupplier.hub_id) {
        // Find all sell rates with selected HUB or no specific HUB
        result = sellRate.hub_id === this.additionalSellRateSupplier.hub_id || sellRate.hub_id === null;
      }

      if (result && this.additionalSellRateSupplier.supplier_id) {
        // Find all sell rates with current supplier or no specific supplier
        result = (
          sellRate.supplier_id === this.additionalSellRateSupplier.supplier_id ||
          sellRate.supplier_id === null
        ) && sellRate.client_id === null;
      } else if (result && this.additionalSellRateSupplier.client_id) {
        // Find all sell rates with current client or no specific client
        result = (sellRate.client_id === this.additionalSellRateSupplier.client_id || sellRate.client_id === null) &&
          sellRate.supplier_id === null;
      }

      return result;
    });

    // Sort filtered sell rates to determine most accurate price
    // (first client or supplier specific, otherwise HUB specific)
    filteredSellRates = filteredSellRates.sort((a, b) => {
      if (this.additionalSellRateSupplier.client_id) {
        if (a.client_id) {
          return -1;
        }
      } else if (this.additionalSellRateSupplier.supplier_id) {
        if (a.supplier_id) {
          return -1;
        }
      } else if (a.hub_id) {
        return 0;
      }

      return 1;
    });

    // Set sell rate and price
    if (filteredSellRates.length) {
      this.additionalSellRateSupplier.sell_rate_id = filteredSellRates[0].id;
      this.price = filteredSellRates[0].price;
    }
  }

  public getUnits(): void {
    if (this.unitList) {
      const uniqueUnits = [];

      this.filteredUnits = this.unitList.filter((unit) => {
        if (uniqueUnits.includes(unit.sell_rate_unit)) {
          return false;
        }

        if (unit.sell_rate_service === this.selectedService) {
          uniqueUnits.push(unit.sell_rate_unit);

          return true;
        }

        return false;
      });
    } else {
      this.filteredUnits = [];
    }
  }

  public submitAdditionalSellRate(): void {
    if (this.form.valid || this.isPeriodic) {
      const isNew = this.additionalSellRateSupplier.id === null || isUndefined(this.additionalSellRateSupplier.id);
      let saveMethod = null;

      if (this.additionalSellRateDate) {
        let momentDate = moment(this.additionalSellRateDate);
        this.additionalSellRateSupplier['date'] = momentDate.format('YYYY-MM-DD');
      }

      if (this.additionalSellRateEndDate) {
        let momentDate = moment(this.additionalSellRateEndDate);
        this.additionalSellRateSupplier['end_date'] = momentDate.format('YYYY-MM-DD');
      }

      let service = null;
      let id = null;

      if (this.additionalSellRateSupplier.supplier_id) {
        service = this.supplierService;
        id = this.additionalSellRateSupplier.supplier_id;
      } else {
        service = this.clientService;
        id = this.additionalSellRateSupplier.client_id;
      }

      if (isNew) {
        saveMethod = service.addAdditionalSellRate(id, this.additionalSellRateSupplier);
      } else {
        saveMethod = service.updateAdditionalSellRate(id, this.additionalSellRateSupplier);
      }

      saveMethod.subscribe((response) => {
        this.additionalSellRateSupplier = new AdditionalSellRateSupplier();
        this.additionalSellRateDate = null;
        this.additionalSellRateEndDate = null;
        this.onSave.emit(response);
        this.form.resetForm();
        this.additionalSellRateModal.hide();
      });
    } else {
      this.messageService.error(MessageSummary['SAVE_EXTRA_SERVICE'], 'Vul alle verplichte velden in (*)');
    }
  }

  public cancelAdditionalSellRate(): void {
    this.additionalSellRateSupplier = new AdditionalSellRateSupplier();
    this.additionalSellRateDate = null;
    this.additionalSellRateEndDate = null;
    this.form.resetForm();
    this.price = null;
    this.additionalSellRateModal.hide();
  }

  private resetFields(changedProperty: string): void {
    switch (changedProperty) {
      case 'type':
        this.price = null;
        this.selectedUnit = null;
        this.selectedService = null;
        break;
      case 'service':
        this.price = null;
        this.selectedUnit = null;
        break;
      default:
        break;
    }
  }
}
