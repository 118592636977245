import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class SearchService extends ApiService {

  public search(term: string): Observable<any> {
    return this.post('search', { keyword: term });
  }
}
