import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { DataTable, Message, SelectItem } from 'primeng/primeng';
import { MessageService } from 'primeng/components/common/messageservice';
import * as moment from 'moment';
import { environment } from '../../environments/environment';

@Injectable()
export class SharedService {
  public zipCodeRegex = new RegExp('^[1-9][0-9]{3}(?!SA|SD|SS)[A-Z]{2}$');
  public ibanRegex = new RegExp('^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{0,14}$');
  public cocRegex = new RegExp('^[0-9]{8}$');
  public vatRegex = new RegExp('(NL)?[0-9]{9}B[0-9]{2}');
  // tslint:disable-next-line:max-line-length
  public emailRegex: RegExp = new RegExp('[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?');
  public phoneRegEx: RegExp = new RegExp('(^\\+[0-9]{2}\\s?|^\\+[0-9]{2}\\s?\\(0\\)|^\\(\\+[0-9]{2}\\s?\\)\\(0\\)|^00[0-9]{2}\\s?|^0)([0-9]{9}$|[0-9\\-\\s]{10}$)');

  public valueChanged = {
    zipcode: '',
    house_number: '',
    house_number_suffix: ''
  };

  public changed: boolean = false;

  /**
   * Check if a Popup Blocker is active
   *
   * @function check:
   * @param {any} popupWindow   Pointer to popup window
   *
   * @function isPopupBlocked:
   * @param {any} scope         Pointer to this
   * @param {any} popupWindow   Pointer to popup window
   *
   * @function displayError
   */
  public popupBlockerChecker = {
    check: function (popupWindow) {
      let self = this;
      if (popupWindow) {
        if (/chrome/.test(navigator.userAgent.toLowerCase())) {
          setTimeout(function () {
            self.isPopupBlocked(self, popupWindow);
          }, 200);
        } else {
          popupWindow.onload = function () {
            self.isPopupBlocked(self, popupWindow);
          };
        }
      } else {
        self.displayError();
      }
    },
    isPopupBlocked: function (scope, popupWindow) {
      if ((popupWindow.innerHeight > 0) === false) {
        scope.displayError();
      }
    },
    displayError: function () {
      alert(
        'Popup Blocker is actief!\n\nVoeg deze website toe aan de uitzonderingenlijst of deactiveer de Popup Blocker.'
      );
    }
  };

  public static getWeekDaysList(): SelectItem[] {
    return [
      {
        label: 'Maandag',
        value: 'monday'
      },
      {
        label: 'Dinsdag',
        value: 'tuesday'
      },
      {
        label: 'Woensdag',
        value: 'wednesday'
      },
      {
        label: 'Donderdag',
        value: 'thursday'
      },
      {
        label: 'Vrijdag',
        value: 'friday'
      },
      {
        label: 'Zaterdag',
        value: 'saturday'
      },
      {
        label: 'Zondag',
        value: 'sunday'
      }
    ];
  }

  constructor(private apiService: ApiService,
              private messageService: MessageService) {
  }

  public createDropdownOptions(object: {}, placeholder: string = null): { label: string, value: any }[] {
    let dropdownOptions = [];

    if (placeholder) {
      dropdownOptions.push({ label: placeholder, value: '' });
    }

    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        dropdownOptions.push({ label: object[key], value: key });
      }
    }

    return dropdownOptions;
  }

  public mapObjectToArray(obj): { key: any, label: string }[] {
    return Object.keys(obj).map((key) => {
      return {
        key,
        label: obj[key]
      };
    }).sort((a, b) => {
      return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
    });
  }

  public mapObjectToSelectItems(
    object: any,
    placeholder: string = null,
    sort: boolean = false
  ): { value: any, label: string }[] {
    let dropdownOptions = [];

    if (placeholder) {
      dropdownOptions.push({ label: placeholder, value: '' });
    }

    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        dropdownOptions.push({ label: object[key], value: key });
      }
    }

    if (sort) {
      dropdownOptions = dropdownOptions.sort((a, b) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
      });
    }

    return dropdownOptions;
  }

  public deepCopy(oldObj: any): any {
    let newObj = oldObj;

    if (oldObj && typeof oldObj === 'object') {
      newObj = Object.prototype.toString.call(oldObj) === '[object Array]' ? [] : {};

      for (let i in oldObj) {
        if (oldObj.hasOwnProperty(i)) {
          newObj[i] = this.deepCopy(oldObj[i]);
        }
      }
    }

    return newObj;
  }

  public handleHash(route: any, modals: any): void {
    route.fragment.subscribe((fragment: string) => {
      if (modals[fragment]) {
        if (typeof modals[fragment][0] === 'function') {
          modals[fragment][0](modals[fragment][1]);
          // tslint:disable-next-line:no-unused-expression
          modals[fragment][1];
        }
      }
    });
  }

  /**
   * Open Popup Window (in a new tab depending on browser)
   *
   * @param {string} features
   *
   * @returns {Window}    Pointer to popup window
   */
  public openPopupWindow(features: string = 'width=800,height=600,menubar=0,toolbar=0'): Window {
    let popupWindow = window.open('', '_blank', features);

    popupWindow.document.write('loading ...');

    return popupWindow;
  }

  /**
   * Replace the URL of the Popup Window
   *
   * @param {any} popupWindow   Pointer to popup window
   * @param {string} url        URL to load
   */
  public popupWindowSetContent(popupWindow = null, url: string = null): void {
    this.popupBlockerChecker.check(popupWindow);

    if (popupWindow && url) {
      popupWindow.location.href = url;
    }
  }

  public isEmpty(str: any): boolean {
    return (!str || 0 === str.length);
  }

  public getCalendarLocale(): any {
    return {
      firstDayOfWeek: 1,
      dayNames: [
        'zondag',
        'maandag',
        'dinsdag',
        'woensdag',
        'donderdag',
        'vrijdag',
        'zaterdag'
      ],
      dayNamesShort: [
        'zon',
        'maa',
        'din',
        'woe',
        'don',
        'vrij',
        'zat'
      ],
      dayNamesMin: [
        'zo',
        'ma',
        'di',
        'wo',
        'do',
        'vr',
        'za'
      ],
      monthNames: [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december'
      ],
      monthNamesShort: [
        'jan',
        'feb',
        'maa',
        'apr',
        'mei',
        'jun',
        'jul',
        'aug',
        'sep',
        'okt',
        'nov',
        'dec'
      ],
      today: 'Vandaag',
      clear: 'Leeg'
    };
  }

  public getZipcode(client: any): Observable<any> {
    if (client.houseNumberAddition) {
      return this.apiService.get(
        `clients/address/${client.postcode.toUpperCase()}/${client.house_number}/${client.houseNumberAddition}`
      );
    }

    return this.apiService.get(`clients/address/${client.postcode.toUpperCase()}/${client.house_number}`);
  }

  public checkZipcode(address: any): boolean | {} {
    let result = {};
    let countryId = address.country_id;

    // If country_id == NL
    if (+countryId === 154) {
      let valid = true;
      let houseNumber = address.house_number;
      let houseNumberAddition = address.house_number_suffix;
      let zipcode = address.postcode;
      let error = {};

      if (typeof (zipcode) === 'undefined' || zipcode === '') {
        valid = false;
        error['postcode'] = 'Postcode is leeg';
      } else {
        zipcode = zipcode.toUpperCase();
      }

      if (typeof (houseNumber) === 'undefined' || houseNumber === '') {
        valid = false;
        error['house_number'] = 'huisnummer is leeg';
      }

      if (!this.zipCodeRegex.test(zipcode)) {
        valid = false;
        if (!error['postcode']) {
          error['postcode'] = 'postcode is niet correct ingevuld (1234AB)';
        }
      }

      if (isNaN(houseNumber)) {
        valid = false;
        if (!error['house_number']) {
          error['house_number'] = 'huisnummer is niet geldig';
        }
      }

      if (valid) {
        this.changed = false;

        if (this.valueChanged.zipcode === '' || this.valueChanged.zipcode !== zipcode) {
          this.valueChanged.zipcode = zipcode;
          this.changed = true;
        }

        if (this.valueChanged.house_number === '' || this.valueChanged.house_number !== houseNumber) {
          this.valueChanged.house_number = houseNumber;
          this.changed = true;
        }

        if (this.valueChanged.house_number_suffix !== houseNumberAddition) {
          this.valueChanged.house_number_suffix = houseNumberAddition;
          this.changed = true;
        }

        if (this.changed === true) {
          result['postcode'] = zipcode;
          result['house_number'] = houseNumber;
          result['houseNumberAddition'] = houseNumberAddition;
          result['success'] = true;
        } else {
          result['success'] = false;
        }

      } else {
        result['success'] = false;
      }
    } else {
      result['success'] = false;
    }

    return result;
  }

  public getEnumOptions(table: string, field: string): Observable<any> {
    return this.apiService.get(`enum/${table}/${field}`);
  }

  /**
   * Display growl error message based on json error response
   *
   * @param errorResponse
   * @param {string} title
   * @param {string} detail
   *
   * @returns {void}
   */
  public handleError(errorResponse, title: string, detail: string = 'Item'): void {
    const messages: Message[] = [];
    errorResponse = errorResponse.json();

    let errorMessages = '';
    let status = errorResponse.status;

    if (!errorResponse.status) {
      if (errorResponse.status_code) {
        status = errorResponse.status_code;
      } else if (errorResponse.type === 3) {
        status = 404;
      }
    }

    switch (status) {
      case 404:
        errorMessages = detail + ' is niet gevonden.';
        break;
      case 500:
        errorMessages = 'Interne fout, neem contact op met de beheerder.';
        break;
      case 422:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (errorResponse.errors.hasOwnProperty(error)) {
              errorMessages += errorResponse.errors[error] + '<br />';
            }
          }
        } else if (errorResponse.message) {
          errorMessages += errorResponse.message;
        } else {
          errorMessages = 'Niet alle velden zijn juist ingevuld.';
        }

        break;
      default:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (errorResponse.errors.hasOwnProperty(error)) {
              errorMessages += errorResponse.errors[error] + '<br />';
            }
          }
        }

        if (errorResponse.message) {
          errorMessages += errorResponse.message;
        }

        break;
    }

    if (errorMessages !== '') {
      messages.push({ id: 'global', severity: 'error', summary: title, detail: errorMessages });

      this.messageService.addAll(messages);
    }
  }

  /**
   * Display growl error message
   *
   * @param {string} title
   * @param {string} detail
   *
   * @returns {void}
   */
  public displayErrorMessage(title: string, detail: string): void {
    this.messageService.clear();
    this.messageService.add({ id: 'global', severity: 'error', summary: title, detail: detail });
  }

  /**
   * Display growl warning message
   *
   * @param {string} title
   * @param {string} detail
   *
   * @returns {void}
   */
  public displayWarningMessage(title: string, detail: string): void {
    this.messageService.clear();
    this.messageService.add({ id: 'global', severity: 'warn', summary: title, detail: detail });
  }

  /**
   * Display growl success message
   *
   * @param {string} title
   * @param {string} detail
   *
   * @returns {void}
   */
  public displaySuccessMessage(title: string, detail: string): void {
    this.messageService.clear();
    this.messageService.add({ id: 'global', severity: 'success', summary: title, detail: detail });
  }

  /**
   * Prepare and filter on date
   *
   * @param dataTable
   * @param {string} field
   * @param filters
   * @param value
   *
   * @returns {void}
   */
  public filterDate(dataTable: DataTable, field: string, filters: any, value: string): void {
    if (!dataTable || !filters) {
      return;
    }

    filters[field] = value;

    // Clear filter called
    if (filters[field] === null) {
      // Purposely give a fake filter value as TurboTable filter has no unset function
      dataTable.filter('', field, 'notEquals');

      return;
    }

    // Filter on range even if no range was supplied
    const rangeStart = moment(filters[field][0]).format('DD/MM/YYYY');
    let rangeEnd = rangeStart;

    if (filters[field][1]) {
      rangeEnd = moment(filters[field][1]).format('DD/MM/YYYY');
    }

    const filterValue = `${rangeStart}-${rangeEnd}`;

    dataTable.filter(filterValue, field, 'between');
  }

  public getRowsPerPage(defaultAmount: number = 30): number {
    if (localStorage.getItem('paginationAmount')) {
      return +localStorage.getItem('paginationAmount');
    }

    return defaultAmount;
  }

  public setRowsPerPage(numRows: number): void {
    localStorage.setItem('paginationAmount', String(numRows));
  }

  public openNewWindow(route: string): void {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupWidth = screenWidth * 0.8;
    const popupHeight = screenHeight * 0.8;
    const offsetLeft = screenWidth * 0.1;
    const offsetTop = screenHeight * 0.1;

    const newWindow = window.open(
      `${environment.BASE_URL}/#/${route}`,
      '_blank',
      `location=0,menubar=0,status=0,titlebar=0,toolbar=0,width=${popupWidth},height=${popupHeight}`
    );
    newWindow.moveTo(offsetLeft, offsetTop);
  }

  public formatZipCode(value: string): string {
    return value ? value.toUpperCase().replace(' ', '') : value;
  }
}
