import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ArticleService } from '../../../services/article.service';

import { Article } from '../../../models/article.model';
import { PackingUnit } from '../../../models/packing-unit.model';

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss']
})
export class ArticleDetailComponent implements OnInit {

  public article: Article;
  public hubs: string[];
  public records: PackingUnit[];
  public loading: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              protected articleService: ArticleService) {
    this.article = new Article();
    this.hubs = [];
    this.loading = true;
  }

  public ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      const id = params['id'];

      this.articleService.getArticle(id).subscribe((response) => {
        this.article = response;

        this.hubs = response.hubs.data.map((hub) => {
          return hub.name;
        });

        this.records = response.packing_units.data;
        this.loading = false;
      }, () => {
        this.router.navigate(['/pages/articles']);
      });
    });
  }

  public translateStockingItem(stocking): string {
    switch (stocking) {
      case 1:
        return 'Ja';
      case 2:
        return 'Nee';
      case 3:
        return 'Ja en Nee';
      default:
        return;
    }
  }
}
