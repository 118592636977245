
import {map, filter, takeUntil} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InvoicesService } from '../../services/invoices.service';
import { CommonModalComponent } from '../modal/child.modal';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Supplier } from '../../models/supplier.model';
import { FilterMetadata, LazyLoadEvent, SelectItem } from 'primeng/primeng';
import { SharedService } from '../../services/shared.service';
import { Invoice } from '../../models/invoice.model';
import { MessageService, MessageSummary } from '../../services/message.service';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { HubService } from '../../services/hub.service';

@Component({
  selector: 'app-exported-invoices',
  templateUrl: './exported-invoices.component.html',
  styleUrls: ['./exported-invoices.component.scss']
})
export class ExportedInvoicesComponent implements OnInit, OnDestroy {
  @ViewChild('detailModal')
  public detailModal: CommonModalComponent;
  @ViewChild('exportModal')
  public exportModal: CommonModalComponent;

  @Input()
  public supplier: Supplier;
  @Input()
  public clientId: number;
  @Input('showExportButton')
  public showExportButton: boolean;

  public loading: boolean;
  public exportLoading: boolean;
  public records: any[];
  public paginationAmount: number = 30;
  public totalRecords: number;
  public selectedInvoiceId: number;
  public invoiceStatuses$: Observable<SelectItem[]>;
  public filters: { [key: string]: FilterMetadata };
  public clientList: SelectItem[] = [];
  public supplierList: SelectItem[] = [];
  public tableVisible: boolean;
  public isClientOrSupplier: boolean;
  public filterStartDate: string;
  public filterEndDate: string;
  public filterCreatedAt: string;
  public myHubs: SelectItem[] = [];
  public selectedHubId: number = null;
  private destroy$: Subject<boolean>;

  constructor(private invoicesService: InvoicesService,
              private route: ActivatedRoute,
              private sharedService: SharedService,
              private messageService: MessageService,
              private hubService: HubService) {
    this.tableVisible = false;
    this.destroy$ = new Subject<boolean>();
  }

  public ngOnInit(): void {
    this.route.queryParams.pipe(
      takeUntil(this.destroy$),
      filter((params) => params['tab'] === 'exported-invoices' && params['id']))
      .subscribe((params) => {
        this.selectedInvoiceId = params['id'];
        this.loadData({ filters: this.filters });
      });

    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.hubService.list().subscribe((hubs) => {
      if (hubs && hubs.length > 0) {
        this.myHubs = hubs;
        this.selectedHubId = hubs[0].value;
      }
      this.loadData({ filters: this.filters });
      this.tableVisible = true;
    });

    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.invoiceStatuses$ = this.sharedService.getEnumOptions('invoices', 'status').pipe(map((enums) => {
      const enumSelectItems: SelectItem[] = [];

      for (const key in enums) {
        if (enums.hasOwnProperty(key)) {
          enumSelectItems.push({ label: enums[key], value: key });
        }
      }

      enumSelectItems.unshift({ label: 'Alle', value: null });

      return enumSelectItems;
    }));

    this.filters = {};

    if (this.clientId) {
      this.filters = { client_id: { value: this.clientId } };
    } else if (this.supplier && this.supplier.id) {
      this.filters = { supplier_id: { value: this.supplier.id } };
    }

    this.isClientOrSupplier = this.clientId !== undefined || this.supplier !== undefined;
  }

  public changeHub(hubId: number): void {
    this.selectedHubId = hubId;
    this.tableVisible = false;

    setTimeout(_ => {
      this.tableVisible = true;
    }, 0);
  }

  /**
   * Retrieve the invoices
   *
   * @param event
   */
  public loadData(event: LazyLoadEvent = { filters: {} }): void {
    this.loading = true;

    if (this.isClientOrSupplier) {
      if (this.filters.client_id) {
        event.filters = Object.assign(event.filters, { client_id: this.filters.client_id });
      }

      if (this.filters.supplier_id) {
        event.filters = Object.assign(event.filters, { supplier_id: this.filters.supplier_id });
      }
    }

    if (this.filters && this.filters.start_date) {
      event.filters = Object.assign(event.filters, { start_date: this.filters.start_date });
    }

    if (this.filters && this.filters.end_date) {
      event.filters = Object.assign(event.filters, { end_date: this.filters.end_date });
    }

    if (this.filters && this.filters.created_at) {
      event.filters = Object.assign(event.filters, { created_at: this.filters.created_at });
    }

    if (this.selectedHubId) {
      event.filters = Object.assign(event.filters, { hub_id: this.selectedHubId });
    }

    if (event.filters && event.filters.amount) {
      const amountFilter = { ...event.filters.amount };
      amountFilter.value = amountFilter.value.replace(',', '.');

      event.filters = Object.assign(event.filters, { amount: amountFilter });
    }

    if (event.filters) {
      this.filters = event.filters;
    }

    this.invoicesService.getInvoices(event, event.first / event.rows + 1, event.rows).subscribe((response) => {
      this.records = response.data;
      this.totalRecords = response.meta.pagination.total;

      this.clientList = this.sharedService.mapObjectToSelectItems(response.meta.clients, 'Alle');
      this.supplierList = this.sharedService.mapObjectToSelectItems(response.meta.suppliers, 'Alle');

      this.loading = false;

      // Open the detail modal when id is passed as a query param
      if (this.selectedInvoiceId) {
        setTimeout(() => {
          this.detailModal.show();
        }, 1000);
      }
    });
  }

  /**
   * Display the detail modal of the exported invoice
   */
  public openDetailPage(id: number): void {
    this.selectedInvoiceId = id;

    this.detailModal.show();
  }

  public ngOnDestroy(): void {
    this.filterStartDate = null;
    this.filterEndDate = null;
    this.filterCreatedAt = null;

    this.destroy$.next();
  }

  public handleUpdate(): void {
    this.refreshTable();

    this.detailModal.hide();
    this.selectedInvoiceId = null;
  }

  /**
   * Set row style class to inverted when status is inverted
   *
   * @param row
   */
  public getRowStyleClass(row: Invoice): string {
    return row.status === 'reverted' ? 'reverted' : '';
  }

  /**
   * Prepare and filter on date
   *
   * @param {string} field
   * @param value
   *
   * @returns {void}
   */
  public filterDate(field: string, value: string): void {
    const filters = this.filters;

    if (value === null) {
      // Purposely give a fake filter value as TurboTable filter has no unset function
      const filter = {
        [field]: {
          value: '',
          matchMode: 'notEquals'
        }
      };

      this.filters = { ...filters, ...filter };

      this.refreshTable();

      return;
    }

    // Filter on range even if no range was supplied
    const rangeStart = moment(value[0]).format('DD/MM/YYYY');
    let rangeEnd = rangeStart;

    if (value[1]) {
      rangeEnd = moment(value[1]).format('DD/MM/YYYY');
    }

    const filter = {
      [field]: {
        value: `${rangeStart}-${rangeEnd}`,
        matchMode: 'between'
      }
    };

    this.filters = { ...filters, ...filter };

    this.refreshTable();
  }

  /**
   * Get the export data of the given date range
   *
   * @param data
   */
  public handleExportSubmit(data: { startDate: Date, endDate: Date }): void {
    this.exportLoading = true;

    this.invoicesService.post('invoices', {
      start_date: moment(data.startDate).format('Y-M-D'),
      end_date: moment(data.endDate).format('Y-M-D'),
      hub_id: this.selectedHubId
    }).subscribe((result: any) => {
      this.exportLoading = false;
      this.exportModal.hide();

      if (result.success) {
        this.messageService.success(MessageSummary['EXPORT'], 'Factuurregels ge&euml;xporteerd');

        this.refreshTable();
      } else {
        this.messageService.error(MessageSummary['EXPORT'], 'Er zijn geen openstaande factuurregels');
      }
    }, (error) => {
      this.exportLoading = false;
    });
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  /**
   * Download the excel that contains all the invoice rows
   */
  public downloadExcel(id: number, event: Event): void {
    event.stopPropagation();

    this.invoicesService.downloadExcel(id).subscribe((response) => {
      FileSaver.saveAs(response, `Factuurregels.xlsx`);
    });
  }

  private refreshTable(): void {
    // Trigger visibility to refresh table
    this.tableVisible = false;

    setTimeout(() => {
      this.tableVisible = true;
    }, 0);
  }
}
