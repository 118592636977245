/* tslint:disable:variable-name */
import { Client } from './client.model';
import { Address } from './address.model';
import { Transporter } from './transporter.model';
import { Order } from './order.model';
import { Collo } from './collo.model';
import { ShipmentStatus } from './shipment-status.enum';
import { ShipmentScan } from './shipment-scan.model';

/**
 * Model Shipment
 */
export class Shipment {
  id: number;
  client_id: number;
  address_id: number;
  transporter_id?: number;
  code: string;
  type: string;
  status: ShipmentStatus;
  status_translated: string;
  barcode?: string;
  has_qr: boolean;
  track_and_trace?: string;
  track_and_trace_url?: string;
  has_track_and_trace?: boolean;
  track_and_trace_email?: string;
  remarks?: string;
  refused_reason?: string;
  load_date?: Date | string;
  discharge_date?: Date | string;
  volume?: number;
  weight?: number;
  num_colli?: number;
  client: Client;
  address: Address;
  transporter: Transporter;
  orders: Order[];
  colli: Collo[];
  scans: ShipmentScan[];
  loadAddress?: Address;
  order_references?: string[];
  order_codes?: string[];
  finished: boolean;
  has_delivery_receipt?: boolean;
  to_postnl?: boolean;
}
