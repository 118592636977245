import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ConfirmationService, Message } from 'primeng/primeng';
import { ClientsService } from '../../../../services/clients.service';
import { SharedService } from '../../../../services/shared.service';
import { Client } from '../../../../models/client.model';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_SUPPLIER
} from '../../../../services/authentication.service';
import { BaseComponent } from '../../../../base.component';
import { MessageService, MessageSummary } from '../../../../services/message.service';

@Component({
  selector: 'client-details-administrative',
  templateUrl: './client-details-administrative.component.html',
  styleUrls: ['./client-details-administrative.scss']
})
export class ClientDetailsAdministrativeComponent extends BaseComponent implements OnInit {

  @ViewChild('form') public form;

  @Input() public client: Client;
  @Input() public defaults: any;

  public validationErrors: any = [];
  public messages: Message[] = [];
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public readonly: boolean = false;

  constructor(
    public sharedService: SharedService,
    protected clientsService: ClientsService,
    protected confirmationService: ConfirmationService,
    protected elRef: ElementRef,
    protected renderer: Renderer2,
    private messageService: MessageService,
    private authService: AuthenticationService) {
    super(authService, [
      {
        'role': ROLE_CUSTOMER,
        'hidden': [],
        'readonly': [
          'vat_number',
          'iban_number',
          'coc_number',
          'email_finance',
          'payment_agreement'
        ]
      },
      {
        'role': ROLE_SUPPLIER,
        'hidden': [],
        'readonly': [
          'vat_number',
          'iban_number',
          'coc_number',
          'email_finance',
          'payment_agreement'
        ]
      },
      {
        'role': ROLE_HUB_OPERATOR,
        'hidden': [],
        'readonly': [
          'vat_number',
          'iban_number',
          'coc_number',
          'email_finance',
          'payment_agreement'
        ]
      }
    ]);
  }

  public ngOnInit(): void {
    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR])) {
      this.isRoleHubOperator = true;
      this.readonly = true;
    }
  }

  public onSubmit(form: any, callback: any = null): void {
    // Add this attribute to notify backend we're editing administrative data
    this.client.update_administrative = true;

    this.clientsService.updateClient(this.client).subscribe((result: any) => {
      if (result) {
        this.client = result;

        if (typeof callback === 'function') {
          callback();
        } else {
          this.validationErrors = [];
          this.messageService.success(MessageSummary['OK'], 'Gegevens opgeslagen');

        }
      } else {
        this.handleError(true);
      }
    }, (error: any) => this.handleError(error));
  }

  private handleError(error: any, message = 'Opslaan mislukt') {
    this.validationErrors = [];

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        // Remove class 'invalid' for all inputs
        let inputs = this.elRef.nativeElement.querySelectorAll('.form-control');
        inputs.forEach((input) => {
          this.renderer.removeClass(input, 'invalid');
        });

        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);

              // Add class 'invalid' to input
              let el = this.elRef.nativeElement.querySelector('[name=' + field + ']');
              this.renderer.addClass(el, 'invalid');
            });
          }
        }
      }
    }

    this.messageService.error(MessageSummary['SAVE_ADMINISTRATIVE_DATA'], message);
  }
}
