
import {takeUntil, distinctUntilChanged} from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-shipment-label-modal',
  templateUrl: './shipment-label-modal.component.html',
  styleUrls: ['./shipment-label-modal.component.scss']
})
export class ShipmentLabelModalComponent implements OnInit, OnDestroy {
  @Output()
  public onSubmit: EventEmitter<{ barcode: string | null }>;

  @Input()
  public submitButtonText: string;

  @Input()
  public iconClass: string;

  @Input()
  public loading: boolean;

  get useBarcode(): boolean {
    return this._useBarcode;
  }

  @Input()
  set useBarcode(useBarcode: boolean) {
    this.shipmentLabelForm = this.formBuilder.group({
      withBarCode: [true, Validators.required],
      barcode: [null, Validators.nullValidator]
    });

    this._useBarcode = useBarcode;
  }

  public shipmentLabelForm: FormGroup;

  private destroy$: Subject<boolean>;

  private _useBarcode: boolean;

  constructor(private formBuilder: FormBuilder) {
    this.onSubmit = new EventEmitter();
    this.submitButtonText = 'Verzendlabel printen';
    this.iconClass = 'fas fa-print';
  }

  public ngOnInit(): void {
    this.destroy$ = new Subject();

    this.shipmentLabelForm = this.formBuilder.group({
      withBarCode: [true, Validators.required],
      barcode: [null, Validators.nullValidator]
    });

    // Add or remove the barcode control when the withBarCode option is changed
    this.shipmentLabelForm.get('withBarCode')
      .valueChanges.pipe(distinctUntilChanged(),
      takeUntil(this.destroy$)).subscribe((value) => {
      if (value === true) {
        if (!this.shipmentLabelForm.get('barcode')) {
          this.shipmentLabelForm.addControl('barcode', new FormControl('', Validators.required));
        }
      } else {
        if (this.shipmentLabelForm.get('barcode')) {
          this.shipmentLabelForm.removeControl('barcode');
        }
      }

      this.shipmentLabelForm.updateValueAndValidity();
    });
  }

  /**
   * Submit the form
   */
  public submit(): void {
    if (this.shipmentLabelForm.valid) {
      this.onSubmit.emit({
        barcode: this.shipmentLabelForm.get('barcode')
          ? this.shipmentLabelForm.value.barcode
          : null
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

}
