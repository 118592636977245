import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { GlobalState } from './global.state';
import { BaImageLoaderService, BaThemePreloader, BaThemeSpinner } from './theme/services';
import { BaThemeConfig } from './theme/theme.config';
import { MessageService } from './services/message.service';

import 'style-loader!./app.scss';
import 'style-loader!./theme/initial.scss';

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  template: `
    <p-growl [life]="growlLife" [sticky]="false" styleClass="growl-overlay"></p-growl>

    <main [ngClass]="{'menu-collapsed': isMenuCollapsed}" baThemeRun>
      <div>
        <div class="additional-bg"></div>
        <router-outlet></router-outlet>
      </div>
    </main>
  `
})
export class App implements AfterViewInit, DoCheck, AfterViewChecked {
  isMenuCollapsed: boolean = false;
  isRoleGuest: boolean = false;
  growlLife: number;

  constructor(
    protected activeRoute: ActivatedRoute,
    protected globalState: GlobalState,
    protected themeImageLoader: BaImageLoaderService,
    protected themeSpinner: BaThemeSpinner,
    protected viewContainerRef: ViewContainerRef,
    protected themeConfig: BaThemeConfig,
    protected cdr: ChangeDetectorRef,
    protected messageService: MessageService) {
    themeConfig.config();

    this.globalState.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    this.growlLife = this.messageService.messageLife ? this.messageService.messageLife : 3000;
  }

  public ngAfterViewInit(): void {
    // Hide spinner once all loaders are completed
    BaThemePreloader.load().then((values) => {
      this.themeSpinner.hide();
    });
  }

  public ngAfterViewChecked(): void {
    // @Hack Modify all forms and inputs in the current DOM tree
    // to dynamically set/add `autocomplete` attribute to 'off'
    [].forEach.call(document.querySelectorAll('form, input'), function (element) {
      element.setAttribute('autocomplete', 'off');
    });
  }

  public ngDoCheck(): void {
    this.cdr.detectChanges();
  }
}
