
import {merge as observableMerge,  Observable } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { Address } from '../../models/address.model';
import { SharedService } from '../../services/shared.service';
import { CommonModalComponent } from '../modal/child.modal';
import { DeliveryAgreement } from '../../models/deliveryAgreement.model';
import { MessageService, MessageSummary } from '../../services/message.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  @ViewChild('addressModal') addressModal: CommonModalComponent;
  @ViewChild('addressForm') addressForm;
  @ViewChild('postcode') postcodeField;
  @ViewChild('houseNo') houseNoField;

  get addresses(): Address[] {
    return this._addresses;
  }

  @Input()
  set addresses(value: Address[]) {
    this._addresses = value;

    this.addressTableVisible = false;

    setTimeout(() => {
      this.addressTableVisible = true;
    }, 0);
  }

  @Input('readonly')
  public readonly: boolean = false;

  @Input('hasHub')
  public hasHub: boolean = false;

  @Input('hubs')
  public hubs: SelectItem[] = [];

  @Input('contactpersons')
  public contactpersons?: SelectItem[] = [];

  @Output() onUpdate: EventEmitter<Address[]>;

  public validationErrors = [];
  public addressTableVisible = true;
  public isNewAddress = true;
  public defaultAddress: Address;
  public editableAddress: Address;
  public zipcodeIsValid: boolean;
  public loadingZipcode: boolean;
  public addressTypes: any;
  public countries: any[];
  public tableVisible: boolean;
  public weekDays: SelectItem[];
  public currentAddressType = null;
  private _addresses: Address[];

  constructor(public sharedService: SharedService,
              protected activeRoute: ActivatedRoute,
              protected router: Router,
              protected confirmationService: ConfirmationService,
              private messageService: MessageService) {
    this.defaultAddress = new Address();
    this.editableAddress = new Address();
    this.zipcodeIsValid = false;
    this.loadingZipcode = false;
    this.onUpdate = new EventEmitter();
    this.tableVisible = true;
    this.weekDays = SharedService.getWeekDaysList();
  }

  ngOnInit() {
    this.addresses = this.addresses || [];

    this.sharedService.getEnumOptions('addresses', 'type').subscribe((addressTypes) => {
      this.addressTypes = [{ label: 'Alle', value: null }];

      for (let key in addressTypes) {
        if (addressTypes.hasOwnProperty(key)) {
          this.addressTypes.push({ label: addressTypes[key], value: key });
        }
      }
    });
  }

  public addAddress(): void {
    this.editableAddress = new Address();
    this.isNewAddress = true;
    this.addressModal.show();

    observableMerge(this.houseNoField.valueChanges, this.postcodeField.valueChanges).subscribe(() => {
      this.focusOutFunction();
    });
  }

  public editAddress(address: Address, event: any): void {
    if (this.readonly) {
      return;
    }

    if (event !== undefined) {
      if (event.target.classList.contains('fas')) {
        return;
      }
    }

    this.editableAddress = { ...address };
    this.isNewAddress = false;
    this.addressModal.show();
  }

  public cancelAddress(): void {
    this.addressForm.resetForm();
    this.addressModal.hide();
  }

  public submitAddress(form): void {
    if (form.valid) {
      if (this.isNewAddress) {
        let address = this.editableAddress;

        this.addresses.push(address);
      } else {
        this.addresses = this.addresses.map((address) => {
          if (address.id === this.editableAddress.id) {
            return this.editableAddress;
          }

          return address;
        });
      }

      this.onUpdate.emit(this.addresses);

      this.editableAddress = new Address();

      // Trigger visibility to refresh table
      this.addressTableVisible = false;

      setTimeout(() => {
        this.addressTableVisible = true;
      }, 0);

      this.addressForm.resetForm();
      this.addressModal.hide();
    } else {
      this.handleError(null, 'SAVE_ADDRESS', 'Vul alle verplichte velden in (*)');
    }
  }

  public confirmDeleteAddress(addressId: number): void {
    this.confirmationService.confirm({
      key: 'address',
      message: 'Weet u zeker dat u dit adres wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteAddress(addressId);
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  public deleteDeliveryAgreement(index: number): void {
    this.editableAddress.delivery_agreements.splice(index, 1);

    // Refresh table
    this.tableVisible = false;
    setTimeout(() => {
      this.tableVisible = true;
    }, 0);
  }

  public addDeliveryAgreement(): void {
    const newDeliveryAgreement = new DeliveryAgreement();
    newDeliveryAgreement.address_id = this.editableAddress.id;

    this.editableAddress.delivery_agreements.push(newDeliveryAgreement);

    // Refresh table
    this.tableVisible = false;
    setTimeout(() => {
      this.tableVisible = true;
    }, 0);
  }

  public focusOutFunction(): void {
    if (this.editableAddress.postcode && this.editableAddress.house_number) {
      let output = this.sharedService.checkZipcode(this.editableAddress);

      if (output['success']) {
        this.loadingZipcode = true;

        this.sharedService.getZipcode(output).subscribe((result) => {
            if (result !== null) {
              this.editableAddress.street = result.street;
              this.editableAddress.location = result.city;
              this.zipcodeIsValid = true;
              this.loadingZipcode = false;
            }
          },
          (error: any) => this.handleZipcodeError(error));
      }
    }
  }

  private handleZipcodeError(error: any): void {
    if (error) {
      this.editableAddress.street = '';
      this.editableAddress.location = '';
      this.messageService.warning(MessageSummary['ZIPCODE_CHECK'], 'Adres niet gevonden o.b.v. postcode en huisnummer');
    }
  }

  private deleteAddress(addressId: number): void {
    const index = this.addresses.findIndex(address => address.id === addressId);

    this.addresses.splice(index, 1);

    this.onUpdate.emit(this.addresses);

    // Trigger visibility to refresh table
    this.addressTableVisible = false;

    setTimeout(() => {
      this.addressTableVisible = true;
    }, 0);
  }

  private handleError(error: any, summary: string, message: string): void {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (err) {
          message += '<br />' + result.errors[err][0];
        });
      }
    }

    this.messageService.warning(MessageSummary[summary], message);
  }
}
