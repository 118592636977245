import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER
} from '../../../services/authentication.service';
import { OrderService } from '../../../services/order.service';
import { SharedService } from '../../../services/shared.service';
import { Order } from '../../../models/order.model';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { Shipment } from '../../../models/shipment.model';
import { Client } from '../../../models/client.model';
import { Address } from '../../../models/address.model';
import { ShipmentService } from '../../../services/shipment.service';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { Supplier } from '../../../models/supplier.model';
import { MessageService, MessageSummary } from '../../../services/message.service';
import * as FileSaver from 'file-saver';
import { BaMenuService } from '../../../theme/services/baMenu';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'order-list-component',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit, OnDestroy, AfterContentInit {

  @ViewChild('addShipmentModal')
  public addShipmentModal: CommonModalComponent;

  @ViewChild('addBackorderModal')
  public addBackorderModal: CommonModalComponent;

  @ViewChild('orderModal')
  public orderModal: CommonModalComponent;

  @ViewChild('importModal')
  public importModal: CommonModalComponent;

  @ViewChild('shipmentForm')
  public shipmentForm: NgForm;

  public tableVisible: boolean = true;
  public records: Order[] = [];
  public totalRecords: number = 0;
  public loading: boolean = true;
  public orderStatuses: any[] = [];
  public selectedOrderStatus: string;
  public filters: { [key: string]: { value?: string | number | string[], matchMode: string } } = {};
  public order: Order;
  public selectedOrders: Order[] = [];
  public selectedClient: Client = null;
  public selectedAddress: Address = null;
  public validationErrors: string[] = [];
  public shipment: Shipment = new Shipment();
  public minDate = new Date();
  public tomorrow = new Date();
  public selectedLoadDate: Date = null;
  public selectedDischargeDate: Date = null;
  public calendarLocale: any = null;
  public packingList: SelectItem[] = [];
  public transporterList: SelectItem[] = [];
  public clientList: SelectItem[] = [];
  public supplierList: SelectItem[] = [];
  public payerList: SelectItem[];
  public colloSupplierList: SelectItem[];
  public clientFilter: Client;
  public supplierFilter: Supplier;
  public colliTableVisible: boolean = true;
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public canImport: boolean = false;
  public isRoleHubOperator: boolean = false;
  public importErrors: string[];
  public bulkActionsEnabled: boolean = false;
  public bulkActions: SelectItem[];
  public selectedBulkAction: string;
  public selectedLoadDateFilter: string;
  public selectedPreferredDeliveryDateFilter: string;
  public selectedCreatedAtFilter: string;
  public status: string;
  public openStatuses: string[] = [
    'received',
    'checked',
    'packingslip_printed',
    'ready_for_shipment',
    'backorder'
  ];
  public savedFilters: { [key: string]: { value?: string | number, matchMode: string } } = {};
  public selectedOrder: Observable<Order>;
  public paginationAmount: number = 30;
  public showAddShipmentModal: boolean;

  private destroy$ = new Subject();

  constructor(
    public sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private messageService: MessageService,
    private shipmentService: ShipmentService,
    private authService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private menuService: BaMenuService) {
    this.tomorrow.setDate(this.minDate.getDate() + 1);
    this.calendarLocale = this.sharedService.getCalendarLocale();
  }

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    this.orderService.filters.pipe(filter((filters) => !!filters)).pipe(takeUntil(this.destroy$)).subscribe((filters) => {
      this.savedFilters = filters;
    });

    if (this.authService.isRoleAuthorized([ROLE_MANAGER])) {
      this.minDate = null;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_MANAGER, ROLE_HUB_OPERATOR, ROLE_LOGISTIC_EMPLOYEE, ROLE_SUPPLIER])) {
      this.canImport = true;
    }

    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: any) => {
      if (params.id && window.location.href.indexOf('/orders/') !== -1) {
        const openSpecific = params.status && params.status === 'open';

        this.orderModal.onShown(() => {
          this.menuService.setCurrentItem('Opdrachten', 'Alle', openSpecific ? 'In behandeling' : null);
        });

        this.orderModal.show();
      }

      if ((params.status && params.status !== this.status) || !params.status) {
        this.status = params.status;
        this.filters = {};
        this.savedFilters = {};
        this.selectedOrderStatus = null;
        this.selectedClient = null;
        this.selectedPreferredDeliveryDateFilter = null;
        this.selectedDischargeDate = null;
        this.selectedCreatedAtFilter = null;
        this.selectedLoadDateFilter = null;

        this.refreshTable();
      }

      this.sharedService.getEnumOptions('orders', 'status').subscribe((orderStatuses) => {
        this.orderStatuses = [
          {
            label: 'Alle',
            value: ''
          }
        ];

        for (let key in orderStatuses) {
          if (this.status !== 'open' || this.openStatuses.indexOf(key) > -1) {
            this.orderStatuses.push({ label: orderStatuses[key], value: key });
          }
        }
      });

      if (params.id) {
        this.selectedOrder = this.orderService.getOrder(params.id);

        this.orderModal.show();
      }
    });

    this.bulkActions = [
      { label: 'Status rollforward', value: 'rollforward' },
      { label: 'Status rollback', value: 'rollback' },
      { label: 'Pakbonnen printen', value: 'print_packing_slips' },
      { label: 'Opdrachtlabels printen', value: 'print_labels' }
    ];
  }

  public ngAfterContentInit(): void {
    this.addShipmentModal.onHidden(() => {
      this.showAddShipmentModal = false;
    });
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(event: any): void {
    this.loading = true;

    if (this.filters.shipment_id) {
      event.filters = Object.assign(event.filters, { shipment_id: this.filters.shipment_id });
    }

    if (this.filters.address_id) {
      event.filters = Object.assign(event.filters, { address_id: this.filters.address_id });
    }

    if (this.filters.load_address_id) {
      event.filters = Object.assign(event.filters, { load_address_id: this.filters.load_address_id });
    }

    this.filters = event.filters;

    this.orderService.getOrders(event, event.first / event.rows + 1, event.rows, this.status).subscribe((result) => {
      this.loading = false;

      // Show selected orders on top
      const selectedOrderIds = this.selectedOrders.map((selectedOrder) => selectedOrder.id);
      const orders = result.data.filter((order: Order) => {
        return !selectedOrderIds.includes(order.id);
      });

      this.records = [...this.selectedOrders, ...orders];
      this.totalRecords = result.pagination.total;

      this.packingList = this.sharedService.mapObjectToSelectItems(result.meta.packings);
      this.transporterList = this.sharedService.mapObjectToSelectItems(result.meta.transporters);
      this.clientList = this.sharedService.mapObjectToSelectItems(result.meta.clients, 'Alle');
      this.supplierList = this.sharedService.mapObjectToSelectItems(result.meta.suppliers, 'Alle');
    });
  }

  public openOrderAdd(): void {
    this.sharedService.openNewWindow('pages/orders/add');
  }

  public closeOrderDetailModal(): void {
    this.orderModal.hide();
    this.refreshTable();
  }

  public onRowSelect(event: any): void {
    if (this.bulkActionsEnabled) {
      return;
    }

    if (event.originalEvent.originalEvent !== undefined) {
      if (event.originalEvent.originalEvent.target.classList.contains('ui-chkbox-box')) {
        if (this.selectedOrders.length === 1) {
          // Set selected client and address
          this.selectedClient = this.selectedOrders[0].client;
          this.selectedAddress = this.selectedOrders[0].address;

          // Hide orders which are already attached to a shipment
          this.filters['shipment_id'] = {
            value: null,
            matchMode: 'equals'
          };

          // this.filters['load_address_id'] = {
          //   value: this.selectedOrders[0].load_address_id,
          //   matchMode: 'equals'
          // };

          // Hide orders which belong to other address
          this.filters['address.postcode'] = {
              value: this.selectedAddress.postcode,
              matchMode: 'equals'
          };

          this.filters['address.house_number'] = {
              value: this.selectedAddress.house_number,
              matchMode: 'equals'
          };

          // Hide orders which have the status cancelled
          this.filters['status'] = {
            value: ['cancelled', 'shipment_cancelled'],
            matchMode: 'notIn'
          };

          // Reload table
          this.tableVisible = false;
          setTimeout(() => {
            this.tableVisible = true;
          }, 0);
        }

        return;
      }
    }
  }

  public onRowClick(event: any): void {
    if (event.originalEvent !== undefined) {
      if (
        event.originalEvent.target.classList.contains('ui-selection-column') ||
        event.originalEvent.target.classList.contains('ui-chkbox')
      ) {
        return;
      }
    }

    if (this.bulkActionsEnabled) {
      return;
    }

    this.sharedService.openNewWindow(`pages/orders/${event.data.id}`);
  }

  /**
   * Call api to export the current order data in excel format
   */
  public export(): void {
    this.loading = true;

    let selectedOrderIds = null;
    if (this.selectedOrders.length > 0) {
      selectedOrderIds = this.selectedOrders.map((selectedOrder) => selectedOrder.id);
    }

    const exportFilters = { ...this.filters };

    if (this.status === 'open') {
      exportFilters.status = {
        value: 'open',
        matchMode: 'equals'
      };
    }

    this.orderService.exportOrders(selectedOrderIds, exportFilters).subscribe((response: Blob) => {
      this.loading = false;

      const timeStamp = new Date().getTime();
      const fileName = `Export opdrachten - ${timeStamp}`;

      FileSaver.saveAs(response, fileName + '.xlsx');
    }, (error) => {
      this.loading = false;

      this.messageService.handleError(error, 'Exporteren mislukt.');
    });
  }

  public onRowUnselect(): void {
    if (this.bulkActionsEnabled) {
      return;
    }

    if (this.selectedOrders.length === 0) {
      this.selectedClient = null;
      this.selectedAddress = null;

      this.filters = {};
      this.savedFilters = {};

      // Reload table
      this.tableVisible = false;
      setTimeout(() => {
        this.tableVisible = true;
      }, 0);
    }
  }

  public createShipment(): void {
    this.showAddShipmentModal = true;
    this.addShipmentModal.show();
  }

  public createBackorder(): void {
    const orderId = this.selectedOrders[0].id;

    this.orderService.getOrder(orderId).subscribe((result) => {
      this.order = result;
      this.order.articles = result.articles.data;
    });

    this.addBackorderModal.show();
  }


  public submitBackorder(): void {
    const articles = this.order.articles.filter((article) => {
      if (article.num_backorder && article.num_backorder > 0) {
        return article;
      }
    });

    this.orderService.addBackorder(this.order.id, articles).subscribe(() => {
      this.refreshTable();

      this.order = null;

      this.hideModal('addBackorderModal');
    }, (error: any) => this.handleError(error, 'Backorder', 'Kan geen backorder aanmaken.'));
  }

  public hideModal(modal: string, askToConfirm: boolean = true) {
    if (askToConfirm === true) {
      this.confirmationService.confirm({
        message: 'Weet u zeker dat u deze zending wilt annuleren?',
        accept: () => {
          this.selectedOrders = [];
          this.selectedClient = null;
          this.selectedAddress = null;
          this.order = new Order();
          this.shipment = new Shipment();
          this.filters = {};
          this.savedFilters = {};

          if (modal === 'addShipmentModal') {
            this.showAddShipmentModal = false;
          }

          this[modal].hide();

          this.refreshTable();
        }
      });
    } else {
      if (modal === 'addShipmentModal') {
        this.shipmentForm.resetForm();
      }

      this[modal].hide();
      this.refreshTable();
    }
  }

  public enableBulkActions(): void {
    this.bulkActionsEnabled = !this.bulkActionsEnabled;
    this.selectedOrders = [];
  }

  public doBulkAction(): void {
    const orderIds = this.selectedOrders.map((order: Order) => {
      return order.id;
    });

    // Determine bulk action
    let action = null;
    switch (this.selectedBulkAction) {
      case 'rollforward':
        action = 'rollforwardOrderStatuses';

        break;
      case 'rollback':
        action = 'rollbackOrderStatuses';

        break;
      case 'print_packing_slips':
        action = 'printOrderPackingSlips';

        break;
      case 'print_labels':
        action = 'printOrderLabels';

        break;
      default:
        this.loading = false;
        this.selectedBulkAction = null;

        return;
    }

    if (action === 'rollbackOrderStatuses') {
      this.confirmationService.confirm({
        message: 'Weet u zeker dat u een rollback wilt uitvoeren?',
        accept: () => {
          this.executeBulkAction(action, orderIds);
        }
      });
    } else {
      this.executeBulkAction(action, orderIds);
    }
  }

  /**
   * Open the order import modal
   */
  public openImportModal(): void {
    this.importModal.show();
  }

  /**
   * Import given CSV file
   *
   * @param data
   */
  public handleImportSubmit(data: {file: File, supplierId: number}): void {
    this.orderService.import(data.file, data.supplierId).subscribe((response) => {
      if (response.status === 'error') {
        this.messageService.error(MessageSummary['IMPORT_FAILED'], 'Controleer uw importbestand.');
        this.importErrors = response.errors;

        return;
      }

      this.importModal.hide();
      this.messageService.success(MessageSummary['OK'], 'Import uitgevoerd');

      // Refresh orders table
      this.tableVisible = false;
      setTimeout(() => {
        this.tableVisible = true;
      }, 0);
    }, (error) => {
      this.messageService.handleError(error, 'Importeren mislukt.');
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
  }

  public onShipmentSuccess(shipment: Shipment) {
    this.selectedClient = null;
    this.selectedAddress = null;
    this.selectedOrders = [];
    this.filters = {};
    this.savedFilters = {};

    // Reload table
    this.tableVisible = false;
    setTimeout(() => {
      this.tableVisible = true;
    }, 0);


    this.addShipmentModal.hide();
    this.selectedOrders = [];
    this.onRowUnselect();

    this.sharedService.openNewWindow(`pages/shipments/${shipment.id}`);
  }

  private executeBulkAction(action: string, orderIds: number[]): void {
    this.loading = true;

    // Perform bulk action
    this.orderService[action](orderIds).subscribe(() => {
      this.loading = false;
      this.messageService.success(MessageSummary['OK'], 'Bulkactie uitgevoerd.');

      // Reset selected orders and action
      this.selectedOrders = [];
      this.selectedBulkAction = null;
      this.bulkActionsEnabled = false;

      // Reload table
      this.refreshTable();
    }, (error) => {
      this.loading = false;
      this.selectedBulkAction = null;
      this.messageService.handleError(error, 'Bulkactie mislukt');
    });
  }

  private handleError(error: any, summary: string = 'Zending', message: string = 'Kan geen nieuwe zending aanmaken.') {
    this.validationErrors = [];
    this.loading = false;

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);
            });
          }
        }
      }
    }

    this.messageService.error(summary, message);
  }

  private refreshTable(tableVisible: string = 'tableVisible'): void {
    this[tableVisible] = false;

    setTimeout(() => {
      this[tableVisible] = true;
    }, 0);
  }
}
