import { Component } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { BaMenuService } from '../theme';
import { PAGES_MENU } from './pages.menu';
import { SearchModel } from '../models/search.model';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_DELIVERER,
  ROLE_FINANCIAL_EMPLOYEE,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER
} from '../services/authentication.service';
import { SearchService } from '../services/search.service';
import { GlobalState } from '../global.state';

@Component({
  selector: 'pages',
  templateUrl: './pages.template.html',
  styleUrls: ['./pages.template.scss']
})
export class Pages {
  public isMenuHidden: boolean = false;
  public sidebarCollapsed: boolean = true;
  public searchTerm: string = '';
  public searchResults: SearchModel[] = [];
  public isBlurred: boolean = false;
  public isRoleAdministrator: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleCustomer: boolean = false;
  public isRoleHubOperator: boolean = false;
  public isRoleDeliverer: boolean = false;
  public isPopup: boolean = false;

  constructor(
    protected authService: AuthenticationService,
    private router: Router,
    protected themeMenuService: BaMenuService,
    protected searchService: SearchService,
    private _state: GlobalState) {
  }

  public ngOnInit(): void {
    this.themeMenuService.updateMenuByRoutes(<Routes>PAGES_MENU[this.authService.getUser().role.id]);

    // Hack: don't show sidebar and header for order and shipment detail page
    const locationHash = window.location.hash;
    const regex = /^#\/pages\/(orders|shipments)\/(\d+|add)$/;
    if (regex.test(locationHash)) {
      this.isPopup = true;
    }

    this.isRoleAdministrator = this.authService.isRoleAuthorized(
      [ROLE_MANAGER, ROLE_LOGISTIC_EMPLOYEE, ROLE_FINANCIAL_EMPLOYEE]
    );
    this.isRoleSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]);
    this.isRoleCustomer = this.authService.isRoleAuthorized([ROLE_CUSTOMER]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);
    this.isRoleDeliverer = this.authService.isRoleAuthorized([ROLE_DELIVERER]);
  }

  public search(): void {
    this.searchService.search(this.searchTerm).subscribe((response) => {
      this.searchResults = Array.prototype.concat.apply([], Object.keys(response).map((key) => {
        if (response[key].length > 0) {
          const object = {};
          object[key] = response[key].map((obj) => {
            obj.searchType = key;
            return obj;
          });

          return object[key];
        } else {
          delete response[key];
        }
      }).filter(value => (typeof value === 'object')));

    });
  }

  public blur(event): void {
    this.isBlurred = (event.target.value !== '' && event.type !== 'blur');
  }

  public select(value: any): void {
    let url = '/pages/';
    let queryParams = null;

    if (this.isRoleAdministrator) {
      switch (value.searchType) {
        case 'client':
          url += `clients/${value.id}`;
          break;
        case 'contactperson':
          if (value.client_id) {
            url += `clients/${value.client_id}`;
          } else if (value.supplier_id) {
            url += `suppliers/${value.supplier_id}`;
          }

          queryParams = {
            id: value.id,
            tab: value.searchType
          };
          break;
        case 'order':
          url += `orders/${value.id}`;
          break;
        case 'shipment':
          url += `shipments/${value.id}`;
          break;
        case 'supplier':
          url += `suppliers/${value.id}`;
          break;
        default:
          break;
      }
    } else if (this.isRoleSupplier) {
      url += `/suppliers/${value.supplier_id ? value.supplier_id : value.id}`;

      queryParams = {
        id: value.id,
        tab: value.searchType
      };
    } else if (this.isRoleCustomer) {
      url += `/clients/${value.client_id ? value.client_id : value.id}`;

      queryParams = {
        id: value.id,
        tab: value.searchType
      };
    }

    if (queryParams) {
      this.router.navigate([`${url}`], { queryParams: queryParams });
    } else {
      this.router.navigate([`${url}`]);
    }
  }

  public toggleMenu(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.sidebarCollapsed);
  }
}
