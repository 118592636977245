/* tslint:disable:max-line-length */
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap';
import { CLIENTS_ROUTING } from './clients.routing';
import { SharedModule } from '../../shared.module';
import { ClientAddComponent } from './client-add/client-add.component';
import { ClientDetailsAdministrativeComponent } from './client-details/administrative/client-details-administrative.component';
import { ClientDetailsCommonComponent } from './client-details/common/client-details-common.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { ClientDetailsContactpersonsComponent } from './client-details/contactpersons/client-details-contactpersons.component';
import { ClientListComponent } from './client-list/client-list.component';
import { ClientsComponent } from './clients.component';
import { EditShipmentOrderModalComponent } from '../../components/edit-shipment-order-modal/edit-shipment-order-modal.component';
import { ShipmentOrderResultComponent } from '../../components/shipment-order-result/shipment-order-result.component';
import { ClientDetailsOrdersComponent } from './client-details/orders/client-details-orders.component';
import { ClientDetailAdditionalSellRatesComponent } from './client-details/additional-sell-rates/client-detail-additional-sell-rates';

@NgModule({
  declarations: [
    ClientsComponent,
    ClientListComponent,
    ClientDetailsComponent,
    ClientDetailsCommonComponent,
    ClientDetailsAdministrativeComponent,
    ClientDetailsContactpersonsComponent,
    ClientDetailsOrdersComponent,
    ClientAddComponent,
    ShipmentOrderResultComponent,
    EditShipmentOrderModalComponent,
    ClientDetailAdditionalSellRatesComponent
  ],
  imports: [
    SharedModule,
    CLIENTS_ROUTING,
    ModalModule
  ],
  entryComponents: [
    ClientDetailsCommonComponent,
    ClientDetailsAdministrativeComponent,
    ClientDetailsContactpersonsComponent,
    ClientDetailsOrdersComponent,
    ClientAddComponent,
    ShipmentOrderResultComponent,
    EditShipmentOrderModalComponent
  ]
})

export class ClientsModule {
}
