import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateValidator } from '../../validators/date.validator';
import { Message } from 'primeng/primeng';
import * as moment from 'moment';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-order-export-modal',
  templateUrl: './order-export-modal.component.html',
  styleUrls: ['./order-export-modal.component.scss']
})
export class OrderExportModalComponent implements OnInit {

  @Input()
  public loading: boolean = false;

  @Output()
  public onSubmit: EventEmitter<{ startDate: Date, endDate: Date }>;

  public today: Date;
  public yesterday: Date;
  public formGroup: FormGroup;
  public calendarLocale: any;
  public errorMessages: Message[];

  constructor(private formBuilder: FormBuilder,
              private sharedService: SharedService) {
    this.onSubmit = new EventEmitter();
    this.today = new Date();
    this.yesterday = moment().subtract(1, 'day').toDate();
    this.calendarLocale = this.sharedService.getCalendarLocale();
  }

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      startDate: [null, Validators.required],
      endDate: [this.today, Validators.required]
    }, {
      validator: DateValidator.dateLessThan('startDate', 'Van', 'endDate', 'Tot')
    });
  }

  /**
   * Submit the form
   */
  public submit(): void {
    if (this.formGroup.valid) {
      this.onSubmit.emit(this.formGroup.value);
    } else {
      if (this.formGroup.errors && this.formGroup.errors['dates']) {
        this.errorMessages = [{ severity: 'error', summary: 'Exporteren', detail: this.formGroup.errors['dates'] }];
      }
    }
  }

}
