import { AfterContentInit, Component, ContentChildren, EventEmitter, Output, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TabComponent } from './tab.component';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_FINANCIAL_EMPLOYEE,
  ROLE_HUB_OPERATOR,
  ROLE_LOGISTIC_EMPLOYEE,
  ROLE_MANAGER,
  ROLE_SUPPLIER,
  ROLE_TRANSPORTER
} from '../../services/authentication.service';

@Component({
  selector: 'tabs',
  styles: [`
    .nav-tabs li a:hover {
      cursor: pointer;
    }
  `],
  template: `
    <div class="col-lg-12 no-padding">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabs"
            (click)="selectTab(tab, 'open')"
            class="nav-item"
            [class.active]="tab.active"
            [class.invalid]="tab.invalid"
            id="{{ tab.id }}">
          <a class="nav-link"><i class="fas {{ tab.icon }}"></i>{{ tab.title }}</a>
        </li>
      </ul>
    </div>
    <div class="tab-content no-padding">
      <ng-content></ng-content>
    </div>
  `
})
export class TabsComponent implements AfterContentInit {

  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @Output() activeTabRequest = new EventEmitter<TabComponent>();
  @Output() onTabSelect = new EventEmitter();

  private tabsOnRole = null;
  private tabsToRemove = [];
  private tabsInOrder = [
    {
      'role': ROLE_MANAGER,
      'tabs': [
        { id: 'common', name: 'Algemeen' },
        { id: 'administrative', name: 'Administratief' },
        { id: 'contactpersons', name: 'Contactpersonen' },
        { id: 'orders', name: 'Opdrachten' },
        { id: 'shipments', name: 'Uitgaande zendingen' },
        { id: 'additional-sell-rates', name: 'Extra diensten' },
        { id: 'invoices', name: 'Facturen' },
        { id: 'articles', name: 'Artikelen' }
      ]
    },
    {
      'role': ROLE_LOGISTIC_EMPLOYEE,
      'tabs': [
        { id: 'common', name: 'Algemeen' },
        { id: 'administrative', name: 'Administratief' },
        { id: 'contactpersons', name: 'Contactpersonen' },
        { id: 'orders', name: 'Opdrachten' },
        { id: 'shipments', name: 'Uitgaande zendingen' },
        { id: 'invoices', name: 'Facturen' },
        { id: 'articles', name: 'Artikelen' }
      ]
    },
    {
      'role': ROLE_FINANCIAL_EMPLOYEE,
      'tabs': [
        { id: 'common', name: 'Algemeen' },
        { id: 'administrative', name: 'Administratief' },
        { id: 'contactpersons', name: 'Contactpersonen' },
        { id: 'orders', name: 'Opdrachten' },
        { id: 'shipments', name: 'Uitgaande zendingen' },
        { id: 'additional-sell-rates', name: 'Extra diensten' },
        { id: 'invoices', name: 'Facturen' },
        { id: 'articles', name: 'Artikelen' }
      ]
    },
    {
      'role': ROLE_HUB_OPERATOR,
      'tabs': [
        { id: 'common', name: 'Algemeen' },
        { id: 'administrative', name: 'Administratief' },
        { id: 'contactpersons', name: 'Contactpersonen' },
        { id: 'orders', name: 'Opdrachten' },
        { id: 'shipments', name: 'Uitgaande zendingen' },
        { id: 'additional-sell-rates', name: 'Extra diensten' },
        { id: 'invoices', name: 'Facturen' },
        { id: 'articles', name: 'Artikelen' }
      ]
    },
    {
      'role': ROLE_SUPPLIER,
      'tabs': [
        { id: 'common', name: 'Algemeen' },
        { id: 'administrative', name: 'Administratief' },
        { id: 'orders', name: 'Opdrachten' },
        { id: 'shipments', name: 'Uitgaande zendingen' },
        { id: 'invoices', name: 'Facturen' },
        { id: 'articles', name: 'Artikelen' }
      ]
    },
    {
      'role': ROLE_CUSTOMER,
      'tabs': [
        { id: 'common', name: 'Algemeen' },
        { id: 'administrative', name: 'Administratief' },
        { id: 'contactpersons', name: 'Contactpersonen' },
        { id: 'invoices', name: 'Facturen' },
        { id: 'shipments', name: 'Uitgaande zendingen' },
        { id: 'articles', name: 'Artikelen' }
      ]
    },
    {
      'role': ROLE_TRANSPORTER,
      'tabs': [
        { id: 'additional-sell-rates', name: 'Extra diensten' }
      ]
    }
  ];

  constructor(
    protected activeRoute: ActivatedRoute,
    protected authenticationService: AuthenticationService) {
  }

  // contentChildren are set
  ngAfterContentInit() {
    // Alter tab names and prepare for tab removal
    this.tabsInOrder.forEach(tabInOrder => {
      if (this.authenticationService.isRoleAuthorized([tabInOrder.role])) {
        this.tabsOnRole = tabInOrder.tabs;

        this.tabs.forEach((tab, tabIndex) => {
          let tabNameFound = null;
          this.tabsOnRole.forEach(tabOnRole => {
            if (tab.id === tabOnRole.id) {
              tabNameFound = tabOnRole.name;
            }
          });
          if (tabNameFound === null) {
            this.tabsToRemove[tabIndex] = tab.id;
          } else {
            tab.title = tabNameFound;
          }
        });
      }
    });

    // Get all active tabs
    let activeTabs = this.tabs.filter((tab) => tab.active);

    // Parse route the determine tab to focus via hash
    let hash = '';
    this.activeRoute.fragment.subscribe((fragment: string) => {
      hash = fragment;
    });

    let action = null;
    if (!hash) {
      // Parse route the determine tab to focus via parameter
      this.activeRoute.params.subscribe((params: any) => {
        if (params['tab']) {
          hash = 'open-' + params['tab'];
          action = 'open';
        }
      });
    }

    if (!hash) {
      // If there is no active tab set, activate the first
      if (activeTabs.length === 0) {
        let tabToFocus = this.tabs.first;
        this.tabs.forEach(tab => {
          if (tab.id === this.tabsOnRole[0].id) {
            tabToFocus = tab;
          }
        });
        this.selectTab(tabToFocus);
      }
    } else {
      // Activate the selected tab
      for (let i = 0; i < this.tabs.toArray().length; i++) {
        if (this.tabs.toArray()[i].id === hash.split('-')[1]) {
          this.selectTab(this.tabs.toArray()[i], action);
        }
      }
    }
  }

  selectTab(tab: TabComponent, action?: string) {
    // Deactivate all tabs
    if (this.tabs) {
      this.tabs.toArray().forEach(t => t.active = false);
    }

    // Activate the tab the user has clicked on.
    tab.active = true;
    tab.action = action;

    this.onTabSelect.emit(tab);
    this.activeTabRequest.emit(tab);
  }

  selectTabId(tabId: string, action?: string) {
    if (this.tabs) {
      this.tabs.forEach(tab => {
        if (tab.id === tabId) {
          this.selectTab(tab, action);
          return;
        }
      });
    }
  }
}
