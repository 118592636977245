import { NgModule } from '@angular/core';

import { SHIPMENTS_ROUTING } from './shipments.routing';
import { SharedModule } from '../../shared.module';

import { ShipmentsComponent } from './shipments.component';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import { ShipmentDetailsComponent } from './shipment-details/shipment-details.component';

@NgModule({
  declarations: [
    ShipmentsComponent,
    ShipmentListComponent,
    ShipmentDetailsComponent
  ],
  imports: [
    SharedModule,
    SHIPMENTS_ROUTING
  ],
  entryComponents: []
})

export class ShipmentsModule {
}
