import { Pipe } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormat {
  transform(value: any,
            decimalLength: number = 2,
            chunkDelimiter: string = '.',
            decimalDelimiter: string = ',') {
    if (value == null) {
      return null;
    }

    if (typeof value === 'string') {
      value = parseFloat(value.replace(decimalDelimiter, chunkDelimiter));
    }

    if (value === 0) {
      return '0' + decimalDelimiter + '00';
    }

    let num = value.toFixed(decimalLength).replace(chunkDelimiter, decimalDelimiter);

    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + chunkDelimiter);
  }
}
