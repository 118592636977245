import { Component, Input, ViewChild } from '@angular/core';
import { ArticleService } from '../../../../../services/article.service';
import { LazyLoadEvent, SelectItem } from 'primeng/primeng';
import { Article } from '../../../../../models/article.model';
import { SharedService } from '../../../../../services/shared.service';
import { ArticleModalComponent } from '../../../../../components/article-modal/article-modal.component';
import { AuthenticationService, ROLE_SUPPLIER } from '../../../../../services/authentication.service';

@Component({
  selector: 'app-supplier-detail-article-list',
  templateUrl: './supplier-detail-article-list.component.html',
  styleUrls: ['./supplier-detail-article-list.component.scss']
})
export class SupplierDetailArticleListComponent {
  @Input()
  public supplierId: number;

  @ViewChild('articleModal')
  public articleModal: ArticleModalComponent;

  public records: Article[];
  public totalRecords: number;
  public article: Article;
  public isRoleSupplier: boolean;
  public tableVisible: boolean;
  public paginationAmount: number = 30;
  public booleanList: SelectItem[] = [
    { label: 'Alle', value: '' },
    { label: 'Ja', value: true },
    { label: 'Nee', value: false }
  ];

  constructor(
    public sharedService: SharedService,
    private articleService: ArticleService,
    private authService: AuthenticationService) {
    this.isRoleSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]);
    this.tableVisible = true;
    this.paginationAmount = this.sharedService.getRowsPerPage();
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(event: LazyLoadEvent): void {
    this.articleService.getSupplierArticles(this.supplierId, event).subscribe((response) => {
      this.records = response.data.map((article) => {
        article.packing_units = article.packing_units.data;

        return article;
      });
      this.totalRecords = response.pagination.total;
    }, error => {
      this.sharedService.handleError(error, 'Artikelen ophalen');
    });
  }

  public onRowSelect(article: Article): void {
    this.article = article;
    this.articleModal.showModal();
  }

  public refreshTable(): void {

    this.tableVisible = false;

    setTimeout(() => {
      this.tableVisible = true;
    }, 0);
  }

  public translateStockingItem(stocking): string {
    switch (stocking) {
      case 1:
        return 'Ja';
      case 2:
        return 'Nee';
      case 3:
        return 'Ja en Nee';
      default:
        return '';
    }
  }
}
