/* tslint:disable:max-line-length */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    AutoCompleteModule,
    CalendarModule,
    CheckboxModule,
    ConfirmDialogModule,
    DataGridModule,
    DataListModule,
    DataTableModule,
    DialogModule,
    DropdownModule,
    FieldsetModule,
    FileUpload,
    FileUploadModule,
    GrowlModule,
    MultiSelectModule,
    RadioButtonModule,
    SelectButtonModule,
    StepsModule,
    TabViewModule
} from 'primeng/primeng';
import { ButtonsModule, ModalModule, TooltipModule } from 'ngx-bootstrap';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

import { CommonModalComponent } from './components/modal/child.modal';
import { LoadingComponent } from './components/loading/loading.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { AddressComponent } from './components/address/address.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { ArticlesWithPackingUnitSelectorComponent } from './components/articles-with-packing-unit-selector/articles-with-packing-unit-selector.component';
import { DeliveryAgreementTableComponent } from './components/delivery-agreement-table/delivery-agreement-table.component';
import { ArticleComponent } from './components/article/article.component';
import { ArticleModalComponent } from './components/article-modal/article-modal.component';
import { AdditionalSellRatesListComponent } from './components/additional-sell-rates/additional-sell-rates-list/additional-sell-rates-list.component';
import { AdditionalSellRateModalComponent } from './components/additional-sell-rates/additional-sell-rate-modal/additional-sell-rate-modal.component';
import { ConversionListComponent } from './components/conversions/conversion-list/conversion-list.component';
import { ConversionModalComponent } from './components/conversions/conversion-modal/conversion-modal.component';

import { CurrencyFormat } from './pipes/currency-format.pipe';
import { CurrencyFormatInput } from './pipes/currency-format-input.pipe';
import { CustomDate } from './pipes/custom-date.pipe';
import { Sort } from './pipes/sort.pipe';
import { ReplaceDotToCommaPipe } from './pipes/replace-dot-to-comma.pipe';
// Styles
import 'style-loader!primeng/resources/themes/omega/theme.css';
import 'style-loader!primeng/resources/primeng.min.css';
import { LoadingAgreementTableComponent } from './components/loading-agreement-table/loading-agreement-table.component';
import { PrintingProcedureWizardComponent } from './components/printing-procedure-wizard/printing-procedure-wizard.component';
import { ShipmentLabelModalComponent } from './components/shipment-label-modal/shipment-label-modal.component';
import { OnlyRolesDirective } from './directives/only-roles.directive';
import { ExportedInvoicesComponent } from './components/exported-invoices/exported-invoices.component';
import { ExportedInvoiceDetailComponent } from './components/exported-invoices/exported-invoice-detail/exported-invoice-detail.component';
import { OrderExportModalComponent } from './components/order-export-modal/order-export-modal.component';
import { OrderImportModalComponent } from './components/order-import-modal/order-import-modal.component';
import { AddressAddComponent } from './components/address/addres-add/address-add.component';
import { SelectOnFocusDirective } from './directives/select-on-focus.directive';
import { AddShipmentModalComponent } from './components/add-shipment-modal/add-shipment-modal.component';
import { SupplierFieldMappingComponent } from './components/supplier-field-mapping/supplier-field-mapping.component';
import { Keys } from './pipes/keys';
import { TypeofPipe } from './pipes/typeof.pipe';
import { ShipmentExportModalComponent } from './components/shipment-export-modal/shipment-export-modal.component';
import { NumericDirective } from './directives/numeric.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DataTableModule,
    GrowlModule,
    DropdownModule,
    ModalModule.forRoot(),
    DataListModule,
    DataGridModule,
    DialogModule,
    AutoCompleteModule,
    FileUploadModule,
    TooltipModule.forRoot(),
    ConfirmDialogModule,
    StepsModule,
    ButtonsModule,
    RadioButtonModule,
    Ng2AutoCompleteModule,
    SelectButtonModule,
    CheckboxModule,
    MultiSelectModule,
    TabViewModule,
    ReactiveFormsModule,
    CalendarModule,
    FieldsetModule
  ],
  declarations: [
    CommonModalComponent,
    TabComponent,
    TabsComponent,
    CurrencyFormat,
    CurrencyFormatInput,
    CustomDate,
    Keys,
    Sort,
    TypeofPipe,
    ReplaceDotToCommaPipe,
    LoadingComponent,
    DeliveryAgreementTableComponent,
    LoadingAgreementTableComponent,
    AddressComponent,
    ArticlesComponent,
    ArticlesWithPackingUnitSelectorComponent,
    ArticleComponent,
    ArticleModalComponent,
    OnlyRolesDirective,
    NumericDirective,
    SelectOnFocusDirective,
    PrintingProcedureWizardComponent,
    ShipmentLabelModalComponent,
    ExportedInvoicesComponent,
    ExportedInvoiceDetailComponent,
    OrderExportModalComponent,
    OrderImportModalComponent,
    AddressAddComponent,
    AdditionalSellRatesListComponent,
    AdditionalSellRateModalComponent,
    ConversionListComponent,
    ConversionModalComponent,
    AddShipmentModalComponent,
    SupplierFieldMappingComponent,
    ShipmentExportModalComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    DataTableModule,
    GrowlModule,
    DropdownModule,
    CommonModalComponent,
    ModalModule,
    DataListModule,
    TabsComponent,
    TabComponent,
    DataGridModule,
    DialogModule,
    AutoCompleteModule,
    TooltipModule,
    CurrencyFormat,
    CustomDate,
    Sort,
    TypeofPipe,
    CurrencyFormatInput,
    FileUploadModule,
    ConfirmDialogModule,
    StepsModule,
    CalendarModule,
    RadioButtonModule,
    Ng2AutoCompleteModule,
    SelectButtonModule,
    LoadingComponent,
    CheckboxModule,
    MultiSelectModule,
    TabViewModule,
    DeliveryAgreementTableComponent,
    LoadingAgreementTableComponent,
    AddressComponent,
    ArticlesComponent,
    ArticlesWithPackingUnitSelectorComponent,
    ArticleComponent,
    ArticleModalComponent,
    OnlyRolesDirective,
    NumericDirective,
    SelectOnFocusDirective,
    PrintingProcedureWizardComponent,
    ReactiveFormsModule,
    ExportedInvoicesComponent,
    ExportedInvoiceDetailComponent,
    OrderExportModalComponent,
    OrderImportModalComponent,
    AddressAddComponent,
    AdditionalSellRatesListComponent,
    AdditionalSellRateModalComponent,
    ConversionListComponent,
    ConversionModalComponent,
    ShipmentLabelModalComponent,
    AddShipmentModalComponent,
    ReplaceDotToCommaPipe,
    SupplierFieldMappingComponent,
    ShipmentExportModalComponent,
  ]
})

export class SharedModule {
}

/*
 * Override FileUpload file size presentation prototype
 */
FileUpload.prototype.formatSize = function (bytes) {
  if (bytes === 0) {
    return '0 B';
  }

  let k = 1000,
    dm = 1,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
