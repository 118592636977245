import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/primeng';
import { Article } from '../../models/article.model';
import { SharedService } from '../../services/shared.service';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_SUPPLIER
} from '../../services/authentication.service';
import { PackingUnit } from '../../models/packing-unit.model';
import { ArticleModalComponent } from '../article-modal/article-modal.component';
import { ReplaceDotToCommaPipe } from '../../pipes/replace-dot-to-comma.pipe';
import { Sort } from '../../pipes/sort.pipe';

@Component({
  selector: 'app-articles-with-packing-unit-selector',
  templateUrl: './articles-with-packing-unit-selector.component.html',
  styleUrls: ['./articles-with-packing-unit-selector.component.scss']
})
export class ArticlesWithPackingUnitSelectorComponent implements OnInit {
  @Input() public supplierId: number;
  @Input() public loading: boolean = false;

  @Input('availableArticles')
  set availableArticles(value) {
    this._availableArticles = this.sharedService.deepCopy(value);
  }

  get availableArticles() {
    return this._availableArticles;
  }

  @Output() onNewArticleAdded: EventEmitter<void>;

  @ViewChild('articleModal') articleModal: ArticleModalComponent;

  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public articleTableVisible: boolean = true;
  public newArticle: Article;
  public articles: Article[];
  public dropdownArticle: Article;
  public validationErrors = [];

  private _availableArticles: Article[];

  constructor(
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private confirmationService: ConfirmationService,
    private replaceDotToCommaPipe: ReplaceDotToCommaPipe,
    private sortPipe: Sort
  ) {
    this.onNewArticleAdded = new EventEmitter<void>();
  }

  public ngOnInit(): void {
    this.isRoleSupplier = this.authService.isRoleAuthorized([ROLE_SUPPLIER]);
    this.isRoleCustomer = this.authService.isRoleAuthorized([ROLE_CUSTOMER]);
    this.isRoleHubOperator = this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR]);

    this.articles = this.articles || [];
  }

  public confirmDeleteArticle(articleId: number): void {
    this.confirmationService.confirm({
      key: 'article',
      message: 'Weet u zeker dat u dit artikel wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteArticle(articleId);
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  public updatePackingUnit(event: any, index: number): void {
    if (!event.value.length) {
      event.value.length = 0;
    }

    if (!event.value.width) {
      event.value.width = 0;
    }

    if (!event.value.height) {
      event.value.height = 0;
    }

    this.articles[index].packing_unit = event.value;
    this.articles[index].packing_unit_id = event.value.id;
  }

  public addArticle(event: any): void {
    event.value.amount = 1;
    event.value.index = this.articles.length + 1;

    if (event.value.packing_units.length > 0) {
      const packingUnits = this.sortPipe.transform(event.value.packing_units) as any;

      event.value.packing_unit_id = packingUnits[0].id;
      event.value.packing_unit = { ...packingUnits[0] };

      if (!event.value.packing_unit.length) {
        event.value.packing_unit.length = 0;
      }

      if (!event.value.packing_unit.width) {
        event.value.packing_unit.width = 0;
      }

      if (!event.value.packing_unit.height) {
        event.value.packing_unit.height = 0;
      }
    } else {
      this.sharedService.displayErrorMessage(
        'Geen verpakkingseenheden',
        'Dit artikel heeft nog geen verpakkingseenheden.'
      );
    }

    let articles = [...this.articles];
    articles.push({ ...event.value });
    this.articles = [...articles];

    this.dropdownArticle = new Article();
  }

  public addNewArticle(): void {
    this.newArticle = new Article();
    this.newArticle.is_stockitem = 2;
    this.newArticle.is_active = true;
    this.newArticle.packing_units = [];
    this.newArticle.supplier_id = this.supplierId;
    this.newArticle.packing_units.push(new PackingUnit());

    this.articleModal.showModal();
  }

  public onSaveNewArticle(article: Article): void {
    this.onNewArticleAdded.emit();

    // Add new article to order
    const orderArticle = { ...article };

    orderArticle.amount = 1;

    let self = this;

    orderArticle.packing_units.forEach(function (value: PackingUnit) {
      let optionLabel = value.name;

      if (value.weight && value.length && value.width && value.height) {
        let volume = (value.length * value.width * value.height) / 1000000;
        volume = Math.round(+volume * 100) / 100;
        volume = self.replaceDotToCommaPipe.transform(volume);

        optionLabel += ` (${value.weight}kg, ${value.length}x${value.width}x${value.height}, ${volume}m3)`;
      }

      value.label = optionLabel;
    });

    if (orderArticle.packing_units[0]) {
      orderArticle.packing_unit_id = orderArticle.packing_units[0].id;
      orderArticle.packing_unit = orderArticle.packing_units[0];

      if (!orderArticle.packing_unit.length) {
        orderArticle.packing_unit.length = 0;
      }

      if (!orderArticle.packing_unit.width) {
        orderArticle.packing_unit.width = 0;
      }

      if (!orderArticle.packing_unit.height) {
        orderArticle.packing_unit.height = 0;
      }
    }

    this.dropdownArticle = new Article();

    let articles = [...this.articles];
    articles.push({ ...orderArticle });
    this.articles = [...articles];
  }

  public getVolume(article): string {
    let volume: any = '';

    if (article.packing_unit.volume) {
      volume = article.packing_unit.volume;
    } else if (article.packing_unit.length && article.packing_unit.width && article.packing_unit.height) {
      volume =
        ((((+article.packing_unit.length / 100) * +article.packing_unit.width) / 100) * +article.packing_unit.height) /
        100;
    }

    volume = Math.round(+volume * 100) / 100;
    volume = volume.toString().replace('.', ',');

    return volume;
  }

  private deleteArticle(articleId: number): void {
    const index = this.articles.findIndex(article => article.id === articleId);

    this.articles.splice(index, 1);

    this.articleTableVisible = false;
    setTimeout(() => {
      this.articleTableVisible = true;
    }, 0);
  }
}
