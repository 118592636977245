import { Pipe } from '@angular/core';
import { SelectItem } from 'primeng/primeng';

@Pipe({
  name: 'sort'
})
export class Sort {
  transform(value: SelectItem[]) {
    if (value === null || value === undefined) {
      return null;
    }

    return value.sort((a, b) => {
      if (!a.value || !a.label) {
        return -1;
      }

      if (!b.value || !b.label) {
        return 1;
      }

      a.label = a.label || '';
      b.label = b.label || '';

      if (a.label === 'Adres toevoegen') {
        return -1;
      }

      return a.label.toLowerCase().trim() < b.label.toLowerCase().trim() ? -1 : 1;
    });
  }
}
