import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalState } from '../../../global.state';
import { AuthenticationService, ROLE_DELIVERER } from '../../../services/authentication.service';

import 'style-loader!./baPageTop.scss';

@Component({
  selector: 'ba-page-top',
  templateUrl: './baPageTop.html'
})
export class BaPageTop {
  @Input() isMenuHidden: boolean = false;

  public isScrolled: boolean = false;
  public isMenuCollapsed: boolean = false;
  public isRoleDeliverer: boolean = false;

  constructor(public auth: AuthenticationService,
              private _state: GlobalState,
              private router: Router) {
    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

    this.isRoleDeliverer = auth.isRoleAuthorized([ROLE_DELIVERER]);
  }

  public toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    this._state.notifyDataChanged('menu.isCollapsed', this.isMenuCollapsed);
    return false;
  }

  public scrolledChanged(isScrolled) {
    this.isScrolled = isScrolled;
  }

  public goToProfilePage(event) {
    event.preventDefault();
    let data = this.auth.getUser();
    this.router.navigate([`/pages/manager/users/profile/${data.id}`]);
  }

  public logout(event) {
    event.preventDefault();
    this.auth.logoutUser().subscribe((result) => {
      this.router.navigate(['auth/login']);
    }, (error) => {
      this.router.navigate(['login']);
    });
  }
}
