import { merge as observableMerge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { ClientsService } from '../../../services/clients.service';
import { SharedService } from '../../../services/shared.service';
import { Client } from '../../../models/client.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService, MessageSummary } from '../../../services/message.service';
import { ClientTypesService } from '../../../services/client-types.service';
import { ClientType } from '../../../models/client-type.model';

@Component({
  selector: 'client-add-component',
  templateUrl: './client-add.modal.html',
  styleUrls: ['./client-add.scss']
})
export class ClientAddComponent implements OnInit {

  @ViewChild('addClientModal')
  public addClientModal: CommonModalComponent;

  @ViewChild('houseNo')
  public houseNoField;

  @ViewChild('zipcode')
  public zipcodeField;

  @ViewChild('ncForm')
  public newClientForm;

  @Input()
  public defaults;

  public addressTypeList: SelectItem[] = [];
  public clientTypeItems: SelectItem[] = [];
  public clientTypes: ClientType[] = [];
  public validationErrors: string[] = [];
  public newClient: Client = new Client(true);
  public zipCodeIsValid: boolean = false;
  public loadingZipcodeCheck: boolean = false;
  public days: SelectItem[] = [];

  constructor(
    public sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private clientsService: ClientsService,
    protected clientTypesService: ClientTypesService) {
  }

  public ngOnInit(): void {
    this.sharedService.getEnumOptions('addresses', 'type').subscribe((addressTypes) => {
      this.addressTypeList = this.sharedService.mapObjectToSelectItems(addressTypes);
    });

    this.clientTypesService.list().subscribe(items => {
      this.clientTypeItems = items;
    });

    this.clientTypesService.getClientTypes(null, null, 99999).subscribe(result => {
      this.clientTypes = result.data;
    });

    this.addClientModal.onHidden(() => {
      this.newClientForm.resetForm();
    });

    observableMerge(this.houseNoField.valueChanges, this.zipcodeField.valueChanges).pipe(
      debounceTime(400))
      .subscribe((houseNo) => {
        this.zipCodeIsValid = false;

        if (this.newClient.addresses[0].postcode !== '' && this.newClient.addresses[0].house_number !== '') {
          this.findZipcode();
        }
      });

    this.days = this.sharedService.createDropdownOptions(this.sharedService.getCalendarLocale().dayNames);
  }

  public showModal(): void {
    this.newClient = new Client(true);
    this.newClient.client_code = this.defaults.clientCode;
    this.validationErrors = [];

    this.addClientModal.show();
  }

  public hideModal(): void {
    this.newClientForm.resetForm();
    this.addClientModal.hide();
  }

  public submitClient(form): void {
    if (form.valid && (!!(this.newClient.client_type.code === 'business' && this.newClient.company_name) || this.newClient.client_type.code !== 'business' )) {
      this.mapNewClient();

      this.clientsService.addClient(this.newClient).subscribe((result) => {
          this.parseResults(result);

          this.router.navigate([this.router.url + '/' + this.newClient.id],
            { fragment: 'add-contactpersons', queryParams: { fromAddClient: true } });

          this.hideModal();
        },
        (error: any) => this.handleError(error));
    } else {
      this.handleError(null, 'Controleer uw invoer.');

      if (form.controls['coc-number'] && form.controls['coc-number'].valid === false) {
        this.validationErrors.push('Het KvK-nummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }

      if (form.controls['vat-number'] && form.controls['vat-number'].valid === false) {
        this.validationErrors.push('Het BTW-nummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }

      if (form.controls['iban-number'] && form.controls['iban-number'].valid === false) {
        this.validationErrors.push('Het IBAN-nummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }

      if (form.controls['phone'] && form.controls.phone.valid === false) {
        this.validationErrors.push('Het telefoonnummer is niet correct ingevuld. Graag controleren en aanpassen.');
      }
    }
  }

  public updateNewClient(clientTypeId: number): void {
    if (clientTypeId) {
      const type = this.clientTypes.find(clientType => clientType.id === clientTypeId);
      if (type) {
        this.newClient.client_type.code = type.code;
        this.newClient.client_type.name = type.name;
      }
    }
  }

  private findZipcode(): void {
    this.loadingZipcodeCheck = true;
    let output = this.sharedService.checkZipcode(this.newClient.addresses[0]);

    if (output['success']) {
      this.sharedService.getZipcode(output).subscribe((result) => {
          this.loadingZipcodeCheck = false;

          if (result) {
            this.zipCodeIsValid = true;
            this.newClient.addresses[0].street = result.street;
            this.newClient.addresses[0].location = result.city;
          } else {
            this.handleZipcodeError(true);
          }
        },
        (error: any) => {
        this.handleZipcodeError(error);
      });
    } else {
      this.loadingZipcodeCheck = false;
    }
  }

  private handleZipcodeError(error: boolean | HttpErrorResponse): void {
    if (error) {
      this.loadingZipcodeCheck = false;
      this.zipCodeIsValid = false;
      this.newClient.addresses[0].street = '';
      this.newClient.addresses[0].location = '';
      this.messageService.warning(MessageSummary['ZIPCODE_CHECK'], 'Adres niet gevonden o.b.v. postcode en huisnummer');
    }
  }

  private parseResults(result: Client): void {
    if (result) {
      this.newClient.id = result.id;

      this.messageService.success(MessageSummary['OK'], 'Contactpersoon opgeslagen');
      this.addClientModal.hide();
    } else {
      this.handleError(true);
    }
  }

  private handleError(error: any, message = 'Kan geen nieuwe relatie aanmaken.'): void {
    this.validationErrors = [];

    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        for (let field in result.errors) {
          if (result.errors.hasOwnProperty(field)) {
            result.errors[field].forEach((err) => {
              this.validationErrors.push(err);
            });
          }
        }
      }
    }

    this.messageService.error(MessageSummary['CLIENT'], message);
  }

  private mapNewClient(): void {
    if (this.newClient.addresses[0].country_id === '-1') {
      this.newClient.addresses[0].country_id = '';
    }
  }
}
