import { Injectable } from '@angular/core';
import { Client } from '../models/client.model';
import { BehaviorSubject, Observable } from 'rxjs';

import { Supplier } from '../models/supplier.model';

@Injectable()
export class StateService {
  private _currentClient$ = new BehaviorSubject<Client>(null);
  private _currentSupplier$ = new BehaviorSubject<Supplier>(null);

  public getCurrentSupplier(): Observable<Supplier> {
    return this._currentSupplier$.asObservable();
  }

  public setCurrentSupplier(supplier: Supplier): void {
    this._currentSupplier$.next(supplier);
  }

  public setCurrentClient(client: Client): void {
    this._currentClient$.next(client);
  }
}
