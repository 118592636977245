import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, SelectItem } from 'primeng/primeng';

import { CommonModalComponent } from '../modal/child.modal';
import { Article } from '../../models/article.model';
import { ArticleService } from '../../services/article.service';
import { ArticleOrder } from '../../models/article-order.model';
import { MessageService, MessageSummary } from '../../services/message.service';
import { ReplaceDotToCommaPipe } from '../../pipes/replace-dot-to-comma.pipe';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {
  @ViewChild('articleModal') articleModal: CommonModalComponent;
  @ViewChild('articleForm') articleForm;

  get articles(): Article[] {
    return this._articles;
  }

  @Input()
  set articles(value: Article[]) {
    this._articles = value;

    this.getAvailableArticles();

    this.articleTableVisible = false;

    setTimeout(() => {
      this.articleTableVisible = true;
    }, 0);
  }

  get supplierId(): number {
    return this._supplierId;
  }

  @Input('supplierId')
  set supplierId(value: number) {
    this._supplierId = value;

    if (value) {
      this.getAvailableArticles();
    }
  }

  @Input()
  public readonly: boolean = false;
  @Input()
  public fullWidth: boolean = false;
  @Input()
  public canCreateNewArticle: boolean = true;
  @Input()
  public canEditArticles: boolean = true;
  @Input()
  public canDeleteArticles: boolean = true;
  @Input()
  public scrollToTop: boolean = false;

  @Output() onUpdate: EventEmitter<Article[]>;

  public validationErrors = [];
  public articleTableVisible = true;
  public isNewArticle = true;
  public defaultArticle: Article;
  public selectedArticle: Article;
  public articleOrder: ArticleOrder;
  public availableArticles: SelectItem[];
  public availablePackingUnits: any[];
  private _articles: Article[];
  private _supplierId: number;

  constructor(private activeRoute: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private articleService: ArticleService,
              private confirmationService: ConfirmationService,
              private replaceDotToCommaPipe: ReplaceDotToCommaPipe) {
    this.defaultArticle = new Article();
    this.articleOrder = new ArticleOrder();
    this.availableArticles = [];
    this.onUpdate = new EventEmitter();
  }

  public ngOnInit(): void {
    this.articles = this.articles || [];

    if (this.scrollToTop) {
      this.articleModal.onShown(() => {
        $('.modal').scrollTop(0);
      });
    }

    this.articleModal.onHidden(() => {
      this.articleForm.resetForm();
    });
  }

  public getAvailableArticles(): void {
    if (this.supplierId) {
      this.articleService.list(this.supplierId).subscribe((result: any) => {
        this.availableArticles = result;
      }, (error: any) => this.handleError(error));
    }
  }

  public getAvailablePackingUnits(): void {
    this.articleService.getPackingUnitList(this.articleOrder.article_id).subscribe((result: any) => {
      this.availablePackingUnits = result.packing_units;
    }, (error: any) => this.handleError(error));

    this.articleService.getArticle(this.articleOrder.article_id).subscribe((result: any) => {
      this.selectedArticle = result;
      this.selectedArticle.packing_units = result.packing_units.data;
    }, (error: any) => this.handleError(error));
  }

  public resetDimensionFields(): void {
    this.articleOrder.length = 0;
    this.articleOrder.width = 0;
    this.articleOrder.height = 0;
    this.articleOrder.weight = 0;
  }

  public addArticle(): void {
    this.articleOrder = new ArticleOrder();
    this.isNewArticle = true;
    this.articleModal.show();
  }

  public onChangePackingUnit(event) {
    let packingUnit = this.availablePackingUnits.find(pack => pack.value === event.value);

    this.resetDimensionFields();
    this.articleOrder.amount = 1;

    if (packingUnit) {
      this.articleOrder.packing_unit_id = packingUnit.value;
      this.articleOrder.height = packingUnit.height || 0;
      this.articleOrder.width = packingUnit.width || 0;
      this.articleOrder.length = packingUnit.length || 0;

      if (packingUnit.weight) {
        this.articleOrder.weight = this.replaceDotToCommaPipe.transform(packingUnit.weight / 1000);
      }
    }
  }

  public editArticle(article: Article, event?: any): void {
    if (this.readonly) {
      return;
    }

    if (event !== undefined) {
      if (event.target.classList.contains('fas')) {
        return;
      }
    }

    this.articleOrder = {
      article_id: article.id,
      packing_unit_id: article.packing_unit_id,
      amount: article.amount,
      weight: article.weight,
      length: article.length,
      width: article.width,
      height: article.height
    };

    this.getAvailablePackingUnits();

    this.isNewArticle = false;
    this.articleModal.show();
  }

  public cancelArticle(): void {
    this.articleForm.resetForm();
    this.articleModal.hide();
  }

  public submitArticle(form): void {
    if (form.valid) {
      this.articleService.getArticle(this.articleOrder.article_id).subscribe((result: any) => {
        let data = result;
        const packingUnit = result.packing_units.data.find((unit: any) => {
          return unit.id === this.articleOrder.packing_unit_id;
        });

        let volume: any = (this.articleOrder.length * this.articleOrder.width * this.articleOrder.height) / 1000000;
        volume = Math.round(volume * 100) / 100;
        volume = volume.toString().replace('.', ',');

        data['amount'] = this.articleOrder.amount;
        data['weight'] = this.articleOrder.weight;
        data['length'] = this.articleOrder.length;
        data['width'] = this.articleOrder.width;
        data['height'] = this.articleOrder.height;
        data['volume'] = volume;
        data['packing_unit'] = packingUnit;
        data['packing_unit_id'] = packingUnit.id;
        data['packing_unit_name'] = packingUnit.name;

        if (this.isNewArticle) {
          this.articles.push(data);
        } else {
          this.articles = this.articles.map((article: Article) => {
            if (article.id === this.articleOrder.article_id) {
              return data;
            }

            return article;
          });
        }

        this.onUpdate.emit(this.articles);

        this.articleOrder = new ArticleOrder();

        // Trigger visibility to refresh table
        this.articleTableVisible = false;

        setTimeout(() => {
          this.articleTableVisible = true;
        }, 0);

        this.articleForm.resetForm();
        this.articleModal.hide();
      });
    } else {
      this.handleError(null, 'ARTICLE', 'Vul alle verplichte velden in (*)');
    }
  }

  public confirmDeleteArticle(articleId: number): void {
    this.confirmationService.confirm({
      key: 'article',
      message: 'Weet u zeker dat u dit artikel wilt verwijderen?',
      accept: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });

        this.deleteArticle(articleId);
      },
      reject: () => {
        [].forEach.call(document.querySelectorAll('.ui-dialog-mask'), function (element) {
          element.style.display = 'none';
        });
      }
    });
  }

  private deleteArticle(articleId: number): void {
    const index = this.articles.findIndex(article => article.id === articleId);

    this.articles.splice(index, 1);

    this.onUpdate.emit(this.articles);

    // Trigger visibility to refresh table
    this.articleTableVisible = false;

    setTimeout(() => {
      this.articleTableVisible = true;
    }, 0);
  }

  private handleError(error: any, summary: string = 'ARTICLE', message: string = 'Er is een fout opgetreden.'): void {
    if (error) {
      const result = error.json();

      if (result.message) {
        message = result.message;
      }

      if (result.errors) {
        Object.keys(result.errors).forEach(function (err) {
          message += '<br />' + result.errors[err][0];
        });
      }
    }

    this.messageService.error(MessageSummary[summary], message);

  }
}
