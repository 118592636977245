import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileUpload } from 'primeng/primeng';
import { CommonModalComponent } from '../../../components/modal/child.modal';
import { ArticleService } from '../../../services/article.service';
import { MessageService, MessageSummary } from '../../../services/message.service';

@Component({
  selector: 'app-article-import',
  templateUrl: './article-import.component.html',
  styleUrls: ['./article-import.component.scss']
})
export class ArticleImportComponent {

  @ViewChild('importModal')
  public importModal: CommonModalComponent;

  @ViewChild('fileUpload')
  public fileUpload: FileUpload;

  @Output('onSubmit')
  public onSubmit: EventEmitter<void> = new EventEmitter<void>();

  public validationErrors: string[] = [];

  constructor(private articleService: ArticleService, private messageService: MessageService) {
  }

  public showModal(): void {
    this.importModal.show();
  }

  public handleError(errors: any, message: string = 'Kan geen import uitvoeren.'): void {
    this.validationErrors = [];

    if (errors) {
      this.validationErrors = errors;
    }

    this.messageService.error(MessageSummary['SOMETHING_WENT_WRONG'], message);
  }

  public submitForm(form: FormGroup): void {
    this.validationErrors = [];

    if (form.valid && this.fileUpload.files[0]) {
      this.articleService.import(this.fileUpload.files[0], []).subscribe((response) => {
        if (response.status === false || response.status_code === 400) {
          this.handleError(response.errors);
        } else {
          this.messageService.success(MessageSummary['OK'], 'Import verwerkt');
          this.onSubmit.emit();
          this.importModal.hide();
        }
      }, _ => {
        this.handleError(null, 'Er is een fout opgetreden.');
      });
    } else {
      this.handleError(null, 'Controleer uw invoer.');

      if (this.fileUpload.files.length === 0) {
        this.validationErrors.push('Selecteer een CSV-bestand.');
      }
    }
  }

  public uploadHandler(event: any): void {
    if (!(event.files.length > 0)) {
      return;
    }
  }
}
