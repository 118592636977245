import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { SelectItem } from 'primeng/primeng';

@Injectable()
export class StockingService extends ApiService {

  public getStockings(): Array<SelectItem> {
    return [
      { 'value': 1, 'label': 'Ja' },
      { 'value': 2, 'label': 'Nee' },
      { 'value': 3, 'label': 'Ja en Nee' }
    ];
  }
}

