import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class ImportService extends ApiService {

  public importFiles(
    files: any[],
    type: string,
    fromDate: string,
    untilDate: string,
    selectedSupplierId: string
  ): Observable<any> {
    let url = '';

    if (type === 'zone') {
      url = 'imports/zones';
    } else if (type === 'prijs') {
      url = 'imports/prices';
    } else if (type === 'afmeting') {
      url = 'imports/sizes';
    } else if (type === 'toeslag') {
      url = 'imports/fees';
    } else if (type === 'ooa') {
      url = 'imports/ooas';
    } else {
      url = 'imports';
    }

    const options = {
      'from_date': fromDate,
      'until_date': untilDate,
      'supplier_id': selectedSupplierId
    };

    return this.uploadFile(url, files[0], options);
  }
}

