import { RouterModule, Routes } from '@angular/router';

import { ArticleListComponent } from './article-list/article-list.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';

const ARTICLE_ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ArticleListComponent
      },
      {
        path: ':id',
        component: ArticleDetailComponent
      }
    ]
  }
];

export const ARTICLE_ROUTING = RouterModule.forChild(ARTICLE_ROUTES);
