import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../../../services/order.service';
import { SharedService } from '../../../../services/shared.service';
import {
  AuthenticationService,
  ROLE_CUSTOMER,
  ROLE_HUB_OPERATOR,
  ROLE_SUPPLIER
} from '../../../../services/authentication.service';
import { Order } from '../../../../models/order.model';
import { CommonModalComponent } from '../../../../components/modal/child.modal';
import { Client } from '../../../../models/client.model';
import { SelectItem } from 'primeng/primeng';
import * as moment from 'moment';
import { InvoicesService } from '../../../../services/invoices.service';
import { MessageService, MessageSummary } from '../../../../services/message.service';

@Component({
  selector: 'client-details-orders',
  templateUrl: './client-details-orders.component.html',
  styleUrls: ['./client-details-orders.component.scss']
})
export class ClientDetailsOrdersComponent {
  @Input('client')
  public client: Client;

  @Input('suppliers')
  public suppliers: SelectItem[];

  @ViewChild('exportModal')
  public exportModal: CommonModalComponent;

  public records: Order[] = [];
  public totalRecords: number = 0;
  public loading: boolean = true;
  public exportLoading: boolean = false;
  public orderStatuses: any[] = [];
  public selectedOrderStatus: string;
  public supplierFilter: string;
  public filters: { [key: string]: { value: string | number, matchMode: string } } = {};
  public selectedOrder: Order;
  public displayTable: boolean;
  public booleanList: SelectItem[] = [
    { label: 'Alle', value: '' },
    { label: 'Ja', value: true },
    { label: 'Nee', value: false }
  ];
  public clientId: number;
  public selectedOrderId: number;
  public isRoleCustomer: boolean = false;
  public isRoleSupplier: boolean = false;
  public isRoleHubOperator: boolean = false;
  public readonly: boolean = false;
  public selectedCreatedAtFilter: string;
  public paginationAmount: number = 30;

  constructor(
    public sharedService: SharedService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected orderService: OrderService,
    protected authService: AuthenticationService,
    private messageService: MessageService,
    private invoicesService: InvoicesService) {
  }

  public ngOnInit(): void {
    this.paginationAmount = this.sharedService.getRowsPerPage();

    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.isRoleCustomer = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_SUPPLIER])) {
      this.isRoleSupplier = true;
      this.readonly = true;
    }

    if (this.authService.isRoleAuthorized([ROLE_HUB_OPERATOR])) {
      this.isRoleHubOperator = true;
      this.readonly = false;
    }

    this.route.queryParams.subscribe((params) => {
      if (params['id'] && params['tab'] === 'order') {
        this.selectedOrderId = params['id'];

        if (this.records.length > 0) {
          this.selectedOrder = this.records.find((order) => order.id === +this.selectedOrderId);

          if (this.selectedOrder) {
            this.editOrder(this.selectedOrder.id);
          }

          this.selectedOrderId = null;
        }

      }
    });

    this.filters = {
      client_id: {
        value: this.client.id,
        matchMode: 'equals'
      }
    };

    this.displayTable = true;

    if (this.authService.isRoleAuthorized([ROLE_CUSTOMER])) {
      this.clientId = this.authService.getUser().client_id;
    } else {
      this.clientId = this.client.id;
    }

    this.filters = {
      client_id: {
        value: this.clientId,
        matchMode: 'equals'
      }
    };

    this.sharedService.getEnumOptions('orders', 'status').subscribe((orderStatuses) => {
      this.orderStatuses = [{
        label: 'Alle',
        value: ''
      }];

      for (let key in orderStatuses) {
        if (orderStatuses.hasOwnProperty(key)) {
          this.orderStatuses.push({ label: orderStatuses[key], value: key });
        }
      }
    });
  }

  public updatePaginationAmount(event: any): void {
    this.paginationAmount = event.rows;
    this.sharedService.setRowsPerPage(event.rows);
  }

  public loadData(event: any): void {
    event.filters = Object.assign({
      client_id: {
        value: this.clientId,
        matchMode: 'equals'
      }
    }, event.filters);

    this.orderService.getOrders(event, event.first / event.rows + 1, event.rows).subscribe((result) => {
      this.loading = false;
      this.records = result.data;
      this.totalRecords = result.pagination.total;

      if (this.selectedOrderId) {
        this.selectedOrder = this.records.find((order) => order.id === +this.selectedOrderId);

        if (this.selectedOrder) {
          this.editOrder(this.selectedOrder.id);
        }

        this.selectedOrderId = null;
      }
    });
  }

  public onRowClick(event: any): void {
    if (event !== undefined) {
      if (event.originalEvent.target.classList.contains('fas') || event.originalEvent.target.classList.contains('fa')) {
        return;
      }
    }

    this.selectedOrder = event.data;

    this.editOrder(this.selectedOrder.id);
  }

  public editOrder(orderId: number): void {
    this.sharedService.openNewWindow(`pages/orders/${orderId}`);
  }

  /**
   * Get the export data of the given order range
   *
   * @param data
   */
  public handleExportSubmit(data: { startDate: Date, endDate: Date }): void {
    this.exportLoading = true;

    this.invoicesService.post('invoices', {
      start_date: moment(data.startDate).format('Y-M-D'),
      end_date: moment(data.endDate).format('Y-M-D'),
      client_id: this.client.id
    }).subscribe((invoice: any) => {
      this.exportLoading = false;
      this.exportModal.hide();

      if (invoice.id) {
        this.router.navigate([`/pages/clients/${this.client.id}`], {
          queryParams: {
            tab: 'exported-invoices',
            id: invoice.id
          }
        });
      } else {
        this.messageService.error(MessageSummary['EXPORT'], 'Er zijn geen openstaande factuurregels');
      }
    }, (error) => {
      this.exportLoading = false;
    });
  }
}
