import { Injectable } from '@angular/core';
import { MessageService as PrimeNGMessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/primeng';
import { now } from 'moment';
import { environment } from '../../environments/environment';
import { Hub } from '../models/hub.model';

export enum MessageSummary {
  ARTICLE = 'Artikel',
  BAD_INPUT = 'Foute invoer',
  CLIENT = 'Ontvanger',
  CREATE_USER = 'Gebruiker account aanmaken',
  DELETE_CLIENT_TYPE = 'Type ontvanger verwijderen',
  EXPORT = 'Export',
  EXPORT_FAILED = 'Export mislukt',
  RETRIEVE_ARTICLES = 'Artikelen ophalen',
  RETRIEVE_LOADING_ADDRESSES = 'Laadadressen ophalen',
  HANDHELD_TERMINAL = 'Aflevering',
  HUB = 'Hub',
  HUB_DELETED = 'Hub verwijderd',
  IMPORT_FAILED = 'Importeren mislukt',
  TEST_IMPORT_FAILED = 'Testimport mislukt',
  OK = 'OK',
  PACKING_DELETED = 'Verzendeenheid verwijderd',
  RECOVER_PASSWORD = 'Wachtwoord herstellen',
  REQUIRED_FIELDS = 'Verplichte velden',
  SAVE_ADDRESS = 'Adres opslaan',
  SAVE_ADMINISTRATIVE_DATA = 'Administratieve gegevens opslaan',
  SAVE_CLIENT_TYPE = 'Type ontvanger opslaan',
  SAVE_CONTACT = 'Contactpersoon opslaan',
  SAVE_EXTRA_SERVICE = 'Extra dienst opslaan',
  SAVE_LOADING_AGREEMENT = 'Laadafspraak opslaan',
  SAVE_ORDER = 'Order opslaan',
  SAVE_SHIPMENT = 'Zending opslaan',
  SAVE_SUPPLIER = 'Leverancier opslaan',
  SAVE_TRANSPORTER = 'Vervoerder opslaan',
  SAVE_USER = 'Gebruiker opslaan',
  SAVE_VALUE = 'Waarde opslaan',
  SELECT_FILE = 'Selecteer bestand',
  SERVER_ERROR = 'Server fout',
  SOMETHING_WENT_WRONG = 'Er is iets fout gegaan',
  SUCCESSFUL = 'Successvol',
  ZIPCODE_CHECK = 'Postcode check'
}

enum MessageSeverity {
  SUCCESS = 'succes',
  WARNING = 'warn',
  ERROR = 'error'
}


@Injectable()
export class MessageService {
  public messageLife: number = JSON.parse(
    JSON.stringify(environment.messageTimeout ? environment.messageTimeout : 3000)
  );

  private messageList: Message[];

  constructor(private messageService: PrimeNGMessageService) {
    this.messageList = [];
  }

  /**
   * Show error message
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  public error(title: string, detail: string): void {
    this.message({ severity: MessageSeverity['ERROR'], summary: title, detail: detail });
  }

  /**
   * Show warning
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  public warning(title: string, detail: string): void {
    this.message({ severity: MessageSeverity['WARNING'], summary: title, detail: detail });
  }

  /**
   * Show success message
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  public success(title: string, detail: string): void {
    this.message({ severity: MessageSeverity['SUCCESS'], summary: title, detail: detail });
  }

  /**
   * Add message to message service
   *
   * @return void
   * @param newMessage
   */
  public message(newMessage: Message): void {
    newMessage.id = newMessage.id ? newMessage.id : now();

    // Remove existing matching toast messages to prevent duplicates
    this.messageList.forEach((message: Message) => {
      if (message.summary === newMessage.summary &&
        message.detail === newMessage.detail) {
        this.clearOldMessage(message.id);
      }
    });

    // Remove all existing toast messages with the same Summary before adding success message
    if (newMessage.severity === MessageSeverity['SUCCESS']) {
      this.messageList = this.messageList.filter((message: Message) => message.summary !== newMessage.summary);
    }

    this.messageList.push(newMessage);

    setTimeout(() => {
      this.clearOldMessage(newMessage.id);
    }, this.messageLife);

    this.messageService.add(newMessage);
  }

  /**
   * Display error message based on json error response
   *
   * @param errorResponse
   * @param title
   * @param detail
   *
   * @return void
   */
  public handleError(errorResponse: any, title: string, detail: string = 'Item'): void {
    let errorMessages = '';
    let status = errorResponse.status;

    if (errorResponse.hasOwnProperty('_body')) {
      errorResponse = errorResponse.json();
    }

    if (!errorResponse.status) {
      if (errorResponse.status_code) {
        status = errorResponse.status_code;
      } else if (errorResponse.type === 3) {
        status = 404;
      }
    }

    // Unauthorized status is handled via authError in the api service
    if (status === 401) {
      return;
    }

    switch (status) {
      case 404:
        errorMessages = detail + ' is niet gevonden.';
        break;
      case 500:
        errorMessages = 'Interne fout, neem contact op met de beheerder.';
        break;
      case 422:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (errorResponse.errors.hasOwnProperty(error)) {
              if (error) {
                errorMessages += `${errorResponse.errors[error]}<br />`;
              }
            }
          }
        } else if (errorResponse.message) {
          errorMessages += errorResponse.message;
        } else {
          errorMessages = 'Niet alle velden zijn juist ingevuld.';
        }

        break;
      default:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (errorResponse.errors.hasOwnProperty(error)) {
              if (error) {
                errorMessages += `${errorResponse.errors[error]}<br />`;
              }
            }
          }
        }

        if (errorResponse.message) {
          errorMessages += errorResponse.message;
        }

        break;
    }

    if (errorMessages !== '') {
      this.error(title, errorMessages);
    }
  }

  /**
   * Remove timeout message (as work-a-round because PrimeNg Growl handles this incorrectly)
   *
   * @param id: any The message to remove
   *
   * @return void
   */
  private clearOldMessage(id: string): void {
    if (this.messageList && this.messageList.length > 0 && this.messageList.find((message: Message) => message.id === id)) {
      this.messageList = this.messageList.filter((messageListItem: Message) => {
        return (messageListItem.id !== id);
      });

      this.messageService.clear();
      this.messageService.addAll(this.messageList);
    }
  }
}
